import { User, UserTable } from "@aten/common/dist/models/User";
import { Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { Icons } from "components/Icons/Icons";
import { authUrl } from "config/path";
import cl from "pages/Users/Users.module.scss";
import React from "react";
import { generatePath, Link } from "react-router-dom";

const usersColumnsTable = (): ColumnsType<UserTable> => [
    {
        width: "7%",
        title: "ID",
        dataIndex: "id",
        key: "id",
        render: (_, data) => (
            <Link className={cl.editById} to={generatePath(authUrl.Index.Users.Edit.url, { id: data.id.toString() })}>
                #{data.id}
            </Link>
        ),
    },
    { title: "ФИО", dataIndex: "name", key: "name" },
    { title: "Логин", dataIndex: "login", key: "login" },
    { title: "Роль", dataIndex: "role", key: "role" },
    { title: "E-mail", dataIndex: "email", key: "email" },
    {
        title: "Телефон",
        dataIndex: "phones",
        key: "phones",
        render: (_, data) =>
            data.phones.length > 0 ? (
                data.phones.length > 1 ? (
                    <Tooltip title={data.phones.map((el) => el.phoneValue).join(`, `)}>
                        <span>
                            {data.phones.length > 1 ? `${data.phones[0].phoneValue} ...` : data.phones[0].phoneValue}
                        </span>
                    </Tooltip>
                ) : (
                    data.phones[0].phoneValue
                )
            ) : (
                "Не указан"
            ),
    },
    { title: "Компания", dataIndex: "company", key: "company" },
    { title: "Город / Страна", dataIndex: "location", key: "location" },
    {
        title: "Действия",
        dataIndex: "action",
        key: "action",
        render: (_: any, data: Omit<User, 'role'>) => {
            return (
                <div style={{ display: "flex" }}>
                    <div style={{ marginRight: 10 }}>
                        <Icons.LoginWith onClick={() => console.log("Войти как: ", data.login)} />
                    </div>
                    <div style={{ marginRight: 10 }}>
                        <Link
                            to={generatePath(authUrl.Index.Users.Edit.url, {
                                id: data.id.toString(),
                            })}>
                            <Icons.EditButton />
                        </Link>
                    </div>
                </div>
            );
        },
    },
];

export default usersColumnsTable;
