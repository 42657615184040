import { Roles } from "data/roles";
import { User, UserResponse, UserTable } from "@aten/common/dist/models/User";
import serializer, { Serializer } from "serializers/index";
import { toFullName } from "utils/fullNameConverter";
import { toLocation } from "utils/locationConverter";

const userSerializer: Serializer<UserResponse, User, UserTable> = (users) => {
  return serializer<UserResponse, User, UserTable>(users, (user) => ({
    ...user,
    key: user.id,
    role: Roles[user.role] ,
    name: toFullName({ name: user.name, surname: user.surname, patronymic: user.patronymic }),
    location: toLocation({ city: user.city, country: user.country }),
  }));
};

export default userSerializer;
