import { CategoriesResponse } from "@aten/common/dist/models/Categories";
import { SelectProps } from "antd/lib/select";
import { useMemo } from "react";
import { useGetCategoriesQuery } from "services/categoriesService";

const transformDataCategories = (categories?: CategoriesResponse): SelectProps["options"] =>
    categories?.objects.map((object) => ({
        label: object.title,
        value: object.id,
        key: object.id,
    }));
const useSelectOptions = () => {
    const { data: categories, isLoading } = useGetCategoriesQuery();
    const options = useMemo(() => transformDataCategories(categories) ?? [], [categories]);
    return { options, loading: isLoading };
};

export default useSelectOptions;
