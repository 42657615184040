import { Lesson, LessonEditResponse } from "@aten/common/dist/models/Lesson";
import { LessonVideos } from "@aten/common/dist/models/LessonVideos";
import { Button, Col, Input, message, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { CustomButton } from "components/CustomButton/CustomButton";
import { Icons } from "components/Icons/Icons";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import ModalDelete from "components/Modal/ModalDelete";
import TextEditor from "components/TextEditor/TextEditor";
import { CustomUploadFile, IUploadImages, UploadImages } from "components/UploadImages/UploadImages";
import UploadPicture from "components/UploadPicture/UploadPicture";
import useModal from "hooks/useModal";
import useNavigate from "hooks/useNavigate";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetFileByIdQuery, useGetFilesByIdsQuery } from "services/fileService";
import { useDeleteLessonMutation, useEditLessonMutation, useSetLessonMutation } from "services/lessonService";
import { useDeleteLessonToFileByIdMutation, useSetLessonToFileMutation } from "services/lessonsToFilesService";
import {
    useDeleteLessonVideosMutation,
    useEditLessonVideosMutation,
    useSetLessonVideosMutation,
} from "services/lessonVideosService";
import concatClasses from "utils/concatClasses";
import { pictureUrl } from "utils/fileHelper";
import { lettersValidate, required, urlsValidate } from "utils/getRules";
import spaces from "../../../assets/scss/Space.module.scss";
import Form, { Item, List, useForm } from "../Form";
import c from "./LessonFormEdit.module.scss";

interface LessonFormEditProp {
    data?: Lesson;
}

interface UploadMainImage {
    fileImageId?: number | null;
}

const LessonFormEdit: FC<LessonFormEditProp> = ({ data, ...props }) => {
    const { id } = useParams();
    const [form] = useForm<Lesson | undefined>();
    const { goBack } = useNavigate();
    const { open, close, visible } = useModal();

    const [uploadMainImage, setUploadMainImage] = useState<UploadMainImage>({ fileImageId: null });
    const [fileList, setFileList] = useState<CustomUploadFile[]>([]);

    const [editLesson] = useEditLessonMutation();
    const [setLesson] = useSetLessonMutation();
    const [deleteLesson] = useDeleteLessonMutation();

    const [setLessonVideos] = useSetLessonVideosMutation();
    const [editLessonVideos] = useEditLessonVideosMutation();
    const [deleteLessonVideos] = useDeleteLessonVideosMutation();

    const [setLessonToFile] = useSetLessonToFileMutation();
    const [deleteLessonToFileById] = useDeleteLessonToFileByIdMutation();

    const { data: mainImageLink } = useGetFileByIdQuery(uploadMainImage.fileImageId ?? 0, {
        skip: !uploadMainImage.fileImageId,
    });

    const { data: pictures } = useGetFilesByIdsQuery(data?.picturesIds.join() || "", {
        skip: !data?.picturesIds,
        refetchOnMountOrArgChange: true,
    });

    useEffect(() => {
        setUploadMainImage({
            fileImageId: data?.imageId && data?.imageId,
        });
        setFileList(pictures?.map((picture) => pictureUrl(picture)) ?? []);
        form.setFieldsValue(data);
    }, [data, pictures]);

    const deleteCurrentLesson = () => {
        id &&
            deleteLesson(id).then(() => {
                message.success("Урок удален");
                goBack(0);
            });
        close();
    };

    const addVideo = () => {
        const lessonVideos = form.getFieldValue("videos") ? [...form.getFieldValue("videos"), {}] : [{}];
        form.setFields([
            {
                name: "videos",
                value: lessonVideos,
            },
        ]);
    };

    const updateMainImage = (imageId: number) => {
        setUploadMainImage({ fileImageId: imageId });
        form.setFieldsValue({ ...data, imageId: imageId });
    };

    const onFinish = async (values: Lesson) => {
        const newData = { ...data, ...values, imageId: Number(uploadMainImage.fileImageId) };
        const videos = form.getFieldsValue(["videos"]).videos;
        let response: LessonEditResponse = id ? await editLesson(newData).unwrap() : await setLesson(newData).unwrap();

        fileList &&
            fileList.forEach((image) => {
                if (image.customStatus === "uploading")
                    setLessonToFile({
                        lessonId: response.updatedId || response.addedId,
                        fileId: image.uid,
                    });
            });

        videos &&
            videos.forEach((video: LessonVideos) => {
                const videoData = {
                    ...video,
                    lessonId: response.updatedId || response.addedId,
                };
                video.id ? editLessonVideos(videoData) : setLessonVideos(videoData);
            });
        id ? message.success("Урок отредактирован") : message.success("Урок добавлен");

        goBack();
    };

    const onRemoveImage: IUploadImages["onRemove"] = (file) => {
        deleteLessonToFileById({
            lessonId: id,
            fileId: file.uid,
        }).then(() => {
            setFileList((prevState) => prevState?.filter((f) => f.uid !== file.uid));
        });
    };

    return (
        <Form form={form} data={{ data }} onFinish={onFinish} {...props}>
            <>
                <LayoutWrapper>
                    <Row gutter={[30, 30]} className={c.row}>
                        <Col span={8}>
                            <Button htmlType="submit" type="primary">
                                {id ? "Сохранить изменения" : "Добавить урок"}
                            </Button>
                        </Col>
                        <Col span={8}>
                            <Button onClick={() => goBack(0)}>{"Отменить"}</Button>
                        </Col>
                        {id && (
                            <Col span={8}>
                                <Button className={"buttonRed"} onClick={open}>
                                    Удалить урок
                                </Button>
                            </Col>
                        )}
                    </Row>
                    <Row gutter={[30, 30]} className={concatClasses(spaces.space, spaces.space__end)}>
                        <Col span={8}>
                            <Item
                                name="title"
                                label={<Label type={LabelTypes.Form} label={"Название"} />}
                                rules={[lettersValidate(), required()]}>
                                <Input />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item
                                name="description"
                                label={<Label type={LabelTypes.Form} label={"Краткое описание"} />}
                                rules={[required()]}>
                                <TextArea showCount maxLength={500} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item
                                name="imageId"
                                label={<Label type={LabelTypes.Form} label={"Основное изображение"} />}
                                rules={[required()]}>
                                <UploadPicture type={"large"} action={updateMainImage} url={mainImageLink} />
                            </Item>
                        </Col>
                        <Col span={24}>
                            <Item name="text" label={<Label type={LabelTypes.Form} label={"Полное описание"} />}>
                                <TextEditor
                                    value={form.getFieldValue("text")}
                                    callback={(htmlValue) =>
                                        form.setFieldsValue({
                                            text: htmlValue,
                                        })
                                    }
                                />
                            </Item>
                        </Col>
                    </Row>
                    <div className={c.videoSection}>
                        <Label type={LabelTypes.Form} label={"Видео урока"} />
                        <Row gutter={[30, 30]} className={concatClasses(spaces.space, spaces.space__end, c.rowVideo)}>
                            <Col span={24}>
                                <Row gutter={[30, 30]}>
                                    <List name="videos">
                                        {(fields, { remove }) =>
                                            fields.map((field, idx) => {
                                                return (
                                                    <Col span={8} key={field.key}>
                                                        <div className={c.video_block}>
                                                            <Button
                                                                onClick={() => {
                                                                    deleteLessonVideos(
                                                                        form.getFieldsValue(["videos"]).videos[idx].id
                                                                    ).then(() => remove(field.name));
                                                                }}
                                                                size="small"
                                                                className={c.video_block__close}>
                                                                <Icons.Close />
                                                            </Button>
                                                            <Item
                                                                name={[field.name, "title"]}
                                                                className={c.video_block__block}
                                                                label={
                                                                    <Label
                                                                        type={LabelTypes.Form}
                                                                        label={"Заголовок видео"}
                                                                    />
                                                                }
                                                                rules={[lettersValidate(), required()]}>
                                                                <Input />
                                                            </Item>
                                                            <Item
                                                                name={[field.name, "link"]}
                                                                className={c.video_block__block}
                                                                label={
                                                                    <Label type={LabelTypes.Form} label={"Id видео"} />
                                                                }
                                                                rules={[required()]}>
                                                                <Input />
                                                            </Item>
                                                        </div>
                                                    </Col>
                                                );
                                            })
                                        }
                                    </List>
                                    <Col span={8} className={c.buttonAddContainer}>
                                        <CustomButton
                                            onClick={addVideo}
                                            wrapperClasses={c.buttonAdd}
                                            buttonText={"Добавить"}
                                            type="primary"
                                            icon={<Icons.Plus />}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <div className={c.imagesSection}>
                        <Label type={LabelTypes.Form} label={"Изображения урока"} />
                        <div className={c.imagesUpload}>
                            <UploadImages fileList={fileList} setFileList={setFileList} onRemove={onRemoveImage} />
                        </div>
                    </div>
                </LayoutWrapper>
                <ModalDelete
                    open={visible}
                    onCancel={close}
                    title={`Вы уверены, что хотите удалить урок "${data?.title ?? ""}"`}
                    onOk={deleteCurrentLesson}
                />
            </>
        </Form>
    );
};

export default LessonFormEdit;
