import { QuestionAnswer } from "@aten/common/dist/models/QuestionAnswers";
import { Test, TestEditResponse } from "@aten/common/dist/models/Test";
import { TestQuestion, TestQuestionEditResponse } from "@aten/common/dist/models/TestQuestion";
import { Button, Checkbox, Col, Input, message, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import cl from "assets/scss/Space.module.scss";
import { CustomButton } from "components/CustomButton/CustomButton";
import clButton from "components/CustomButton/CustomButton.module.scss";
import Form, { Item, List, useForm } from "components/Form/Form";
import { Icons } from "components/Icons/Icons";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import ModalDelete from "components/Modal/ModalDelete";
import UploadPicture from "components/UploadPicture/UploadPicture";
import useModal from "hooks/useModal";
import useNavigate from "hooks/useNavigate";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetFileByIdQuery } from "services/fileService";
import {
    useDeleteQuestionAnswerMutation,
    useEditQuestionAnswerMutation,
    useSetQuestionAnswerMutation,
} from "services/questionAnswersService";
import {
    useDeleteTestQuestionMutation,
    useEditTestQuestionMutation,
    useGetTestQuestionByTestIdQuery,
    useSetTestQuestionMutation,
} from "services/questionsServices";
import { useDeleteTestMutation, useEditTestMutation, useSetTestMutation } from "services/testsService";
import concatClasses from "utils/concatClasses";
import { lettersValidate, numbersValidate, required } from "utils/getRules";
import { testDataConverter } from "utils/testDataConverter";
import c from "./TestFormEdit.module.scss";

interface TestFormEditProp {
    data?: Test;
}

interface UploadMainImage {
    fileImageId?: number;
}

const TestFormEdit: FC<TestFormEditProp> = ({ data: test, ...props }) => {
    const { id } = useParams();
    const { goBack } = useNavigate();
    const [form] = useForm<Test | undefined>();
    const { open, close, visible } = useModal();

    const [uploadMainImage, setUploadMainImage] = useState<UploadMainImage>({ fileImageId: 0 });

    const [editTest] = useEditTestMutation();
    const [setTest] = useSetTestMutation();
    const [deleteTest] = useDeleteTestMutation();
    const [deleteTestQuestion] = useDeleteTestQuestionMutation();
    const [editTestQuestion] = useEditTestQuestionMutation();
    const [setTestQuestion] = useSetTestQuestionMutation();
    const [editQuestionAnswer] = useEditQuestionAnswerMutation();
    const [setQuestionAnswer] = useSetQuestionAnswerMutation();
    const [deleteQuestionAnswer] = useDeleteQuestionAnswerMutation();

    const { data: mainImageLink } = useGetFileByIdQuery(uploadMainImage.fileImageId ?? 0, {
        skip: !uploadMainImage.fileImageId,
    });

    const { data: questions } = useGetTestQuestionByTestIdQuery(id || "", {
        skip: !id,
    });

    const convertedTestData = testDataConverter(test, questions);

    useEffect(() => {
        test?.imageId &&
            setUploadMainImage({
                fileImageId: test.imageId,
            });
        form.setFieldsValue(convertedTestData);
    }, [test]);

    const deleteCurrentTest = () => {
        id && deleteTest(id).then(() => goBack(0));
        close();
    };

    const insertTestQuestion = () => {
        const testQuestions = form.getFieldValue("testQuestions") ? [...form.getFieldValue("testQuestions"), {}] : [{}];
        form.setFields([
            {
                name: "testQuestions",
                value: testQuestions,
            },
        ]);
    };

    const insertQuestionAnswer = (index: number) => {
        const testQuestion = form.getFieldValue("testQuestions");

        testQuestion[index].questionAnswers = testQuestion[index].questionAnswers
            ? [...testQuestion[index].questionAnswers, {}]
            : [{}];

        form.setFields([
            {
                name: "testQuestions",
                value: testQuestion,
            },
        ]);
    };

    const updateMainImage = (imageId: number) => {
        setUploadMainImage({ fileImageId: imageId });
        form.setFields([{ name: "imageId", value: imageId }]);
    };

    const onFinish = async (values: Test) => {
        const newData = { ...test, ...values, imageId: Number(uploadMainImage.fileImageId) };
        const questions = form.getFieldsValue(["testQuestions"]).testQuestions;
        let response: TestEditResponse = id ? await editTest(newData).unwrap() : await setTest(newData).unwrap();
        questions &&
            questions.forEach((question: TestQuestion) => {
                const answers: QuestionAnswer[] = question.questionAnswers;
                const questionData = {
                    ...question,
                    testId: response.updatedId || response.addedId,
                };
                responseQuestion(questionData, answers);
            });
        id ? message.success("Тест отредактирован") : message.success("Тест добавлен");

        goBack();
    };

    const responseQuestion = async (questionData: TestQuestion, answers: QuestionAnswer[]) => {
        let response: TestQuestionEditResponse = questionData.id
            ? await editTestQuestion(questionData).unwrap()
            : await setTestQuestion(questionData).unwrap();

        answers?.forEach((answer: QuestionAnswer) => {
            const answerData = {
                ...answer,
                testQuestionId: response.updatedId || response.addedId,
            };
            answer.id ? editQuestionAnswer(answerData) : setQuestionAnswer(answerData);
        });
    };

    return (
        <Form form={form} data={{ test }} onFinish={onFinish} {...props}>
            <>
                <LayoutWrapper>
                    <Row gutter={[30, 30]} className={c.row}>
                        <Col span={8}>
                            <Button htmlType="submit" type="primary">
                                {id ? "Сохранить изменения" : "Добавить тест"}
                            </Button>
                        </Col>
                        <Col span={8}>
                            <Button onClick={() => goBack(0)}>{"Отменить"}</Button>
                        </Col>
                        {id && (
                            <Col span={8}>
                                <Button className={"buttonRed"} onClick={open}>
                                    Удалить тест
                                </Button>
                            </Col>
                        )}
                    </Row>
                    <Row className={c.rowMargin} gutter={[30, 30]}>
                        <Col span={8}>
                            <Item
                                name="title"
                                label={<Label type={LabelTypes.Form}>Название</Label>}
                                rules={[lettersValidate(), required()]}>
                                <Input />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item
                                name="timeLimit"
                                label={<Label type={LabelTypes.Form}>Время на выполнение (в минутах)</Label>}
                                rules={[numbersValidate(), required()]}>
                                <Input type="number" />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item
                                name="fullDescription"
                                label={<Label type={LabelTypes.Form} label={"Описание"} />}
                                rules={[required()]}>
                                <TextArea showCount maxLength={500} />
                            </Item>
                        </Col>
                        {/*<Col span={8}>*/}
                        {/*    <Item*/}
                        {/*        label={<Label type={LabelTypes.Form}>Урок (который необходимо завершить)</Label>}*/}
                        {/*        // name="needLessonToPassId"*/}
                        {/*        rules={[required()]}>*/}
                        {/*        <Select options={lessonsOptions} />*/}
                        {/*    </Item>*/}
                        {/*</Col>*/}
                        <Col span={8}>
                            <Item
                                name="imageId"
                                label={<Label type={LabelTypes.Form} label={"Основное изображение"} />}
                                rules={[required()]}>
                                <UploadPicture type={"large"} action={updateMainImage} url={mainImageLink} />
                            </Item>
                        </Col>
                        {/*<Col span={8}>*/}
                        {/*    <Item*/}
                        {/*        name="repeatPassageNumberOfDays"*/}
                        {/*        label={<Label type={LabelTypes.Form} label={"Количество попыток"} />}*/}
                        {/*        rules={[required()]}>*/}
                        {/*        <Input type="number" />*/}
                        {/*    </Item>*/}
                        {/*</Col>*/}
                    </Row>
                    <Row className={concatClasses(cl.space, cl.space__between)} gutter={[30, 50]}>
                        <Col span={24}>
                            <div className={concatClasses(c.titleH4)}>Вопросы по тесту</div>
                            <List name="testQuestions">
                                {(fields, { remove }) => (
                                    <div className={concatClasses(c.list)}>
                                        {fields.map((field, idxQ) => {
                                            return (
                                                <div className={c.Item} key={field.key}>
                                                    <Button
                                                        size="small"
                                                        onClick={() => {
                                                            deleteTestQuestion(
                                                                form.getFieldsValue(["testQuestions"]).testQuestions[
                                                                    idxQ
                                                                ].id
                                                            ).then(() => remove(field.name));
                                                        }}
                                                        className={c.Item_close}
                                                        icon={<Icons.Close />}></Button>
                                                    <div className={c.Item_props}>
                                                        <div className={c.Item_desc}>
                                                            <div className={c.Item_descItem}>
                                                                <span>Формулировка вопроса</span>
                                                                <Item
                                                                    name={[field.name, "question"]}
                                                                    rules={[lettersValidate(), required()]}>
                                                                    <Input />
                                                                </Item>
                                                            </div>
                                                            <div className={c.Item_descItem}>
                                                                <span>Описание</span>
                                                                <Item
                                                                    name={[field.name, "fullDescription"]}
                                                                    rules={[required()]}>
                                                                    <TextArea showCount maxLength={500} />
                                                                </Item>
                                                            </div>
                                                            <div className={c.Item_descItem}>
                                                                <span>Порядковый номер вопроса</span>
                                                                <Item
                                                                    name={[field.name, "indexNumber"]}
                                                                    rules={[numbersValidate(), required()]}>
                                                                    <Input type="number" />
                                                                </Item>
                                                            </div>
                                                            <div className={c.Item_descItem}>
                                                                <span>Количество баллов за вопрос</span>
                                                                <Item
                                                                    name={[field.name, "score"]}
                                                                    rules={[numbersValidate(), required()]}>
                                                                    <Input type="number" />
                                                                </Item>
                                                            </div>
                                                        </div>
                                                        <div className={c.Item_questions}>
                                                            <div className={c.Item_questionsTitle}>
                                                                Ответы на вопрос
                                                            </div>
                                                            <List name={[field.name, "questionAnswers"]}>
                                                                {(answers, { remove }) => (
                                                                    <div className={c.Item_questionsList}>
                                                                        {answers.map((answer, idxA) => {
                                                                            return (
                                                                                <div
                                                                                    className={c.Item_questionsItem}
                                                                                    key={answer.key}>
                                                                                    <div>
                                                                                        <span
                                                                                            className={
                                                                                                c.Item_questionsItemTitle
                                                                                            }>
                                                                                            Ответ {idxA + 1}
                                                                                        </span>
                                                                                        <Item
                                                                                            name={[
                                                                                                answer.name,
                                                                                                "answer",
                                                                                            ]}
                                                                                            rules={[required()]}>
                                                                                            <Input />
                                                                                        </Item>
                                                                                    </div>
                                                                                    <div className={c.answerDelete}>
                                                                                        <Item
                                                                                            valuePropName="checked"
                                                                                            name={[
                                                                                                answer.name,
                                                                                                "isCorrect",
                                                                                            ]}>
                                                                                            <Checkbox
                                                                                                className={
                                                                                                    c.Item_questionsItemChekbox
                                                                                                }
                                                                                            />
                                                                                        </Item>
                                                                                        <Button
                                                                                            size="small"
                                                                                            onClick={() => {
                                                                                                deleteQuestionAnswer(
                                                                                                    form.getFieldsValue(
                                                                                                        [
                                                                                                            "testQuestions",
                                                                                                        ]
                                                                                                    ).testQuestions[
                                                                                                        idxQ
                                                                                                    ].questionAnswers[
                                                                                                        idxA
                                                                                                    ].id
                                                                                                ).then(() =>
                                                                                                    remove(answer.name)
                                                                                                );
                                                                                            }}
                                                                                            className={c.Item_close}
                                                                                            icon={
                                                                                                <Icons.Close />
                                                                                            }></Button>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                )}
                                                            </List>
                                                        </div>
                                                        <Button
                                                            onClick={() => {
                                                                insertQuestionAnswer(idxQ);
                                                            }}
                                                            size="small"
                                                            className={c.Item_questionsItemButton}
                                                            icon={<Icons.PlusDark />}>
                                                            Добавить ответ
                                                        </Button>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </List>
                        </Col>
                        <Col span={4}>
                            <CustomButton
                                type="primary"
                                onClick={insertTestQuestion}
                                buttonText="Добавить"
                                icon={<Icons.PlusDark />}
                                disabled={false}
                                wrapperClasses={concatClasses(
                                    clButton.addButton_fullWidth,
                                    clButton.addButton_noMargin,
                                    clButton.addButton_primary
                                )}
                            />
                        </Col>
                    </Row>
                </LayoutWrapper>
                <ModalDelete
                    open={visible}
                    onCancel={close}
                    title={`Вы уверены, что хотите удалить тест "${test?.title ?? ""}"`}
                    onOk={deleteCurrentTest}
                />
            </>
        </Form>
    );
};

export default TestFormEdit;
