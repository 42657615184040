import { AtenEvent, EventsResponse } from "@aten/common/dist/models/AtenEvent";
import { FetchBaseQueryMeta } from "@reduxjs/toolkit/query";
import { UrlApi } from "config/api";
import { IPagination } from "hooks/usePagination";
import { commonApi } from "services/commonApiService";
import { downloadBlob, getFileNameFromMeta } from "utils/fileHelper";

export const EventsApi = commonApi.injectEndpoints({
    endpoints: (builder) => ({
        getEvents: builder.query<EventsResponse, { userId?: string; pagination: IPagination; searchParams: string }>({
            query: ({ userId, pagination, searchParams }) => ({
                url: userId ? `${UrlApi.Events}/${userId}` : `${UrlApi.Events}${searchParams}`,
                params: {
                    ...pagination,
                },
            }),
            providesTags: ["Event"],
        }),
        getEventById: builder.query<AtenEvent | undefined, string>({
            query: (id) => `${UrlApi.Events}/${id}`,
            providesTags: ["Event"],
        }),
        newEvent: builder.mutation<string, AtenEvent>({
            query: (body: AtenEvent) => ({
                url: UrlApi.Events,
                method: "POST",
                body,
            }),
            invalidatesTags: ["Event"],
        }),
        deleteEvent: builder.mutation<string, string>({
            query: (id: string) => ({
                url: `${UrlApi.Events}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Event"],
        }),
        updateEvent: builder.mutation<string, AtenEvent>({
            query: (body: AtenEvent) => ({
                url: `${UrlApi.Events}/${body.id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: ["Event"],
        }),

        getEventMemberExcel: builder.mutation<void, { id: string; name: string }>({
            query: (prop: { id: string; name: string }) => ({
                url: `${UrlApi.Events}/DownloadMembersExcel`,
                params: {
                    eventId: prop.id,
                },
                responseHandler: (response) => response.blob(),
            }),
            transformResponse: async (file: Blob, meta: FetchBaseQueryMeta): Promise<void> => {
                downloadBlob({ fileName: getFileNameFromMeta(meta), file });
            },
        }),
    }),
});

export const {
    useGetEventsQuery,
    useGetEventByIdQuery,
    useDeleteEventMutation,
    useUpdateEventMutation,
    useNewEventMutation,
    useGetEventMemberExcelMutation,
} = EventsApi;
