import { Select } from "antd";
import Form, { Item, useForm } from "components/Form/Form";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import Modal, { ModalProps } from "components/Modal/Modal";
import { FC, useEffect } from "react";
import { ModalType } from "utils/getContentAndFuncForDelete";
import { required } from "utils/getRules";
import useGetLessonOptions from "./hooks/useGetLessonOptions";
import useGetTestOptions from "./hooks/useGetTestOptions";

interface ModalAddProps extends ModalProps {
    type: ModalType;
    themeTitle: string;
    onClose: () => void;
    onAdd: (values: ModalResponse) => void;
    lessonsCount: number;
    testsCount: number;
    loading?: boolean;
}

export interface ModalResponse {
    id: number;
    indexNumber: number;
}

const ModalAddToTheme: FC<ModalAddProps> = ({
    type,
    themeTitle,
    onAdd,
    onClose,
    lessonsCount,
    testsCount,
    loading,
    ...props
}) => {
    const [modalForm] = useForm();

    const { lessonsIdOptions, lessonsLabelOptions } = useGetLessonOptions();
    const { testsIdOptions, testsLabelOptions } = useGetTestOptions();

    useEffect(() => {
        modalForm.resetFields();
    }, [lessonsIdOptions, testsIdOptions]);

    const onFinish = () => {
        modalForm.validateFields().then((values: ModalResponse) => {
            onAdd(values);
        });
    };

    const indexNumber = (type: ModalType) => {
        let options = [];
        let allCount = lessonsCount + testsCount;
        if (lessonsCount || !testsCount) {
            for (let i = 1; i <= allCount + 1; i++) {
                options.push({
                    label: i,
                    value: i,
                });
            }
        } else {
            options.push({
                label: 1,
                value: 1,
            });
        }
        // switch (type) {
        //     case "lesson":
        //         if (lessonsCount && lessonsCount > 0) {
        //             for (let i = 1; i <= lessonsCount + 1; i++) {
        //                 options.push({
        //                     label: i,
        //                     value: i,
        //                 });
        //             }
        //         } else {
        //             options.push({
        //                 label: 1,
        //                 value: 1,
        //             });
        //         }
        //         break;
        //     case "test":
        //         if (testsCount && testsCount > 0) {
        //             for (let i = 1; i <= testsCount + 1; i++) {
        //                 options.push({
        //                     label: i,
        //                     value: i,
        //                 });
        //             }
        //         } else {
        //             options.push({
        //                 label: 1,
        //                 value: 1,
        //             });
        //         }
        //         break;
        // }
        return options;
    };

    return (
        <Modal
            onOk={onFinish}
            okButtonText={"Добавить"}
            onCancel={onClose}
            loading={loading}
            title={`Добавление ${type === "lesson" ? "урока" : " теста"} к теме "${themeTitle}"`}
            {...props}>
            <Form form={modalForm}>
                <Item
                    name="id"
                    label={<Label type={LabelTypes.Form} label={type === "lesson" ? "ID урока" : "ID теста"} />}
                    rules={[required()]}>
                    <Select showSearch options={type === "lesson" ? lessonsIdOptions : testsIdOptions}></Select>
                </Item>
                <Item name="id" label={<Label type={LabelTypes.Form} label={"Название"} />} rules={[required()]}>
                    <Select showSearch options={type === "lesson" ? lessonsLabelOptions : testsLabelOptions}></Select>
                </Item>
                <Item
                    name="indexNumber"
                    label={<Label type={LabelTypes.Form} label="Порядковый номер" />}
                    rules={[required()]}>
                    <Select showSearch options={indexNumber(type)}></Select>
                </Item>
            </Form>
        </Modal>
    );
};

export default ModalAddToTheme;
