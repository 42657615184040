import { ProjectTable } from "@aten/common/models/Project";
import { ColumnsType } from "antd/lib/table";
import { Icons } from "components/Icons/Icons";
import TableHeaderController from "components/TableHeaderController/TableHeaderController";
import Tooltip from "components/Tooltip/Tooltip";
import { authUrl } from "config/path";
import { ISort } from "hooks/useSort";
import { DeletingProjectsProps } from "pages/Projects/Projects";
import { generatePath, Link } from "react-router-dom";
import { numToDate } from "utils/dateConverter";
import { getProjectStatus, statusCourseConverter } from "utils/statusTypeConverter";

type ColumnsProps = {
    setDeleteProjects: (props: DeletingProjectsProps) => void;
    setDeleteModal: (isOpen: boolean) => void;
    sort?: ISort;
    handleSortChange?: (sortSetting: ISort) => void;
};

const projectsColumnsTable = (props: ColumnsProps): ColumnsType<ProjectTable> => [
    {
        title: "№ П/П",
        dataIndex: "projectNumber",
        key: "projectNumber",
    },
    {
        title: (
            <TableHeaderController
                title="ID"
                propertyName="id"
                sorting
                sort={props.sort}
                handleSortChange={props.handleSortChange}
            />
        ),
        dataIndex: "id",
        key: "id",
    },
    {
        title: (
            <TableHeaderController
                title="Заказчик"
                propertyName="customerName"
                sorting
                sort={props.sort}
                handleSortChange={props.handleSortChange}
            />
        ),
        dataIndex: "customerName",
        key: "customerName",
        render: (customerName) => <Tooltip title={customerName} children={customerName} />,
    },
    {
        title: (
            <TableHeaderController
                title="Название"
                propertyName="projectName"
                sorting
                sort={props.sort}
                handleSortChange={props.handleSortChange}
            />
        ),
        dataIndex: "projectName",
        key: "projectName",
        render: (projectName) => <Tooltip title={projectName} children={projectName} />,
    },
    {
        title: (
            <TableHeaderController
                title="Дата отгрузки"
                propertyName="shipmentStartDate"
                sorting
                sort={props.sort}
                handleSortChange={props.handleSortChange}
            />
        ),
        dataIndex: "shipmentStartDate",
        key: "shipmentStartDate",
        render: (shipmentStartDate) => numToDate(shipmentStartDate),
    },
    {
        title: (
            <TableHeaderController
                title="Адрес"
                propertyName="customerAddress"
                sorting
                sort={props.sort}
                handleSortChange={props.handleSortChange}
            />
        ),
        dataIndex: "customerAddress",
        key: "customerAddress",
        render: (customerAddress) => <Tooltip title={customerAddress} children={customerAddress} />,
    },
    {
        title: (
            <TableHeaderController
                title="Сумма"
                propertyName="orderSum"
                sorting
                sort={props.sort}
                handleSortChange={props.handleSortChange}
            />
        ),
        dataIndex: "orderSum",
        key: "orderSum",
    },
    {
        title: (
            <TableHeaderController
                title="Дистрибьютор"
                propertyName="distr"
                sorting
                sort={props.sort}
                handleSortChange={props.handleSortChange}
            />
        ),
        dataIndex: "distrName",
        key: "distrName",
    },
    {
        title: (
            <TableHeaderController
                title="Интегратор"
                propertyName="integrator"
                sorting
                sort={props.sort}
                handleSortChange={props.handleSortChange}
            />
        ),
        dataIndex: "integrName",
        key: "integrName",
    },
    {
        title: "Статус",
        dataIndex: "projectStatusId",
        key: "projectStatusId",
        render: (_, data) => getProjectStatus(data.projectStatusId),
    },
    {
        title: "Действия",
        dataIndex: "action",
        key: "action",
        render: (_, data) => {
            return (
                <div style={{ display: "flex" }}>
                    <div style={{ marginRight: 10 }}>
                        <Icons.Delete
                            onClick={() => {
                                props.setDeleteProjects({ id: data.id, title: data.projectName });
                                props.setDeleteModal(true);
                            }}
                        />
                    </div>
                    <div style={{ marginRight: 10 }}>
                        <Link
                            to={generatePath(authUrl.Index.Projects.Edit.url, {
                                id: data.id.toString(),
                            })}>
                            <Icons.EditButton />
                        </Link>
                    </div>
                </div>
            );
        },
    },
];

export default projectsColumnsTable;
