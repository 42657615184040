import { Button, ButtonProps } from "antd";
import React, { FC } from "react";
import concatClasses from "utils/concatClasses";
import cl from "./CustomButton.module.scss";

export interface ICustomButton extends ButtonProps {
  wrapperClasses?: string;
  buttonText?: string;
  icon?: React.ReactNode;
}

export const CustomButton: FC<ICustomButton> = ({
  wrapperClasses = "",
  buttonText,
  icon,
  ...props
}) => {
  return (
    <div className={concatClasses(cl.addButton, wrapperClasses)}>
      <Button icon={icon} {...props}>
        {buttonText}
      </Button>
    </div>
  );
};
