import {
    LessonsToThemes,
    LessonsToThemesResponse,
} from "@aten/common/dist/models/LessonsToThemes";
import { UrlApi } from "config/api";
import { commonApi } from "services/commonApiService";

interface objectQuery {
    lessonId?: string;
    themeId?: string;
}

export const lessonsToThemesApi = commonApi.injectEndpoints({
    endpoints: (build) => ({
        getLessonToThemeId: build.query<LessonsToThemesResponse, objectQuery>({
            query: ({ lessonId, themeId }) =>
                `${UrlApi.LessonsToThemes}?LessonId=${lessonId}&ThemeId=${themeId}`,
            providesTags: ["Theme"],
        }),
        setLessonToTheme: build.mutation<string, LessonsToThemes>({
            query: (data: LessonsToThemes) => ({
                url: UrlApi.LessonsToThemes,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Lesson", "Theme"],
        }),
        deleteLessonToTheme: build.mutation<string, string>({
            query: (id: string) => ({
                url: `${UrlApi.LessonsToThemes}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Lesson", "Theme"],
        }),
    }),
});

export const lessonsToThemesExtendedApi = lessonsToThemesApi.injectEndpoints({
    endpoints: (build) => ({
        deleteLessonToThemeById: build.mutation<string, objectQuery>({
            async queryFn(data, { dispatch }) {
                const lessonToThemeId = await dispatch(
                    lessonsToThemesApi.endpoints.getLessonToThemeId.initiate(
                        data
                    )
                )
                    .unwrap()
                    .then((result) => result.objects[0].id);
                return {
                    data: await dispatch(
                        lessonsToThemesApi.endpoints.deleteLessonToTheme.initiate(
                            lessonToThemeId?.toString() ?? ""
                        )
                    ).unwrap(),
                };
            },
        }),
    }),
});

export const {
    useSetLessonToThemeMutation,
    useDeleteLessonToThemeByIdMutation,
} = lessonsToThemesExtendedApi;
