import usePage from "components/Contexts/PageContext";
import { useCallback } from "react";

interface UseFluidProps {}

const breakpointToFluid = 1200;
const baseVW = 1920;

const _fluid = (
    min: number,
    max: number,
    wCurrent: number,
    wMin: number = breakpointToFluid,
    wMax: number = baseVW
) => {
    const k = (max - min) / (wMax - wMin);
    const b = min - k * wMin;

    return Math.round(k * wCurrent + b);
};

const useFluid = ({}: UseFluidProps = {}) => {
    const { windowSize } = usePage();

    const fluid = useCallback(
        (min: number, max: number, units: string = "px", wMin: number = breakpointToFluid, wMax: number = baseVW) => {
            if (windowSize.width <= wMin) return min;
            if (windowSize.width >= wMax) return max;

            return _fluid(min, max, windowSize.width, wMin, wMax);
        },
        [windowSize.width]
    );

    const getFluidTableHeight = (min: number, max: number) => `calc(100vh - ${fluid(min, max)}px)`;

    return { fluid, getFluidTableHeight };
};

export default useFluid;
