import React from "react";

export enum ProjectStatuses {
    Consideration,
    Opened,
    Closed,
}

export const ProjectStatusesObject = {
    [ProjectStatuses.Consideration]: "На рассмотрении",
    [ProjectStatuses.Opened]: "Открыт",
    [ProjectStatuses.Closed]: "Закрыт",
};
export const getProjectStatus = (id?: ProjectStatuses) => {
    const cont = {
        styles: {
            display: "inline-block",
            color:
                id === ProjectStatuses.Consideration
                    ? "#E9BB18"
                    : id === ProjectStatuses.Opened
                    ? "#6CDC05"
                    : "#DE1111",
            padding: "5px 15px",
            background:
                id === ProjectStatuses.Consideration
                    ? "#FDFAED"
                    : id === ProjectStatuses.Opened
                    ? "#F0FCE6"
                    : "#FCE7EE",
            borderRadius: 4,
        },
    };
    return React.createElement("span", { style: cont.styles }, id ? ProjectStatusesObject[id] : "-");
};

export enum CourseStatuses {
    UnderReview = "UnderReview",
    Rejected = "Rejected",
    VerifiedBy = "VerifiedBy",
}

export const CourseStatusesObject = {
    [CourseStatuses.UnderReview]: "На рассмотрении",
    [CourseStatuses.Rejected]: "Отклонен",
    [CourseStatuses.VerifiedBy]: "Подтвержден",
};

export const statusCourseConverter = (status: CourseStatuses): React.ReactNode => {
    const cont = {
        styles: {
            display: "inline-block",
            color:
                status === CourseStatuses.UnderReview
                    ? "#E9BB18"
                    : status === CourseStatuses.VerifiedBy
                    ? "#6CDC05"
                    : "#DE1111",
            padding: "5px 15px",
            background:
                status === CourseStatuses.UnderReview
                    ? "#FDFAED"
                    : status === CourseStatuses.VerifiedBy
                    ? "#F0FCE6"
                    : "#FCE7EE",
            borderRadius: 4,
        },
        text:
            status === CourseStatuses.UnderReview
                ? CourseStatusesObject[CourseStatuses.UnderReview]
                : status === CourseStatuses.VerifiedBy
                ? CourseStatusesObject[CourseStatuses.VerifiedBy]
                : CourseStatusesObject[CourseStatuses.Rejected],
    };

    return React.createElement("span", { style: cont.styles }, cont.text);
};
