import { message } from "antd";
import { CustomButton } from "components/CustomButton/CustomButton";
import { CustomTable } from "components/CustomTable/CustomTable";
import { Icons } from "components/Icons/Icons";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import ModalDelete from "components/Modal/ModalDelete";
import { authUrl } from "config/path";
import useFluid from "hooks/useFluid";
import useModal from "hooks/useModal";
import useNavigate from "hooks/useNavigate";
import usePagination from "hooks/usePagination";
import CoursesSearchModal from "pages/Courses/CoursesSearchModal";
import style from "pages/Projects/Projects.module.scss";
import { FC, useState } from "react";
import { generatePath, useParams } from "react-router-dom";
import coursesSerializer from "serializers/course";
import { useDeleteCourseMutation, useGetCoursesQuery } from "services/coursesApi";
import tableColumns from "./tableColumns";

export type DeletingCoursesProps = {
    title: string;
    id: string;
};

const UserCourses: FC = () => {
    const { navigate } = useNavigate();
    const { userId } = useParams();
    const { getFluidTableHeight } = useFluid();

    const { pagination, changePagination } = usePagination();
    const { visible: visibleSearch, open: openSearch, close: closeSearch } = useModal();
    const { visible: visibleDelete, open: openDelete, close: closeDelete } = useModal();

    const [deletingCourse, setDeletingCourse] = useState<DeletingCoursesProps>();
    const [searchString, setSearchString] = useState<string>("");

    const { data, isFetching } = useGetCoursesQuery({
        userId: Number(userId),
        IsNeedStatus: true,
        pagination,
        SearchParams: searchString,
    });

    const [deleteCourse] = useDeleteCourseMutation();

    const serializedCourses = coursesSerializer(data);

    const modalDeleteCourse = () => {
        deleteCourse(deletingCourse?.id ?? "").then(() => {
            message.success("Курс удален");
            closeDelete();
        });
    };

    const search = (values: string) => {
        setSearchString(values);
    };

    return (
        <>
            <LayoutWrapper>
                <div className={style.actions}>
                    <CustomButton
                        onClick={() => navigate(generatePath(authUrl.Index.Courses.New.url))}
                        buttonText={"Добавить курс"}
                        type="primary"
                        icon={<Icons.Plus />}
                    />
                    <CustomButton
                        wrapperClasses={style.searchBtn}
                        type="primary"
                        onClick={openSearch}
                        icon={<Icons.Search />}
                    />
                </div>
                <CustomTable
                    columns={tableColumns({
                        setDeletingCourse,
                        openDelete,
                    })}
                    data={serializedCourses}
                    pagination={{
                        total: data?.total,
                        onChange: changePagination,
                    }}
                    scroll={{ y: getFluidTableHeight(310, 398) }}
                    loading={isFetching}
                />
            </LayoutWrapper>
            <ModalDelete
                title={`Вы уверены, что хотите удалить курс "${deletingCourse?.title}"`}
                open={visibleDelete}
                onCancel={closeDelete}
                onOk={modalDeleteCourse}
            />
            <CoursesSearchModal onOk={search} visible={visibleSearch} close={closeSearch} />
        </>
    );
};

export default UserCourses;
