import {Manager, ManagerResponse} from "@aten/common/dist/models/Manager";
import {UrlApi} from "config/api";
import {commonApi} from "services/commonApiService";
import {ManagerToUserApi} from "services/managerToUserService";
import {fullEntityName} from "utils/fullNameConverter";

export const managersApi = commonApi.injectEndpoints({
    endpoints: (builder) => ({
        getManagers: builder.query<ManagerResponse, number | boolean | void>({
            query: (ObjectsCount?: number | boolean) => {
                const params = ObjectsCount ? {
                    params: ObjectsCount
                } : {}
                return {
                    url: UrlApi.Managers,
                    params
                };
            },
            providesTags: ['Managers']
        }),
        getManagerById: builder.query<Manager | undefined, string>({
            query: (id) => {
                return {
                    url: UrlApi.Managers,
                    params: {
                        Id: id,
                    },
                }
            },
            transformResponse: (res: Manager[]) => {
                if (res.length === 0) {
                    return undefined;
                }
                return res[0];
            },
            providesTags: ['Managers']

        }),
        setNewManager: builder.mutation<{ addedId: number }, Manager>({
            query: (body) => ({
                url: `${UrlApi.Managers}`,
                method: "POST",
                body
            })
        })
    }),
});

export const managersExtendedApi = managersApi.injectEndpoints({
    endpoints: (builder) => ({
        getManagersByUserId: builder.query({
            queryFn: async (userId, {dispatch}) => {
                const list = await dispatch(ManagerToUserApi.endpoints.getManagersInUser.initiate(userId, {forceRefetch: true})).unwrap()
                const listWithData = await Promise.all(list?.map(async ({managerId}: any) => await dispatch(managersApi.endpoints.getManagerById.initiate(managerId, {forceRefetch: true})).unwrap().then((res) => res)))
                return {
                    data: {
                        list: listWithData.map(data => ({
                            ...data,
                            value: data?.id.toString()??'',
                            label: fullEntityName(data)
                        })),
                        relations: list
                    }
                }
            },
        })
    }),
})

export const {
    useGetManagersQuery,
    useGetManagerByIdQuery,
    useGetManagersByUserIdQuery,
    useSetNewManagerMutation
} = managersExtendedApi;
