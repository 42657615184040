import { Engineers } from "@aten/common/models/Engineers";
import { EngineersToUsersResponseCollection } from "@aten/common/models/EngineersToUsers";
import { UrlApi } from "config/api";
import { commonApi } from "services/commonApiService";
import { engineersApi } from "services/engineersService";

export const engineersToUsersApi = commonApi.injectEndpoints({
    endpoints: (builder) => ({
        getEngineersToUsers: builder.query<EngineersToUsersResponseCollection, void>({
            query: () => {
                return {
                    url: UrlApi.EngineersToUsers,
                };
            },
            providesTags: ["EngineerToUser"],
        }),
        getEngineerToUsersByIds: builder.query<string[] | undefined, { userId: string; engineerId?: string }>({
            query: ({ userId, engineerId }) => ({
                url: UrlApi.EngineersToUsers,
                params: {
                    userId,
                    engineerId,
                },
            }),
            transformResponse: (res: EngineersToUsersResponseCollection) => {
                if (res.objects.length === 0) {
                    return undefined;
                }
                return res.objects.map(({ engineerId }: { engineerId: number }) => engineerId);
            },
            providesTags: ["EngineerToUser"],
        }),
        setEngineerToUser: builder.mutation<void, { userId: number; engineerId: number }>({
            query: ({ userId, engineerId }) => ({
                url: UrlApi.EngineersToUsers,
                method: "POST",
                body: {
                    userId,
                    engineerId,
                },
            }),
            invalidatesTags: ["EngineerToUser"],
        }),
    }),
});

export const engineersToUsersExtendedApi = engineersToUsersApi.injectEndpoints({
    endpoints: (build) => ({
        getEngineersDataToUsers: build.query<(Engineers | undefined)[], string>({
            async queryFn(userId, { dispatch }) {
                const idList = await dispatch(
                    engineersToUsersApi.endpoints.getEngineerToUsersByIds.initiate({ userId })
                ).unwrap();

                const data = await Promise.all(
                    idList?.map((id) => dispatch(engineersApi.endpoints.getEngineerById.initiate(id)).unwrap()) ?? []
                );

                return {
                    data: data.map((eng) => {
                        if (eng) {
                            return eng;
                        }
                    }),
                };
            },
        }),
    }),
});

export const {
    useGetEngineersToUsersQuery,
    useGetEngineerToUsersByIdsQuery,
    useSetEngineerToUserMutation,
    useGetEngineersDataToUsersQuery,
} = engineersToUsersExtendedApi;
