import { createApi } from "@reduxjs/toolkit/dist/query/react";
import {baseQueryFn} from "./baseQueryFn/baseQueryFn";

export const commonApi = createApi({
    reducerPath: "Api",
    baseQuery: baseQueryFn,
    tagTypes: [
        "Cooperation",
        "ProjectsToManagers",
        "EngineerToProject",
        "EngineerToUser",
        "Models",
        "Managers",
        "ManagersToUser",
        "Advantages",
        "User",
        "Course",
        "Categories",
        "Catalogues",
        "Engineers",
        "EventContacts",
        "Event",
        "File",
        "Lesson",
        "Project",
        "Members",
        "News",
        "Phones",
        "QuestionAnswer",
        "TestQuestion",
        "Test",
        "Theme",
        "ThemeToCourse",
        "Licences",
        "TestToTheme",
        "LessonToTheme",
        "LessonVideos",
        "LessonsToFiles",
        "PriceListCategories",
        "PriceLists",
        "PriceListConstant",
        "priceListPropertySort",
    ],
    endpoints: () => ({}),
});
