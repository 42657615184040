import lessonsSerializer from "serializers/lesson";
import { useGetLessonsQuery } from "services/lessonService";

type useGetLessonOptionsResponse =
    | {
          key: number | undefined;
          value: number | undefined;
          label: string | number | null | undefined;
      }[]
    | undefined;

interface OptionsResponse {
    lessonsIdOptions: useGetLessonOptionsResponse;
    lessonsLabelOptions: useGetLessonOptionsResponse;
}

const useGetLessonOptions = (): OptionsResponse => {
    const { data: lessons } = useGetLessonsQuery();
    const serializedLessons = lessonsSerializer(lessons);

    return {
        lessonsIdOptions: serializedLessons?.map((lesson) => ({
            key: lesson.id,
            value: lesson.id,
            label: lesson.id,
        })),
        lessonsLabelOptions: serializedLessons?.map((lesson) => ({
            key: lesson.id,
            value: lesson.id,
            label: lesson.title,
        })),
    };
};

export default useGetLessonOptions;
