import {Catalogue, CataloguesResponse} from "@aten/common/dist/models/Catalogue";
import {Course, CourseResponse} from "@aten/common/dist/models/Course";
import { UrlApi } from "config/api";
import { commonApi } from "services/commonApiService";


export const cataloguesApi = commonApi.injectEndpoints({
    endpoints: (build) => ({
        getCatalogues: build.query<
            CataloguesResponse,
            { StartIndex: number, ObjectsCount:number, CategoryId?: number | string  }
            >({
            query: ({ StartIndex = 0 , ObjectsCount = 10, CategoryId }) => ({
                url: UrlApi.Catalogues,
                params: {
                    StartIndex,
                    ObjectsCount,
                    CategoryId
                },
            }),
            providesTags: ["Catalogues"],
        }),

        getCataloguesById: build.query<Catalogue, string>({
            query: (id: string) => `${UrlApi.Catalogues}/${id}`,
            providesTags: ["Catalogues"],
        }),
        setCatalogues: build.mutation<string, Catalogue>({
            query: (body: Catalogue) => ({
                url: UrlApi.Catalogues,
                method: "POST",
                body,
            }),
            invalidatesTags: ["Catalogues"],
        }),
        editCatalogues: build.mutation<CataloguesResponse, Catalogue>({
            query: (body: Catalogue) => ({
                url: `${UrlApi.Catalogues}/${body.id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: ["Catalogues"],
        }),
        deleteCatalogues: build.mutation<CataloguesResponse, string>({
            query: (id: string) => ({
                url: `${UrlApi.Catalogues}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Catalogues"],
        }),
    }),
});

export const {
    useEditCataloguesMutation,
    useGetCataloguesByIdQuery,
    useDeleteCataloguesMutation,
    useGetCataloguesQuery,
    useSetCataloguesMutation
} = cataloguesApi;