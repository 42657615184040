import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment/moment";
import stringHelper from "utils/helpers/stringHelper";

dayjs.extend(customParseFormat);

// todo: type it
type TransformFn = (value: any) => any;

const time: TransformFn = (time) => (time ? dayjs(time, "HH:mm:ss") : undefined);

const date: TransformFn = (date) => (date ? moment(date) : undefined);

const type: TransformFn = (type) => stringHelper.convertToString(type);

const formTransform = {
  time,
  date,
  type,
};

export default formTransform;
