// / `1656941064607` -> `14.03.2022`
export const numToDate = (date: number | undefined | null): string => {
  if (!date) {
    return "";
  }

  const d = new Date(date);
  return `${d.getDate().toString().padStart(2, "0")}.${(d.getMonth() + 1)
    .toString()
    .padStart(2, "0")}.${d.getFullYear()}`;
};

export const numToMounthDate = (date: number | undefined | null): string => {
  if (!date) {
    return "";
  }
  const d = new Date(date);
  function getWeekDay(date: number) {
    switch (d.getDay()) {
      case 0:
        return "Вс";
        break;
      case 1:
        return "Пн";
        break;
      case 2:
        return "Вт";
        break;
      case 3:
        return "Ср";
        break;
      case 4:
        return "Чт";
        break;
      case 5:
        return "Пт";
        break;
      case 6:
        return "Сб";
        break;
    }
  }
  
  return ` ${d
    .toLocaleString("ru", {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    })
    .toString()} ${getWeekDay(date)}`;
};
