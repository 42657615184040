import { message } from "antd/es";
import { CustomButton } from "components/CustomButton/CustomButton";
import { CustomTable } from "components/CustomTable/CustomTable";
import { Icons } from "components/Icons/Icons";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import ModalDelete from "components/Modal/ModalDelete";
import { authUrl } from "config/path";
import useFluid from "hooks/useFluid";
import useModal from "hooks/useModal";
import useNavigate from "hooks/useNavigate";
import usePagination from "hooks/usePagination";
import lessonColumnsTable from "pages/Lesson/lessonColumnsTable";
import LessonsModalForm from "pages/Lesson/LessonsModalForm";
import cl from "pages/Theme/Themes.module.scss";
import { useState } from "react";
import { generatePath } from "react-router-dom";
import lessonsSerializer from "serializers/lesson";
import { useDeleteLessonMutation, useGetLessonsQuery } from "services/lessonService";

export type DeletingLessonProps = {
    title: string;
    id: string;
};

const Lessons = () => {
    const { navigate } = useNavigate();
    const { pagination, changePagination } = usePagination();
    const { getFluidTableHeight } = useFluid();

    const { open: openSearch, close: closeSearch, visible: visibleSearch } = useModal();
    const { open: openDelete, close: closeDelete, visible: visibleDelete } = useModal();

    const [searchString, setSearchString] = useState<string>("");
    const [deletingLesson, setDeletingLesson] = useState<DeletingLessonProps>({
        id: "",
        title: "",
    });

    const { data, isLoading, isFetching } = useGetLessonsQuery(
        {
            pagination,
            SearchParams: searchString,
        },
        { refetchOnMountOrArgChange: true }
    );
    const [deleteLesson] = useDeleteLessonMutation();

    const lessons = lessonsSerializer(data);

    const modalDeleteLesson = () => {
        deleteLesson(deletingLesson.id).then(() => {
            message.success("Урок удален");
            closeDelete();
        });
    };

    const search = (values: string) => {
        setSearchString(values);
    };

    return (
        <>
            <LayoutWrapper>
                <div className={cl.actions}>
                    <CustomButton
                        onClick={() => {
                            navigate(generatePath(authUrl.Index.Lessons.New.url));
                        }}
                        buttonText={"Добавить урок"}
                        type="primary"
                        icon={<Icons.Plus />}
                    />
                    <CustomButton
                        wrapperClasses={cl.searchBtn}
                        type="primary"
                        onClick={openSearch}
                        icon={<Icons.Search />}
                    />
                </div>
                <CustomTable
                    columns={lessonColumnsTable({
                        openDelete,
                        setDeletingLesson,
                    })}
                    data={lessons}
                    loading={isLoading || isFetching}
                    paginationItem={{
                        total: data?.total,
                        onChange: changePagination,
                    }}
                    scroll={{ y: getFluidTableHeight(358, 398) }}
                />
            </LayoutWrapper>
            <ModalDelete
                title={`Вы уверены, что хотите удалить урок "${deletingLesson.title}"`}
                open={visibleDelete}
                onCancel={closeDelete}
                onOk={modalDeleteLesson}
            />
            <LessonsModalForm visible={visibleSearch} onOk={search} onCancel={closeSearch} />
        </>
    );
};

export default Lessons;
