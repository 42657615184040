import { TestTable } from "@aten/common/dist/models/Test";
import { ColumnsType } from "antd/lib/table";
import { Icons } from "components/Icons/Icons";
import { authUrl } from "config/path";
import { DeletingTestProps } from "pages/Test/Test";
import { generatePath, Link } from "react-router-dom";

type ColumnsProps = {
    setDeletingTests: (name: DeletingTestProps) => void;
    openDelete: () => void;
};

const testColumnsTable = (props: ColumnsProps): ColumnsType<TestTable> => [
    { width: "5%", title: "№", key: "orderNumber", dataIndex: "orderNumber" },
    { width: "5%", title: "ID", key: "id", dataIndex: "id" },
    { title: "Название", key: "title", dataIndex: "title" },
    { title: "Описание", key: "fullDescription", dataIndex: "fullDescription" },
    {
        width: "10%",
        title: "Действия",
        key: "action",
        dataIndex: "action",
        render: (_, el) => {
            return (
                <>
                    <div style={{ display: "flex" }}>
                        <div style={{ marginRight: 10 }}>
                            <Link
                                to={generatePath(authUrl.Index.Tests.Edit.url, {
                                    id: el.id.toString(),
                                })}>
                                <Icons.EditButton />
                            </Link>
                        </div>
                        <div>
                            <Icons.Delete
                                onClick={() => {
                                    props.setDeletingTests({
                                        id: el.id.toString(),
                                        title: el.title,
                                    });
                                    props.openDelete();
                                }}
                            />
                        </div>
                    </div>
                </>
            );
        },
    },
];

export default testColumnsTable;
