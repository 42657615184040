import { CustomButton, ICustomButton } from "components/CustomButton/CustomButton";
import ModalDelete from "components/Modal/ModalDelete";
import useModal from "hooks/useModal";
import React, { FC } from "react";
import cl from "./CustomButton.module.scss";

interface ButtonDeleteProps extends ICustomButton {
    modalText?: string;
    onOk?: () => void;
    onClose?: () => void;
    modalOnClick?: boolean;
}

const ButtonDelete: FC<ButtonDeleteProps> = ({ modalText, onOk, onClose, modalOnClick, ...props }) => {
    const { visible, open, close } = useModal({});

    const handleOk = () => {
        onOk && onOk();
        close();
    };

    const handleClose = () => {
        onClose && onClose();
        close();
    };

    const handleClick = () => {
        modalOnClick && open();
    };

    return (
        <>
            <CustomButton type="primary" className={cl.red} onClick={handleClick} {...props} />
            {modalOnClick && <ModalDelete open={visible} onOk={handleOk} onCancel={handleClose} title={modalText} />}
        </>
    );
};

export default ButtonDelete;
