import { WindowSize } from "components/Contexts/PageContext";

const getColSpanForCurrentWindow = (windowSize: WindowSize) => {
    if (windowSize.width < 650) return 24;
    if (windowSize.width < 1000) return 12;

    return 8;
};

export default getColSpanForCurrentWindow;
