import {ResponseApi} from "@aten/common/dist/models";
import {UrlApi} from "config/api";
import {commonApi} from "services/commonApiService";

export const ManagerToUserApi = commonApi.injectEndpoints({
    endpoints: (builder) => ({
        getManagersInUser: builder.query<{ id: number, userId: number, managerId: number }[], string>({

            query: (userId) => {
                return {
                    url: UrlApi.ManagersToUsers,
                    params: {
                        userId
                    }
                };
            },
            transformResponse: (res: ResponseApi<{ id: number, userId: number, managerId: number }[]>) => {
                return res.objects
            },
            providesTags: ['ManagersToUser', 'Managers']
        }),
        setManagerToUser: builder.mutation<void, { managerId: number, userId: number }>({
            query: (body) => ({
                url: UrlApi.ManagersToUsers,
                method: "POST",
                body
            })
        })
    })
})
export const {useGetManagersInUserQuery, useSetManagerToUserMutation} = ManagerToUserApi