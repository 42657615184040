import { PriceList } from "@aten/common/models/PriceList";
import { PriceListPropertySortList } from "@aten/common/models/PriceListPropertySort";
import { ColumnGroupType } from "antd/es/table/interface";
import { Icons } from "components/Icons/Icons";
import { getPropertyTypeByColumnName } from "pages/PriceLists/PriceListSort/PriceListSort";
import { DeletingPriceListsProps } from "pages/PriceLists/PriceListsOwn";
import { Key } from "react";
import render from "utils/helpers/render";

export type TypeSort = "string" | "number" | "date" | "boolean" | "notSort";

export interface PriceListColumn extends Omit<ColumnGroupType<PriceList>, "children"> {
    hidden?: boolean;
    typeSort?: TypeSort;
    children?: ColumnGroupType<PriceList>["children"];
}

export type PriceListColumns = PriceListColumn[];

interface GetColumnsArgs {
    isOther?: boolean;
    hiddenKeys?: (Key | undefined)[];
    openDeletePriceModal?: () => void;
    setDeletingPrice?: (value: DeletingPriceListsProps) => void;
    onEdit?: (priceList: Partial<PriceList>) => void;
    propertySortList?: PriceListPropertySortList;
}

const getColumns = ({
    isOther = false,
    hiddenKeys = [],
    openDeletePriceModal,
    setDeletingPrice,
    onEdit,
    propertySortList,
}: GetColumnsArgs = {}): PriceListColumns => {
    const hiddenColumnKeys: (Key | undefined)[] = [...(isOther ? ["action"] : []), ...hiddenKeys];

    const originColumns: PriceListColumns = [
        {
            title: "Действия",
            key: "action",
            typeSort: "notSort",
            width: 105,
            render: (_, record) => {
                return (
                    <div style={{ display: "flex" }}>
                        <div style={{ marginRight: 10 }}>
                            <Icons.Delete
                                onClick={() => {
                                    record.id &&
                                        record.name &&
                                        setDeletingPrice?.({
                                            title: record.name,
                                            id: record.id.toString(),
                                        });
                                    openDeletePriceModal?.();
                                }}
                            />
                        </div>
                        <div style={{ marginRight: 10 }}>
                            <Icons.EditButton onClick={() => onEdit?.(record)} />
                        </div>
                    </div>
                );
            },
        },
        {
            title: "Категория",
            key: "childCategoryName",
            typeSort: "string",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Модель",
            key: "modelName",
            typeSort: "string",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "P/N",
            key: "partNumber",
            typeSort: "string",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Цена_F",
            key: "fcost",
            typeSort: "number",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Цена_DDP",
            key: "ddpcost",
            typeSort: "number",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Цена_MSRP",
            key: "msrpcost",
            typeSort: "number",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Изменение цены за последние 30 дней",
            key: "lastThirtyDaysCostChangesPositive",
            width: 250,
            typeSort: "string",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Name",
            key: "engName",
            typeSort: "string",
            render: (engName) => render.ellipsisText(engName),
        },
        {
            title: "Наименование",
            key: "name",
            typeSort: "string",
            render: (name) => render.ellipsisText(name),
        },
        {
            title: "Ссылка",
            key: "link",
            typeSort: "string",
            render: (link) => render.link(link),
        },
        {
            title: "EOL",
            key: "isEol",
            typeSort: "boolean",
            render: (value) => render.boolValue(value),
        },
        {
            title: "Гарантия",
            key: "guaranteePeriod",
            typeSort: "string",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Дата Изменения",
            key: "changeDate",
            typeSort: "date",
            render: (changeDate) => render.date(changeDate),
        },
        {
            title: "Дата Удаления",
            key: "deletionDate",
            typeSort: "date",
            render: (deletionDate) => render.date(deletionDate),
        },
        {
            title: "EAC",
            key: "isEac",
            typeSort: "boolean",
            render: (value) => render.boolValue(value),
        },
        {
            title: "EAC до",
            key: "isEacbefore",
            typeSort: "boolean",
            render: (value) => render.boolValue(value),
        },
        {
            title: "Нотификация",
            key: "isNotification",
            typeSort: "boolean",
            render: (value) => render.boolValue(value),
        },
        {
            title: "Нотификации до",
            key: "isNotificationBefore",
            typeSort: "boolean",
            render: (value) => render.boolValue(value),
        },
        {
            title: "Length(SU)",
            key: "lengthSu",
            width: 105,
            typeSort: "number",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Width(SU)",
            key: "widthSu",
            width: 105,
            typeSort: "number",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Height(SU)",
            key: "heightSu",
            width: 105,
            typeSort: "number",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Weight(SU)",
            key: "weightSu",
            width: 105,
            typeSort: "number",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Length(SU1)",
            key: "lengthSuone",
            width: 105,
            typeSort: "number",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Width(SU1)",
            key: "widthSuone",
            width: 105,
            typeSort: "number",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Height(SU1)",
            key: "heightSuone",
            width: 105,
            typeSort: "number",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Weight(SU1)",
            key: "weightSuone",
            width: 105,
            typeSort: "number",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Length(BU)",
            key: "lengthBu",
            width: 105,
            typeSort: "number",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Width(BU)",
            key: "widthBu",
            width: 105,
            typeSort: "number",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Height(BU)",
            key: "heightBu",
            width: 105,
            typeSort: "number",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "N,W,(BU)",
            key: "nwbu",
            width: 105,
            typeSort: "number",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "G,W,(BU)",
            key: "gwbu",
            width: 105,
            typeSort: "number",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Base",
            key: "base",
            typeSort: "number",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Length(CAR)",
            key: "lengthCar",
            width: 105,
            typeSort: "number",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Width(CAR)",
            key: "widthCar",
            width: 105,
            typeSort: "number",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Height(CAR)",
            key: "heightCar",
            width: 105,
            typeSort: "number",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "N,W(CAR)",
            key: "nwcar",
            width: 105,
            typeSort: "number",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "G,W,(CAR)",
            key: "gwcar",
            width: 105,
            typeSort: "number",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Страна производства",
            key: "manufacturerCountry",
            typeSort: "string",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Розница",
            key: "isRetal",
            typeSort: "boolean",
            render: (value) => render.boolValue(value),
        },
        {
            title: "Маркетинг",
            key: "isMarketing",
            typeSort: "boolean",
            render: (value) => render.boolValue(value),
        },
    ];

    const sortedColumns: PriceListColumns = isOther
        ? originColumns
        : originColumns
              .map((column) => {
                  const propertyType = getPropertyTypeByColumnName(column.key);
                  const propertySort = propertySortList?.find((ps) => ps.propertyType === propertyType);

                  return {
                      ...column,
                      order: propertySort?.orderNumber,
                      hidden: !(
                          column.key === "action" ||
                          !!propertySortList?.find(
                              (ps) => ps.propertyType === getPropertyTypeByColumnName(column.key) && ps.isShow
                          )
                      ),
                  };
              })
              .sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0));

    return sortedColumns.filter((column) => !hiddenColumnKeys.includes(column?.key));
};

export default getColumns;
