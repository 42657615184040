import { DefaultOptionType } from "antd/lib/select";

const projectStatusOptions: DefaultOptionType[] = [
    { value: 0, label: "На рассмотрении" },
    {
        value: 1,
        label: "Открыт",
    },
    { value: 2, label: "Закрыт" },
];


export default projectStatusOptions;