import { Button, Checkbox, Col, ModalProps, Row } from "antd";

import cl from "assets/scss/Space.module.scss";
import Form, { Item, useForm } from "components/Form/Form";
import Modal from "components/Modal/Modal";
import {fieldsList} from "components/Modal/ModalProjectReport/fieldsList";
import { useProjectExcelDocument } from "pages/Projects/hooks/useProjectExcelDocument";
import { FC, useEffect, useReducer, useState } from "react";
import concatClasses from "utils/concatClasses";
import style from "./ModalProjectReport.module.scss";

interface ModalSearchProps extends ModalProps {
    projectsQuantity: number;
    onCancel: () => void;
}

const initialFieldValues = Object.fromEntries(fieldsList.map(({field})=>([[field], false])))
const ModalSearch: FC<ModalSearchProps> = ({ onOk, onCancel, projectsQuantity, ...props }) => {
    const [form] = useForm();

    const [checkboxState, setCheckboxState] = useState<boolean>(true);

    const [fieldValues, setFieldValues] = useReducer(
        (state: any, newState: any) => ({ ...state, ...newState }),
        initialFieldValues
    );

    const { getSearchObject, excelDownloading, excelDownloaded } = useProjectExcelDocument();

    useEffect(() => {
        form.setFieldsValue(initialFieldValues);
    }, [initialFieldValues]);

    useEffect(() => {
        excelDownloaded && onCancel();
    }, [excelDownloaded]);

    const selectAll = () => {
        Object.keys(initialFieldValues).forEach((key) => {
            initialFieldValues[key as keyof typeof initialFieldValues] = checkboxState;
        });
        setFieldValues(initialFieldValues);
        setCheckboxState((prev) => !prev);
    };

    const footer = () => (
        <div className={style.btns}>
            <Row gutter={30}>
                <Col span={8}>
                    <Button type="primary" onClick={selectAll} className={style.btns__item}>
                        Выбрать все
                    </Button>
                </Col>
                <Col span={8}>
                    <Button onClick={onCancel} className={style.btns__item}>
                        Отменить
                    </Button>
                </Col>
                <Col span={8}>
                    <Button
                        type="primary"
                        onClick={() => {
                            form.setFieldsValue(fieldValues);
                            getSearchObject(fieldValues);
                        }}
                        className={style.btns__item}
                        disabled={!Object.values(fieldValues).find((on) => on === true) || !(projectsQuantity > 0)}
                        loading={excelDownloading}>
                        Выгрузить
                    </Button>
                </Col>
            </Row>
        </div>
    );

    return (
        <Modal width={800} footer={footer()} className={style.wrap} {...props}>
            <Form form={form} className={style.report}>
                <Row className={concatClasses(cl.space)} gutter={[30, 20]}>
                    {fieldsList.map(({field,label}, index)=><Col key={field || index} span={6} className={style.item}>
                        <Item name={field} className={style.item__checkbox}>
                            <Checkbox
                                onClick={() => {
                                    setFieldValues({
                                        [field]: !fieldValues[field],
                                    })
                                    if (field === 'IsIncludeNeedFractionalDelivery') {
                                        setFieldValues({
                                            'IsIncludeFractionalDeliveryDate': !fieldValues[field],
                                        })
                                    }
                                }}
                                checked={fieldValues[field]}
                                disabled={!field}
                            />
                        </Item>
                        <span className={style.item__label}>{label}</span>
                    </Col>)}
                </Row>
            </Form>
        </Modal>
    );
};

export default ModalSearch;
