import { Engineers, EngineersResponse, NewEngineer } from "@aten/common/dist/models/Engineers";
import { UrlApi } from "config/api";
import { commonApi } from "services/commonApiService";

export const engineersApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    getEngineers: builder.query<EngineersResponse, number>({
      query: (ObjectsCount: number) => {
        return {
          url: UrlApi.Engineers,
          params: {
            ObjectsCount,
          },
        };
      },
    }),
    getEngineerById: builder.query<Engineers | undefined, string | number>({
      query: (id) => ({
        url: UrlApi.Engineers,
        params: {
          Id: id,
        },
      }),
      transformResponse: (res: Engineers[]) => {
        if (res.length === 0) {
          return undefined;
        }
        return res[0];
      },
      providesTags:['Project']
    }),
    setEngineer: builder.mutation<{addedId: number}, NewEngineer>({
      query: (props: NewEngineer) => ({
        url: UrlApi.Engineers,
        method: "POST",
        body: props,
      }),
      invalidatesTags: ['Project']
    }),

  }),
});

export const { useGetEngineersQuery, useGetEngineerByIdQuery, useSetEngineerMutation } = engineersApi;
