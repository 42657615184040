import { Catalogue } from "@aten/common/dist/models/Catalogue";
import { Button, Col, Input, message, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import c from "components/Form/CataloguesFormEdit/CataloguesFormEdit.module.scss";
import useSelectOptions from "components/Form/CataloguesFormEdit/hooks/useSelectOptions";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import ModalDelete from "components/Modal/ModalDelete";
import SelectWithAdding from "components/Select/SelectWithAdding";
import UploadPicture from "components/UploadPicture/UploadPicture";
import useModal from "hooks/useModal";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    useDeleteCataloguesMutation,
    useEditCataloguesMutation,
    useSetCataloguesMutation,
} from "services/cataloguesService";
import { useSetCategoriesMutation } from "services/categoriesService";
import { useGetFileByIdQuery } from "services/fileService";
import { required } from "utils/getRules";
import Form, { Item, useForm } from "../Form";

interface CataloguesProps {
    data?: Catalogue;
}

interface UploadFilesType {
    fileImageId?: number;
    fileId?: number;
}

export const CataloguesFormEdit: FC<CataloguesProps> = ({ data, ...props }) => {
    const navigate = useNavigate();
    const [form] = useForm<Catalogue>();

    const [uploadFiles, setUploadFiles] = useState<UploadFilesType>({ fileId: 0, fileImageId: 0 });

    const { options, loading } = useSelectOptions();
    const { visible, open, close } = useModal({});
    const { data: imageLink } = useGetFileByIdQuery(uploadFiles.fileImageId ?? 0, { skip: !uploadFiles.fileImageId });
    const { data: fileLink } = useGetFileByIdQuery(uploadFiles.fileId ?? 0, { skip: !uploadFiles.fileId });

    const [setCatigories, { isLoading }] = useSetCategoriesMutation();
    const [setCatalogues, { isLoading: loadingSetCatalogues }] = useSetCataloguesMutation();
    const [editCatalogues, { isLoading: loadingEditCatalogues }] = useEditCataloguesMutation();
    const [DeleteCataloguesMutation] = useDeleteCataloguesMutation();

    const addCategory = (title?: string) => setCatigories({ title: title ?? "" });

    useEffect(() => {
        data &&
            setUploadFiles({
                fileImageId: data?.imageId,
                fileId: data?.fileId,
            });
        data && form.setFieldsValue(data);
    }, [data]);

    const dataUpdate = async (newData: Catalogue) => {
        data?.id
            ? await editCatalogues({
                  ...data,
                  ...newData,
                  imageId: Number(uploadFiles?.fileImageId),
                  fileId: Number(uploadFiles.fileId),
              }).then(() => {
                  message.success("Каталог отредактирован");
              })
            : await setCatalogues({
                  ...newData,
                  imageId: Number(uploadFiles?.fileImageId),
                  fileId: Number(uploadFiles.fileId),
              }).then(() => {
                  message.success("Каталог добавлен");
              });

        setTimeout(() => {
            navigate(-1);
        }, 1500);
    };

    const imageUpdate = (imageId: number) => {
        setUploadFiles({ ...uploadFiles, fileImageId: imageId });
        form.setFieldsValue({ ...data, imageId: imageId });
    };
    const fileUpdate = (fileId: number) => {
        setUploadFiles({ ...uploadFiles, fileId: fileId });
        form.setFieldsValue({ ...data, fileId: fileId });
    };

    const deleteCatalogues = () => {
        data?.id &&
            DeleteCataloguesMutation(data?.id.toString()).then(() => {
                close();
                navigate(-1);
            });
    };

    return (
        <Form form={form} data={{}} onFinish={dataUpdate} {...props}>
            <>
                <LayoutWrapper>
                    <Row gutter={[30, 30]} className={c.row}>
                        <Col span={8}>
                            <Button
                                loading={loadingSetCatalogues || loadingEditCatalogues}
                                htmlType="submit"
                                type="primary">
                                {data?.id ? "Редактировать Каталог" : "Добавить Каталог"}
                            </Button>
                        </Col>
                        <Col span={8}>
                            <Button onClick={() => navigate(-1)}>
                                {data?.id ? "Отменить Редактирование" : "Отменить Добавление"}
                            </Button>
                        </Col>
                        {data?.id && (
                            <Col span={8}>
                                <Button className={"buttonRed"} onClick={open}>
                                    Удалить каталог
                                </Button>
                            </Col>
                        )}
                    </Row>
                    <Row gutter={[30, 30]}>
                        <Col span={8}>
                            <Item
                                name="title"
                                label={<Label type={LabelTypes.Form} label={"Название Каталога"} />}
                                rules={[required()]}>
                                <Input />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item
                                name="categoryId"
                                rules={[required()]}
                                label={<Label type={LabelTypes.Form} label={"Категория Каталога"} />}>
                                <SelectWithAdding
                                    addLoading={isLoading}
                                    loading={loading}
                                    addEntity={addCategory}
                                    options={options}
                                />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item
                                name="fileId"
                                label={<Label type={LabelTypes.Form} label={"Файл Каталога"} />}
                                rules={[required()]}>
                                <UploadPicture type={"large"} urlFile={fileLink} action={fileUpdate} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item
                                name="fullTextDescription"
                                label={<Label type={LabelTypes.Form} label={"Описание каталога"} />}
                                rules={[required()]}>
                                <TextArea showCount maxLength={500} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item
                                name="imageId"
                                label={<Label type={LabelTypes.Form} label={"Изображение Каталога"} />}
                                rules={[required()]}>
                                <UploadPicture type={"large"} action={imageUpdate} url={imageLink} />
                            </Item>
                        </Col>
                    </Row>
                </LayoutWrapper>
                <ModalDelete
                    open={visible}
                    onOk={() => deleteCatalogues()}
                    onCancel={close}
                    title={`Вы уверены, что хотите удалить каталог "${data?.title ?? ""}"`}
                />
            </>
        </Form>
    );
};
