import { CustomButton } from "components/CustomButton/CustomButton";
import { CustomTable } from "components/CustomTable/CustomTable";
import { Icons } from "components/Icons/Icons";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import ModalDelete from "components/Modal/ModalDelete";
import { authUrl } from "config/path";
import useFluid from "hooks/useFluid";
import useModal from "hooks/useModal";
import usePagination from "hooks/usePagination";

import certificatesColumnsTable from "pages/Advantages/advantagesColumnsTable";
import { FC, useState } from "react";
import { generatePath, Link } from "react-router-dom";
import { advantagesSerializer } from "serializers/advantages";
import { useDeleteAdvantagesMutation, useGetAdvantagesQuery } from "services/advantagesService";
import { Advantages } from "@aten/common/dist/models/Advantages";
import { Checkbox } from "antd";
import Label from "../../components/Label/Label";
import { sassFalse } from "sass";

export type DeletingAdvantagesProps = {
    title: string;
    id: string;
};

export interface roleStatus {
    editing: boolean;
    viewing: boolean;
}

const RolesPage: FC = () => {
    const { pagination, changePagination } = usePagination();
    const advantages = [
        {
            category: "Пользователи",
            admin: {
                editing: true,
                viewing: true,
            },
            proAv: {
                editing: true,
                viewing: true,
            },
            KBM_PDU: {
                editing: true,
                viewing: true,
            },
            teaching: {
                editing: false,
                viewing: true,
            },
            integrator: {
                editing: false,
                viewing: false,
            },
            distributor: {
                editing: false,
                viewing: false,
            },
            fileInfo: {
                id: 5,
                fileType: "Picture",
                link: "Resources/AtenFiles/230b7b38-e758-48dd-95bc-4b7c34eb46d7_Скриншот 05-05-2022 101657.jpg",
            },
            key: 1004,
        },
        {
            category: "Проекты",
            admin: {
                editing: true,
                viewing: true,
            },
            proAv: {
                editing: false,
                viewing: true,
            },
            KBM_PDU: {
                editing: false,
                viewing: true,
            },
            teaching: {
                editing: false,
                viewing: true,
            },
            integrator: {
                editing: true,
                viewing: true,
            },
            distributor: {
                editing: true,
                viewing: true,
            },
            fileInfo: {
                id: 5,
                fileType: "Picture",
                link: "Resources/AtenFiles/230b7b38-e758-48dd-95bc-4b7c34eb46d7_Скриншот 05-05-2022 101657.jpg",
            },
            key: 1004,
        },
        {
            category: "Курсы",
            admin: {
                editing: true,
                viewing: true,
            },
            proAv: {
                editing: true,
                viewing: true,
            },
            KBM_PDU: {
                editing: true,
                viewing: true,
            },
            teaching: {
                editing: true,
                viewing: true,
            },
            integrator: {
                editing: true,
                viewing: true,
            },
            distributor: {
                editing: true,
                viewing: true,
            },
            fileInfo: {
                id: 5,
                fileType: "Picture",
                link: "Resources/AtenFiles/230b7b38-e758-48dd-95bc-4b7c34eb46d7_Скриншот 05-05-2022 101657.jpg",
            },
            key: 1004,
        },
        {
            category: "Темы",
            admin: {
                editing: true,
                viewing: true,
            },
            proAv: {
                editing: true,
                viewing: true,
            },
            KBM_PDU: {
                editing: true,
                viewing: true,
            },
            teaching: {
                editing: true,
                viewing: true,
            },
            integrator: {
                editing: true,
                viewing: true,
            },
            distributor: {
                editing: true,
                viewing: true,
            },
            fileInfo: {
                id: 5,
                fileType: "Picture",
                link: "Resources/AtenFiles/230b7b38-e758-48dd-95bc-4b7c34eb46d7_Скриншот 05-05-2022 101657.jpg",
            },
            key: 1004,
        },
        {
            category: "Тесты",
            admin: {
                editing: true,
                viewing: true,
            },
            proAv: {
                editing: true,
                viewing: true,
            },
            KBM_PDU: {
                editing: true,
                viewing: true,
            },
            teaching: {
                editing: true,
                viewing: true,
            },
            integrator: {
                editing: true,
                viewing: true,
            },
            distributor: {
                editing: true,
                viewing: true,
            },
            fileInfo: {
                id: 5,
                fileType: "Picture",
                link: "Resources/AtenFiles/230b7b38-e758-48dd-95bc-4b7c34eb46d7_Скриншот 05-05-2022 101657.jpg",
            },
            key: 1004,
        },
        {
            category: "Оффлайн мероприятия",
            admin: {
                editing: true,
                viewing: true,
            },
            proAv: {
                editing: false,
                viewing: true,
            },
            KBM_PDU: {
                editing: false,
                viewing: true,
            },
            teaching: {
                editing: true,
                viewing: true,
            },
            integrator: {
                editing: true,
                viewing: true,
            },
            distributor: {
                editing: true,
                viewing: true,
            },
            fileInfo: {
                id: 5,
                fileType: "Picture",
                link: "Resources/AtenFiles/230b7b38-e758-48dd-95bc-4b7c34eb46d7_Скриншот 05-05-2022 101657.jpg",
            },
            key: 1004,
        },
        {
            category: "Сертификаты",
            admin: {
                editing: true,
                viewing: true,
            },
            proAv: {
                editing: false,
                viewing: true,
            },
            KBM_PDU: {
                editing: false,
                viewing: true,
            },
            teaching: {
                editing: false,
                viewing: true,
            },
            integrator: {
                editing: false,
                viewing: false,
            },
            distributor: {
                editing: false,
                viewing: false,
            },
            fileInfo: {
                id: 5,
                fileType: "Picture",
                link: "Resources/AtenFiles/230b7b38-e758-48dd-95bc-4b7c34eb46d7_Скриншот 05-05-2022 101657.jpg",
            },
            key: 1004,
        },
        {
            category: "Каталоги",
            admin: {
                editing: true,
                viewing: true,
            },
            proAv: {
                editing: false,
                viewing: true,
            },
            KBM_PDU: {
                editing: false,
                viewing: true,
            },
            teaching: {
                editing: false,
                viewing: true,
            },
            integrator: {
                editing: false,
                viewing: false,
            },
            distributor: {
                editing: false,
                viewing: false,
            },
            fileInfo: {
                id: 5,
                fileType: "Picture",
                link: "Resources/AtenFiles/230b7b38-e758-48dd-95bc-4b7c34eb46d7_Скриншот 05-05-2022 101657.jpg",
            },
            key: 1004,
        },
        {
            category: "Прайс-листы",
            admin: {
                editing: true,
                viewing: true,
            },
            proAv: {
                editing: true,
                viewing: true,
            },
            KBM_PDU: {
                editing: true,
                viewing: true,
            },
            teaching: {
                editing: false,
                viewing: true,
            },
            integrator: {
                editing: false,
                viewing: false,
            },
            distributor: {
                editing: false,
                viewing: false,
            },
            fileInfo: {
                id: 5,
                fileType: "Picture",
                link: "Resources/AtenFiles/230b7b38-e758-48dd-95bc-4b7c34eb46d7_Скриншот 05-05-2022 101657.jpg",
            },
            key: 1004,
        },
        {
            category: "Новости",
            admin: {
                editing: true,
                viewing: true,
            },
            proAv: {
                editing: true,
                viewing: true,
            },
            KBM_PDU: {
                editing: true,
                viewing: true,
            },
            teaching: {
                editing: true,
                viewing: true,
            },
            integrator: {
                editing: false,
                viewing: false,
            },
            distributor: {
                editing: false,
                viewing: false,
            },
            fileInfo: {
                id: 5,
                fileType: "Picture",
                link: "Resources/AtenFiles/230b7b38-e758-48dd-95bc-4b7c34eb46d7_Скриншот 05-05-2022 101657.jpg",
            },
            key: 1004,
        },
        {
            category: "Сотрудники",
            admin: {
                editing: true,
                viewing: true,
            },
            proAv: {
                editing: true,
                viewing: true,
            },
            KBM_PDU: {
                editing: true,
                viewing: true,
            },
            teaching: {
                editing: true,
                viewing: true,
            },
            integrator: {
                editing: false,
                viewing: false,
            },
            distributor: {
                editing: false,
                viewing: false,
            },
            fileInfo: {
                id: 5,
                fileType: "Picture",
                link: "Resources/AtenFiles/230b7b38-e758-48dd-95bc-4b7c34eb46d7_Скриншот 05-05-2022 101657.jpg",
            },
            key: 1004,
        },
        {
            category: "Преимущества",
            admin: {
                editing: true,
                viewing: true,
            },
            proAv: {
                editing: true,
                viewing: true,
            },
            KBM_PDU: {
                editing: true,
                viewing: true,
            },
            teaching: {
                editing: true,
                viewing: true,
            },
            integrator: {
                editing: false,
                viewing: false,
            },
            distributor: {
                editing: false,
                viewing: false,
            },
            fileInfo: {
                id: 5,
                fileType: "Picture",
                link: "Resources/AtenFiles/230b7b38-e758-48dd-95bc-4b7c34eb46d7_Скриншот 05-05-2022 101657.jpg",
            },
            key: 1004,
        },
        {
            category: "Сотрудничество",
            admin: {
                editing: true,
                viewing: true,
            },
            proAv: {
                editing: true,
                viewing: true,
            },
            KBM_PDU: {
                editing: true,
                viewing: true,
            },
            teaching: {
                editing: true,
                viewing: true,
            },
            integrator: {
                editing: false,
                viewing: false,
            },
            distributor: {
                editing: false,
                viewing: false,
            },
            fileInfo: {
                id: 5,
                fileType: "Picture",
                link: "Resources/AtenFiles/230b7b38-e758-48dd-95bc-4b7c34eb46d7_Скриншот 05-05-2022 101657.jpg",
            },
            key: 1004,
        },
    ];
    const columns = [
        {
            width: "7%",
            title: "Категория",
            dataIndex: "category",
            key: "category",
        },
        {
            title: "Администратор",
            dataIndex: "admin",
            key: "admin",
            render: (arg: roleStatus) => {
                return (
                    <>
                        <Label>
                            <Checkbox checked={arg.editing}>Редактирование</Checkbox>
                        </Label>
                        <Label>
                            <Checkbox checked={arg.viewing}>Просмотр</Checkbox>
                        </Label>
                    </>
                );
            },
        },
        {
            title: "Супервайзер ProAV",
            dataIndex: "proAv",
            key: "proAv",
            render: (arg: roleStatus) => {
                return (
                    <>
                        <Label>
                            <Checkbox checked={arg.editing}>Редактирование</Checkbox>
                        </Label>
                        <Label>
                            <Checkbox checked={arg.viewing}>Просмотр</Checkbox>
                        </Label>
                    </>
                );
            },
        },
        {
            title: "Супервайзер KBM/PDU",
            dataIndex: "KBM_PDU",
            key: "KBM_PDU",
            render: (arg: roleStatus) => {
                return (
                    <>
                        <Label>
                            <Checkbox checked={arg.editing}>Редактирование</Checkbox>
                        </Label>
                        <Label>
                            <Checkbox checked={arg.viewing}>Просмотр</Checkbox>
                        </Label>
                    </>
                );
            },
        },
        {
            title: "Супервайзер обучения",
            dataIndex: "teaching",
            key: "teaching",
            render: (arg: roleStatus) => {
                return (
                    <>
                        <Label>
                            <Checkbox checked={arg.editing}>Редактирование</Checkbox>
                        </Label>
                        <Label>
                            <Checkbox checked={arg.viewing}>Просмотр</Checkbox>
                        </Label>
                    </>
                );
            },
        },
        {
            title: "Интегратор",
            dataIndex: "integrator",
            key: "integrator",
            render: (arg: roleStatus) => {
                return (
                    <>
                        <Label>
                            <Checkbox checked={arg.editing}>Редактирование</Checkbox>
                        </Label>
                        <Label>
                            <Checkbox checked={arg.viewing}>Просмотр</Checkbox>
                        </Label>
                    </>
                );
            },
        },
        {
            title: "Дистрибьютор",
            dataIndex: "distributor",
            key: "distributor",
            render: (arg: roleStatus) => {
                return (
                    <>
                        <Label>
                            <Checkbox checked={arg.editing}>Редактирование</Checkbox>
                        </Label>
                        <Label>
                            <Checkbox checked={arg.viewing}>Просмотр</Checkbox>
                        </Label>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <LayoutWrapper>
                <CustomTable data={advantages} columns={columns} scroll={{ x: 400 }} />
            </LayoutWrapper>
        </>
    );
};

export default RolesPage;
