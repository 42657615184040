import { Input, InputProps } from "antd";
import React, { FC } from "react";
import InputMask from "react-input-mask";

interface TInputWithMask extends InputProps {
  mask: string;
  maskChar?: string;
  formatChars?: {
    [key: string]: string;
  };
  alwaysShowMask?: boolean;
  inputRef?: () => void;
}

const InputWithMask: FC<TInputWithMask> = ({ mask, maskChar, formatChars, inputRef, alwaysShowMask, ...props }) => {
  return (
    <InputMask mask={mask} value={props.value} onChange={props.onChange} disabled={props.disabled}>
      {(inputProps: InputProps) => <Input {...inputProps} disabled={props.disabled} />}
    </InputMask>
  );
};

export default InputWithMask;
