import React, {FC} from 'react';
import s from './Title.module.scss'

const Title: FC<{ title: string }> = ({title, children}) => {
  return (
    <div className={s.tableHead}>
      <div className={s.tableHead_title}>{title}</div>
      {children}
    </div>
  );
};

export default Title;