import { Course, CourseResponse, CourseTable } from "@aten/common/dist/models/Course";
import serializer, { Serializer } from "serializers/index";

const coursesSerializer: Serializer<CourseResponse, Course, CourseTable> = (courses) => {
    return serializer<CourseResponse, Course, CourseTable>(courses, (cour) => ({
        ...cour,
        key: cour.id,
    }));
};

export default coursesSerializer;
