import { AtenEvent, EventsResponse, EventsTable } from "@aten/common/dist/models/AtenEvent";
import serializer, { Serializer } from "serializers";

export const eventsSerializer: Serializer<EventsResponse, AtenEvent, EventsTable> = (
  data: EventsResponse | undefined
) => {
  return serializer<EventsResponse, AtenEvent, EventsTable>(data, (event) => ({
    ...event,
    key: event.id,
  }));
};
