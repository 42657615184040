import moment from "moment/moment";
import {getProjectStatus} from "utils/statusTypeConverter";
export const download = (callback: (a: any) => void, data: any,
                         userDistr?: any,
                         userSi?: any) => {
    const di = userDistr?.objects?.find(({id}: any) => id === data?.distrId)
    const intr = userSi?.objects?.find(({id}: any) => id === data?.integratorId)
    callback({
        projectTypeId: data?.projectTypeId,
        deliveryCountry: di?.country,
        distr: `${di?.surname ?? ''} ${di?.name ?? ''} ${di?.patronymic ?? ''}`,
        managers: [...(data?.managerDistList ?? []), ...(data?.managerIntegrList ?? [])]
            .map(({surname, name, patronymic, email, phone}) => `${surname} ${name} ${patronymic} ${email} ${phone}\n`)
            .join(''),
        integrator: `${intr?.surname ?? ''} ${intr?.name ?? ''} ${intr?.patronymic ?? ''}`,
        engineers: (data?.engineerList ?? [])
            .map(({surname, name, patronymic, email, phone}: any) => `${surname} ${name} ${patronymic} ${email} ${phone}\n`)
            .join(''),
        projectName: data?.projectName,
        customerName: data?.customerName,
        customerSite: data?.customerSite,
        customerAddress: data?.customerAddress,
        objectAddress: data?.objectAddress,
        shortInstallationDescription: data?.shortInstallationDescription,
        makingDecisionDate: moment(data?.makingDecisionDate).format('DD MM YYYY'),
        supplyEquipmentDate: moment(data?.supplyEquipmentDate).format('DD MM YYYY'),
        projectStatusId: getProjectStatus(data?.projectStatusId),
        vendors: data?.vendors,
        needFractionalDelivery: data?.supplyEquipmentDate ? 'Да' : 'Нет',
        fractionalDeliveryDate: data?.supplyEquipmentDate ? moment(data?.supplyEquipmentDate).format('DD MM YYYY') : '-',
        orderSum: data?.orderSum,
        models: (data?.modelsList ?? [])
            .map(({modelName, name, price, quantity, worth}: any) => `${modelName} ${name} ${price} ${quantity} ${worth}\n`)
            .join(''),
        comments: data?.comments,
    })
}