import { CheckOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Loader from "components/Loader/Loader";
import { urlApi, UrlApi } from "config/api";
import { createRef, FC, useState } from "react";
import { useSelector } from "react-redux";

import cl from "./UploaderPicture.module.scss";

interface UploadPictureProps {
    type?: "origin" | "large";
    url?: string;
    urlFile?: string;
    action?: (avatarId: number) => void;
    loading?: boolean;
}

const UploadPicture: FC<UploadPictureProps> = ({ url, action, loading, urlFile }) => {
    const [loadingImg, setLoadingImg] = useState<boolean | undefined>();
    const token = useSelector((st: any) => st.auth.token);
    const inputRef = createRef<HTMLInputElement>();

    const clickOnInput = () => inputRef.current?.click();

    return (
        <div className={url || loading ? cl.uploaderWrap__large : cl.uploaderWrap__emp}>
            <input
                type="file"
                ref={inputRef}
                onChange={(inp: any) => {
                    let body = new FormData();
                    body.append("uploadedFile", inp.target.files[0]);
                    setLoadingImg(true);
                    fetch(`${urlApi}${UrlApi.Files}`, {
                        method: "POST",
                        headers: {
                            Authorization: token,
                        },
                        body,
                    })
                        .then((res) => {
                            setLoadingImg(false);
                            return res.json();
                        })
                        .then((id) => action && action(id.addedId))
                        .catch(() => {
                            setLoadingImg(false);
                        });
                }}
            />
            {/*
            {url ? (
                <>
                    <img className={cl.avatar} src={process.env.REACT_APP_API_SERVER + "/" + url} alt={"avatar"} />
                    <Button className={cl.reset} type="primary" onClick={clickOnInput}>
                        Заменить
                    </Button>
                </>
            ) : urlFile ? (
                <>
                    <Button className={cl.reset} type="primary" onClick={clickOnInput}>
                        <CheckOutlined style={{ color: "#40a126", marginRight: 10 }} />
                        Заменить
                    </Button>
                </>
            ) : (
                <Button className={cl.reset} type="primary" onClick={clickOnInput}>
                    Добавить
                </Button>
            )} */}

            <>
                {loadingImg || loading ? (
                    <Loader />
                ) : (
                    <img className={cl.avatar} src={process.env.REACT_APP_API_SERVER + "/" + url} alt={"avatar"} />
                )}
                <Button className={cl.reset} type="primary" onClick={clickOnInput}>
                    {url ? (
                        "Заменить"
                    ) : urlFile ? (
                        <>
                            <CheckOutlined style={{ color: "#40a126", marginRight: 10 }} /> Заменить
                        </>
                    ) : (
                        "Добавить"
                    )}
                </Button>
            </>
        </div>
    );
};

export default UploadPicture;
