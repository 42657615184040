import { Test, TestResponse, TestTable } from "@aten/common/dist/models/Test";
import serializer, { Serializer } from "serializers/index";

const testSerializer: Serializer<TestResponse, Test, TestTable> = (tests) => {
  return serializer<TestResponse, Test, TestTable>(tests, (les, i) => ({
    ...les,
    orderNumber: ++i,
    key: les.id,
  }));
};

export default testSerializer;
