import { Select } from "antd";
import { DefaultOptionType } from "rc-select/es/Select";
import React, { FC } from "react";
import { SelectDefaultProps } from "types/select";

const SelectDefault: FC<SelectDefaultProps> = ({
  allowClear = true,
  showSearch = false,
  filterOption,
  options,
  ...props
}) => {
  const defaultFilterOption = (input: string, option: DefaultOptionType | undefined): boolean =>
    option?.label?.toString().toLowerCase().includes(input.toLowerCase()) ?? false;

  return (
    <Select
      showSearch={showSearch}
      filterOption={showSearch ? filterOption ?? defaultFilterOption : false}
      options={options}
      allowClear={allowClear}
      {...props}
    />
  );
};

export default SelectDefault;
