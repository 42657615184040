import { FC, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

let modules = {
    toolbar: [
        [{ header: [1, 2, 3, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ["link"],
        ["image"],
    ],
};

let modulesWithoutImages = {
    toolbar: [
        [{ header: [1, 2, 3, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ["link"],
    ],
};

let formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
];

interface TextEditorProps {
    value?: string;
    callback?: (htmlValue: string) => void;
    imageAllow?: boolean;
    quillClass?: string;
}

const TextEditor: FC<TextEditorProps> = ({ value, callback, imageAllow = true, quillClass }) => {
    const [currentValue, setCurrentValue] = useState<string | undefined>("");

    useEffect(() => {
        value && setCurrentValue(value);
    }, [value]);

    useEffect(() => {
        currentValue && callback && callback(currentValue);
    }, [currentValue]);

    return (
        <ReactQuill
            className={quillClass ?? ""}
            modules={imageAllow ? modules : modulesWithoutImages}
            formats={imageAllow ? formats : formats.slice(0, -1)}
            value={currentValue}
            onChange={(e) => setCurrentValue(e)}
        />
    );
};

export default TextEditor;
