import { Form as AForm } from "antd";
import Label from "components/Label/Label";
import Loader from "components/Loader/Loader";
import { StoreValue } from "rc-field-form/lib/interface";
import React, { ReactElement } from "react";
import { RequireOnlyOne } from "types";
import { FormItem, IFormProps } from "types/form";
import concatClasses from "utils/concatClasses";
import style from "./Form.module.scss";

interface IFormItem extends Omit<FormItem, "key"> {
    prefix?: string;
    postfix?: string;
}

interface FormProps<DataType> extends Omit<IFormProps<DataType>, "data"> {
    formItems?: FormItem[];
    children?: ReactElement | ReactElement[];
    data?: DataType;
}

type TFormProps<DataType> = RequireOnlyOne<FormProps<DataType>, "formItems" | "children">;

const Form = <DataType extends Record<string, StoreValue> | undefined>({
    data,
    isLoading,
    formItems,
    children,
    onFinish,
    form,
    ...props
}: TFormProps<DataType>): JSX.Element => {
    if (isLoading) {
        return <Loader />;
    }

    return (
        <AForm form={form} initialValues={data} onFinish={onFinish} layout="vertical" requiredMark={false} {...props}>
            {formItems &&
                formItems.map((item) => (
                    <AForm.Item key={item.key} label={<Label>{item.label}</Label>} rules={item.rules} name={item.name}>
                        {item.children}
                    </AForm.Item>
                ))}
            {children}
        </AForm>
    );
};

const AFormItem = ({ prefix, postfix, label, ...props }: IFormItem): JSX.Element => {
    return (
        <div className={style.formItem_wrapper}>
            {label}
            <div className={concatClasses(style.formItem, !!label && style.formItem_label)}>
                {prefix && <span className={style.formItem_extra}>{prefix}</span>}
                <AForm.Item {...props} />
                {postfix && <span className={style.formItem_extra}>{postfix}</span>}
            </div>
        </div>
    );
};

export default Form;
export const Item = AForm.Item;
export const CustomItem = AFormItem;
export const List = AForm.List;
export const useForm = AForm.useForm;
