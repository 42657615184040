import { useState } from "react";

interface UseModalProps {
    initVisible?: boolean;
}

interface UseModalResponse {
    visible: boolean;
    open: () => void;
    close: () => void;
}

const useModal = ({ initVisible }: UseModalProps = {}): UseModalResponse => {
    const [visible, setVisible] = useState(initVisible ?? false);

    const close = () => {
        setVisible(false);
    };

    const open = () => {
        setVisible(true);
    };

    return {
        visible,
        open,
        close,
    };
};

export default useModal;
