import { Table } from "antd/es";

interface GetColumnsArgs {}

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

type PriceListSortColumns = (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
})[];

const getColumns = ({}: GetColumnsArgs = {}): PriceListSortColumns => {
    return [
        {
            title: "Нумерация",
            dataIndex: "number",
            key: "number",
            width: 100,
            render: (_, r, index) => <span>{index + 1}</span>,
        },
        {
            title: "Название",
            dataIndex: "title",
            key: "title",
        },
    ];
};

export default getColumns;
