import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Empty, Pagination, TableProps } from "antd";
import { itemRender } from "components/CustomTable/CustomTable";
import Loader from "components/Loader/Loader";
import PriceListsCollapse from "pages/PriceLists/PriceListsTable/PriceListsCollapse";
import { PriceListColumn, PriceListColumns } from "pages/PriceLists/utils/getColumns";
import { Key, useEffect, useRef, useState } from "react";
import concatClasses from "utils/concatClasses";
import style from "./PriceListsTable.module.scss";

export interface PriceListsItem<T> {
    parentCategory: string;
    priceLists: T[];
}

export interface PriceListsTableType<T extends object> {
    data?: PriceListsItem<T>[];
    total?: number;
}

type TableScroll<T> = TableProps<T>["scroll"] & {
    x: Key;
};

interface PriceListsTableProps<T extends object, U extends object> {
    dataSource?: T[];
    pagination?: TableProps<T>["pagination"];
    columns?: PriceListColumns;
    scroll?: TableScroll<T>;
    loading?: boolean;
    isOther?: boolean;
    searchParams?: string;
    setMainLoading?: (value: boolean) => void;
}

export interface TableWrapper {
    width: number | undefined;
    height: number | undefined;
}

export interface ISort {
    column?: PriceListColumn;
    order?: "asc" | "desc";
}

export const calculateWidthByColumns = (columns: PriceListColumns, defaultWidth: number = 200) => {
    return (
        columns
            ?.filter((c) => !c.hidden)
            .reduce(
                (result, current) =>
                    result + Number(isNaN(Number(current.width)) ? defaultWidth : Number(current.width) - 30),
                0
            ) ?? undefined
    );
};

const PriceListsTable = <T extends object, U extends object>({
    dataSource,
    columns,
    pagination,
    scroll,
    loading,
    isOther = true,
    searchParams,
    setMainLoading,
}: PriceListsTableProps<T, U>) => {
    const tableCellWidth = (Number(Number(100 / (columns?.length ?? 1)).toFixed(3)) * Number(scroll?.x)) / 100;
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [wrapper, setWrapper] = useState<TableWrapper>({
        width: undefined,
        height: undefined,
    });
    const [sort, setSort] = useState<ISort>({
        column: undefined,
        order: undefined,
    });

    useEffect(() => {
        if (wrapperRef.current) {
            const tableBody = wrapperRef.current.querySelector(`.${style.table_tbody}`);
            const tableHead = wrapperRef.current.querySelector(`.${style.table_thead}`);

            setWrapper({
                width: wrapperRef.current?.offsetWidth,
                height:
                    (tableBody?.clientHeight ?? 0) > wrapperRef.current?.offsetHeight
                        ? wrapperRef.current?.offsetHeight - (tableHead?.clientHeight ?? 0)
                        : tableBody?.clientHeight,
            });
        }
    }, [wrapperRef.current]);

    const clickOnColumn = (column: PriceListColumn) => {
        if (column.key !== "action") {
            if (sort.column !== column)
                setSort({
                    column: column,
                    order: "asc",
                });
            else {
                sort.order === "asc"
                    ? setSort({ column: column, order: "desc" })
                    : sort.order === "desc"
                    ? setSort({ column: column, order: undefined })
                    : setSort({ column: column, order: "asc" });
            }
        }
    };

    const checkAscStatus = (column: PriceListColumn) => {
        if (column.key === sort.column?.key && sort.order === "asc") return style.table_thead_cell_arrows_item_active;
    };

    const checkDescStatus = (column: PriceListColumn) => {
        if (column.key === sort.column?.key && sort.order === "desc") return style.table_thead_cell_arrows_item_active;
    };

    return (
        <div className={style.wrapper_table}>
            <div
                className={concatClasses(style.wrapper, loading && style.wrapper_loading)}
                ref={wrapperRef}
                style={{ height: scroll?.y }}>
                <div className={style.table} style={{ width: "fit-content", minWidth: "100%" }}>
                    <div className={style.table_thead}>
                        {columns?.map((column, index) => (
                            <div
                                onClick={() => clickOnColumn(column)}
                                key={index}
                                className={concatClasses(style.table_cell, style.table_thead_cell)}
                                style={{
                                    width: column?.width ?? tableCellWidth,
                                    maxWidth: column?.width ?? tableCellWidth,
                                    minWidth: column?.width ?? tableCellWidth,
                                }}>
                                {column.title}
                                {column.key !== "action" && (
                                    <div className={style.table_thead_cell_arrows}>
                                        <CaretUpOutlined
                                            className={concatClasses(
                                                style.table_thead_cell_arrows_item,
                                                checkDescStatus(column)
                                            )}
                                        />
                                        <CaretDownOutlined
                                            className={concatClasses(
                                                style.table_thead_cell_arrows_item,
                                                checkAscStatus(column)
                                            )}
                                        />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>

                    <div className={style.table_tbody}>
                        <div
                            className={concatClasses(style.table_tbody_mask, loading && style.table_tbody_mask_active)}>
                            <Loader
                                className={style.table_tbody_mask_loader}
                                style={{
                                    top: wrapper.height ? wrapper.height / 2 + 16 : undefined,
                                    left: wrapper.width ? wrapper.width / 2 - 24 : undefined,
                                }}
                            />
                        </div>
                        {!dataSource?.length ? (
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                style={{
                                    width: wrapperRef.current?.offsetWidth
                                        ? wrapperRef.current?.offsetWidth - 8
                                        : undefined,
                                }}
                            />
                        ) : (
                            dataSource?.map((category, index) => {
                                return (
                                    <PriceListsCollapse
                                        isOther={isOther}
                                        key={(category as any)?.id ?? index}
                                        wrapperRef={wrapperRef}
                                        category={category}
                                        columns={columns as any}
                                        tableCellWidth={tableCellWidth}
                                        className={index % 2 === 0 ? style.collapse_even : style.collapse_odd}
                                        searchParams={searchParams}
                                        setMainLoading={setMainLoading}
                                        width={Number(scroll?.x)}
                                        sortOptions={sort}
                                    />
                                );
                            })
                        )}
                    </div>
                </div>
            </div>

            <Pagination itemRender={itemRender} hideOnSinglePage={false} showSizeChanger={false} {...pagination} />
        </div>
    );
};
export default PriceListsTable;
