import { Modal as ModalAntd, ModalProps as ModalAntdProps } from "antd";
import { ModalFooter } from "components/Modal/ModalFooter";
import React, { FC, ReactNode } from "react";
import concatClasses from "utils/concatClasses";
import style from "./Modal.module.scss";

export interface ModalProps extends Omit<ModalAntdProps, "closeIcon"> {
    footer?: boolean | ReactNode;
    loading?: boolean;
    header?: ReactNode;
    title?: ReactNode;
    okButtonText?: string;
    cancelButtonText?: string;
    maxTitle?: string;
    onCancel?: () => void;
    onOk?: () => void;
}

const Modal: FC<ModalProps> = ({
    children,
    footer = true,
    onCancel,
    onOk,
    header = true,
    forceRender = true,
    title,
    width = 710,
    okButtonProps,
    cancelButtonProps,
    loading,
    className,
    okButtonText = "Ок",
    cancelButtonText = "Отмена",
    maxTitle,
    ...props
}) => {
    return (
        <ModalAntd
            onCancel={onCancel}
            forceRender={forceRender}
            style={{ maxWidth: width }}
            className={concatClasses(className, style.modal)}
            title={
                maxTitle ? (
                    <div>
                        <div className={style.maxTitle}>{maxTitle}</div>
                        {title}
                    </div>
                ) : (
                    title
                )
            }
            footer={
                <ModalFooter
                    footer={footer}
                    onCancel={onCancel}
                    cancelButtonProps={cancelButtonProps}
                    okButtonProps={okButtonProps}
                    okButtonText={okButtonText}
                    cancelButtonText={cancelButtonText}
                    loading={loading}
                    onOk={onOk}
                />
            }
            {...props}>
            {children}
        </ModalAntd>
    );
};

export default Modal;
