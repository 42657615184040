import { Project, ProjectResponse, ProjectTable } from "@aten/common/dist/models/Project";
import serializer, { Serializer } from "serializers/index";
import { fullEntityName } from "utils/fullNameConverter";

const projectsSerializer: Serializer<ProjectResponse, Project, ProjectTable> = (projects) => {
    return serializer<ProjectResponse, Project, ProjectTable>(projects, (proj, i) => {
        const counter = projects?.requestedStartIndex ?? 1;
        return {
            ...proj,
            key: proj.id,
            projectNumber: i + 1 + counter,
            distrName: fullEntityName(proj.distr),
            integrName: fullEntityName(proj.integrator),
        };
    });
};

export default projectsSerializer;
