import { Engineers } from "@aten/common/dist/models/Engineers";
import { Button, Col, Input, Row, Tooltip } from "antd";
import { FormInstance } from "antd/es";
import cl from "assets/scss/Space.module.scss";
import { Item, List } from "components/Form/Form";
import { ListOptions } from "components/Form/ProjectFormEdit/ProjectFormEdit";
import clForm from "components/Form/ProjectFormEdit/ProjectFormEdit.module.scss";
import { Icons } from "components/Icons/Icons";
import InputWithMask from "components/Input/InputWithMask";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import SelectWithAdding from "components/Select/SelectWithAdding";
import React, { FC } from "react";
import concatClasses from "utils/concatClasses";
import { fullEntityName } from "utils/fullNameConverter";
import { emailValidate, phoneValidate, required } from "utils/getRules";

interface EngineerListProps {
    form: FormInstance;
    engineerOptions: ListOptions<Engineers>[];
    setDisableEngineerOption: () => void;
    setRemovedEngineers: React.Dispatch<React.SetStateAction<number[]>>;
    deleteFromEngineerOptions: (id?: number) => void;
    edit?: string;
    isLoading?: boolean
}

const EngineerList: FC<EngineerListProps> = ({
    edit,
    form,
    engineerOptions,
    setDisableEngineerOption,
    deleteFromEngineerOptions,
    setRemovedEngineers,
    isLoading
}) => {
    const addEngineer = () => {
        form.setFields([
            {
                name: "engineerList",
                value: [...form.getFieldValue("engineerList"), ""],
            },
        ]);
    };
    return (
        <div>
            <List name="engineerList">
                {(fields, {  remove }) => (
                    <>
                        {fields.map((field, idx) => {
                            return (
                                <Row
                                    key={idx}
                                    className={concatClasses(
                                        cl.space,
                                        cl.space__between,
                                        clForm.Row,
                                        cl.space__alignCenter
                                    )}
                                    gutter={[30, 30]}>
                                    <Col span={8}>
                                        <div className={clForm.item}>
                                            <Label type={LabelTypes.Form} label={"ФИО инженера"} />
                                            {edit ? (
                                                <span className={clForm.span}>
                                                    {fullEntityName(form.getFieldValue("engineerList")[idx])}
                                                </span>
                                            ) : (
                                                <Item name={[field.name, "id"]} rules={[required()]}>
                                                    <SelectWithAdding
                                                        showSearch
                                                        options={engineerOptions}
                                                        onChange={(value, option: any) => {
                                                            if(typeof option.value !== 'number' && option.value.includes('__new__')) {
                                                                const [surname, name, patronymic] = option.label.split(" ");
                                                                form.setFieldValue(
                                                                    ["engineerList", field.name, "name"],
                                                                    name
                                                                );
                                                                form.setFieldValue(
                                                                    ["engineerList", field.name, "patronymic"],
                                                                    patronymic ?? ""
                                                                );
                                                                form.setFieldValue(
                                                                    ["engineerList", field.name, "surname"],
                                                                    surname
                                                                );
                                                                setDisableEngineerOption();

                                                                return
                                                            }

                                                            form.setFieldValue(
                                                                ["engineerList", field.name, "email"],
                                                                engineerOptions?.[option.orderNumber]?.email
                                                            );

                                                            form.setFieldValue(
                                                                ["engineerList", field.name, "phone"],
                                                                engineerOptions?.[option.orderNumber]?.phone
                                                            );
                                                            const [name, surname, patronymic] = option.label.split(" ");

                                                            form.setFieldValue(
                                                                ["engineerList", field.name, "name"],
                                                                name
                                                            );
                                                            form.setFieldValue(
                                                                ["engineerList", field.name, "patronymic"],
                                                                patronymic ?? ""
                                                            );
                                                            form.setFieldValue(
                                                                ["engineerList", field.name, "surname"],
                                                                surname
                                                            );
                                                            form.setFieldValue(
                                                                ["engineerList", field.name, "id"],
                                                                "id" in option ? option.id : option.label
                                                            );
                                                            setDisableEngineerOption();
                                                        }}
                                                    />
                                                </Item>
                                            )}
                                        </div>
                                    </Col>

                                    <Col span={8}>
                                        <div className={clForm.item}>
                                            <Label type={LabelTypes.Form} label={"E-mail инженера"} />
                                            {edit ? (
                                                <span className={clForm.span}>
                                                    {form.getFieldValue("engineerList")[idx].email}
                                                </span>
                                            ) : (
                                                <Item
                                                    name={[field.name, "email"]}
                                                    rules={[emailValidate(), required()]}>
                                                    <Input disabled={form.getFieldValue("engineerList")[idx].email} />
                                                </Item>
                                            )}
                                        </div>
                                    </Col>
                                    <Col span={edit ? 8 : 7}>
                                        <div className={clForm.item}>
                                            <Label type={LabelTypes.Form} label={"Телефон инженера"} />
                                            {edit ? (
                                                <span className={clForm.span}>
                                                    {form.getFieldValue("engineerList")[idx].phone}
                                                </span>
                                            ) : (
                                                <Item
                                                    name={[field.name, "phone"]}
                                                    rules={[phoneValidate(), required()]}>
                                                    <InputWithMask
                                                        mask="+7 999 999 99 99"
                                                        disabled={form.getFieldValue("engineerList")[idx].phone}
                                                    />
                                                </Item>
                                            )}
                                        </div>
                                    </Col>
                                    {!edit && (
                                        <Col span={1}>
                                            <Icons.Delete
                                                onClick={() => {
                                                    const id = form.getFieldValue(["engineerList", field.name, "id"]);
                                                    deleteFromEngineerOptions(id);
                                                    setRemovedEngineers((prevState) => [...prevState, id]);
                                                    remove(idx);
                                                }}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            );
                        })}
                    </>
                )}
            </List>
            {!edit && (
                <Row className={concatClasses(cl.space, clForm.Row, cl.space__alignCenter)}>
                    <Col>
                        <Tooltip title={!form.getFieldValue("integratorId") ? "Выберите интегратора" : ""}>
                            <Button
                                loading={isLoading}
                                disabled={!form.getFieldValue("integratorId") || isLoading}
                                onClick={() => {
                                    addEngineer();
                                }}>
                                Добавить инженера
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default EngineerList;
