import { message } from "antd/es";
import { CustomButton } from "components/CustomButton/CustomButton";
import { CustomTable } from "components/CustomTable/CustomTable";
import { Icons } from "components/Icons/Icons";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import ModalDelete from "components/Modal/ModalDelete";
import { authUrl } from "config/path";
import useFluid from "hooks/useFluid";
import useModal from "hooks/useModal";
import useNavigate from "hooks/useNavigate";
import usePagination from "hooks/usePagination";
import testColumnsTable from "pages/Test/testColumnsTable";
import TestModalSearch from "pages/Test/TestModalSearch";
import cl from "pages/Theme/Themes.module.scss";
import { FC, useState } from "react";
import { generatePath } from "react-router-dom";
import testSerializer from "serializers/test";
import { useDeleteTestMutation, useGetTestsQuery } from "services/testsService";

export type DeletingTestProps = {
    title: string;
    id: string;
};

const Tests: FC = () => {
    const { navigate } = useNavigate();
    const { pagination, changePagination } = usePagination();
    const { open: openSearch, close: closeSearch, visible: visibleSearch } = useModal();
    const { open: openDelete, close: closeDelete, visible: visibleDelete } = useModal();
    const { getFluidTableHeight } = useFluid();

    const [deletingTests, setDeletingTests] = useState<DeletingTestProps>({ id: "", title: "" });
    const [searchString, setSearchString] = useState<string>("");

    const { data, isLoading, isFetching } = useGetTestsQuery(
        {
            pagination,
            SearchParams: searchString,
        },
        { refetchOnMountOrArgChange: true }
    );
    const [deleteTest] = useDeleteTestMutation();

    const tests = testSerializer(data);

    const modalDeleteTest = () => {
        deleteTest(deletingTests.id.toString()).then(() => {
            message.success("Тест удален");
            closeDelete();
        });
    };

    const search = (values: string) => {
        setSearchString(values);
    };

    return (
        <>
            <LayoutWrapper>
                <div className={cl.actions}>
                    <CustomButton
                        onClick={() => {
                            navigate(generatePath(authUrl.Index.Tests.New.url));
                        }}
                        buttonText={"Добавить тест"}
                        type="primary"
                        icon={<Icons.Plus />}
                    />
                    <CustomButton
                        wrapperClasses={cl.searchBtn}
                        type="primary"
                        onClick={openSearch}
                        icon={<Icons.Search />}
                    />
                </div>
                <CustomTable
                    columns={testColumnsTable({ setDeletingTests, openDelete })}
                    data={tests}
                    loading={isLoading || isFetching}
                    pagination={{
                        total: data?.total,
                        onChange: changePagination,
                    }}
                    scroll={{ y: getFluidTableHeight(358, 398) }}
                />
            </LayoutWrapper>
            <ModalDelete
                title={`Вы уверены, что хотите удалить тест "${deletingTests.title}"`}
                open={visibleDelete}
                onCancel={closeDelete}
                onOk={modalDeleteTest}
            />
            <TestModalSearch visible={visibleSearch} onOk={search} close={closeSearch} />
        </>
    );
};

export default Tests;
