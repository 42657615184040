import { Button, Modal, ModalProps } from "antd";
import mark from "assets/images/icons/map-marker.svg";
import Loader from "components/Loader/Loader";
import { ymapsApiKey as apikey } from "config/api";
import React, { FC, useRef, useState } from "react";
import { Map, Placemark, YMaps, YMapsApi } from "react-yandex-maps";
import styles from "./ModalMap.module.scss";

interface ModalMapProps extends Omit<ModalProps, "onCancel" | "onOk"> {
    address?: string;
    visible: boolean;
    onCancel: (e?: string) => void;
    onOk: (e: string) => void;
}

const defaultPlace = "Россия, Москва";
const ModalMap: FC<ModalMapProps> = ({ address = defaultPlace, onCancel, onOk, visible }) => {
    const [addressDef, setAddressDef] = useState<{ address: string; coords: number[] } | undefined>();

    let ref = useRef<YMapsApi | null>();

    const getCoords = (yMaps: YMapsApi, addr?: string | number[]) => {
        yMaps.geocode(addr).then((result: any) => {
            const geo = result.geoObjects.get(0);
            setAddressDef({
                address: geo.getAddressLine(),
                coords: geo.geometry.getCoordinates(),
            });
        });
    };

    return (
        <Modal
            destroyOnClose
            className={styles.Modal}
            onCancel={() => onCancel(address)}
            width="750px"
            footer={false}
            open={visible}
            children={
                <>
                    <YMaps ref={ref} query={{ apikey }}>
                        <Map
                            onLoad={(yMap) => {
                                getCoords(yMap, address);
                            }}
                            modules={["geocode"]}
                            state={{ center: addressDef?.coords ?? [], zoom: 10 }}
                            onClick={async (e: any) => {
                                let coords = await e.get("coords");
                                getCoords(ref.current?.ymaps.api, coords);
                            }}
                            height={"100%"}
                            width={"100%"}>
                            <Placemark
                                geometry={addressDef?.coords ?? []}
                                options={{
                                    iconLayout: "default#image",
                                    iconImageHref: mark,
                                    iconImageSize: [46, 46],
                                }}
                            />
                        </Map>
                        {!ref.current && <Loader customClass={styles.mapLoader} />}
                    </YMaps>
                    <div className={styles.Modal_btns}>
                        <Button
                            onClick={() => {
                                onOk && onOk(addressDef?.address ?? "");
                            }}
                            type="primary"
                            disabled={!ref.current}>
                            Принять
                        </Button>
                        <Button
                            onClick={() => {
                                getCoords(ref.current?.ymaps.api, defaultPlace);
                                onOk && onOk(defaultPlace);
                            }}
                            disabled={!ref.current}>
                            Сбросить
                        </Button>
                    </div>
                </>
            }
        />
    );
};

export default ModalMap;
