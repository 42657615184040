import { Phone } from "@aten/common/dist/models/Phones";
import { UrlApi } from "config/api";
import { commonApi } from "services/commonApiService";

export const phonesApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    setPhone: build.mutation<string, Phone>({
      query: (data: Phone) => ({
        url: UrlApi.Phones,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Phones"],
    }),
    editPhone: build.mutation<string, Phone>({
      query: (data: Phone) => ({
        url: `${UrlApi.Phones}/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Phones"],
    }),
    deletePhone: build.mutation<string, string>({
      query: (id: string) => ({
        url: `${UrlApi.Phones}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Phones"],
    }),
  }),
});

export const { useSetPhoneMutation, useEditPhoneMutation, useDeletePhoneMutation } = phonesApi;
