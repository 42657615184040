import { PageProvider } from "@aten/admin/src/components/Contexts/PageContext";
import "antd/dist/antd.min.css";
import "assets/scss/style.scss";
import AppRouter from "components/AppRouter/AppRouter";
import { LayoutProvider } from "components/Contexts/LayoutContext";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React from "react";
import { useGetUserByTokenQuery } from "services/userService";
import { logout, setUser } from "slices/authSlice";
import { authRoutes, publicRoutes } from "./config/routes";

export const App = () => {
    const dispatch = useAppDispatch();

    const { isAuth, token, user } = useAppSelector(({ auth }) => auth);

    const { data: userData, isError } = useGetUserByTokenQuery(
        { token },
        {
            skip: !token || !!user,
        }
    );

    if (isError) {
        dispatch(logout());
    }

    if (!user && userData) {
        dispatch(setUser(userData));
    }

    return (
        <PageProvider>
            <LayoutProvider>
                <AppRouter routes={isAuth ? authRoutes : publicRoutes} />
            </LayoutProvider>
        </PageProvider>
    );
};
