import { PaginationProps, Table } from "antd";
import { TablePaginationConfig } from "antd/es";
import { defaultPageSize } from "hooks/usePagination";
import React from "react";
import { ITableData, ITableProps } from "types/table";
import concatClasses from "utils/concatClasses";
import { Icons } from "../Icons/Icons";
import style from "./CustomTable.module.scss";

export const itemRender: PaginationProps["itemRender"] = (_, type, originalElement) => {
    if (type === "prev") {
        return <Icons.PaginPrev />;
    }
    if (type === "next") {
        return <Icons.PaginNext />;
    }

    return originalElement;
};

export const CustomTable = <TData extends ITableData>({
    setFullHeight = false,
    data,
    columns,
    onPagination,
    width,
    pagination,
    className,
    scroll,
    ...props
}: ITableProps<TData>): JSX.Element => {
    const defaultPagination: TablePaginationConfig = {
        position: ["bottomCenter"],
        pageSize: defaultPageSize,
    };

    return (
        <Table
            className={concatClasses(style.table, className)}
            columns={columns}
            dataSource={data}
            scroll={{ x: width, ...scroll }}
            pagination={{
                ...defaultPagination,
                ...pagination,
                hideOnSinglePage: true,
                itemRender,
            }}
            {...props}
        />
    );
};
