import React, { FC, useEffect, useState } from "react";
import { Checkbox, Col, Form, Input, message, Modal, ModalProps, Row, Upload } from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";

import st from "./ModalProjectMailDistribution.module.scss";
import { CustomButton } from "../../CustomButton/CustomButton";
import { required } from "../../../utils/getRules";
import { useSendMailMessageMutation } from "../../../services/projectsService";
import { Project } from "@aten/common/models/Project";
import UploadPicture from "../../UploadPicture/UploadPicture";
import { useGetFileByIdQuery } from "../../../services/fileService";

interface SubmitValues {
    MessageSubject?: string;
    MessageBody?: string;
    AttachLinkToTheProject?: boolean;
    FileImagePath?: string;
}

interface ModalProjectMailDistributionProps extends Omit<ModalProps, "onCancel"> {
    projectList?: Project[];
    onCancel: () => void;
}

export const ModalProjectMailDistribution: FC<ModalProjectMailDistributionProps> = ({
    onCancel,
    projectList,
    ...props
}) => {
    const [form] = useForm<SubmitValues>();
    const [send] = useSendMailMessageMutation();
    const [fileId, setFileId] = useState<number>();
    const [FileLink, setFileLink] = useState<string>();
    const onFinish = (values: SubmitValues) => {
        send({
            body: {
                ...values,
                AttachLinkToTheProject: values.AttachLinkToTheProject ?? false,
                projects: projectList,
                FileImagePath: FileLink,
            },
        }).then((res) => {
            const isError = "error" in res;
            message[isError ? "error" : "success"](isError ? "Произошла ошибка" : "Запрос отправлен");
            onCancel();
        });
    };

    const {
        data: imageLink,
        isLoading: loading,
        isFetching: fetching,
    } = useGetFileByIdQuery(fileId ?? 0, {
        skip: !fileId,
    });
    const imageUpdate = (imageId: number) => {
        setFileId(imageId);
        form.setFieldValue("FileImagePath", imageLink);
        form.validateFields(["FileImagePath"]).then();
    };

    useEffect(() => {
        setFileLink(imageLink);
    }, [loading]);

    const normFile = (e: any) => {
        const f = e?.fileList[0];
        return URL.createObjectURL(f.originFileObj);
    };

    return (
        <Modal {...props} className={st.wrap} footer={false}>
            <Form layout={"vertical"} form={form} onFinish={onFinish}>
                <Form.Item rules={[required()]} name={"MessageSubject"} label={"Тема письма"}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[required()]} name={"MessageBody"} label={"Тело письма"}>
                    <TextArea />
                </Form.Item>
                <Form.Item
                    valuePropName="checked"
                    name={"AttachLinkToTheProject"}
                    label={"Прикрепить ссылку на редактирование проекта"}>
                    <Checkbox checked={false} />
                </Form.Item>
                <Form.Item name={"FileImagePath"} getValueFromEvent={normFile}>
                    <UploadPicture
                        loading={loading || fetching}
                        action={imageUpdate}
                        url={imageLink ?? ""}></UploadPicture>
                </Form.Item>
                <Row gutter={[30, 30]} className={st.footer}>
                    <Col span={12}>
                        <CustomButton wrapperClasses={st.button} buttonText={"Отмена"} onClick={onCancel} />
                    </Col>
                    <Col span={12}>
                        <CustomButton
                            htmlType={"submit"}
                            type="primary"
                            wrapperClasses={st.button}
                            buttonText={"Отправить"}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
