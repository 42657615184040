import { EventContact } from "@aten/common/dist/models/EventContact";
import { EventContactResponse } from "@aten/common/models/EventContact";
import { UrlApi } from "config/api";
import { commonApi } from "services/commonApiService";

export const eventContactsApi = commonApi.injectEndpoints({
    endpoints: (build) => ({
        getEventContacts: build.query<EventContact[], null>({
            query: () => ({
                url: UrlApi.EventContacts,
            }),
            transformResponse: (res: EventContactResponse) => {
                return res.objects;
            },
        }),
        setEventContact: build.mutation<string, EventContact>({
            query: (data) => ({
                url: UrlApi.EventContacts,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["EventContacts"],
        }),
        editEventContact: build.mutation<string, EventContact>({
            query: (data) => ({
                url: `${UrlApi.EventContacts}/${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["EventContacts"],
        }),
        deleteEventContact: build.mutation<string, string>({
            query: (id: string) => ({
                url: `${UrlApi.EventContacts}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["EventContacts"],
        }),
    }),
});

export const {
    useSetEventContactMutation,
    useEditEventContactMutation,
    useDeleteEventContactMutation,
    useGetEventContactsQuery,
} = eventContactsApi;
