import { Layout } from "antd";
import useLayoutContext from "components/Contexts/LayoutContext";
import Header from "components/Header/Header";
import Logo from "components/Logo/Logo";
import SiderMenu from "components/SiderMenu/SiderMenu";
import { authUrl, publicUrl } from "config/path";
import { siderMenuList } from "config/SiderMenuList";
import React, { FC, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import arrayHelper from "utils/helpers/arrayHelper";
import menuHelper from "utils/helpers/menuHelper";
import cl from "./LayoutMain.module.scss";

const { Sider, Content } = Layout;

interface ILayoutMain {}

const LayoutMain: FC<ILayoutMain> = ({}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const { title, setTitle, loading } = useLayoutContext();
    const [activeKey, setActiveKey] = useState<string>();

    useEffect(() => {
        const activeKeyByPathname = menuHelper.getActiveKey(siderMenuList, location.pathname);

        if (!activeKey || !activeKeyByPathname || activeKeyByPathname !== activeKey) setActiveKey(activeKeyByPathname);

        //todo: refactor it
        if (location.pathname === publicUrl.Login.url || location.pathname === authUrl.Index.url)
            navigate(authUrl.Index.Users.List.url);
    }, [activeKey, location.pathname, navigate]);

    const flattenMenuItems = useMemo(() => arrayHelper.flatten(siderMenuList), []);
    const currentMenuItem = flattenMenuItems.find((item) => item.key === activeKey);

    useEffect(() => {
        if (flattenMenuItems.find((item) => item.url === location.pathname)) setTitle?.(undefined);
    }, [flattenMenuItems, location.pathname, setTitle]);

    return (
        <Layout className={cl.layout}>
            <Sider width={292} className={cl.overflow}>
                <Logo />
                <SiderMenu activeKey={activeKey} setActiveKey={setActiveKey} />
            </Sider>
            <Layout>
                <Header loading={loading} title={title ?? currentMenuItem?.label ?? "—"} />
                <Content className={cl.content}>
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    );
};

export default LayoutMain;
