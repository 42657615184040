import { Form } from "antd";
import { FormInstance } from "antd/es";
import React, { createContext, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

export const EditableContext = createContext<FormInstance | null>(null);

interface DraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    index: number;
    moveRow: (dragIndex: number, hoverIndex: number) => void;
}

const type = "DraggableBodyRow";

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }: DraggableBodyRowProps) => {
    const [form] = Form.useForm();
    const ref = useRef<HTMLTableRowElement>(null);
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: (monitor) => {
            const { index: dragIndex } = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
            };
        },
        drop: (item: { index: number }) => {
            moveRow(item.index, index);
        },
    });
    const [, drag] = useDrag({
        type,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));

    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr
                    ref={ref}
                    className={`${className}${isOver ? dropClassName : ""}`}
                    style={{ cursor: "move", ...style }}
                    {...restProps}
                />
            </EditableContext.Provider>
        </Form>
    );
};

export default DraggableBodyRow;
