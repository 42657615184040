import { ConfigProvider } from "antd";
import rus from "antd/lib/locale/ru_RU";

import { App } from "App";
import Loader from "components/Loader/Loader";
import moment from "moment";
import "moment/locale/ru";
import { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persist } from "store";

moment.locale("ru");

ReactDOM.render(
    // <React.StrictMode>
    <ConfigProvider locale={rus}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persist}>
                <Suspense fallback={<Loader />}>
                    <App />
                </Suspense>
            </PersistGate>
        </Provider>
    </ConfigProvider>,
    // </React.StrictMode>,
    document.getElementById("root")
);
