import ProjectSearch from "@aten/common/dist/models/Project";
import { message } from "antd";
import { useEffect, useState } from "react";
import { useGetProjectExcelQuery } from "services/projectsService";
import { filterQueryString } from "utils/filterQueryString";

export const useProjectExcelDocument = () => {
    const [queryString, setQueryString] = useState<string | undefined>(undefined);
    const [excelDownloaded, setExcelDownloaded] = useState<boolean>(false);

    const {
        isSuccess,
        isError,
        isLoading: excelDownloading,
    } = useGetProjectExcelQuery(queryString ?? "", { skip: !queryString, refetchOnMountOrArgChange: true });

    useEffect(() => {
        isSuccess && setExcelDownloaded(true);
    }, [isSuccess]);

    const getSearchObject = (data: typeof ProjectSearch) => {
        const str = filterQueryString(data);

        queryString === str && excelDownloaded && message.warn("Отчет с этими полями был только что выгружен");

        isError && message.error("Не удалось выгрузить");

        setQueryString(str);
        setExcelDownloaded(false);
    };

    return { getSearchObject, excelDownloading, excelDownloaded };
};
