import { NewEngineer } from "@aten/common/dist/models/Engineers";
import { EngineersToUsers } from "@aten/common/dist/models/EngineersToUsers";
import { Manager } from "@aten/common/dist/models/Manager";
import { Project } from "@aten/common/dist/models/Project";
import { ProjectsToEngineers } from "@aten/common/dist/models/projectsToEngineers";
import { ManagersToUser, ProjectsToManagers } from "@aten/common/models/Manager";
import { SerializedError } from "@reduxjs/toolkit";
import { BaseQueryError } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { message } from "antd";
import { FormInstance } from "antd/es";

interface RTKMutationType<T, U> {
    (data: T): Promise<{ data: U } | { error: BaseQueryError<any> | SerializedError }> & {
        unwrap: () => Promise<U>;
    };
}

interface EntitiesList {
    engineerList: [];
    managerDistList: [];
    managerIntegrList: [];
    modelsList?: [];
}

interface ProjectSendDataProps {
    id?: string;
    userId?: number;
    value: Project & EntitiesList;
    form: FormInstance;
    project?: Project;
    editProj: RTKMutationType<Project, { updatedId: number }>;
    addProj: RTKMutationType<Project, { addedId: number }>;
    deleteEngineerRelation: RTKMutationType<number | undefined, void>;
    setNewManager: RTKMutationType<Manager, { addedId: number }>;
    setManagerToUser: RTKMutationType<ManagersToUser, void>;
    setManagerToProject: RTKMutationType<ProjectsToManagers, void>;
    setNewEngineer: RTKMutationType<NewEngineer, { addedId: number }>;
    setEngineerToProject: RTKMutationType<ProjectsToEngineers, void>;
    setEngineerToUser: RTKMutationType<EngineersToUsers, void>;
    deleteManagerRelation: RTKMutationType<number | undefined, void>;
    removedEngineers: number[];
    removedManagers: number[];
    calculateOrderSum: any;
}

interface ProjectSendDataFunctionType {
    (props: ProjectSendDataProps): Promise<number | void>;
}

export const projectSendData: ProjectSendDataFunctionType = async ({
    id,
    value,
    userId,
    form,
    project,
    editProj,
    addProj,
    removedEngineers,
    deleteEngineerRelation,
    setNewManager,
    setManagerToUser,
    setManagerToProject,
    setNewEngineer,
    setEngineerToProject,
    setEngineerToUser,
    removedManagers,
    deleteManagerRelation,
}) => {
    const withProjectId = id ? { id } : {};

    const noEngineer = value.engineerList.length < 1;
    const noManagerDist = value.managerDistList.length < 1;
    const noManagerInt = value.managerIntegrList.length < 1;

    if (noEngineer || noManagerDist || noManagerInt) {
        noEngineer && message.warn("Не выбран ни один инженер");
        noManagerDist && message.warn("Не выбран ни один менеджер дистрибьютера");
        noManagerInt && message.warn("Не выбран ни один менеджер интегратора");
        return;
    }

    const verification: (typeRes: "success" | "error", typeReq: "add" | "edit", text?: string) => void = (
        typeRes,
        typeReq,
        text
    ) => {
        typeReq === "add" && typeRes === "success" && message.success("Проект добавлен");
        typeReq === "edit" && typeRes === "success" && message.success("Проект отредактирован");
        typeRes === "error" && message.error(text ?? "Отправка не удалась");
    };
    const data = {
        ...withProjectId,
        ...value,

        userId: project?.userId ?? userId,
        orderSum: value.orderSum,
        //todo добавить поля
        shipmentEndDate: Date.parse(value.supplyEquipmentDate.toString()),
        shipmentStartDate: Date.parse(value.supplyEquipmentDate.toString()),
        supplyEquipmentDate: Date.parse(value.supplyEquipmentDate.toString()),
        makingDecisionDate: Date.parse(value.makingDecisionDate.toString()),
        fractionalDeliveryDate: !!value.fractionalDeliveryDate
            ? Date.parse(value.fractionalDeliveryDate.toString())
            : undefined,
        needFractionalDelivery: !!value.fractionalDeliveryDate,
        shortDescription: "???",
        individualNumber: "sdsd",
    };
    delete data?.modelsList;

    const { engineerList, managerDistList, managerIntegrList, ...dataProject } = data;
    const newProjId = id
        ? await editProj(data as Project & EntitiesList).then((res) => {
              if ("data" in res) {
                  verification("success", "edit");
                  return res.data.updatedId;
              }
              if ("error" in res) {
                  verification("error", "edit");
              }
          })
        : await addProj(dataProject as Project & EntitiesList).then((res) => {
              if ("data" in res) {
                  verification("success", "add");
                  return res.data.addedId;
              }
              if ("error" in res) {
                  if (res?.error?.data?.information) {
                      verification("error", "edit", res.error.data.information);
                      return;
                  }

                  verification("error", "edit");
              }
          });

    //удаление engins
    project?.projectsToEngineers
        .filter(({ engineerId }) => removedEngineers.includes(engineerId))
        .map(({ id }) => deleteEngineerRelation(id));
    project?.projectsToEngineers
        .filter(({ engineerId }) => !form.getFieldValue("engineerList").find(({ id }: any) => engineerId === id))
        .map(({ id }) => deleteEngineerRelation(id));

    //добавление менеджера дистра
    const addingNewDistManagerComplex = async (args: any) => {
        const newId = await setNewManager({ ...args, managerType: 1 })
            .unwrap()
            .then((res: any) => {
                return res.addedId;
            });

        setManagerToUser({ managerId: newId, userId: form.getFieldValue("distrId") });
        newProjId && setManagerToProject({ managerId: newId, projectId: id ? +id : newProjId });
    };

    const addingDistManager = (managerId: number | string, args: any) => {
        if (managerId && typeof managerId === "number") {
            newProjId &&
                setManagerToProject({
                    projectId: id ? +id : newProjId,
                    managerId: managerId ?? "",
                });
        } else {
            addingNewDistManagerComplex(args);
        }
    };

    id
        ? form
              .getFieldValue("managerDistList")
              .filter((elem: any) => !project?.projectsToManagers.find(({ managerId }) => elem.id == managerId))
              .map(({ id, ...args }: { id: number }) => addingDistManager(id, args))
        : form.getFieldValue("managerDistList").map(({ id, ...args }: { id: number }) => addingDistManager(id, args));

    //добавление менеджера интегратора
    const addingNewManagerComplex = async (args: any) => {
        const newId = await setNewManager({ ...args, managerType: 0 })
            .unwrap()
            .then((res: any) => {
                return res.addedId;
            });
        setManagerToUser({ managerId: newId, userId: form.getFieldValue("integratorId") });
        newProjId && setManagerToProject({ managerId: newId, projectId: id ? +id : newProjId });
    };
    const addingManager = (managerId: number | string, args: any) => {
        if (managerId && typeof managerId === "number") {
            newProjId &&
                setManagerToProject({
                    projectId: id ? +id : newProjId,
                    managerId: managerId ?? "",
                });
        } else {
            addingNewManagerComplex(args);
        }
    };

    id
        ? form
              .getFieldValue("managerIntegrList")
              .filter((elem: any) => !project?.projectsToManagers.find(({ managerId }) => elem.id == managerId))
              .map(({ id, ...args }: { id: number }) => addingManager(id, args))
        : form.getFieldValue("managerIntegrList").map(({ id, ...args }: { id: number }) => addingManager(id, args));

    // добавление инженера
    const addingNewEngineerComplex = async (args: NewEngineer) => {
        const newEngId = await setNewEngineer(args)
            .unwrap()
            .then((res: any) => {
                return res.addedId;
            });
        setEngineerToUser({ engineerId: newEngId, userId: form.getFieldValue("integratorId") });
        newProjId && setEngineerToProject({ engineerId: newEngId, projectId: id ?? newProjId });
    };
    const addingEngineers = (engId: number | string, args: any) => {
        if (engId && typeof engId === "number") {
            newProjId &&
                setEngineerToProject({
                    projectId: id ?? newProjId,
                    engineerId: engId ?? "",
                });
        } else {
            addingNewEngineerComplex(args);
        }
    };
    id
        ? form
              .getFieldValue("engineerList")
              .filter((elem: any) => !project?.projectsToEngineers.find(({ engineerId }) => elem.id == engineerId))
              .map(({ id: engId, ...args }: { id: number }) => addingEngineers(engId, args))
        : form
              .getFieldValue("engineerList")
              .map(({ id: engId, ...args }: { id: number }) => addingEngineers(engId, args));

    //удаление манагеров
    project?.projectsToManagers
        .filter(({ managerId }) => removedManagers.includes(managerId))
        .map(({ id }) => deleteManagerRelation(id));

    const replaceManager = () => {
        project?.projectsToManagers
            .filter(
                ({ managerId }) =>
                    !form.getFieldValue("managerDistList").find(({ id }: any) => managerId === id) &&
                    !form.getFieldValue("managerIntegrList").find(({ id }: any) => id === managerId)
            )
            .map(({ id }) => deleteManagerRelation(id));
    };

    replaceManager();
    return newProjId;
};
