import { message } from "antd/es";
import { CustomButton } from "components/CustomButton/CustomButton";
import { CustomTable } from "components/CustomTable/CustomTable";
import { Icons } from "components/Icons/Icons";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import ModalDelete from "components/Modal/ModalDelete";
import { authUrl } from "config/path";
import useFluid from "hooks/useFluid";
import useModal from "hooks/useModal";
import useNavigate from "hooks/useNavigate";
import usePagination from "hooks/usePagination";
import themesColumnsTable from "pages/Theme/themesColumnsTable";
import ThemesModalSearch from "pages/Theme/ThemesModalSearch";
import { FC, useState } from "react";
import { generatePath } from "react-router-dom";
import themesSerializer from "serializers/theme";
import { useDeleteThemeMutation, useGetThemesQuery } from "services/themeService";
import cl from "./Themes.module.scss";

export type DeletingThemesProps = {
    title: string;
    id: string;
};

const Themes: FC = () => {
    const { navigate } = useNavigate();
    const { getFluidTableHeight } = useFluid();

    const { open: openSearch, close: closeSearch, visible: visibleSearch } = useModal();
    const { open: openDelete, close: closeDelete, visible: visibleDelete } = useModal();
    const { pagination, changePagination } = usePagination();

    const [deletingTheme, setDeletingTheme] = useState<DeletingThemesProps>({ id: "", title: "" });
    const [searchString, setSearchString] = useState<string>("");

    const { data, isLoading, isFetching } = useGetThemesQuery(
        {
            pagination,
            SearchParams: searchString,
        },
        { refetchOnMountOrArgChange: true }
    );

    const [deleteTheme] = useDeleteThemeMutation();

    const themes = themesSerializer(data);

    const modalDeleteTheme = () => {
        deleteTheme(deletingTheme.id).then(() => {
            message.success("Тема удалена");
            closeDelete();
        });
    };

    const search = (values: string) => {
        setSearchString(values);
    };

    return (
        <>
            <LayoutWrapper>
                <div className={cl.actions}>
                    <CustomButton
                        onClick={() => navigate(generatePath(authUrl.Index.Themes.New.url))}
                        buttonText={"Добавить тему"}
                        type="primary"
                        icon={<Icons.Plus />}
                    />
                    <CustomButton
                        wrapperClasses={cl.searchBtn}
                        type="primary"
                        onClick={openSearch}
                        icon={<Icons.Search />}
                    />
                </div>
                <CustomTable
                    columns={themesColumnsTable({
                        setDeletingTheme,
                        openDelete,
                    })}
                    data={themes}
                    loading={isLoading || isFetching}
                    pagination={{
                        total: data?.total,
                        onChange: changePagination,
                    }}
                    scroll={{ y: getFluidTableHeight(358, 398) }}
                />
            </LayoutWrapper>
            <ModalDelete
                title={`Вы уверены, что хотите удалить тему "${deletingTheme.title}"`}
                open={visibleDelete}
                onCancel={closeDelete}
                onOk={modalDeleteTheme}
            />
            <ThemesModalSearch visible={visibleSearch} onOk={search} close={closeSearch} />
        </>
    );
};

export default Themes;
