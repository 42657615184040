import { Col, DatePicker, Input, Row, Select } from "antd";
import cl from "assets/scss/Space.module.scss";
import Form, { Item, useForm } from "components/Form/Form";
import { Icons } from "components/Icons/Icons";

import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import ModalSearch, { ModalSize } from "components/Modal/ModalSearch";
import React, { FC, useEffect } from "react";
import { useGetEventContactsQuery } from "services/eventContactsService";
import concatClasses from "utils/concatClasses";

interface OfflineEventsSearchModalProps {
    data?: object;
    onOk: (e: any) => void;
    onCancel: () => void;
    visible: boolean;
}

const select = [
    {
        id: 0,
        value: 0,
        title: "Селект 0",
    },
    {
        id: 1,
        value: 1,
        title: "Селект 1",
    },
    {
        id: 2,
        value: 2,
        title: "Селект 2",
    },
];
const OfflineEventsSearchModal: FC<OfflineEventsSearchModalProps> = ({ data, onOk, onCancel, visible }) => {
    const [form] = useForm();
    const { Option } = Select;

    const { data: contacts } = useGetEventContactsQuery(null, { refetchOnMountOrArgChange: true });
    useEffect(() => {
        form.setFieldsValue(data);
    }, [data]);

    const closeModalByKeys = <T,>(data: T) => {
        window.onkeyup = (ev) => {
            if (ev.key === "Enter") {
                onOk(data);
                onCancel();
            }
            ev.key === "Escape" && onCancel();
        };
    };

    closeModalByKeys(form.getFieldsValue());

    const onFinish = () => {
        const data = form.getFieldsValue();
        const DateFrom = data.DateFrom?.format("MM.DD.YYYY") ?? undefined;
        const DateTo = data.DateTo?.format("MM.DD.YYYY") ?? undefined;
        onOk({
            ...data,
            DateFrom,
            DateTo,
        });
    };
    const onClose = () => {
        form.resetFields();
        onOk("");
        onCancel();
    };

    return (
        <ModalSearch width={ModalSize.Big} onOk={onFinish} open={visible} onCancel={onClose}>
            <Form form={form}>
                <Row className={concatClasses(cl.space)} gutter={[30, 30]}>
                    <Col span={8}>
                        <Item name="EventId" label={<Label type={LabelTypes.Form} label={"ID"} />}>
                            <Input />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="Title" label={<Label type={LabelTypes.Form} label={"Название"} />}>
                            <Input />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item
                            name="Vacancy"
                            label={<Label type={LabelTypes.Form} label={"Кол-во мест на мероприятие"} />}>
                            <Input />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="DateFrom" label={<Label type={LabelTypes.Form} label={"Дата начала"} />}>
                            <DatePicker format={"DD.MM.YYYY"} />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="DateTo" label={<Label type={LabelTypes.Form} label={"Дата завершения"} />}>
                            <DatePicker format={"DD.MM.YYYY"} />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="Address" label={<Label type={LabelTypes.Form} label={"Адрес"} />}>
                            <Input />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="EventContactId" label={<Label type={LabelTypes.Form} label={"Контактные лица"} />}>
                            <Select
                                suffixIcon={<Icons.SelectArrow />}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    input.length >= 3
                                        ? (((option?.label as string) ?? "").toLowerCase() ?? "").includes(
                                              input.toLowerCase()
                                          )
                                        : true
                                }
                                options={contacts?.map((el) => ({ key: el.id, label: el.fio, value: el.id }))}
                            />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="Theme" label={<Label type={LabelTypes.Form} label={"Тематика"} />}>
                            <Input />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="Category" label={<Label type={LabelTypes.Form} label={"категория"} />}>
                            <Input />
                        </Item>
                    </Col>
                </Row>
            </Form>
        </ModalSearch>
    );
};
export default OfflineEventsSearchModal;
