import {Model, ModelResponse} from "@aten/common/models/Model";
import {UrlApi} from "config/api";
import {commonApi} from "services/commonApiService";

export const ModelApi = commonApi.injectEndpoints({
    endpoints: build => ({
        getModelsList: build.query<Model[] | undefined, any>({
            query() {
                return {
                    url: UrlApi.Models,
                }
            },
            transformResponse: (res: ModelResponse) => {
                return res.objects
            },
            providesTags: ['Models']
        }),
        getModelByProjectId: build.query<Model[] | undefined, string>({
            query(projectId) {
                return {
                    url: UrlApi.Models,
                    params: {
                        projectId
                    }
                }
            },
            transformResponse: (res: ModelResponse) => {
                return res.objects
            },
            providesTags: ['Models']
        }),
        setNewModel: build.mutation<void, Model>({
            query(body) {
                return {
                    url: UrlApi.Models,
                    method: 'POST',
                    body
                }
            },
            invalidatesTags: ['Models']
        }),
        editModel: build.mutation<void, Model>({
            query(body) {
                return {
                    url: `${UrlApi.Models}/${body.id}`,
                    method: 'PUT',
                    body
                }
            },
            invalidatesTags: ['Models']
        }),
        deleteModel: build.mutation<void, string>({
            query(id) {
                return {
                    url: `${UrlApi.Models}/${id}`,
                    method: 'DELETE',
                }
            }
        })

    })
})
export const {useGetModelByProjectIdQuery, useGetModelsListQuery, useSetNewModelMutation, useEditModelMutation, useDeleteModelMutation} = ModelApi