import { News, NewsResponse } from "@aten/common/dist/models/News";
import { UrlApi } from "config/api";
import { IPagination } from "hooks/usePagination";
import { commonApi } from "services/commonApiService";

export const newsApi = commonApi.injectEndpoints({
    endpoints: (build) => ({
        getNews: build.query<NewsResponse, { pagination: IPagination }>({
            query: ({ pagination }) => ({
                url: UrlApi.News,
                params: {
                    ...pagination,
                },
            }),
            providesTags: ["News"],
        }),
        getNewsById: build.query<News, string>({
            query: (id: string) => `${UrlApi.News}/${id}`,
            transformResponse: (res: NewsResponse) => {
                return res.objects[0];
            },
            providesTags: ["News"],
        }),
        editNews: build.mutation<string, News>({
            query: (body: News) => ({
                url: `${UrlApi.News}/${body.id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: ["News"],
        }),
        setNews: build.mutation<string, News>({
            query: (body: News) => ({
                url: UrlApi.News,
                method: "POST",
                body,
            }),
            invalidatesTags: ["News"],
        }),
        deleteNews: build.mutation<NewsResponse, string>({
            query: (id: string) => ({
                url: `${UrlApi.News}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["News"],
        }),
    }),
});

export const { useGetNewsQuery, useGetNewsByIdQuery, useEditNewsMutation, useSetNewsMutation, useDeleteNewsMutation } =
    newsApi;
