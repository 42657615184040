import { Spin } from "antd";
import { CustomButton } from "components/CustomButton/CustomButton";
import { Icons } from "components/Icons/Icons";
import useFluid from "hooks/useFluid";
import useModal from "hooks/useModal";
import usePagination from "hooks/usePagination";
import PriceListSearchModal from "pages/PriceLists/Modals/PriceListSearchModal";
import styles from "pages/PriceLists/PriceLists.module.scss";
import PriceListsTable, { calculateWidthByColumns } from "pages/PriceLists/PriceListsTable/PriceListsTable";
import getOtherColumns from "pages/PriceLists/utils/getOtherColumns";
import React, { FC, useMemo, useState } from "react";
import { useGetPriceListParentCategoriesQuery } from "services/priceListParentCategoriesService";
import { usePriceListDownloadStandardMutation } from "services/priceListsService";
import { downloadBlob } from "utils/fileHelper";

const PriceListsOwn: FC = ({}) => {
    const { getFluidTableHeight } = useFluid();

    const { pagination, changePagination, current } = usePagination();
    const { visible: searchModal, open: openSearchModal, close: closeSearchModal } = useModal();
    const [searchParams, setSearchParams] = useState<string>();

    const {
        data: categories,
        isLoading,
        isFetching,
    } = useGetPriceListParentCategoriesQuery({
        ...pagination,
        showNativeType: false,
    });

    const [downloadStandard, { isLoading: loadStandard }] = usePriceListDownloadStandardMutation();

    const onDownloadStandard = async () => {
        const file = await downloadStandard({ searchParams, isOwn: false }).unwrap();
        downloadBlob({ fileName: "Standard other price-lists.xlsx", file });
    };

    const columns = useMemo(() => getOtherColumns(), []);

    const onSearch = (data: string) => {
        setSearchParams(data);
    };

    const onClearSearch = () => {
        setSearchParams("");
        closeSearchModal();
    };

    return (
        <Spin spinning={isLoading}>
            <div className={styles.topWrapper}>
                <CustomButton
                    wrapperClasses={`${styles.button} ${styles.button_bn}`}
                    buttonText="Загрузить Excel"
                    icon={<Icons.Download />}
                    loading={loadStandard}
                    onClick={onDownloadStandard}
                />
                <CustomButton
                    wrapperClasses={styles.searchBtn}
                    type="primary"
                    onClick={openSearchModal}
                    icon={<Icons.Search />}
                />
            </div>
            <PriceListsTable
                columns={columns}
                dataSource={categories?.objects}
                pagination={{
                    current,
                    onChange: changePagination,
                    total: categories?.total ?? 0,
                }}
                scroll={{ x: calculateWidthByColumns(columns), y: getFluidTableHeight(362, 402) }}
                loading={isLoading || isFetching}
                isOther
                searchParams={searchParams}
            />

            <PriceListSearchModal
                open={searchModal}
                onClearSearch={onClearSearch}
                onOk={onSearch}
                onCancel={closeSearchModal}
            />
        </Spin>
    );
};

export default PriceListsOwn;
