import { Phone } from "@aten/common/dist/models/Phones";
import { User, UserEditResponse } from "@aten/common/dist/models/User";
import { Button, Col, Input, message, Row } from "antd";
import cl from "assets/scss/Space.module.scss";
import ButtonDelete from "components/CustomButton/ButtonDelete";
import { CustomButton } from "components/CustomButton/CustomButton";
import clButton from "components/CustomButton/CustomButton.module.scss";
import Form, { Item, List, useForm } from "components/Form/Form";
import { Icons } from "components/Icons/Icons";
import InputWithMask from "components/Input/InputWithMask";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import Loader from "components/Loader/Loader";
import SelectDefault from "components/Select/SelectDefault";
import { authUrl } from "config/path";
import { optionsRole } from "data/options";
import { FC, useEffect } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useDeletePhoneMutation, useEditPhoneMutation, useSetPhoneMutation } from "services/phonesService";
import { useSetUserMutation, useUpdateUserMutation } from "services/userService";
import { IFormProps } from "types/form";
import concatClasses from "utils/concatClasses";
import { numToDate } from "utils/dateConverter";
import { emailValidate, onlyLettersValidation, required } from "utils/getRules";
import clForm from "./UserFormEdit.module.scss";

interface UserFormEditProps extends IFormProps<User> {
    isCreate: boolean;
    deleteUser: () => void;
    isLoadingDelete: boolean;
}

const UserFormEdit: FC<UserFormEditProps> = ({
    isLoadingDelete,
    deleteUser,
    isCreate,
    data: user,
    isLoading,
    ...props
}) => {
    const { id } = useParams();

    const navigate = useNavigate();

    const [form] = useForm<typeof user>();
    const [setUser] = useSetUserMutation();
    const [updateUser] = useUpdateUserMutation();
    const [setPhone] = useSetPhoneMutation();
    const [editPhone] = useEditPhoneMutation();
    const [deletePhone] = useDeletePhoneMutation();

    const disabledButtons = isLoadingDelete || isCreate;

    useEffect(() => {
        form.setFields([
            {
                name: "phones",
                value: [...(id ? user?.phones ?? [] : [])],
            },
        ]);
    }, [form, id, user?.phones]);

    const onFinish = async (values: User) => {
        const newData = { ...user, ...values };
        const phones = form.getFieldsValue(["phones"]).phones;
        let response: UserEditResponse = id ? await updateUser(newData).unwrap() : await setUser(newData).unwrap();
        phones &&
            phones.forEach((phone: Phone) => {
                if (phone.id) {
                    editPhone({ id: phone.id, userId: phone.userId, phoneValue: phone.phoneValue });
                    return;
                }
                setPhone({ id: 0, userId: response.addedUserId || response.updatedId, phoneValue: phone.phoneValue });
            });
        id ? message.success("Пользователь отредактирован") : message.success("Пользователь добавлен");
        goBack();
    };

    const goBack = () => {
        navigate(-1);
    };

    if (isLoading) {
        return <Loader />;
    }

    const insertPhone = () => {
        form.setFields([
            {
                name: "phones",
                value: [...form.getFieldValue("phones"), ""],
            },
        ]);
    };

    return (
        <Form
            data={user}
            onFinish={onFinish}
            form={form}
            className={concatClasses(clForm.form, clForm.form__vertical)}
            {...props}>
            <>
                <Row className={concatClasses(cl.space, cl.space__between)} gutter={[30, 30]}>
                    <Col span={8}>
                        <Item
                            name="surname"
                            label={<Label type={LabelTypes.Form} label={"Фамилия"} />}
                            rules={[onlyLettersValidation("Фамилия должны содержать буквы"), required()]}>
                            <Input />
                        </Item>
                        <Item
                            name="name"
                            label={<Label type={LabelTypes.Form} label={"Имя"} />}
                            rules={[required(), onlyLettersValidation("Имя должно содержать буквы")]}>
                            <Input />
                        </Item>
                        <Item name="patronymic" label={<Label type={LabelTypes.Form} label={"Отчество"} />}>
                            <Input />
                        </Item>
                        <Item
                            name="login"
                            label={<Label type={LabelTypes.Form} label={"Логин"} />}
                            rules={[required()]}>
                            <Input />
                        </Item>
                        <Item name="city" label={<Label type={LabelTypes.Form} label={"Город"} />} rules={[required()]}>
                            <Input />
                        </Item>
                        <Item name="inn" label={<Label type={LabelTypes.Form} label={"ИНН"} />} rules={[required()]}>
                            <InputWithMask mask="9999 9999 9999 9999" />
                        </Item>
                        <List name="phones">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field, idx) => {
                                        return (
                                            <div key={field.key}>
                                                <Item
                                                    rules={[
                                                        {
                                                            validator: async (_, names: string) => {
                                                                if (!names || names.replace(/_/g, "").length < 16) {
                                                                    return Promise.reject(
                                                                        new Error("Введите номер полностью")
                                                                    );
                                                                }
                                                            },
                                                        },
                                                    ]}
                                                    name={[field.name, "phoneValue"]}
                                                    label={
                                                        idx === 0 ? (
                                                            <Label type={LabelTypes.Form} label={"Телефон"} />
                                                        ) : (
                                                            ""
                                                        )
                                                    }>
                                                    <InputWithMask mask="+7 999 999 99 99" />
                                                </Item>
                                                <Icons.Delete
                                                    onClick={() => {
                                                        id
                                                            ? deletePhone(
                                                                  form.getFieldsValue(["phones"]).phones[idx].id
                                                              ).then(() => remove(field.name))
                                                            : remove(field.name);
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                                </>
                            )}
                        </List>
                    </Col>
                    <Col span={8}>
                        <Item
                            name="email"
                            label={<Label type={LabelTypes.Form} label={"E-mail"} />}
                            rules={[required(), emailValidate()]}>
                            <Input />
                        </Item>
                        <Item name="role" label={<Label type={LabelTypes.Form} label={"Роль"} />} rules={[required()]}>
                            <SelectDefault options={optionsRole} />
                        </Item>
                        <Item
                            name="company"
                            label={<Label type={LabelTypes.Form} label={"Компания"} />}
                            rules={[required()]}>
                            <Input />
                        </Item>
                        <Item
                            name="password"
                            label={<Label type={LabelTypes.Form} label={"Пароль"} />}
                            rules={[required()]}>
                            {/*todo хеш пароля админу не надо видеть, оставить поле пустым, проверять при сохранении */}
                            <Input disabled={!!user} />
                        </Item>
                        <Item
                            name="country"
                            label={<Label type={LabelTypes.Form} label={"Страна"} />}
                            rules={[required()]}>
                            <Input />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Button
                            className={concatClasses(clForm.rightBtn)}
                            disabled={disabledButtons}
                            onClick={() =>
                                navigate(
                                    generatePath(authUrl.Index.Projects.ForUser.url, {
                                        userId: (user?.id ?? "").toString(),
                                    })
                                )
                            }>
                            Проекты пользователя
                        </Button>
                        <Button
                            className={concatClasses(clForm.rightBtn)}
                            disabled={disabledButtons}
                            onClick={() =>
                                navigate(
                                    generatePath(authUrl.Index.Courses.ForUser.url, {
                                        userId: (user?.id ?? "").toString(),
                                    })
                                )
                            }>
                            Курсы пользователя
                        </Button>

                        <Button
                            className={concatClasses(clForm.rightBtn)}
                            disabled={disabledButtons}
                            onClick={() =>
                                navigate(
                                    generatePath(authUrl.Index.OfflineEvents.ForUser.url, {
                                        userId: (user?.id ?? "").toString(),
                                    })
                                )
                            }>
                            Офлайн мероприятия
                        </Button>
                    </Col>
                </Row>
                <Row className={concatClasses(cl.space, cl.space__start)} gutter={[30, 30]}>
                    <Col span={8}>
                        <CustomButton
                            onClick={insertPhone}
                            buttonText="Добавить телефон"
                            icon={<Icons.PlusDark />}
                            wrapperClasses={concatClasses(clButton.addButton_fullWidth, clButton.addButton_noMargin)}
                        />
                    </Col>
                    <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                        <Label type={LabelTypes.Gray}>
                            <>Дата регистрации: {numToDate(user?.registrationDate)}</>
                        </Label>
                    </Col>
                </Row>
                <Row className={concatClasses(cl.space, cl.space__between)} gutter={[30, 30]}>
                    <Col span={8}>
                        <Button type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                    </Col>
                    <Col span={8}>
                        <Button onClick={goBack}>Отменить</Button>
                    </Col>
                    <Col span={8}>
                        <ButtonDelete
                            disabled={true}
                            modalOnClick={true}
                            modalText="Вы уверены, что хотите удалить пользователя?"
                            onOk={deleteUser}
                            wrapperClasses={concatClasses(clButton.addButton_fullWidth, clButton.addButton_noMargin)}
                            buttonText="Удалить пользователя"
                        />
                    </Col>
                </Row>
            </>
        </Form>
    );
};

export default UserFormEdit;
