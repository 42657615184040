import {PaginationProps} from "antd";
import {Icons} from "components/Icons/Icons";
import React from "react";

export const itemRenderPaging: PaginationProps["itemRender"] = (_, type, originalElement) => {
    if (type === "prev") {
        return <Icons.PaginPrev />;
    }
    if (type === "next") {
        return <Icons.PaginNext />;
    }

    return originalElement;
};