import { MembersFormEdit } from "components/Form/MembersFormEdit/MembersFormEdit";
import useLayout from "hooks/useLayout";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useGetMembersByIdQuery } from "services/membersService";

const MembersEdit: FC = () => {
    const { id } = useParams();

    const { data, isLoading } = useGetMembersByIdQuery(id ?? "", { skip: !id });

    useLayout(
        {
            title: data?.fio ?? "Добавить сотрудника",
            loading: isLoading,
        },
        [data]
    );

    return <MembersFormEdit data={data} />;
};

export default MembersEdit;
