import { Tag } from "antd";
import { FC } from "react";
import { CourseUserStatus } from "./lib";

interface ProgressStatusProps {
    status: CourseUserStatus;
}

const statusObj: Record<
    CourseUserStatus,
    {
        color: string;
        name: string;
    }
> = {
    [CourseUserStatus.Passed]: {
        color: "success",
        name: "Сдал",
    },
    [CourseUserStatus.NotPassed]: {
        color: "error",
        name: "Не сдал",
    },
};

const CourseUserProgressStatus: FC<ProgressStatusProps> = ({ status }) => {
    const { color, name } = statusObj[status];

    return <Tag color={color}>{name}</Tag>;
};

export default CourseUserProgressStatus;
