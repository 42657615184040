import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { ISort } from "hooks/useSort";
import { FC } from "react";
import concatClasses from "utils/concatClasses";
import styles from "./TableHeaderController.module.scss";

interface TableHeaderControllerProps {
    title: string;
    propertyName?: string;
    sorting?: boolean;
    sort?: ISort;
    handleSortChange?: (sortSetting: ISort) => void;
}

const TableHeaderController: FC<TableHeaderControllerProps> = ({
    title,
    propertyName,
    sorting = false,
    sort,
    handleSortChange,
}) => {
    const changeSort = () => {
        if (sort?.propertyName === propertyName) {
            if (sort?.isAscending === true) handleSortChange?.({ propertyName, isAscending: false });
            if (sort?.isAscending === false) handleSortChange?.({ propertyName, isAscending: undefined });
            if (sort?.isAscending === undefined) handleSortChange?.({ propertyName, isAscending: true });
        } else handleSortChange?.({ propertyName, isAscending: true });
    };

    return (
        <div className={styles.headerTable}>
            <div className={styles.headerTable__title}>
                {title}
                {sorting && (
                    <div className={styles.sort} onClick={changeSort}>
                        <CaretUpOutlined
                            className={concatClasses(
                                styles.sort__arrow,
                                styles.sort__arrow_up,
                                sort?.propertyName === propertyName &&
                                    sort?.isAscending === false &&
                                    styles.sort__arrow__active
                            )}
                        />
                        <CaretDownOutlined
                            className={concatClasses(
                                styles.sort__arrow,
                                styles.sort__arrow_down,
                                sort?.propertyName === propertyName &&
                                    sort?.isAscending === true &&
                                    styles.sort__arrow__active
                            )}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
export default TableHeaderController;
