import { UrlApi } from "config/api";
import { commonApi } from "services/commonApiService";

export const ProjectsToEngineersApi = commonApi.injectEndpoints({
    endpoints: (build) => ({
        setEngineerToProject: build.mutation<void, { engineerId: string | number; projectId: string | number }>({
            query: ({ engineerId, projectId }) => ({
                url: UrlApi.ProjectsToEngineers,
                method: "POST",
                body: {
                    engineerId,
                    projectId,
                },
            }),
        }),
        deleteEngineerToProject: build.mutation<void, number | undefined>({
            query: (id) => ({
                url: UrlApi.ProjectsToEngineers + "/" + id,
                method: "DELETE",
            }),
        }),
    }),
});

export const { useSetEngineerToProjectMutation, useDeleteEngineerToProjectMutation } = ProjectsToEngineersApi;
