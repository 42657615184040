import { CoursesToThemes, CoursesToThemesResponse } from "@aten/common/dist/models/CoursesToThemes";
import { UrlApi } from "config/api";
import { commonApi } from "services/commonApiService";

interface objectQuery {
    courseId?: string;
    themeId?: string;
}

export const themesToCoursesApi = commonApi.injectEndpoints({
    endpoints: (build) => ({
        getThemeToCourseId: build.query<CoursesToThemesResponse, objectQuery>({
            query: ({ courseId, themeId }) => `${UrlApi.ThemesToCourses}?CourseId=${courseId}&ThemeId=${themeId}`,
            providesTags: ["Course"],
        }),
        setThemeToCourse: build.mutation<string, CoursesToThemes>({
            query: (data: CoursesToThemes) => ({
                url: UrlApi.ThemesToCourses,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Course", "Theme"],
        }),
        deleteThemeToCourse: build.mutation<string, string>({
            query: (id: string) => ({
                url: `${UrlApi.ThemesToCourses}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Course", "Theme"],
        }),
    }),
});

export const themesToCoursesExntendedApi = themesToCoursesApi.injectEndpoints({
    endpoints: (build) => ({
        deleteThemeToCourseById: build.mutation<string, objectQuery>({
            async queryFn(data, { dispatch }) {
                const themeToCourseId = await dispatch(themesToCoursesApi.endpoints.getThemeToCourseId.initiate(data))
                    .unwrap()
                    .then((result) => result.objects[0].id);

                return {
                    data: await dispatch(
                        themesToCoursesApi.endpoints.deleteThemeToCourse.initiate(themeToCourseId?.toString() ?? "")
                    ).unwrap(),
                };
            },
            invalidatesTags: ["ThemeToCourse"],
        }),
    }),
});

export const { useSetThemeToCourseMutation, useDeleteThemeToCourseByIdMutation } = themesToCoursesExntendedApi;
