import * as routerDom from "react-router-dom";

const useNavigate = () => {
    const navigate = routerDom.useNavigate();
    const goBack = (ms: number = 1500) => {
        setTimeout(() => {
            navigate(-1);
        }, ms);
    };

    return {
        goBack,
        navigate,
    };
};

export default useNavigate;
