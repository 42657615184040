import Modal, { ModalProps } from "components/Modal/Modal";
import React, { FC } from "react";
import concatClasses from "utils/concatClasses";
import cl from "./Modal.module.scss";

type ModalDeleteProps = ModalProps;

const ModalDelete: FC<ModalDeleteProps> = ({
    width = 410,
    className,
    closable = false,
    maskClosable = true,
    okButtonText = "Да",
    cancelButtonText = "Нет",
    ...props
}) => {
    return (
        <Modal
            destroyOnClose={true}
            forceRender
            width={width}
            closable={closable}
            className={concatClasses(className, cl.modal_delete)}
            maskClosable={maskClosable}
            okButtonText={okButtonText}
            cancelButtonText={cancelButtonText}
            {...props}
        />
    );
};

export default ModalDelete;
