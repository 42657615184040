import { EventGuests } from "@aten/common/dist/models/EventGuests";
import { EventRegistration } from "@aten/common/dist/models/eventsRegistration";
import { useMemo } from "react";
import { useGetAllMembersQuery } from "services/eventRegistrationsApi";

type EventMemberEntity = {
    id?: string | number;
    name?: string;
    surname?: string;
};

type EventRegistrationTuple = [EventRegistration[], EventGuests[]];

const memberKeyGenerator = ({ surname, name, id }: EventMemberEntity) => `${surname}${name}${id}`;
const memberWithKey = <U>(user: U & EventMemberEntity) => ({ ...user, key: memberKeyGenerator(user) });

const sortMembersByEventRegistrationId = ([mainMembers, relatedMembers]: EventRegistrationTuple) => {
    const allMembersList = mainMembers.map((mainEl) => {
        return [mainEl, ...relatedMembers.filter((relEl) => relEl.eventRegistrationId === mainEl.id)];
    });
    return allMembersList.flat().map(memberWithKey);
};

const useGettingGuests = (eventId: string) => {
    const { data } = useGetAllMembersQuery(eventId || "", {
        skip: !eventId,
        refetchOnMountOrArgChange: true,
    });

    const usersInEvent = useMemo(() => {
        if (data && data.length > 0) {
            return sortMembersByEventRegistrationId(data as EventRegistrationTuple);
        }
        return [];
    }, [data]);

    return { usersInEvent };
};

export default useGettingGuests;
