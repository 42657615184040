export interface GetContentAndFuncForDelete {
    type?: ModalType;
    deleteTheme?: () => Promise<void>;
    deleteLesson?: () => Promise<void>;
    deleteTest?: () => Promise<void>;
    deleteCourse?: () => Promise<void>;
    themeTitle?: string;
    courseTitle?: string;
    lessonTitle?: string;
    testTitle?: string;
}

export interface GetContentAndFuncForDeleteReturn {
    title?: string;
    func?: () => Promise<void>;
}

export type ModalType = "theme" | "course" | "lesson" | "test";

const getContentAndFuncForDelete = ({ type, ...props }: GetContentAndFuncForDelete) => {
    const getData = (props: GetContentAndFuncForDelete): GetContentAndFuncForDeleteReturn => ({
        title: `Вы уверены, что хотите удалить ${
            props.lessonTitle ?? props.testTitle ?? props.courseTitle ?? props.themeTitle
        }`,
        func: props.deleteLesson ?? props.deleteTest ?? props.deleteCourse ?? props.deleteTheme,
    });

    const getModalDataFromType: Record<ModalType, GetContentAndFuncForDeleteReturn> = {
        lesson: getData({ deleteLesson: props?.deleteLesson, lessonTitle: `урок "${props.lessonTitle}"` }),
        theme: getData({ deleteTheme: props?.deleteTheme, themeTitle: `тему "${props.themeTitle}"` }),
        course: getData({ deleteCourse: props?.deleteCourse, courseTitle: `курс "${props.courseTitle}"` }),
        test: getData({ deleteTest: props?.deleteTest, testTitle: `тест "${props.testTitle}"` }),
    };

    return type && getModalDataFromType[type];
};

export default getContentAndFuncForDelete;
