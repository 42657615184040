import { LicencesTableData } from "@aten/common/models/LicencesTableData";
import { ColumnsType } from "antd/es/table";
import { CustomButton } from "components/CustomButton/CustomButton";
import { CustomTable } from "components/CustomTable/CustomTable";
import { Icons } from "components/Icons/Icons";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import ModalDelete from "components/Modal/ModalDelete";
import { useAppSelector } from "hooks/redux";
import useFluid from "hooks/useFluid";
import useModal from "hooks/useModal";
import usePagination from "hooks/usePagination";
import certificatesColumnsTable from "pages/Certificates/certificatesColumnsTable";
import CertificatesModalSearch from "pages/Certificates/CertificatesModalSearch";
import ModalAddCertificate from "pages/Certificates/ModalAddCertificate";
import React, { useState } from "react";
import {
    useDeleteLicencesMutation,
    useGetLicencesTableDataQuery,
    useLazyDownloadLicencesQuery,
} from "services/licencesServices";
import { ITableData } from "types/table";
import styles from "./Certificates.module.scss";

export type DeletingCertificatesProps = {
    title: string;
    id: string;
};
const Certificates = () => {
    const user = useAppSelector((selector) => selector.auth.user);
    const { fluid, getFluidTableHeight } = useFluid();

    const [dataCertificate, setDataCertificate] = useState<LicencesTableData>();
    const [deletingCertificate, setDeletingCertificate] = useState<DeletingCertificatesProps>();
    const [searchString, setSearchString] = useState<string>("");

    const { visible: searchVisible, close: searchClose, open: searchOpen } = useModal({});
    const { visible: deleteVisible, close: deleteClose, open: deleteOpen } = useModal({});
    const { visible, close, open } = useModal({});
    const { changePagination, pagination } = usePagination();
    const {
        data: certificates,
        isLoading,
        isFetching,
    } = useGetLicencesTableDataQuery(
        {
            pagination,
            userId: user?.id,
            searchString,
        },
        { refetchOnMountOrArgChange: true }
    );
    const [deleteLicences, { isLoading: isLoadingDelete }] = useDeleteLicencesMutation();
    const [downloadLicences] = useLazyDownloadLicencesQuery();

    const deleteCertificate = () => {
        deleteLicences(deletingCertificate?.id ?? "").then(deleteClose);
    };
    const createCertificate = () => {
        open();
        setDataCertificate(undefined);
    };
    const searchCertificate = (val: string) => {
        setSearchString(val);
    };

    return (
        <>
            <LayoutWrapper>
                <div className={styles.actions}>
                    <CustomButton
                        type="primary"
                        buttonText={"Добавить сертификат"}
                        icon={<Icons.Plus />}
                        onClick={createCertificate}
                    />
                    <CustomButton
                        wrapperClasses={styles.searchBtn}
                        type="primary"
                        onClick={searchOpen}
                        icon={<Icons.Search />}
                    />
                </div>

                <CustomTable
                    data={certificates?.licencesTableData?.objects}
                    columns={
                        certificatesColumnsTable({
                            setModalCertificate: open,
                            setDeleteCertificateModal: deleteOpen,
                            setDeletingCertificate,
                            setDataCertificate,
                            downloadLicences,
                        }) as ColumnsType<ITableData>
                    }
                    scroll={{
                        y: getFluidTableHeight(361, 397),
                    }}
                    pagination={{ total: certificates?.licencesTableData?.total, onChange: changePagination }}
                    loading={isLoading || isFetching}
                />
            </LayoutWrapper>

            <ModalAddCertificate
                open={visible}
                onClose={close}
                courses={certificates?.courses.objects}
                data={dataCertificate}
                userId={user?.id ?? 0}
            />
            <ModalDelete
                title={`Вы уверены, что хотите удалить Сертификат "${deletingCertificate?.title}"`}
                open={deleteVisible}
                onCancel={deleteClose}
                loading={isLoadingDelete}
                onOk={deleteCertificate}
            />
            <CertificatesModalSearch
                courses={certificates?.courses.objects}
                onOk={searchCertificate}
                visible={searchVisible}
                close={searchClose}
            />
        </>
    );
};

export default Certificates;
