import formTransform from "pages/PriceLists/utils/convertFormValues/formTransforms";
import requestTransform from "pages/PriceLists/utils/convertFormValues/requestTransforms";

type FormTransformType = keyof typeof formTransform;
type RequestTransformType = keyof typeof requestTransform;

type FormTransformKeys<T extends object> = {
    [key in keyof typeof formTransform]?: Array<keyof T>;
};

type RequestTransformKeys<T extends object> = {
    [key in keyof typeof requestTransform]?: Array<keyof T>;
};

type TransformType<T extends object> = FormTransformKeys<T> | RequestTransformKeys<T>;

const convertFormValues = <T extends object, K extends TransformType<T>>(
    initialValues: T,
    keys: K,
    forForm?: boolean
) => {
    return Object.entries(keys).reduce(
        (values, [transformType, keyList]) => {
            const valuesByTransform = keyList?.reduce((obj, key) => {
                const transformFn = forForm
                    ? formTransform[transformType as FormTransformType]
                    : requestTransform[transformType as RequestTransformType];

                return {
                    ...obj,
                    [key]: transformFn(initialValues[key] ?? null),
                };
            }, {});

            return {
                ...values,
                ...valuesByTransform,
            };
        },
        { ...initialValues }
    );
};

export const convertToForm = <T extends object>(initialValues: T, keys: FormTransformKeys<T>) =>
    convertFormValues(initialValues, keys, true);
export const convertToRequest = <T extends object>(initialValues: T, keys: RequestTransformKeys<T>) =>
    convertFormValues(initialValues, keys, false);

export default convertFormValues;
