import { CustomButton } from "components/CustomButton/CustomButton";
import { CustomTable } from "components/CustomTable/CustomTable";
import { Icons } from "components/Icons/Icons";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import ModalDelete from "components/Modal/ModalDelete";
import ModalProjectReport from "components/Modal/ModalProjectReport/ModalProjectReport";
import { authUrl } from "config/path";
import { useAppSelector } from "hooks/redux";
import useFluid from "hooks/useFluid";
import useModal from "hooks/useModal";
import usePagination from "hooks/usePagination";
import useSort from "hooks/useSort";
import ProjectModalSearch from "pages/Projects/ProjectModalSearch";
import projectsColumnsTable from "pages/Projects/projectsColumnsTable";
import { FC, useState } from "react";
import { Link, useParams } from "react-router-dom";
import projectsSerializer from "serializers/project";
import { useDeleteProjectMutation, useGetProjectsQuery } from "services/projectsService";
import style from "./Projects.module.scss";
import {ModalProjectMailDistribution} from "../../components/Modal/ModalProjectMailDistribution";
import {ModalMinCostProject} from "../../components/Modal/ModalMinCostProject/ModalMinCostProject";
import {useProjectCost} from "./hooks/useProjectCost";

export type DeletingProjectsProps = {
    title: string | null;
    id: string | number;
};
const Projects: FC = () => {
    const { pagination, changePagination } = usePagination();
    const { sort, handleSortChange } = useSort();
    const { visible: searchModal, open: openSearchModal, close: closeSearchModal } = useModal();
    const [reportModal, setReportModal] = useState<boolean>(false);
    const [distributionModal, setDistributionModal] = useState<boolean>(false);
    const [minCostModal, setMinCostModal] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [deleteProjects, setDeleteProjects] = useState<DeletingProjectsProps>({ id: "", title: "" });
    const auth = useAppSelector((state) => state.auth);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const { user } = auth;
    const { userId } = useParams();
    const { getFluidTableHeight } = useFluid();

    const {costData, setCosts} = useProjectCost()

    const { data, isLoading, isFetching } = useGetProjectsQuery(
        {
            pagination,
            userId: userId ?? "",
            sorting: sort,
            params: searchQuery,
        },
        { skip: !user?.id, refetchOnMountOrArgChange: true }
    );

    const [deleteProject, { isLoading: deleteProjectLoading }] = useDeleteProjectMutation();

    const projects = projectsSerializer(data);

    return (
        <>
            <LayoutWrapper>
                <div className={style.actions}>
                    <div>
                        <CustomButton type="primary" buttonText={"Мин. сумма проекта"} onClick={()=>setMinCostModal(true)} />
                    </div>
                    <div>
                        <CustomButton type="primary" buttonText={"Создать рассылку"} onClick={()=>setDistributionModal(true)} />
                    </div>
                    <div>
                        <CustomButton type="primary" buttonText={"Выгрузить отчет"} onClick={() => setReportModal(true)} />
                    </div>
                    <Link to={authUrl.Index.Projects.New.url}>
                        <CustomButton type="primary" buttonText={"Добавить"} icon={<Icons.Plus />} />
                    </Link>
                    <CustomButton
                        wrapperClasses={style.searchBtn}
                        type="primary"
                        onClick={openSearchModal}
                        icon={<Icons.Search />}
                    />
                </div>
                <CustomTable
                    data={projects}
                    columns={projectsColumnsTable({
                        setDeleteModal,
                        setDeleteProjects,
                        sort,
                        handleSortChange,
                    })}
                    pagination={{
                        ...pagination,
                        total: data?.total,
                        onChange: changePagination,
                    }}
                    loading={isLoading || isFetching}
                    scroll={{ y: getFluidTableHeight(368, 407) }}
                />
            </LayoutWrapper>
            <ModalDelete
                loading={deleteProjectLoading}
                title={`Вы уверены, что хотите удалить проект "${deleteProjects.title}"`}
                open={deleteModal}
                onCancel={() => setDeleteModal(false)}
                onOk={() => deleteProject(deleteProjects.id).then(() => setDeleteModal(false))}
            />
            <ProjectModalSearch
                data={data}
                visible={searchModal}
                actions={{
                    getData: setSearchQuery,
                    closeModal: closeSearchModal,
                }}
            />
            <ModalProjectReport
                projectsQuantity={data?.total ?? 0}
                open={reportModal}
                onCancel={() => setReportModal(false)}
                onOk={() => {
                    setReportModal(false);
                }}
            />
            <ModalProjectMailDistribution
                projectList = {data?.objects}
                open={distributionModal}
                onCancel={()=>setDistributionModal(false)}
            />
            <ModalMinCostProject
                setCosts={setCosts}
                values = {costData}
                open={minCostModal}
                close={()=>setMinCostModal(false)}
                onCancel={()=>setMinCostModal(false)}
            />

        </>
    );
};
export default Projects;
