import { SelectProps } from "antd/lib/select";

export const booleanNullOptions: SelectProps["options"] = [
    {
        value: "null",
        label: "Не задано",
    },
    {
        value: "true",
        label: "Да",
    },
    {
        value: "false",
        label: "Нет",
    },
];
