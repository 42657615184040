import { Catalogue } from "@aten/common/models/Catalogue";
import { Space, Typography } from "antd";
import { message } from "antd/es";
import { Icons } from "components/Icons/Icons";
import Loader from "components/Loader/Loader";
import { authUrl } from "config/path";
import { ModalDeleteCataloguesProps } from "pages/Catalogues/Catalogues";
import styles from "pages/Catalogues/Catalogues.module.scss";
import React, { FC } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useGetFileByIdQuery } from "services/fileService";
import { downloadLocalBlob, linkBlob } from "utils/fileHelper";

interface ICatalogItem {
    className?: string;
    catalogue: Catalogue;
    setDeleteModal: (isOpen: boolean) => void;
    setModalDeleteCatalogues: (props: ModalDeleteCataloguesProps) => void;
}

export const CatalogueItem: FC<ICatalogItem> = ({ className, catalogue, setDeleteModal, setModalDeleteCatalogues }) => {
    const navigate = useNavigate();

    const { data: file } = useGetFileByIdQuery(catalogue.fileId);
    const { data: fileImg, isLoading, isFetching } = useGetFileByIdQuery(catalogue.imageId);

    const onClickEditButton = () => {
        navigate(
            generatePath(authUrl.Index.Catalogues.Edit.url, {
                id: catalogue.id.toString(),
            })
        );
    };

    const onClickDeleteButton = () => {
        setModalDeleteCatalogues({ id: catalogue.id.toString(), title: catalogue.title });
        setDeleteModal(true);
    };

    const downloadFile = () => {
        file ? downloadLocalBlob(file) : message.error("Файл отсутствует");
    };

    return (
        <div className={styles.item + (className ? ` ${className}` : "")}>
            {isLoading || isFetching ? (
                <Loader />
            ) : (
                <img src={linkBlob(fileImg ?? "")} alt="" className={styles.item__img} />
            )}

            <div className={styles.item__desc}>
                <h3>{catalogue.title}</h3>
                <Typography.Paragraph ellipsis={{ rows: 3, expandable: true }}>
                    {catalogue.fullTextDescription}
                </Typography.Paragraph>
                <div className={styles.item__links}>
                    <div className={styles.item__links_block}>
                        <a onClick={downloadFile}>Скачать</a>
                        {/*<a href="pages/Catalogues/CatalogueItem">Просмотреть на сайте</a>*/}
                    </div>
                    <Space size={10} className={styles.item__links_block}>
                        <Icons.EditButton onClick={onClickEditButton} />
                        <Icons.Delete onClick={onClickDeleteButton} />
                    </Space>
                </div>
            </div>
        </div>
    );
};
