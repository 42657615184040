import { FormItemLabelProps } from "antd/es/form/FormItemLabel";
import { LabelTypes, TLabelTypes, typeToClass } from "components/Label/labelTypes";
import React, { FC } from "react";

interface LabelProps extends FormItemLabelProps {
    type?: TLabelTypes;
    empty?: boolean;
}

const Label: FC<LabelProps> = ({ type = LabelTypes.Default, label, empty, children = "", ...props }) => {
    return (
        <span className={typeToClass[LabelTypes[type]]} {...props}>
            {empty ? "ㅤ" : children || label}
        </span>
    );
};

export default Label;
