import { Button, Layout } from "antd";
import Loader from "components/Loader/Loader";
import { authUrl } from "config/path";
import { useAppSelector } from "hooks/redux";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { generatePath, Link } from "react-router-dom";
import { logout } from "slices/authSlice";
import cl from "./Header.module.scss";

export interface IHeader {
    title: string;
    loading?: boolean;
}

const Header: FC<IHeader> = ({ title, loading = false }) => {
    const { user } = useAppSelector(({ auth }) => auth);
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(logout());
    };

    return (
        <Layout.Header className={cl.header}>
            <div className={cl.header__title}>{loading ? <Loader /> : title}</div>
            <div className={cl.header__item}>
                <div className={cl.header__logText}>
                    Вы вошли как:{" "}
                    <span className={cl.header__logUser}>
                        <Link
                            to={generatePath(authUrl.Index.Users.Edit.url, {
                                id: user?.id.toString() ?? "",
                            })}>
                            {user?.login ?? ""}
                        </Link>
                    </span>
                </div>
                <div className={cl.header__logOut}>
                    <Button onClick={onClick} type="primary">
                        Выйти
                    </Button>
                </div>
            </div>
        </Layout.Header>
    );
};

export default Header;
