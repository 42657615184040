import { TabsProps } from "components/Tabs/Tabs";
import { authUrl } from "config/path";

export const priceListsTabs: TabsProps["items"] = [
    {
        label: "Мои прайсы-листы",
        key: authUrl.Index.PriceLists.Own.url,
    },
    {
        label: "Чужие прайсы-листы",
        key: authUrl.Index.PriceLists.Other.url,
    },
];
