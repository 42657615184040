import { Members, MembersResponse } from "@aten/common/dist/models/Members";
import { UrlApi } from "config/api";
import { IPagination } from "hooks/usePagination";
import { commonApi } from "services/commonApiService";

export const membersApi = commonApi.injectEndpoints({
    endpoints: (build) => ({
        getMembers: build.query<MembersResponse, { pagination: IPagination }>({
            query: ({ pagination }) => ({
                url: UrlApi.Members,
                params: {
                    ...pagination,
                },
            }),
            providesTags: ["Members"],
        }),
        getMembersById: build.query<Members, string>({
            query: (id: string) => `${UrlApi.Members}/${id}`,
            providesTags: ["Members"],
        }),
        editMembers: build.mutation<string, Members>({
            query: (body: Members) => ({
                url: `${UrlApi.Members}/${body.id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: ["Members"],
        }),
        setMembers: build.mutation<string, Members>({
            query: (body: Members) => ({
                url: UrlApi.Members,
                method: "POST",
                body,
            }),
            invalidatesTags: ["Members"],
        }),
        deleteMembers: build.mutation<MembersResponse, string>({
            query: (id: string) => ({
                url: `${UrlApi.Members}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Members"],
        }),
    }),
});

export const {
    useGetMembersQuery,
    useGetMembersByIdQuery,
    useEditMembersMutation,
    useSetMembersMutation,
    useDeleteMembersMutation,
} = membersApi;
