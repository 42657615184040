import { Test } from "@aten/common/dist/models/Test";
import { TestQuestionResponse } from "@aten/common/dist/models/TestQuestion";

export const testDataConverter = (test?: Test, questions?: TestQuestionResponse) => {
  return (
    test && {
      ...test,
      testQuestions: questions?.objects,
    }
  );
};
