import { AtenEvent } from "@aten/common/dist/models/AtenEvent";
import { EventContact } from "@aten/common/dist/models/EventContact";
import { Button, Col, DatePicker, Input, message, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import cl from "assets/scss/Space.module.scss";
import { CustomButton } from "components/CustomButton/CustomButton";
import clButton from "components/CustomButton/CustomButton.module.scss";
import { CustomTable } from "components/CustomTable/CustomTable";
import Form, { Item, List, useForm } from "components/Form/Form";
import { Icons } from "components/Icons/Icons";
import InputWithMask from "components/Input/InputWithMask";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import ModalDelete from "components/Modal/ModalDelete";
import ModalMap from "components/Modal/ModalMap/ModalMap";
import TextEditor from "components/TextEditor/TextEditor";
import UploadPicture from "components/UploadPicture/UploadPicture";
import { authUrl } from "config/path";
import useLayout from "hooks/useLayout";
import useNavigate from "hooks/useNavigate";
import moment from "moment";
import { Moment } from "moment/moment";
import style from "pages/Cooperation/Cooperation.module.scss";
import useGettingGuests from "pages/OfflineEvents/hooks/useGettingGuests";
import { FC, useEffect, useState } from "react";
import { generatePath, useParams } from "react-router-dom";
import {
    useDeleteEventContactMutation,
    useEditEventContactMutation,
    useSetEventContactMutation,
} from "services/eventContactsService";
import {
    useDeleteEventMutation,
    useGetEventByIdQuery,
    useGetEventMemberExcelMutation,
    useNewEventMutation,
    useUpdateEventMutation,
} from "services/eventsServise";
import { useGetFileByIdQuery } from "services/fileService";
import { useGetLicencesTableDataQuery } from "services/licencesServices";
import concatClasses from "utils/concatClasses";
import { dateChoseValidation, required } from "utils/getRules";
import styles from "./OfflineEventsEdit.module.scss";

const OfflineEventsEdit: FC = () => {
    const { id } = useParams();
    const [form] = useForm();
    const { goBack } = useNavigate();

    const [fileId, setFileId] = useState<number>();
    const [edit, setEdit] = useState(!id);
    const [map, setMap] = useState(false);
    const [startDate, setStartDate] = useState<Moment | null | undefined>(undefined);
    const [deleteModal, setDeleteModal] = useState(false);
    const { data: licenses } = useGetLicencesTableDataQuery({ searchString: "" });

    const { data: event, isLoading } = useGetEventByIdQuery(id || "", {
        skip: !id,
        refetchOnMountOrArgChange: true,
    });
    const {
        data: avatarLink,
        isLoading: loading,
        isFetching: fetching,
    } = useGetFileByIdQuery(fileId ?? 0, {
        skip: !fileId,
        refetchOnMountOrArgChange: true,
    });
    const { usersInEvent } = useGettingGuests(id ?? "");

    const [exlDownload] = useGetEventMemberExcelMutation();
    const [updateEvent] = useUpdateEventMutation();
    const [newEvent] = useNewEventMutation();
    const [setEventContact] = useSetEventContactMutation();
    const [editEventContact] = useEditEventContactMutation();
    const [deleteEventContact] = useDeleteEventContactMutation();
    const [deleteEvent] = useDeleteEventMutation();

    useLayout(
        {
            title: event?.title ?? "Добавить новое оффлайн пероприятие",
            loading: isLoading,
        },
        [event]
    );

    useEffect(() => {
        form.setFieldsValue({
            ...event,
            startDate: moment(event?.startDate),
            endDate: moment(event?.endDate),
        });
        setStartDate(moment(event?.startDate));
        setFileId(event?.imageId);

        !id &&
            form.setFields([
                {
                    name: "eventContacts",
                    value: [],
                },
                {
                    name: "startDate",
                    value: null,
                },
                {
                    name: "endDate",
                    value: null,
                },
            ]);
    }, [event]);

    const onFinish = (values: AtenEvent) => {
        const newData = { ...event, ...values };

        let eventContacts = form.getFieldsValue(["eventContacts"]).eventContacts;

        newData.startDate = +moment(newData.startDate);
        newData.endDate = +moment(newData.endDate);

        id
            ? updateEvent(newData).then(() => message.success("Мероприятие отредактировано"))
            : newEvent(newData).then((res: any) => {
                  eventContacts.forEach((element: EventContact) => {
                      setEventContact({
                          id: 0,
                          eventId: res.data.addedId,
                          fio: element.fio,
                          phone: element.phone,
                      });
                  });
                  message.success("Мероприятие создано");
                  goBack(100);
              });

        if (id) {
            eventContacts.forEach((element: EventContact) => {
                !element.id
                    ? setEventContact({
                          id: 0,
                          eventId: +id,
                          fio: element.fio,
                          phone: element.phone,
                      })
                    : editEventContact({
                          id: element.id,
                          eventId: +id,
                          fio: element.fio,
                          phone: element.phone,
                      });
            });

            const defaultContactIdsList = event?.eventContacts.map(({ id }) => id);
            const newContIdsList = eventContacts.map(({ id }: AtenEvent) => id);

            const set = new Set(newContIdsList);
            const finalList = defaultContactIdsList?.filter((e) => !set.has(e));
            finalList?.forEach((id) => deleteEventContact(id.toString()));
        }

        setEdit(false);
    };

    const insertContact = () => {
        form.setFields([
            {
                name: "eventContacts",
                value: [...form.getFieldValue("eventContacts"), ""],
            },
        ]);
    };
    const avatarUpdate = (avatarId: number) => {
        setFileId(avatarId);
        form.setFieldValue("imageId", avatarId);
        form.validateFields(["imageId"]).then();
    };

    return (
        <>
            <LayoutWrapper>
                <Form data={{ event }} form={form} onFinish={onFinish}>
                    <>
                        <Row className={concatClasses(styles.rowMargin50, cl.space, cl.space__end)} gutter={[30, 50]}>
                            {!edit ? (
                                <>
                                    <Col span={8}>
                                        <Button
                                            type="primary"
                                            htmlType="button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setEdit(!edit);
                                            }}>
                                            Редактировать
                                        </Button>
                                    </Col>
                                </>
                            ) : (
                                <>
                                    <Col span={8}>
                                        <Button htmlType="submit" type="primary">
                                            {id ? "Сохранить изменения" : "Сохранить новое мероприятие"}
                                        </Button>
                                    </Col>
                                    <Col span={8} onClick={() => (id ? setEdit(!edit) : goBack(100))}>
                                        <Button>Отменить</Button>
                                    </Col>
                                    <Col span={8}>
                                        {id && (
                                            <Button onClick={() => setDeleteModal(true)} className={"buttonRed"}>
                                                Удалить мероприятие
                                            </Button>
                                        )}
                                    </Col>
                                </>
                            )}
                        </Row>
                        <Row className={concatClasses(styles.rowMargin50, cl.space, cl.space__end)} gutter={[30, 50]}>
                            <Col span={8}>
                                <div className={concatClasses(styles.labelMargin)}>
                                    <Label type={LabelTypes.Form}>Название мероприятия</Label>
                                </div>
                                {!edit ? (
                                    <div className={concatClasses(styles.lessonInfo)}>{event?.title}</div>
                                ) : (
                                    <>
                                        <Item name="title" rules={[required()]}>
                                            <Input />
                                        </Item>
                                    </>
                                )}
                                <div className={concatClasses(styles.labelMargin)}>
                                    <Label type={LabelTypes.Form}>Краткое описание мероприятия</Label>
                                </div>
                                {!edit ? (
                                    <div className={concatClasses(styles.lessonInfo)}>{event?.shortDescription}</div>
                                ) : (
                                    <>
                                        <Item name="shortDescription" rules={[required()]}>
                                            <TextArea />
                                        </Item>
                                    </>
                                )}
                                <div className={concatClasses(styles.labelMargin)}>
                                    <Label type={LabelTypes.Form}>Сертификат</Label>
                                </div>
                                {!edit ? (
                                    <div className={concatClasses(styles.lessonInfo)}>
                                        {
                                            licenses?.licencesTableData?.objects?.find(
                                                (item: any) => item.id === event?.licenceId
                                            )?.name
                                        }
                                    </div>
                                ) : (
                                    <>
                                        <Item name="licenceId" rules={[required()]}>
                                            <Select
                                                options={
                                                    licenses?.licencesTableData?.objects?.map(({ id, name }: any) => ({
                                                        value: id,
                                                        label: name,
                                                    })) ?? []
                                                }
                                            />
                                        </Item>
                                    </>
                                )}
                            </Col>
                            <Col span={8}>
                                <div className={concatClasses(styles.labelMargin)}>
                                    <Label type={LabelTypes.Form}>Описание мероприятия</Label>
                                </div>
                                {!edit ? (
                                    <div
                                        className={concatClasses(styles.description)}
                                        dangerouslySetInnerHTML={{
                                            __html: event?.description ?? "",
                                        }}></div>
                                ) : (
                                    <>
                                        <Item name="description" rules={[required()]}>
                                            <TextEditor
                                                imageAllow={false}
                                                value={form.getFieldValue("description")}
                                                callback={(htmlValue) =>
                                                    form.setFieldsValue({ description: htmlValue })
                                                }
                                            />
                                        </Item>
                                    </>
                                )}
                            </Col>

                            <Col span={8}>
                                <div className={style.label}>Изображение</div>
                                <Item name={"imageId"} rules={[required()]}>
                                    <UploadPicture
                                        loading={loading || fetching}
                                        action={avatarUpdate}
                                        url={avatarLink ?? ""}
                                    />
                                </Item>
                            </Col>
                        </Row>
                        <Row className={concatClasses(styles.rowMargin50, cl.space, cl.space__end)} gutter={[30, 50]}>
                            <Col span={8}>
                                <div className={concatClasses(styles.labelMargin)}>
                                    <Label type={LabelTypes.Form}>Количество мест</Label>
                                </div>
                                {!edit ? (
                                    <div className={concatClasses(styles.lessonInfo)}>{event?.vacancy}</div>
                                ) : (
                                    <>
                                        <Item
                                            name="vacancy"
                                            rules={[
                                                required(),
                                                {
                                                    validator: async (_, vacancy: number) => {
                                                        if (isNaN(vacancy)) {
                                                            return Promise.reject(new Error("Введите число"));
                                                        }
                                                    },
                                                },
                                            ]}>
                                            <Input />
                                        </Item>
                                    </>
                                )}
                            </Col>
                            <Col span={8}>
                                <div className={concatClasses(styles.labelMargin)}>
                                    <Label type={LabelTypes.Form}>Дата начала</Label>
                                </div>
                                {!edit ? (
                                    <div className={concatClasses(styles.lessonInfo)}>
                                        {moment(event?.startDate).format("DD.MM.YYYY")}
                                    </div>
                                ) : (
                                    <>
                                        <Item name="startDate" rules={[required()]}>
                                            <DatePicker
                                                onChange={(val) => {
                                                    setStartDate(val);
                                                    form.setFieldValue("endDate", val);
                                                }}
                                                disabledDate={(data) => dateChoseValidation(data).past}
                                                format={"DD.MM.YYYY"}
                                            />
                                        </Item>
                                    </>
                                )}
                            </Col>
                            <Col span={8}>
                                <div className={concatClasses(styles.labelMargin)}>
                                    <Label type={LabelTypes.Form}>Дата окончания</Label>
                                </div>
                                {!edit ? (
                                    <div className={concatClasses(styles.lessonInfo)}>
                                        {moment(event?.endDate).format("DD.MM.YYYY")}
                                    </div>
                                ) : (
                                    <>
                                        <Item name="endDate" rules={[required()]}>
                                            <DatePicker
                                                disabled={!startDate}
                                                format={"DD.MM.YYYY"}
                                                disabledDate={(data) => dateChoseValidation(data, startDate).past}
                                            />
                                        </Item>
                                    </>
                                )}
                            </Col>
                        </Row>
                        <Row className={concatClasses(styles.rowMargin50, cl.space, cl.space__end)} gutter={[30, 50]}>
                            <Col span={8}>
                                <div className={concatClasses(styles.labelMargin)}>
                                    <Label type={LabelTypes.Form}>Тематика</Label>
                                </div>
                                {!edit ? (
                                    <div className={concatClasses(styles.lessonInfo)}>{event?.themes}</div>
                                ) : (
                                    <>
                                        <Item name="themes" rules={[required()]}>
                                            <Input />
                                        </Item>
                                    </>
                                )}
                            </Col>
                            <Col span={8}>
                                <div className={concatClasses(styles.labelMargin)}>
                                    <Label type={LabelTypes.Form}>Категория</Label>
                                </div>
                                {!edit ? (
                                    <div className={concatClasses(styles.lessonInfo)}>{event?.category}</div>
                                ) : (
                                    <>
                                        <Item name="category" rules={[required()]}>
                                            <Input />
                                        </Item>
                                    </>
                                )}
                            </Col>
                            <Col span={8}>
                                <div className={styles.flexAddress}>
                                    <div>
                                        <div className={concatClasses(styles.labelMargin)}>
                                            <Label type={LabelTypes.Form}>Адрес</Label>
                                        </div>
                                        {!edit ? (
                                            <div className={concatClasses(styles.lessonInfo)}>{event?.address}</div>
                                        ) : (
                                            <>
                                                <Item name="address" rules={[required()]}>
                                                    <Input />
                                                </Item>
                                            </>
                                        )}
                                    </div>
                                    <div>
                                        <Button
                                            className={concatClasses(styles.buttonIcon)}
                                            type="primary"
                                            disabled={!edit}
                                            icon={<Icons.Address />}
                                            size="large"
                                            onClick={() => setMap(!map)}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row
                            className={concatClasses(styles.rowMargin50, cl.space, cl.space__end, cl.space__alignEnd)}
                            gutter={[30, 50]}>
                            <Col span={16}>
                                {!edit ? (
                                    <>
                                        <Row
                                            className={concatClasses(styles.mx0, cl.space, cl.space__end)}
                                            gutter={[30, 50]}>
                                            <Col span={12} className={concatClasses(styles.pl0)}>
                                                <div className={concatClasses(styles.labelMargin)}>
                                                    <Label type={LabelTypes.Form}>ФИО контактного лица</Label>
                                                </div>
                                            </Col>
                                            <Col span={12} className={concatClasses(styles.pr0)}>
                                                <div className={concatClasses(styles.labelMargin)}>
                                                    <Label type={LabelTypes.Form}>Телефон контактного лица</Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        {event?.eventContacts?.map((element) => (
                                            <Row
                                                className={concatClasses(styles.mx0, cl.space, cl.space__end)}
                                                gutter={[30, 0]}
                                                key={element.id}>
                                                <Col span={12} className={concatClasses(styles.pl0)}>
                                                    <div className={concatClasses(styles.lessonInfo)}>
                                                        {element.fio}
                                                    </div>
                                                </Col>
                                                <Col span={12} className={concatClasses(styles.pr0)}>
                                                    <div className={concatClasses(styles.lessonInfo)}>
                                                        {element.phone}
                                                    </div>
                                                </Col>
                                            </Row>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        <Row
                                            className={concatClasses(styles.mx0, cl.space, cl.space__end)}
                                            gutter={[30, 50]}>
                                            <Col span={12} className={concatClasses(styles.pl0)}>
                                                <div className={concatClasses(styles.labelMargin)}>
                                                    <Label type={LabelTypes.Form}>ФИО контактного лица</Label>
                                                </div>
                                            </Col>
                                            <Col span={12} className={concatClasses(styles.pr0)}>
                                                <div className={concatClasses(styles.labelMargin)}>
                                                    <Label type={LabelTypes.Form}>Телефон контактного лица</Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <List name="eventContacts">
                                            {(fields, { remove }) => (
                                                <>
                                                    {fields.map((field, idx) => {
                                                        return (
                                                            <Row
                                                                key={field.key}
                                                                className={concatClasses(
                                                                    styles.notLastMargin,
                                                                    styles.mx0,
                                                                    cl.space,
                                                                    cl.space__end
                                                                )}
                                                                gutter={[30, 30]}>
                                                                <Col span={12} className={concatClasses(styles.pl0)}>
                                                                    <Item name={[idx, "fio"]} rules={[required()]}>
                                                                        <Input />
                                                                    </Item>
                                                                </Col>
                                                                <Col span={10} className={concatClasses(styles.pr0)}>
                                                                    <Item
                                                                        name={[idx, "phone"]}
                                                                        rules={[
                                                                            {
                                                                                validator: async (_, names: string) => {
                                                                                    if (
                                                                                        !names ||
                                                                                        names.replace(/_/g, "").length <
                                                                                            16
                                                                                    ) {
                                                                                        return Promise.reject(
                                                                                            new Error(
                                                                                                "Введите номер полностью"
                                                                                            )
                                                                                        );
                                                                                    }
                                                                                },
                                                                            },
                                                                        ]}>
                                                                        <InputWithMask mask="+7 999 999 99 99" />
                                                                    </Item>
                                                                </Col>
                                                                <Col
                                                                    span={2}
                                                                    className={concatClasses(styles.pr0, styles.df)}>
                                                                    <Icons.Delete
                                                                        onClick={() => {
                                                                            remove(idx);
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        );
                                                    })}
                                                </>
                                            )}
                                        </List>
                                    </>
                                )}
                            </Col>
                            <Col span={8}>
                                <CustomButton
                                    type="primary"
                                    buttonText="Добавить контактное лицо"
                                    icon={<Icons.PlusDark />}
                                    disabled={!edit}
                                    onClick={insertContact}
                                    wrapperClasses={concatClasses(
                                        clButton.addButton_fullWidth,
                                        clButton.addButton_noMargin,
                                        clButton.addButton_primary
                                    )}
                                />
                            </Col>
                        </Row>
                    </>
                </Form>
            </LayoutWrapper>
            <LayoutWrapper>
                <div className={styles.tableHead}>
                    <div className={styles.tableHead_title}>Участники</div>

                    <CustomButton
                        disabled={!(usersInEvent && usersInEvent.length > 0)}
                        buttonText={"Выгрузить"}
                        type="primary"
                        icon={<Icons.Plus />}
                        onClick={() =>
                            exlDownload({
                                id: id ?? "",
                                name: `Участники мероприятия "${event?.title ?? ""}"`,
                            })
                        }
                    />
                </div>
                <CustomTable
                    setFullHeight
                    columns={[
                        {
                            title: "Имя",
                            key: "name",
                            dataIndex: "name",
                        },
                        {
                            title: "Фамилия",
                            key: "surname",
                            dataIndex: "surname",
                        },
                        {
                            title: "Телефон",
                            key: "phone",
                            dataIndex: "phone",
                        },
                        {
                            title: "E-mail",
                            key: "email",
                            dataIndex: "email",
                        },
                        {
                            title: "Количество мест",
                            key: "vacancy",
                            dataIndex: "vacancy",
                        },
                    ]}
                    data={usersInEvent}
                    loading={false}
                />
            </LayoutWrapper>
            <ModalMap
                onCancel={(e: string | null | undefined) => {
                    form.setFieldsValue({ address: e });
                    setMap(false);
                }}
                onOk={(e: string) => {
                    form.setFieldsValue({ address: e });
                    setMap(false);
                }}
                address={form.getFieldValue("address")}
                visible={map}
            />
            <ModalDelete
                open={deleteModal}
                onCancel={() => setDeleteModal(false)}
                title={`Вы уверены, что хотите удалить мероприятие`}
                onOk={() => {
                    deleteEvent(id ?? "");
                    goBack(100);
                }}
            />
        </>
    );
};

export default OfflineEventsEdit;
