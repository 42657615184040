import { LicencesResponse } from "@aten/common/dist/models/Licences";
import { CourseResponse } from "@aten/common/models/Course";
import { Licences } from "@aten/common/models/Licences";
import { LicencesTableDataResponse } from "@aten/common/models/LicencesTableData";
import { FetchBaseQueryMeta } from "@reduxjs/toolkit/query";
import { UrlApi } from "config/api";
import { IPagination } from "hooks/usePagination";
import certificateSerializer from "serializers/certificate";
import { commonApi } from "services/commonApiService";
import { coursesApi } from "services/coursesApi";
import { downloadBlob, getFileNameFormLink, getFileNameFromMeta } from "utils/fileHelper";

export const licencesApi = commonApi.injectEndpoints({
    endpoints: (build) => ({
        getLicences: build.query<LicencesResponse, { userId?: number; pagination?: IPagination; searchString: string }>(
            {
                query: ({ userId, pagination, searchString }) => {
                    return {
                        url: `${UrlApi.Licences}${searchString}`,
                        params: {
                            ...pagination,
                            userId,
                        },
                    };
                },
            }
        ),
        setLicences: build.mutation<string, Licences>({
            query: (body: Licences) => ({
                url: `${UrlApi.Licences}`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["Licences"],
        }),
        editLicences: build.mutation<string, Licences>({
            query: (body: Licences) => ({
                url: `${UrlApi.Licences}/${body.id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: ["Licences"],
        }),
        downloadLicences: build.query<string, string>({
            query: (id: string) => ({
                url: `${UrlApi.Licences}/Download`,
                params: {
                    id,
                },
                responseHandler: async (response: any) => await response.blob(),
            }),
            transformResponse: async (file: Blob, meta: FetchBaseQueryMeta): Promise<string> => {
                const fileName = getFileNameFromMeta(meta);

                downloadBlob({ fileName: getFileNameFormLink(fileName), file });

                return "";
            },
        }),
        deleteLicences: build.mutation<LicencesResponse, string>({
            query: (id: string) => ({
                url: `${UrlApi.Licences}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Licences"],
        }),
    }),
});

const licencesExtendedApi = licencesApi.injectEndpoints({
    endpoints: (build) => ({
        getLicencesTableData: build.query<
            { licencesTableData: LicencesTableDataResponse; courses: CourseResponse },
            { userId?: number; pagination?: IPagination; searchString: string }
        >({
            async queryFn({ userId, pagination, searchString }, { dispatch }) {
                const certificates = await dispatch(
                    licencesApi.endpoints.getLicences.initiate(
                        {
                            ...pagination,
                            userId,
                            searchString,
                        },
                        { forceRefetch: true }
                    )
                ).unwrap();

                const courses = await dispatch(
                    coursesApi.endpoints.getCourses.initiate({}, { forceRefetch: true })
                ).unwrap();

                const serializedData = certificateSerializer(certificates);

                const licencesTableData: LicencesTableDataResponse = {
                    total: certificates.total,
                    requestedObjectsCount: pagination?.ObjectsCount,
                    requestedStartIndex: pagination?.StartIndex,
                    objects: serializedData?.map((license) => ({
                        ...license,
                        courseName: courses.objects?.find((course) => course.id === license.courseId)?.title ?? "-",
                    })),
                };

                return {
                    data: {
                        licencesTableData,
                        courses,
                    },
                };
            },
            providesTags: ["Licences"],
        }),
    }),
});

export const {
    useGetLicencesTableDataQuery,
    useSetLicencesMutation,
    useEditLicencesMutation,
    useDeleteLicencesMutation,
    useLazyDownloadLicencesQuery,
} = licencesExtendedApi;
