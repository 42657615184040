import { Icons } from "components/Icons/Icons";
import { urlApi, UrlApi } from "config/api";
import { useAppSelector } from "hooks/redux";
import React from "react";
import { getFileNameFormLink } from "utils/fileHelper";

import cl from "./InputUploaderPicture.module.scss";

interface InputUploadPictureProps {
    text?: string;
    action?: (avatarId: number) => void;
}

const InputUploadFile: React.FC<InputUploadPictureProps> = ({ text, action }) => {
    const token = useAppSelector((state) => state.auth.token);

    return (
        <div className={cl.uploaderWrap}>
            <input
                type="file"
                onChange={(inp: any) => {
                    let body = new FormData();
                    body.append("uploadedFile", inp.target.files[0]);
                    fetch(`${urlApi}${UrlApi.Files}`, {
                        method: "POST",
                        headers: {
                            Authorization: token,
                        },
                        body,
                    })
                        .then((res) => res.json())
                        .then((id) => action?.(id.addedId))
                        .catch((err) => console.log(err));
                }}
            />

            <>
                <div className={cl.text}>{getFileNameFormLink(text ?? "")}</div>
                <Icons.Attach />
            </>
        </div>
    );
};

export default InputUploadFile;
