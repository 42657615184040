import {
    TestsToThemes,
    TestsToThemesResponse,
} from "@aten/common/dist/models/TestsToThemes";
import { UrlApi } from "config/api";
import { commonApi } from "services/commonApiService";

interface objectQuery {
    testId?: string;
    themeId?: string;
}

export const testsToThemesApi = commonApi.injectEndpoints({
    endpoints: (build) => ({
        getTestToThemeId: build.query<TestsToThemesResponse, objectQuery>({
            query: ({ testId, themeId }) =>
                `${UrlApi.TestsToThemes}?TestId=${testId}&ThemeId=${themeId}`,
            providesTags: ["Theme"],
        }),
        setTestToTheme: build.mutation<string, TestsToThemes>({
            query: (data: TestsToThemes) => ({
                url: UrlApi.TestsToThemes,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Test", "Theme"],
        }),
        deleteTestToTheme: build.mutation<string, string>({
            query: (id: string) => ({
                url: `${UrlApi.TestsToThemes}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Test", "Theme"],
        }),
    }),
});

export const testsToThemesEntendedApi = testsToThemesApi.injectEndpoints({
    endpoints: (build) => ({
        deleteTestToThemeById: build.mutation<string, objectQuery>({
            async queryFn(data, { dispatch }) {
                const testToThemeId = await dispatch(
                    testsToThemesApi.endpoints.getTestToThemeId.initiate(data)
                )
                    .unwrap()
                    .then((result) => result.objects[0].id);

                return {
                    data: await dispatch(
                        testsToThemesApi.endpoints.deleteTestToTheme.initiate(
                            testToThemeId?.toString() ?? ""
                        )
                    ).unwrap(),
                };
            },
            invalidatesTags: ["TestToTheme"],
        }),
    }),
});

export const { useSetTestToThemeMutation, useDeleteTestToThemeByIdMutation } =
    testsToThemesEntendedApi;
