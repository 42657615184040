import LayoutWrapper from "components/Layouts/LayoutWrapper";
import Tabs from "components/Tabs/Tabs";
import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import { priceListsTabs } from "staticContent/tabs";

const PriceListsTabs: FC = () => {
    return (
        <>
            <Tabs items={priceListsTabs} />
            <LayoutWrapper>
                <Outlet />
            </LayoutWrapper>
        </>
    );
};

export default PriceListsTabs;
