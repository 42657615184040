import { Select } from "antd";
import Form, { Item, useForm } from "components/Form/Form";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import Modal, { ModalProps } from "components/Modal/Modal";
import { FC, useEffect } from "react";
import { required } from "utils/getRules";
import useGetThemeOptions from "./hooks/useGetThemeOptions";

interface ModalAddProps extends ModalProps {
    courseTitle: string;
    onClose: () => void;
    onAdd: (values: ModalResponse) => void;
    themesCount: number | undefined;
    loading?: boolean;
}

export interface ModalResponse {
    id: number;
    indexNumber: number;
}

const ModalAddToTheme: FC<ModalAddProps> = ({ courseTitle, onAdd, onClose, themesCount, loading, ...props }) => {
    const [modalForm] = useForm();

    const { themesIdOptions, themesLabelOptions } = useGetThemeOptions();

    useEffect(() => {
        modalForm.resetFields();
    }, [themesIdOptions, themesLabelOptions]);

    const onFinish = () => {
        modalForm.validateFields().then((values: ModalResponse) => {
            onAdd(values);
        });
    };

    const indexNumber = () => {
        let options = [];
        if (themesCount && themesCount > 0) {
            for (let i = 1; i <= themesCount + 1; i++) {
                options.push({
                    label: i,
                    value: i,
                });
            }
        } else {
            options.push({
                label: 1,
                value: 1,
            });
        }
        return options;
    };

    return (
        <Modal
            onOk={onFinish}
            okButtonText={"Добавить"}
            onCancel={onClose}
            loading={loading}
            title={`Добавление темы к курсу "${courseTitle}"`}
            {...props}>
            <Form form={modalForm}>
                <Item name="id" label={<Label type={LabelTypes.Form} label="ID темы" />} rules={[required()]}>
                    <Select showSearch options={themesIdOptions}></Select>
                </Item>
                <Item name="id" label={<Label type={LabelTypes.Form} label={"Название"} />} rules={[required()]}>
                    <Select showSearch options={themesLabelOptions}></Select>
                </Item>
                <Item
                    name="indexNumber"
                    label={<Label type={LabelTypes.Form} label="Порядковый номер" />}
                    rules={[required()]}>
                    <Select showSearch options={indexNumber()}></Select>
                </Item>
            </Form>
        </Modal>
    );
};

export default ModalAddToTheme;
