import { EventRegistrationResponse } from "@aten/common/dist/models/eventsRegistration";
import { UrlApi } from "config/api";
import { commonApi } from "services/commonApiService";
import { eventRegistrationGuestApi } from "services/eventRegistrationGuestService";

export const eventRegistrationsApi = commonApi.injectEndpoints({
    endpoints: (build) => ({
        getUsersInEvent: build.query({
            query: (EventId: string) => ({
                url: `${UrlApi.EventRegistrations}?EventId=${EventId}`,
                param: {
                    EventId,
                },
            }),
            transformResponse: (res: EventRegistrationResponse) => {
                return res.objects;
            },
        }),
    }),
});

const extendedEventRegistrationsApi = eventRegistrationsApi.injectEndpoints({
    endpoints: (build) => ({
        getAllMembers: build.query({
            async queryFn(id, { dispatch }) {
                const idList = await dispatch(
                    eventRegistrationsApi.endpoints.getUsersInEvent.initiate(id, { forceRefetch: true })
                ).unwrap();

                const data = await Promise.all(
                    idList?.map(({ id }) =>
                        dispatch(
                            eventRegistrationGuestApi.endpoints.getGuests.initiate(id?.toString() ?? "", {
                                forceRefetch: true,
                            })
                        ).unwrap()
                    ) ?? []
                );

                return { data: [idList, data.flat()] };
            },
        }),
    }),
});
export const { useGetUsersInEventQuery, useGetAllMembersQuery } = extendedEventRegistrationsApi;
