import {useState} from "react";
import * as XLSX from "xlsx";


function s2ab(s: any) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
}
export const useOneProjectExcelDownload = () => {

    const [oneProjectExcelSave, setOneProjectExcelSave] = useState<boolean>(false)
    const toggleProjectExcelSaveModal = () => setOneProjectExcelSave(!oneProjectExcelSave)

    type ProjectExtended = {[k: string]: string | number | undefined}
    const downloadExcel = (data: ProjectExtended) => {
        if(localStorage.getItem('OneProjectExcelDownload')){
            const transformed = {
                'Тип проекта': data.projectTypeId,
                'Страна поставки': data.deliveryCountry,
                'Дистрибьютор': data.distr,
                'Менеджер': data.managers,
                'Интегратор': data.integrator,
                "Инженер": data.engineers,
                "Название проекта": data.projectName,
                'Имя заказчика': data.customerName,
                'Сайт заказчика': data.customerSite,
                'Адрес заказчика': data.customerAddress,
                "Адрес объекта": data.objectAddress,
                "Краткое описание инсталяции": data.shortInstallationDescription,
                "Дата принятия решения": data.makingDecisionDate,
                "Дата поставки оборудования": data.supplyEquipmentDate,
                "Статус проекта": data.projectStatusId,
                "Конкуренты (вендоры) в проекте": data.vendors,
                "Необходимость дробной поставки": data.needFractionalDelivery,
                "Дата дробной поставки": data.fractionalDeliveryDate,
                "Сумма заказа": data.orderSum,
                "Модель": data.models,
                'Дополнительная информация': data.comments,

            }
            const workbook = XLSX.utils.book_new();

            const cell = (dataCell: ProjectExtended) => {
                const keys: string[] = []
                const vals: string[] = []
                Object.entries(dataCell).forEach(([k, v]) => {
                    keys.push(k)
                    vals.push(XLSX.utils.format_cell({
                        v,
                        t: 's', // t: 's' для форматированного текста
                        s: {alignment: {wrapText: true}} // применение отступов и разрывов строк
                    }))
                })
                return [Array.from(new Set(keys)), vals]
            }
            const sheetName = String(data?.projectName ?? 'project');
            const sheetData = cell(transformed);
            const sheet = XLSX.utils.aoa_to_sheet(sheetData, {cellStyles: true});
            XLSX.utils.book_append_sheet(workbook, sheet, sheetName);

            const file = XLSX.write(workbook, {type: 'binary', bookType: 'xlsx'});
            const blob = new Blob([s2ab(file)], {type: 'application/octet-stream'});
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${data.projectName}.xlsx`;
            link.click();
        }
    }

    return {
        toggleProjectExcelSaveModal,
        downloadExcel,
        isShowSaveOneProjectExcelModal: oneProjectExcelSave
    }
}