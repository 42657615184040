import { Theme } from "@aten/common/dist/models/Theme";
import { Button, Col, Input, message, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { CustomButton } from "components/CustomButton/CustomButton";
import { CustomTable } from "components/CustomTable/CustomTable";
import { lessonColumns, testColumns } from "components/Form/ThemesFormEdit/themesFormEditColumnsTable";
import { Icons } from "components/Icons/Icons";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import ModalDelete from "components/Modal/ModalDelete";
import TextEditor from "components/TextEditor/TextEditor";
import useModal from "hooks/useModal";
import useNavigate from "hooks/useNavigate";
import { DeletingLessonProps } from "pages/Lesson/Lesson";
import { DeletingTestProps } from "pages/Test/Test";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import lessonsSerializer from "serializers/lesson";
import testSerializer from "serializers/test";
import { useGetLessonsByThemeIdQuery } from "services/lessonService";
import { useDeleteLessonToThemeByIdMutation, useSetLessonToThemeMutation } from "services/lessonsToThemesService";
import { useGetTestsByThemeIdQuery } from "services/testsService";
import { useDeleteTestToThemeByIdMutation, useSetTestToThemeMutation } from "services/testsToThemesService";
import { useDeleteThemeMutation, useEditThemeMutation, useSetThemeMutation } from "services/themeService";
import getContentAndFuncForDelete, { ModalType } from "utils/getContentAndFuncForDelete";
import { lettersValidate, required } from "utils/getRules";
import Form, { Item, useForm } from "../Form";
import ModalAddToTheme, { ModalResponse } from "./ModalAddToTheme/ModalAddToTheme";
import c from "./ThemesFormEdit.module.scss";
import { CommonFetchBaseQueryError } from "types/common";

interface ThemesFormEditProps {
    data?: Theme;
}

const ThemesFormEdit: FC<ThemesFormEditProps> = ({ data: theme, ...props }) => {
    const { id } = useParams();
    const [form] = useForm<Theme | undefined>();
    const { goBack } = useNavigate();
    const { open: openDelete, close: closeDelete, visible: visibleDelete } = useModal();
    const { open: openAdd, close: closeAdd, visible: visibleAdd } = useModal();

    const [typeModal, setTypeModal] = useState<ModalType>("theme");
    const [deletingThemeTitle, setDeletingThemeTitle] = useState<string>("");
    const [deletingLesson, setDeletingLesson] = useState<DeletingLessonProps>();
    const [deletingTest, setDeletingTest] = useState<DeletingTestProps>();

    const { data: lessons, isLoading: isLoadingLesson } = useGetLessonsByThemeIdQuery(id ?? "", {
        skip: !id,
        refetchOnMountOrArgChange: true,
    });
    const { data: tests, isLoading: isLoadingTest } = useGetTestsByThemeIdQuery(id ?? "", {
        skip: !id,
        refetchOnMountOrArgChange: true,
    });

    const [setTheme, { isLoading: isLoadingAddTheme }] = useSetThemeMutation();
    const [editTheme, { isLoading: isLoadingEditTheme }] = useEditThemeMutation();
    const [deleteTheme, { isLoading: isLoadingDeleteTheme }] = useDeleteThemeMutation();

    const [setLessonToTheme, { isLoading: isLoadingAddLesson }] = useSetLessonToThemeMutation();
    const [deleteLessonToThemeById, { isLoading: isLoadingDeleteLesson }] = useDeleteLessonToThemeByIdMutation();

    const [setTestToTheme, { isLoading: isLoadingAddTest }] = useSetTestToThemeMutation();
    const [deleteTestToThemeById, { isLoading: isLoadingDeleteTest }] = useDeleteTestToThemeByIdMutation();

    const serializedLessons = lessonsSerializer(lessons);
    const serializedTests = testSerializer(tests);

    useEffect(() => {
        form.setFieldsValue(theme);
        setDeletingThemeTitle(theme?.title ?? "");
    }, [theme, lessons, tests]);

    const deleteCurrentTheme = async () => {
        id &&
            (await deleteTheme(id).then(() => {
                message.success("Тема удалена");
                closeDelete();
                goBack(500);
            }));
    };

    const deleteCurrentLesson = async () => {
        deletingLesson?.id &&
            (await deleteLessonToThemeById({
                lessonId: deletingLesson.id,
                themeId: id,
            }).then(() => {
                message.success("Урок удален");
                closeDelete();
            }));
    };

    const deleteCurrentTest = async () => {
        deletingTest?.id &&
            (await deleteTestToThemeById({
                testId: deletingTest.id,
                themeId: id,
            }).then(() => {
                message.success("Тест удален");
                closeDelete();
            }));
    };

    const settingsModal = () => {
        return getContentAndFuncForDelete({
            type: typeModal,
            deleteTheme: deleteCurrentTheme,
            deleteLesson: deleteCurrentLesson,
            deleteTest: deleteCurrentTest,
            themeTitle: deletingThemeTitle,
            lessonTitle: deletingLesson?.title,
            testTitle: deletingTest?.title,
        });
    };

    const addTestToTheme = async (values: ModalResponse) => {
        enum PossibleError {
            AlreadyExist,
            NoLessonInTheme,
        }
        id &&
            (await setTestToTheme({
                testId: values.id,
                themeId: +id,
                indexNumber: values.indexNumber,
            })
                .unwrap()
                .then(() => {
                    message.success("Тест добавлен к теме");
                    closeAdd();
                })
                .catch(({ data }: CommonFetchBaseQueryError<PossibleError>) => {
                    message.error(data.information);
                }));
    };

    const addLessonToTheme = async (values: ModalResponse) => {
        id &&
            (await setLessonToTheme({
                lessonId: values.id,
                themeId: +id,
                indexNumber: values.indexNumber,
            })
                .unwrap()
                .then(() => {
                    message.success("Урок добавлен к теме");
                    closeAdd();
                })
                .catch((err) => {
                    message.error(err.data.information);
                }));
    };

    const addTestClick = () => {
        setTypeModal("test");
        openAdd();
    };

    const addLessonClick = () => {
        setTypeModal("lesson");
        openAdd();
    };

    const deleteThemeClick = () => {
        setTypeModal("theme");
        openDelete();
    };

    const onFinish = (values: Theme) => {
        id
            ? editTheme({ ...theme, ...values }).then(() => {
                  message.success("Тема отредактирована");
                  goBack();
              })
            : setTheme({ ...theme, ...values }).then(() => {
                  message.success("Тема добавлена");
                  goBack();
              });
    };

    const onFinishAddToTheme = (values: ModalResponse) => {
        typeModal === "lesson" ? addLessonToTheme(values) : addTestToTheme(values);
    };

    return (
        <Form form={form} data={theme} onFinish={onFinish} {...props}>
            <>
                <LayoutWrapper>
                    <Row gutter={[30, 30]} className={c.row}>
                        <Col span={8}>
                            <Button htmlType="submit" type="primary" loading={isLoadingAddTheme || isLoadingEditTheme}>
                                {id ? "Сохранить изменения" : "Добавить тему"}
                            </Button>
                        </Col>
                        <Col span={8}>
                            <Button onClick={() => goBack(0)}>
                                {id ? "Отменить изменения" : "Отменить добавление"}
                            </Button>
                        </Col>
                        {id && (
                            <Col span={8}>
                                <Button className={"buttonRed"} onClick={deleteThemeClick}>
                                    Удалить тему
                                </Button>
                            </Col>
                        )}
                    </Row>

                    <Row gutter={[30, 30]}>
                        <Col span={8}>
                            <Item
                                name="title"
                                label={<Label type={LabelTypes.Form} label={"Название"} />}
                                rules={[lettersValidate(), required()]}>
                                <Input />
                            </Item>
                        </Col>
                        <Col span={16}>
                            <Item
                                name="shortDescription"
                                label={<Label type={LabelTypes.Form} label={"Краткое описание"} />}
                                rules={[required()]}>
                                <TextArea showCount maxLength={500} />
                            </Item>
                        </Col>
                        <Col span={24}>
                            <Item
                                name="description"
                                label={<Label type={LabelTypes.Form} label={"Полное описание"} />}
                                rules={[required()]}>
                                <TextEditor
                                    value={form.getFieldValue("description")}
                                    callback={(htmlValue) =>
                                        form.setFieldsValue({
                                            description: htmlValue,
                                        })
                                    }
                                />
                            </Item>
                        </Col>
                        <Col span={8} className={c.quantity}>
                            <span className={c.quantity__label}>Число уроков:</span>{" "}
                            <span className={c.quantity__value}>{theme?.lessonsIds?.length ?? 0}</span>
                        </Col>
                        <Col span={8} className={c.quantity}>
                            <span className={c.quantity__label}>Число тестов: </span>
                            <span className={c.quantity__value}>{theme?.testsIds?.length ?? 0}</span>
                        </Col>
                    </Row>
                </LayoutWrapper>
                {theme && (
                    <>
                        <LayoutWrapper>
                            <div className={c.tableHead}>
                                <div className={c.tableHead__title}>Уроки темы</div>
                                <CustomButton
                                    className={c.tableHead__btn}
                                    onClick={addLessonClick}
                                    buttonText={"Добавить урок"}
                                    type="primary"
                                    icon={<Icons.Plus />}
                                />
                            </div>
                            <CustomTable
                                setFullHeight={true}
                                columns={lessonColumns({
                                    setDeletingLesson,
                                    openDelete,
                                    setTypeModal,
                                })}
                                pagination={false}
                                data={serializedLessons}
                                loading={isLoadingLesson}
                            />
                        </LayoutWrapper>
                        <LayoutWrapper>
                            <div className={c.tableHead}>
                                <div className={c.tableHead__title}>Тесты темы</div>
                                <CustomButton
                                    className={c.tableHead__btn}
                                    onClick={addTestClick}
                                    buttonText={"Добавить тест"}
                                    type="primary"
                                    icon={<Icons.Plus />}
                                />
                            </div>
                            <CustomTable
                                setFullHeight={true}
                                columns={testColumns({
                                    setDeletingTest,
                                    openDelete,
                                    setTypeModal,
                                })}
                                pagination={false}
                                data={serializedTests}
                                loading={isLoadingTest}
                            />
                        </LayoutWrapper>
                        <ModalAddToTheme
                            type={typeModal}
                            open={visibleAdd}
                            loading={isLoadingAddLesson || isLoadingAddTest}
                            themeTitle={theme.title}
                            lessonsCount={lessons?.total ?? 0}
                            testsCount={tests?.total ?? 0}
                            onClose={closeAdd}
                            onAdd={onFinishAddToTheme}
                        />
                    </>
                )}
                <ModalDelete
                    open={visibleDelete}
                    onCancel={closeDelete}
                    loading={isLoadingDeleteLesson || isLoadingDeleteTest || isLoadingDeleteTheme}
                    title={settingsModal()?.title}
                    onOk={settingsModal()?.func}
                />
            </>
        </Form>
    );
};

export default ThemesFormEdit;
