import { combineReducers, configureStore } from "@reduxjs/toolkit";
import type { Selector as RRSelector } from "react-redux";
import {
  persistReducer,
  persistStore
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { commonApi } from "services/commonApiService";
import { onlyTokenApi } from "services/onlyTokenApi";
import authReducer from "slices/authSlice";

const reducersPath = commonApi.reducerPath;

const persistConfig = {
  key: "admin",
  storage,
  blacklist: [reducersPath, onlyTokenApi.reducerPath, "history"],
};
const rootReducer = combineReducers({
    auth: authReducer,
    [commonApi.reducerPath]: commonApi.reducer,
    [onlyTokenApi.reducerPath]: onlyTokenApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            // {
            //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            // },
        }).concat([commonApi.middleware, onlyTokenApi.middleware]),
});
export const persist = persistStore(store);

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type Selector<S> = RRSelector<RootState, S>;
