import { Roles, rolesToLabel } from "data/roles";
import { SelectOption } from "types/select";

export const optionsRole: SelectOption[] = [
  {
    key: Roles.Admin,
    label: Roles.Admin,
    value: rolesToLabel[Roles.Admin],
  },
  {
    key: Roles.SupervisorProAv,
    label: Roles.SupervisorProAv,
    value: rolesToLabel[Roles.SupervisorProAv],
  },
  {
    key: Roles.SupervisorKVMPDU,
    label: Roles.SupervisorKVMPDU,
    value: rolesToLabel[Roles.SupervisorKVMPDU],
  },
  {
    key: Roles.SupervisorTeaching,
    label: Roles.SupervisorTeaching,
    value: rolesToLabel[Roles.SupervisorTeaching],
  },
  {
    key: Roles.SI,
    label: Roles.SI,
    value: rolesToLabel[Roles.SI],
  },
  {
    key: Roles.Disti,
    label: Roles.Disti,
    value: rolesToLabel[Roles.Disti],
  },

];
