import { EventGuestsCollection } from "@aten/common/models/EventGuests";
import { UrlApi } from "config/api";
import { commonApi } from "services/commonApiService";

export const eventRegistrationGuestApi = commonApi.injectEndpoints({
    endpoints: (build) => ({
        getGuests: build.query<EventGuestsCollection, string>({
            query: (EventRegistrationId: string) => {
                return {
                    url: UrlApi.EventRegistrationGuest,
                    params: {
                        EventRegistrationId,
                    },
                };
            },
        }),
    }),
});

export const { useGetGuestsQuery } = eventRegistrationGuestApi;
