import { ResponseApi } from "@aten/common/dist/models";

export type SerializeFunction<DataType, SerializedType> = (data: DataType, i: number) => SerializedType;

export type Serializer<ResponseData extends ResponseApi<DataType[]>, DataType, SerializedType> = (
    data: ResponseData | undefined
) => SerializedType[] | undefined;

type SerializeHandler = <ResponseData extends ResponseApi<DataType[]>, DataType, SerializedType>(
    data: ResponseData | undefined,
    serializeFunction: SerializeFunction<DataType, SerializedType>
) => SerializedType[] | undefined;

const serializer: SerializeHandler = (data, serializeFunction) => {
    if (!data) {
        return undefined;
    }

    return data.objects.map((item, i) => serializeFunction(item, i));
};

export default serializer;
