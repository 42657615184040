import { Advantages } from "@aten/common/dist/models/Advantages";
import { Button, Col, Input, message, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import ModalDelete from "components/Modal/ModalDelete";
import useModal from "hooks/useModal";
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    useDeleteAdvantagesMutation,
    useEditAdvantagesMutation,
    useSetAdvantagesMutation,
} from "services/advantagesService";
import { required } from "utils/getRules";
import Form, { Item, useForm } from "../Form";
import c from "./AdvantagesFormEdit.module.scss";

interface AdvantagesProps {
    data?: Advantages;
}

export const AdvantagesFormEdit: FC<AdvantagesProps> = ({ data, ...props }) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [form] = useForm<typeof data>();

    const { visible, open, close } = useModal({});

    const [editAdvantages] = useEditAdvantagesMutation();
    const [setAdvantages] = useSetAdvantagesMutation();
    const [deleteAdvantages] = useDeleteAdvantagesMutation();

    useEffect(() => {
        form.setFieldsValue(data);
    }, [data]);

    const dataUpdate = async (newData: Advantages) => {
        id
            ? await editAdvantages({
                  ...data,
                  ...newData,
                  imageId: 5,
                  sortLine: "string",
              }).then(() => {
                  message.success("Преимущество отредактировано");
              })
            : await setAdvantages({
                  ...newData,
                  imageId: 5,
                  sortLine: "string",
              }).then(() => {
                  message.success("Преимущество добавлено");
              });
        setTimeout(() => {
            navigate(-1);
        }, 1000);
    };

    const deleteCurrentAdvanteges = () => {
        id &&
            deleteAdvantages(id).then(() => {
                close();
                navigate(-1);
            });
    };

    return (
        <Form form={form} data={{}} onFinish={dataUpdate} {...props}>
            <>
                <LayoutWrapper>
                    <Row gutter={[30, 30]} className={c.row}>
                        <Col span={8}>
                            <Button htmlType="submit" type="primary">
                                {id ? "Сохранить изменения" : "Добавить преимущество"}
                            </Button>
                        </Col>
                        <Col span={8}>
                            <Button onClick={() => navigate(-1)}>
                                {id ? "Отменить изменения" : "Отменить добавление"}
                            </Button>
                        </Col>
                        {id && (
                            <Col span={8}>
                                <Button className={"buttonRed"} onClick={open}>
                                    Удалить преимущество
                                </Button>
                            </Col>
                        )}
                    </Row>
                    <Row gutter={[30, 30]}>
                        <Col span={8}>
                            <Item
                                name="title"
                                label={<Label type={LabelTypes.Form} label={"Заголовок"} />}
                                rules={[required()]}>
                                <Input />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item
                                name="description"
                                label={<Label type={LabelTypes.Form} label={"Текст преимущества"} />}
                                rules={[required()]}>
                                <TextArea showCount maxLength={500} />
                            </Item>
                        </Col>
                    </Row>
                </LayoutWrapper>
                <div>
                    <ModalDelete
                        open={visible}
                        onOk={() => deleteCurrentAdvanteges()}
                        onCancel={close}
                        title={`Вы уверены, что хотите удалить преимущество "${data?.title ?? ""}"`}
                    />
                </div>
            </>
        </Form>
    );
};
