import {
    useAddModelToProjectMutation,
    useDeleteModelFromProjectMutation,
    useEditModelInProjectMutation,
    useGetModelsByProjectIdQuery,
} from "../../../../services/modelsAndProjectContollerService";
import { useMemo } from "react";
import { Model } from "@aten/common/models/Model";

export const useModels = (
    projectId?: string
): {
    modelsChecking: (val: Model[], newProjectId: number | void) => Promise<any>;
    models?: Model[];
} => {
    const { data } = useGetModelsByProjectIdQuery(projectId ?? "", {
        skip: !projectId,
        refetchOnMountOrArgChange: true,
    });

    const [addModelToProject] = useAddModelToProjectMutation();
    const [editModelInProject] = useEditModelInProjectMutation();
    const [deleteModelFromProject] = useDeleteModelFromProjectMutation();

    const modelsChecking = async (value?: Model[], newProjectId?: number | void) => {
        const adding = value
            ?.filter(({ id }) => {
                return !data?.objects.find((el) => id === el.modelId);
            })
            .map(({ id, modelId, quantity, worth }) => ({
                modelId: modelId ?? id,
                worth,
                quantity: Number(quantity),
                projectId: Number(projectId ? projectId : newProjectId),
            }));

        const deleting = data?.objects
            .filter(({ modelId }) => {
                return !value?.find((el) => modelId === el.id);
            })
            .map(({ id }) => id);

        const updating = data?.objects.reduce((acc: any[], { modelId, quantity, id }) => {
            const newObj: any = value?.find((el) => modelId === el.id && el.quantity !== quantity);
            if (newObj) {
                return [
                    ...acc,
                    {
                        id,
                        worth: newObj.worth,
                        modelId: newObj.id,
                        projectId: Number(projectId ? projectId : newProjectId),
                        quantity: Number(newObj.quantity),
                    },
                ];
            }
            return acc;
        }, []);

        !!deleting?.length && (await deleteModelFromProject(deleting));
        !!updating?.length && (await editModelInProject(updating));

        return !!adding?.length && (await addModelToProject(adding).then((res) => res));
    };

    const models = useMemo(() => {
        return data?.objects.map(({ id, quantity, worth, modelId, projectId, model, project }) => ({
            id,
            modelName: model?.modelName,
            name: model?.name,
            price: model?.price,
            isEol: model?.isEol,
            isRetal: model?.isRetal,
            project,
            modelId,
            projectId,
            quantity,
            worth,
        }));
    }, [data]);

    return {
        models,
        modelsChecking,
    };
};
