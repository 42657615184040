import { Cooperation as CooperationType } from "@aten/common/models/Cooperation";
import { Button, Col, Input, Row } from "antd";
import cl from "assets/scss/Space.module.scss";
import Form, { Item, useForm } from "components/Form/Form";
import clForm from "components/Form/ProjectFormEdit/ProjectFormEdit.module.scss";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import TextEditor from "components/TextEditor/TextEditor";
import UploadPicture from "components/UploadPicture/UploadPicture";
import { FC, useEffect, useState } from "react";
import {
    useEditCooperationMutation,
    useGetCooperationQuery,
    useSetCooperationMutation,
} from "services/cooperationService";
import { useGetFileByIdQuery } from "services/fileService";
import concatClasses from "utils/concatClasses";
import { required } from "utils/getRules";
import style from "./Cooperation.module.scss";

const Cooperation: FC = () => {
    const [form] = useForm();
    const [state, setState] = useState<CooperationType>({} as CooperationType);
    const [fileId, setFileId] = useState<number>();

    const {
        data: avatarLink,
        isLoading: loading,
        isFetching: fetching,
    } = useGetFileByIdQuery(fileId ?? 0, {
        skip: !fileId,
    });

    const avatarUpdate = (avatarId: number) => {
        setFileId(avatarId);
        form.setFieldValue("imageId", avatarId);
        form.validateFields(["imageId"]).then();
    };

    const { data: cooperationData } = useGetCooperationQuery();
    const [setCooperation, { isLoading }] = useSetCooperationMutation();
    const [editCooperation, { isLoading: loadingEdit }] = useEditCooperationMutation();

    useEffect(() => {
        setState(cooperationData?.objects?.length ? cooperationData?.objects[0] : {});
    }, [cooperationData]);

    useEffect(() => {
        form.setFieldsValue({ ...state });

        form.setFieldValue("id", state.id);
        setFileId(state?.imageId);
    }, [state]);

    const onFinish = (val: CooperationType) => {
        !!Object.keys(state).length ? editCooperation(val) : setCooperation(val);
    };

    return (
        <LayoutWrapper>
            <Form data={state} form={form} onFinish={onFinish}>
                <>
                    <Row className={concatClasses(cl.space, cl.space__end, clForm.editRow)} gutter={[30, 30]}>
                        <Item name={"id"}>
                            <Input type={"hidden"} />
                        </Item>
                        <Col span={16}>
                            <div className={style.label}>Текстовое содержимое</div>
                            <Item name={"textDescription"} rules={[state && required()]}>
                                <TextEditor
                                    imageAllow={false}
                                    value={form.getFieldValue("textDescription")}
                                    callback={(htmlValue) =>
                                        form.setFieldsValue({
                                            textDescription: htmlValue,
                                        })
                                    }
                                />
                            </Item>
                        </Col>
                        <Col className={style.img} span={8} style={{ minHeight: 260 }}>
                            <div className={style.label}>Изображение</div>
                            <Item name={"imageId"} rules={[required()]}>
                                <UploadPicture
                                    loading={loading || fetching}
                                    action={avatarUpdate}
                                    url={avatarLink ?? ""}
                                />
                            </Item>
                        </Col>
                    </Row>

                    <Row className={concatClasses(cl.space, cl.space__end, clForm.editRow)} gutter={[30, 30]}>
                        <Col>
                            <Button loading={isLoading || loadingEdit} htmlType={"submit"}>
                                Сохранить
                            </Button>
                        </Col>
                    </Row>
                </>
            </Form>
        </LayoutWrapper>
    );
};
export default Cooperation;
