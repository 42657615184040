import { Course } from "@aten/common/models/Course";
import { Licences } from "@aten/common/models/Licences";
import { LicencesTableData } from "@aten/common/models/LicencesTableData";
import { Input, message, Select } from "antd";
import Form, { Item, useForm } from "components/Form/Form";
import InputUploadFile from "components/InputUploadFile/InputUploadFile";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import Modal, { ModalProps } from "components/Modal/Modal";
import moment from "moment";

import { FC, useEffect, useState } from "react";
import { useGetFileByIdQuery } from "services/fileService";

import { useEditLicencesMutation, useSetLicencesMutation } from "services/licencesServices";
import { required } from "utils/getRules";

interface ModalAddProps extends ModalProps {
    userId: number;
    onClose?: () => void;
    data?: LicencesTableData;
    courses?: Course[];
}

const ModalAddCertificate: FC<ModalAddProps> = ({ data, courses, userId, onClose, ...props }) => {
    const [modalForm] = useForm<LicencesTableData | undefined>();
    const [fileId, setFileId] = useState<number>();

    const { data: fileLink } = useGetFileByIdQuery(fileId ?? 0, { skip: !fileId });
    const [setLicences] = useSetLicencesMutation();
    const [editLicences] = useEditLicencesMutation();

    useEffect(() => {
        modalForm.resetFields();
        data && setFileId(data?.fileId);
        data &&
            modalForm.setFieldsValue({
                ...data,
                evaluationEndDate: moment(data?.evaluationEndDate),
                evaluationStartDate: moment(data?.evaluationStartDate),
            });
    }, [data]);

    const onFinish = () => {
        modalForm.validateFields().then((values) => {
            data?.id
                ? editLicences({
                      ...data,
                      ...(values as Licences),
                      evaluationEndDate: moment(values?.evaluationEndDate).unix() * 1000,
                      evaluationStartDate: moment(values?.evaluationStartDate).unix() * 1000,
                      fileId: Number(fileId),
                      userId,
                  }).then(() => {
                      message.success("Сертификат отредактирован");
                  })
                : setLicences({
                      ...(values as Licences),
                      userId,
                      evaluationEndDate: moment(values?.evaluationEndDate).unix() * 1000,
                      evaluationStartDate: moment(values?.evaluationStartDate).unix() * 1000,
                      fileId: Number(fileId),
                  }).then(() => {
                      message.success("Сертификат добавлен");
                      modalForm.resetFields();
                  });

            onClose && onClose();
        });
    };
    const fileUpdate = (fileId: number) => {
        setFileId(fileId);
        modalForm.setFieldsValue({ ...data, fileId: fileId });
    };
    return (
        <Modal
            okButtonText={!data?.id ? "Добавить" : "Изменить"}
            onCancel={onClose}
            title={`${!data?.id ? "Добавление" : "Редактирование"} сертификата`}
            onOk={onFinish}
            {...props}>
            <Form form={modalForm}>
                <Item
                    name="name"
                    label={<Label type={LabelTypes.Form} label={"Название сертификата"} />}
                    rules={[required()]}>
                    <Input />
                </Item>
                <Item
                    name="validityPeriodInDays"
                    label={<Label type={LabelTypes.Form} label={"Срок годности (в днях)"} />}
                    rules={[required()]}>
                    <Input type="number" />
                </Item>

                <Item name="fileId" label={<Label type={LabelTypes.Form} label={"Сертификат"} />} rules={[required()]}>
                    <InputUploadFile text={fileLink} action={fileUpdate} />
                </Item>
            </Form>
        </Modal>
    );
};
export default ModalAddCertificate;
