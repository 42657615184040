import LayoutMain from "components/Layouts/LayoutMain";
import { AuthorizedPath, authUrl, PublicPath, publicUrl } from "config/path";
import Advantages from "pages/Advantages/Advantages";
import AdvantagesEdit from "pages/Advantages/AdvantagesEdit/AdvantagesEdit";
import Catalogues from "pages/Catalogues/Catalogues";
import CataloguesEdit from "pages/Catalogues/CataloguesEdit/CataloguesEdit";
import Certificates from "pages/Certificates/Certificates";
import Cooperation from "pages/Cooperation/Cooperation";
import Courses from "pages/Courses/Courses";
import CoursesEdit from "pages/Courses/CoursesEdit/CoursesEdit";
import UserCourses from "pages/Courses/UserCourses/UserCourses";
import Lessons from "pages/Lesson/Lesson";
import LessonEdit from "pages/Lesson/LessonEdit/LessonEdit";
import Login from "pages/Login/Login";
import Redirect from "pages/Login/Redirect";
import MembersEdit from "pages/Members/MembersEdit/MembersEdit";
import MembersPage from "pages/Members/MembersPage";
import News from "pages/News/News";
import NewsEdit from "pages/News/NewsEdit/NewsEdit";
import OfflineEvents from "pages/OfflineEvents/OfflineEvents";
import OfflineEventsEdit from "pages/OfflineEvents/OfflineEventsEdit/OfflineEventsEdit";
import PriceListsOther from "pages/PriceLists/PriceListsOther";
import PriceListsOwn from "pages/PriceLists/PriceListsOwn";
import PriceListsTabs from "pages/PriceLists/PriceListsTabs";
import ProjectEdit from "pages/Projects/ProjectEdit/ProjectEdit";
import Projects from "pages/Projects/Projects";
import Tests from "pages/Test/Test";
import TestEdit from "pages/Test/TestEdit/TestEdit";
import ThemeEdit from "pages/Theme/ThemeEdit/ThemeEdit";
import Themes from "pages/Theme/Themes";
import UserEdit from "pages/Users/UserEdit/UserEdit";
import Users from "pages/Users/Users";
import { Outlet, RouteObject } from "react-router-dom";
import RolesPage from "../pages/Roles/Roles";

export type Route<Path extends PublicPath | AuthorizedPath = PublicPath | AuthorizedPath> = RouteObject & {
    path: Path;
};

export type PublicRoute = Route<PublicPath>;
export type AuthRoute = Route<AuthorizedPath>;

export const publicRoutes: PublicRoute[] = [
    {
        path: publicUrl.Login.path,
        element: <Login />,
    },
    {
        path: publicUrl.All.path,
        element: <Redirect />,
    },
];

export const authRoutes: AuthRoute[] = [
    {
        path: authUrl.Index.path,
        element: <LayoutMain />,
        children: [
            {
                path: authUrl.Index.Projects.path,
                element: <Outlet />,
                children: [
                    {
                        path: authUrl.Index.Projects.List.path,
                        element: <Projects />,
                    },
                    {
                        path: authUrl.Index.Projects.Edit.path,
                        element: <ProjectEdit />,
                    },
                    {
                        path: authUrl.Index.Projects.New.path,
                        element: <ProjectEdit />,
                    },
                    {
                        path: authUrl.Index.Projects.ForUser.path,
                        element: <Projects />,
                    },
                ],
            },
            {
                path: authUrl.Index.Users.path,
                element: <Outlet />,
                children: [
                    {
                        path: authUrl.Index.Users.List.path,
                        element: <Users />,
                    },
                    {
                        path: authUrl.Index.Users.Edit.path,
                        element: <UserEdit />,
                    },
                    {
                        path: authUrl.Index.Users.New.path,
                        element: <UserEdit />,
                    },
                ],
            },
            {
                path: authUrl.Index.Courses.path,
                element: <Outlet />,
                children: [
                    {
                        path: authUrl.Index.Courses.List.path,
                        element: <Courses />,
                    },
                    {
                        path: authUrl.Index.Courses.Edit.path,
                        element: <CoursesEdit />,
                    },
                    {
                        path: authUrl.Index.Courses.New.path,
                        element: <CoursesEdit />,
                    },
                    {
                        path: authUrl.Index.Courses.ForUser.path,
                        element: <UserCourses />,
                    },
                ],
            },
            {
                path: authUrl.Index.Themes.path,
                element: <Outlet />,
                children: [
                    {
                        path: authUrl.Index.Themes.List.path,
                        element: <Themes />,
                    },
                    {
                        path: authUrl.Index.Themes.Edit.path,
                        element: <ThemeEdit />,
                    },
                    {
                        path: authUrl.Index.Themes.New.path,
                        element: <ThemeEdit />,
                    },
                ],
            },
            {
                path: authUrl.Index.Lessons.path,
                element: <Outlet />,
                children: [
                    {
                        path: authUrl.Index.Lessons.List.path,
                        element: <Lessons />,
                    },
                    {
                        path: authUrl.Index.Lessons.Edit.path,
                        element: <LessonEdit />,
                    },
                    {
                        path: authUrl.Index.Lessons.New.path,
                        element: <LessonEdit />,
                    },
                ],
            },
            {
                path: authUrl.Index.Tests.path,
                element: <Outlet />,
                children: [
                    {
                        path: authUrl.Index.Tests.List.path,
                        element: <Tests />,
                    },
                    {
                        path: authUrl.Index.Tests.Edit.path,
                        element: <TestEdit />,
                    },
                    {
                        path: authUrl.Index.Tests.New.path,
                        element: <TestEdit />,
                    },
                ],
            },
            {
                path: authUrl.Index.OfflineEvents.path,
                element: <Outlet />,
                children: [
                    {
                        path: authUrl.Index.OfflineEvents.List.path,
                        element: <OfflineEvents />,
                    },
                    {
                        path: authUrl.Index.OfflineEvents.Edit.path,
                        element: <OfflineEventsEdit />,
                    },
                    {
                        path: authUrl.Index.OfflineEvents.New.path,
                        element: <OfflineEventsEdit />,
                    },
                    {
                        path: authUrl.Index.OfflineEvents.ForUser.path,
                        element: <OfflineEvents />,
                    },
                ],
            },
            {
                path: authUrl.Index.Certificates.path,
                element: <Outlet />,
                children: [
                    {
                        path: authUrl.Index.Certificates.List.path,
                        element: <Certificates />,
                    },
                ],
            },
            {
                path: authUrl.Index.Roles.url,
                element: <Outlet />,
                children: [
                    {
                        path: authUrl.Index.Roles.List.path,
                        element: <RolesPage />,
                    },
                ],
            },
            {
                path: authUrl.Index.Catalogues.path,
                element: <Outlet />,
                children: [
                    {
                        path: authUrl.Index.Catalogues.List.path,
                        element: <Catalogues />,
                    },
                    {
                        path: authUrl.Index.Catalogues.Edit.path,
                        element: <CataloguesEdit />,
                    },
                    {
                        path: authUrl.Index.Catalogues.New.path,
                        element: <CataloguesEdit />,
                    },
                ],
            },
            {
                path: authUrl.Index.PriceLists.path,
                element: <PriceListsTabs />,
                children: [
                    {
                        path: authUrl.Index.PriceLists.Own.path,
                        element: <PriceListsOwn />,
                    },
                    {
                        path: authUrl.Index.PriceLists.Other.path,
                        element: <PriceListsOther />,
                    },
                ],
            },
            {
                path: authUrl.Index.News.path,
                element: <Outlet />,
                children: [
                    {
                        path: authUrl.Index.News.List.path,
                        element: <News />,
                    },
                    {
                        path: authUrl.Index.News.Edit.path,
                        element: <NewsEdit />,
                    },
                    {
                        path: authUrl.Index.News.New.path,
                        element: <NewsEdit />,
                    },
                ],
            },
            {
                path: authUrl.Index.Members.path,
                element: <Outlet />,
                children: [
                    {
                        path: authUrl.Index.Members.List.path,
                        element: <MembersPage />,
                    },
                    {
                        path: authUrl.Index.Members.Edit.path,
                        element: <MembersEdit />,
                    },
                    {
                        path: authUrl.Index.Members.New.path,
                        element: <MembersEdit />,
                    },
                ],
            },
            {
                path: authUrl.Index.Advantages.path,
                element: <Outlet />,
                children: [
                    {
                        path: authUrl.Index.Advantages.List.path,
                        element: <Advantages />,
                    },
                    {
                        path: authUrl.Index.Advantages.Edit.path,
                        element: <AdvantagesEdit />,
                    },
                    {
                        path: authUrl.Index.Advantages.New.path,
                        element: <AdvantagesEdit />,
                    },
                ],
            },
            {
                path: authUrl.Index.Cooperation.path,
                element: <Cooperation />,
            },

            {
                path: authUrl.All.path,
                element: <>NotFoundPage</>,
            },
            {
                path: authUrl.NotFound.path,
                element: <>NotFoundPage</>,
            },
        ],
    },
];
