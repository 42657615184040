import { Manager, ManagerType } from "@aten/common/dist/models/Manager";
import { Engineers } from "@aten/common/models/Engineers";
import { Model } from "@aten/common/models/Model";
import { Button, Col, DatePicker, Input, message, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import cl from "assets/scss/Space.module.scss";
import Form, { Item, useForm } from "components/Form/Form";
import EngineerList from "components/Form/ProjectFormEdit/components/EngineerList/EngineerList";
import ManagerDistList from "components/Form/ProjectFormEdit/components/ManagerDistList/ManagerDistList";
import ManagerIntegrList from "components/Form/ProjectFormEdit/components/ManagerIntegrList/ManagerIntegrList";
import ModelsList from "components/Form/ProjectFormEdit/components/ModelsList/ModelsList";
import { projectSendData } from "components/Form/ProjectFormEdit/utils/projectSendData";
import InputDaDataAddress from "components/InputDadataAddress/InputDaDataAddress";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import Loader from "components/Loader/Loader";
import ModalDelete from "components/Modal/ModalDelete";
import { useAppSelector } from "hooks/redux";
import useLayout from "hooks/useLayout";
import useModal from "hooks/useModal";
import useNavigate from "hooks/useNavigate";
import moment from "moment";
import { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useSetEngineerMutation } from "services/engineersService";
import { useGetEngineersDataToUsersQuery, useSetEngineerToUserMutation } from "services/engineersToUsersService";
import { useGetManagersByUserIdQuery, useSetNewManagerMutation } from "services/managerService";
import { useSetManagerToUserMutation } from "services/managerToUserService";
import { useGetModelByProjectIdQuery } from "services/modelService";
import {
    useAddProjectMutation,
    useCalculateOrderSumMutation,
    useDeleteProjectMutation,
    useEditProjectMutation,
    useGetProjectByIdQuery,
} from "services/projectsService";
import {
    useDeleteEngineerToProjectMutation,
    useSetEngineerToProjectMutation,
} from "services/projectsToEngineersService";
import { useDeleteProjectToManagerMutation, useSetProjectToManagerMutation } from "services/projectsToManagersService";
import { useGetUserByRoleQuery } from "services/userService";
import { booleanSelectList, projectStatusList, ProjectTypesList } from "staticContent/staticData";
import concatClasses from "utils/concatClasses";
import { numToDate } from "utils/dateConverter";
import { fullEntityName } from "utils/fullNameConverter";
import { required } from "utils/getRules";
import { listToSelect } from "utils/listToSelect";
import { getProjectStatus } from "utils/statusTypeConverter";
import { useModels } from "./hooks/useModels";

import clForm from "./ProjectFormEdit.module.scss";
import { useRefreshState } from "../../../utils/refreshState";
import { ModalOneProjectExcelSave } from "../../Modal/ModalOneProjectExcelSave/ModalOneProjectExcelSave";
import { useOneProjectExcelDownload } from "./hooks/useOneProjectExcelDownload";
import { download } from "./utils/dowload";
import { useGetCostQuery } from "../../../services/RoleToBudgetService";
import { ModalWrongModelType } from "./components/ModalWrongModelType/ModalWrongModelType";
import { excelFileGenerator } from "../../../utils/excelFileGenerator";
import { minSumType } from "../../../pages/Projects/hooks/useProjectCost";

interface ProjectFormEditProps {}

type Entities = "integrators" | "distrs";
type EntitiesSelectState = Record<Entities, { value: string | boolean | number; label: string }[] | undefined>;

export type ListOptions<T> = Partial<T> & {
    value?: number | string;
    label: string;
    disabled: boolean;
    orderNumber: number;
};

const ProjectFormEdit: FC<ProjectFormEditProps> = ({}) => {
    const { id } = useParams();
    const { visible, open, close } = useModal();
    const userId = useAppSelector((state) => state.auth.user?.id);
    const { goBack } = useNavigate();
    const { toggleProjectExcelSaveModal, isShowSaveOneProjectExcelModal, downloadExcel } = useOneProjectExcelDownload();

    const refresh = useRefreshState();

    const [form] = useForm<typeof project>();
    const supplyDateValid =
        Date.parse(new Date().toString()) > Date.parse(new Date(form.getFieldValue("supplyEquipmentDate")).toString());

    const [showFractionalDeliveryDate, setShowFractionalDeliveryDate] = useState<boolean | undefined>(undefined);
    const [otherEntities, setOtherEntities] = useState<EntitiesSelectState>({} as EntitiesSelectState);
    const [edit, setEdit] = useState<string | undefined>(id);
    const [currentDistId, setCurrentDistId] = useState<number | undefined>(undefined);
    const [currentIntegrId, setCurrentIntegrId] = useState<number | undefined>(undefined);
    const [engineerOptions, setEngineerOptions] = useState<ListOptions<Engineers>[]>([]);
    const [managerIntegrOptions, setManagerIntegrOptions] = useState<ListOptions<Manager>[]>([]);
    const [managerDistOptions, setManagerDistOptions] = useState<ListOptions<Manager>[]>([]);
    const [removedManagers, setRemovedManagers] = useState<number[]>([]);
    const [removedEngineers, setRemovedEngineers] = useState<number[]>([]);
    const [isWrongModelType, setIsWrongModelType] = useState(false);
    const [wrongModelTypeList, setWrongModelTypeList] = useState<any[]>([]);
    const [projectType, setProjectType] = useState<string | number | undefined>("");
    const { data: project, isLoading } = useGetProjectByIdQuery(id || "", {
        skip: !id,
        refetchOnMountOrArgChange: true,
    });
    const { data: userDistr } = useGetUserByRoleQuery(1, { refetchOnMountOrArgChange: true });
    const { data: userSi } = useGetUserByRoleQuery(0, { refetchOnMountOrArgChange: true });
    const { data: managersByDistr, isLoading: isManagerDistLoading } = useGetManagersByUserIdQuery(currentDistId, {
        skip: !currentDistId,
        refetchOnMountOrArgChange: true,
    });
    const { data: managersByIntegr, isLoading: isManagerIntrLoading } = useGetManagersByUserIdQuery(currentIntegrId, {
        skip: !currentIntegrId,
        refetchOnMountOrArgChange: true,
    });
    const { data: engineersList, isLoading: isEngineersLoading } = useGetEngineersDataToUsersQuery(
        currentIntegrId?.toString() ?? "",
        {
            skip: !currentIntegrId,
            refetchOnMountOrArgChange: true,
        }
    );

    const { data: costs } = useGetCostQuery();

    const costData = useMemo(() => {
        const obj: {
            ProAV?: number;
            KVMPDU?: number;
        } = {
            ProAV: undefined,
            KVMPDU: undefined,
        };
        if (costs) {
            obj.ProAV = costs?.objects.find(({ projectTypeId }) => projectTypeId === minSumType.ProAV)?.minimumBudget;
            obj.KVMPDU = costs?.objects.find(({ projectTypeId }) => projectTypeId === minSumType.KVMPDU)?.minimumBudget;
        }
        return obj.KVMPDU && obj.ProAV ? obj : undefined;
    }, [costs]);

    const { models, modelsChecking } = useModels(id);

    const [deleteProject, { isLoading: deleteProjectLoad }] = useDeleteProjectMutation();
    const [addProj, { isLoading: addLoad }] = useAddProjectMutation();
    const [editProj, { isLoading: editLoad }] = useEditProjectMutation();
    const [setNewEngineer] = useSetEngineerMutation();
    const [setEngineerToUser] = useSetEngineerToUserMutation();
    const [setEngineerToProject] = useSetEngineerToProjectMutation();
    const [setNewManager] = useSetNewManagerMutation();
    const [setManagerToProject] = useSetProjectToManagerMutation();
    const [setManagerToUser] = useSetManagerToUserMutation();
    const [deleteManagerRelation] = useDeleteProjectToManagerMutation();
    const [deleteEngineerRelation] = useDeleteEngineerToProjectMutation();

    const [calculateOrderSum] = useCalculateOrderSumMutation();
    useLayout(
        {
            title: project?.projectName ?? "Добавить новый проект",
            loading: isLoading,
        },
        [project]
    );
    const onProjectType = (value: any, item: any) => {
        const typeItem = item.label === "KVM/PDU" ? "KVM_PDU" : "ProAv";
        setProjectType(typeItem);
        form.setFieldValue("modelsList", []);
    };

    useEffect(() => {
        setCurrentDistId(project?.distrId);
        setCurrentIntegrId(project?.integratorId);
        setShowFractionalDeliveryDate(project?.needFractionalDelivery);
    }, [project]);
    useEffect(() => {
        setOtherEntities({
            integrators: userSi?.objects.map(listToSelect),
            distrs: userDistr?.objects.map(listToSelect),
        });
    }, [userDistr, userSi]);
    useEffect(() => {
        if (id) {
            form.setFieldsValue({
                ...project,
                makingDecisionDate: moment(project?.makingDecisionDate),
                supplyEquipmentDate: moment(project?.supplyEquipmentDate),
                fractionalDeliveryDate: moment(project?.fractionalDeliveryDate),
                needFractionalDelivery: project?.needFractionalDelivery,
                integratorAddress: project?.integrator.country ?? "",
                integratorSite: project?.integrator.email ?? "",
                countryOfShipment: project?.distr.country,
                modelsList: models,
            });
            setProjectType(project?.projectTypeId ?? "");
        } else {
            form.setFieldsValue({
                projectStatusId: 1,
            });
        }
    }, [id, project, models, userSi]);

    useEffect(() => {
        if (supplyDateValid && !edit) message.warn("Невозможно сохранить проект с текущей датой поставки");
    }, [supplyDateValid, project, edit]);

    useEffect(() => {
        form.setFieldsValue({
            managerDistList: managersByDistr?.list.filter((mi) =>
                project?.projectsToManagers.find((hmi) => hmi.managerId === mi.id && mi.managerType === 1)
            ),
        });
    }, [managersByDistr?.list, project]);

    useEffect(() => {
        form.setFieldsValue({
            managerIntegrList: managersByIntegr?.list.filter((mi) =>
                project?.projectsToManagers.find((hmi) => hmi.managerId === mi.id && mi.managerType === 0)
            ),
        });
    }, [managersByIntegr?.list, project]);

    useEffect(() => {
        form.setFieldsValue({
            engineerList: engineersList?.filter((en) =>
                project?.projectsToEngineers.find((e) => e.engineerId === en?.id)
            ),
        });
    }, [engineersList, project]);
    useEffect(() => {
        setEngineerOptions(
            engineersList?.map((eng, index) => ({
                ...eng,
                label: `${eng?.surname} ${eng?.name} ${eng?.patronymic}`,
                value: eng?.id,
                orderNumber: index,
                disabled: form.getFieldValue("engineerList").some((e: Engineers) => e.id === eng?.id),
            })) ?? []
        );
    }, [engineersList, project]);
    useEffect(() => {
        setManagerIntegrOptions(
            managersByIntegr?.list.map((manager, index) => ({
                ...manager,
                label: `${manager?.surname} ${manager?.name} ${manager?.patronymic}`,
                value: manager?.id,
                orderNumber: index,
                disabled: form.getFieldValue("managerIntegrList").some((e: Manager) => e.id === manager?.id),
            })) ?? []
        );
    }, [managersByIntegr, project]);
    useEffect(() => {
        setManagerDistOptions(
            managersByDistr?.list.map((manager, index) => ({
                ...manager,
                label: `${manager?.surname} ${manager?.name} ${manager?.patronymic}`,
                value: manager?.id,
                orderNumber: index,
                disabled: form.getFieldValue("managerDistList").some((e: Manager) => e.id === manager?.id),
            })) ?? []
        );
    }, [managersByDistr, project]);
    const setDisableEngineerOption = () => {
        const newOptions = engineerOptions.map((option) => ({
            ...option,
            disabled: form.getFieldValue("engineerList").some((o: any) => o.id === option.value),
        }));
        setEngineerOptions(newOptions);
    };
    const setDisableManagerOption = (managerType: ListOptions<Manager>[], managerTypeValue: 0 | 1) => {
        const isIntgr = managerTypeValue === 0;
        const newOptions = managerType.map((option) => ({
            ...option,
            disabled: !!form.getFieldValue(isIntgr ? "managerIntegrList" : "managerDistList").some((o: any) => {
                return o.id === option.value;
            }),
        }));
        isIntgr ? setManagerIntegrOptions(newOptions) : setManagerDistOptions(newOptions);
    };
    const deleteFromEngineerOptions = (id?: number) => {
        if (id) {
            const newOptions = engineerOptions.map((option) => {
                return option.id === id && option.disabled
                    ? {
                          ...option,
                          disabled: false,
                      }
                    : {
                          ...option,
                      };
            });
            setEngineerOptions(newOptions);
        }
    };

    const deleteFromManagerOptions = (
        managerTypeValue: ManagerType,
        id?: number,
        managerType?: ListOptions<Manager>[]
    ) => {
        const isIntgr = managerTypeValue === 0;
        if (id) {
            const newOptions = managerType?.map((option) => {
                return option.id === id && option.disabled
                    ? {
                          ...option,
                          disabled: false,
                      }
                    : { ...option };
            });
            isIntgr ? setManagerIntegrOptions(newOptions ?? []) : setManagerDistOptions(newOptions ?? []);
        }
    };

    const filterOption = (input: string, option: { value: string | boolean | number; label: string } | undefined) =>
        input.length >= 3
            ? (((option?.label as string) ?? "").toLowerCase() ?? "").includes(input.toLowerCase())
            : true;

    const distrCountyChange = (value?: number) => {
        const country = userDistr?.objects.find(({ id }) => id === value)?.country;
        form.setFieldValue("countryOfShipment", country);
        form.setFieldValue("managerDistList", "");
        setCurrentDistId(value);
    };

    const integratorDataChange = (value?: number) => {
        const curInt = userSi?.objects.find(({ id }) => id === value);
        setCurrentIntegrId(value);
        form.setFieldValue("engineerList", []);
        form.setFieldValue("integratorSite", curInt?.email ?? "");
        form.setFieldValue("integratorAddress", `${curInt?.country ?? ""} ${curInt?.city ?? ""}`);
    };

    const onFinish = async (value: any) => {
        delete value.integratorAddress;
        delete value.integratorSite;

        const data = form.getFieldsValue();
        if (costData?.ProAV && data?.projectTypeId === "ProAV" && Number(data?.orderSum) < costData?.ProAV) {
            message.warn(`Стоимость проекта ProAV не может быть меньше ${costData?.ProAV}`);
            return;
        }
        if (costData?.KVMPDU && data?.projectTypeId === "KVMPDU" && Number(data?.orderSum) < costData?.KVMPDU) {
            message.warn(`Стоимость проекта KVMPDU не может быть меньше ${costData?.KVMPDU}`);
            return;
        }

        const newId = await projectSendData({
            id,
            value,
            userId,
            form,
            project,
            editProj,
            addProj,
            removedEngineers,
            deleteEngineerRelation,
            setNewManager,
            setManagerToUser,
            setManagerToProject,
            setNewEngineer,
            setEngineerToProject,
            setEngineerToUser,
            removedManagers,
            deleteManagerRelation,
            calculateOrderSum,
        });

        if (newId) {
            await calculateOrderSum({ projectId: newId });
            const res = await modelsChecking(value.modelsList as Model[], newId).then((res) => res);

            if (res?.data?.responseWithErrors?.length > 0) {
                setIsWrongModelType(true);
                setWrongModelTypeList(res?.data?.responseWithErrors);
            }
            toggleProjectExcelSaveModal();
            const OneProjectExcelDownload = localStorage.getItem("OneProjectExcelDownload");
            const suggestOneProjectExcelDownload = localStorage.getItem("suggestOneProjectExcelDownload");
            if (suggestOneProjectExcelDownload) {
                OneProjectExcelDownload && download(downloadExcel, form.getFieldsValue());
                goBack(0);
            }
        }
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Form
                    form={form}
                    data={project}
                    onFinish={onFinish}
                    className={clForm.form}
                    onFieldsChange={() => {
                        refresh();
                    }}>
                    <>
                        {edit && (
                            <Row className={concatClasses(cl.space, cl.space__end, clForm.editRow)} gutter={[30, 30]}>
                                <Col span={8}>
                                    <Button onClick={() => goBack(0)}>К списку</Button>
                                </Col>
                                <Col span={8}>
                                    <Button type="primary" onClick={() => setEdit(undefined)}>
                                        Редактировать проект
                                    </Button>
                                </Col>
                            </Row>
                        )}
                        <Row className={concatClasses(cl.space, cl.space__between, clForm.Row)} gutter={[30, 30]}>
                            <Col span={24}>
                                <div className={clForm.title}>Дистрибьютор</div>
                            </Col>
                            <Col span={8}>
                                <div className={clForm.item}>
                                    <Label type={LabelTypes.Form} label={"Тип проекта"} />
                                    {edit && project ? (
                                        <span className={clForm.span}>{project?.projectTypeId}</span>
                                    ) : (
                                        <Item name="projectTypeId" rules={[required()]}>
                                            <Select
                                                onChange={onProjectType}
                                                disabled={!!id}
                                                options={ProjectTypesList}
                                            />
                                        </Item>
                                    )}
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className={clForm.item}>
                                    <Label type={LabelTypes.Form} label={"Страна поставки"} />
                                    {edit ? (
                                        <span className={clForm.span}>{project?.distr.country}</span>
                                    ) : (
                                        <Item name="countryOfShipment">
                                            <Input disabled />
                                        </Item>
                                    )}
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className={clForm.item}>
                                    <Label type={LabelTypes.Form} label={"Дистрибьютор"} />
                                    {edit ? (
                                        <span className={clForm.span}>{fullEntityName(project?.distr)}</span>
                                    ) : (
                                        <Item name="distrId" rules={[required()]}>
                                            <Select
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={filterOption}
                                                onChange={distrCountyChange}
                                                options={otherEntities.distrs}
                                            />
                                        </Item>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <ManagerDistList
                            isManagerDistLoading={isManagerDistLoading}
                            edit={edit}
                            form={form}
                            managerDistOptions={managerDistOptions}
                            setDisableManagerOption={setDisableManagerOption}
                            setRemovedManagers={setRemovedManagers}
                            deleteFromManagerOptions={deleteFromManagerOptions}
                        />

                        <Row className={concatClasses(cl.space, cl.space__between, clForm.Row)} gutter={[30, 30]}>
                            <Col span={24}>
                                <div className={clForm.title}>Партнер</div>
                            </Col>
                        </Row>
                        <Row className={concatClasses(cl.space, cl.space__between, clForm.Row)} gutter={[30, 30]}>
                            <Col span={8}>
                                <div className={clForm.item}>
                                    <Label type={LabelTypes.Form} label={"Название интегратора"} />
                                    {edit ? (
                                        <span className={clForm.span}>{fullEntityName(project?.integrator)}</span>
                                    ) : (
                                        <Item name="integratorId" rules={[required()]}>
                                            <Select
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={filterOption}
                                                onChange={integratorDataChange}
                                                options={otherEntities.integrators}
                                            />
                                        </Item>
                                    )}
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className={clForm.item}>
                                    <Label type={LabelTypes.Form} label={"Сайт интегратора"} />
                                    {edit ? (
                                        <span className={clForm.span}>
                                            {userSi?.objects.find(({ id }) => id === form.getFieldValue("integratorId"))
                                                ?.email ?? ""}
                                        </span>
                                    ) : (
                                        <Item name={"integratorSite"}>
                                            <Input disabled />
                                        </Item>
                                    )}
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className={clForm.item}>
                                    <Label type={LabelTypes.Form} label={"Адрес интегратора"} />
                                    {edit ? (
                                        <span className={clForm.span}>
                                            {userSi?.objects.find(({ id }) => id === form.getFieldValue("integratorId"))
                                                ?.country ?? ""}
                                        </span>
                                    ) : (
                                        <Item name={"integratorAddress"}>
                                            <Input disabled />
                                        </Item>
                                    )}
                                </div>
                            </Col>
                        </Row>

                        <EngineerList
                            isLoading={isEngineersLoading}
                            form={form}
                            engineerOptions={engineerOptions}
                            deleteFromEngineerOptions={deleteFromEngineerOptions}
                            setRemovedEngineers={setRemovedEngineers}
                            edit={edit}
                            setDisableEngineerOption={setDisableEngineerOption}
                        />

                        <ManagerIntegrList
                            isLoading={isManagerIntrLoading}
                            form={form}
                            managerIntegrOptions={managerIntegrOptions}
                            setDisableManagerOption={setDisableManagerOption}
                            setRemovedManagers={setRemovedManagers}
                            deleteFromManagerOptions={deleteFromManagerOptions}
                            edit={edit}
                        />
                        <Row className={concatClasses(cl.space, cl.space__between, clForm.Row)} gutter={[30, 30]}>
                            <Col span={24}>
                                <div className={clForm.title}>Заказчик</div>
                            </Col>
                            <Col span={8}>
                                <div className={clForm.item}>
                                    <Label type={LabelTypes.Form} label={"Название проекта"} />
                                    {edit ? (
                                        <span className={clForm.span}>{project?.projectName}</span>
                                    ) : (
                                        <Item name="projectName" rules={[required()]}>
                                            <Input />
                                        </Item>
                                    )}
                                </div>
                                <div className={clForm.item}>
                                    <Label type={LabelTypes.Form} label={"Адрес заказчика"} />
                                    {edit ? (
                                        <span className={clForm.span}>{project?.customerAddress}</span>
                                    ) : (
                                        <Item name="customerAddress" rules={[required()]}>
                                            <InputDaDataAddress />
                                        </Item>
                                    )}
                                </div>
                                <div className={clForm.item}>
                                    <Label type={LabelTypes.Form} label={"Дата принятия решения"} />
                                    {edit ? (
                                        <span className={clForm.span}>{numToDate(project?.makingDecisionDate)}</span>
                                    ) : (
                                        <Item rules={[required()]} name="makingDecisionDate">
                                            <DatePicker format={"DD.MM.YYYY"} onChange={() => {}} />
                                        </Item>
                                    )}
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className={clForm.item}>
                                    <Label type={LabelTypes.Form} label={"Название заказчика"} />
                                    {edit ? (
                                        <span className={clForm.span}>{project?.customerName}</span>
                                    ) : (
                                        <Item rules={[required()]} name={"customerName"}>
                                            <Input />
                                        </Item>
                                    )}
                                </div>
                                <div className={clForm.item}>
                                    <Label type={LabelTypes.Form} label={"Адрес объекта"} />
                                    {edit ? (
                                        <span className={clForm.span}>{project?.objectAddress}</span>
                                    ) : (
                                        <Item rules={[required()]} name={"objectAddress"}>
                                            <InputDaDataAddress />
                                        </Item>
                                    )}
                                </div>
                                <div className={clForm.item}>
                                    <Label type={LabelTypes.Form} label={"Дата поставки оборудования"} />
                                    {edit ? (
                                        <span className={clForm.span}>{numToDate(project?.supplyEquipmentDate)}</span>
                                    ) : (
                                        <Item name={"supplyEquipmentDate"} rules={[required()]}>
                                            <DatePicker
                                                format={"DD.MM.YYYY"}
                                                disabledDate={(date) => {
                                                    return date < moment(new Date());
                                                }}
                                            />
                                        </Item>
                                    )}
                                </div>
                            </Col>
                            <Col span={8} className={clForm.flexCol}>
                                <div className={clForm.item}>
                                    <Label type={LabelTypes.Form} label={"Сайт заказчика"} />
                                    {edit ? (
                                        <span className={clForm.span}>{project?.customerSite}</span>
                                    ) : (
                                        <Item rules={[required()]} name={"customerSite"}>
                                            <Input />
                                        </Item>
                                    )}
                                </div>
                                <div className={concatClasses(clForm.item, clForm.item__100)}>
                                    <Label type={LabelTypes.Form} label={"Краткое описание инсталяции"} />
                                    {edit ? (
                                        <span className={clForm.span}>{project?.shortInstallationDescription}</span>
                                    ) : (
                                        <Item
                                            className={clForm.textarea}
                                            rules={[required()]}
                                            name={"shortInstallationDescription"}>
                                            <TextArea className={clForm.textarea100} />
                                        </Item>
                                    )}
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className={clForm.item}>
                                    <Label type={LabelTypes.Form} label={"Статус проекта"} />
                                    {edit ? (
                                        <span className={clForm.span}>
                                            {getProjectStatus(project?.projectStatusId)}
                                        </span>
                                    ) : (
                                        <Item name="projectStatusId" rules={[required()]}>
                                            <Select
                                                options={projectStatusList}
                                                disabled={!id}
                                                defaultValue={!id && 0}
                                            />
                                        </Item>
                                    )}
                                </div>
                                <div className={clForm.item}>
                                    <Label type={LabelTypes.Form} label={"Необходимость дробной поставки"} />
                                    {edit ? (
                                        <span className={clForm.span}>
                                            {project?.needFractionalDelivery
                                                ? numToDate(project?.fractionalDeliveryDate)
                                                : "Нет"}
                                        </span>
                                    ) : showFractionalDeliveryDate ? (
                                        <Item name={"fractionalDeliveryDate"} rules={[required()]}>
                                            <DatePicker
                                                format={"DD.MM.YYYY"}
                                                onChange={(date) => {
                                                    if (date === null) {
                                                        form.setFieldValue("needFractionalDelivery", false);
                                                        form.setFieldValue("fractionalDeliveryDate", null);
                                                        setShowFractionalDeliveryDate(false);
                                                    }
                                                }}
                                            />
                                        </Item>
                                    ) : (
                                        <Item rules={[required()]}>
                                            <Select
                                                defaultValue={form.getFieldValue("needFractionalDelivery")}
                                                options={booleanSelectList}
                                                onChange={(value) => {
                                                    setShowFractionalDeliveryDate(value);
                                                }}
                                            />
                                        </Item>
                                    )}
                                </div>
                            </Col>
                            <Col span={16}>
                                <div className={clForm.item}>
                                    <Label type={LabelTypes.Form} label={"Конкуренты (вендоры) в проекте"} />
                                    {edit ? (
                                        <span className={clForm.span}>{project?.vendors}</span>
                                    ) : (
                                        <Item name={"vendors"} rules={[required()]}>
                                            <TextArea className={clForm.textarea150px} />
                                        </Item>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row
                            className={concatClasses(cl.space, cl.space__alignCenter, cl.space__margBot)}
                            gutter={[30, 30]}>
                            <Col span={8}>
                                <div className={clForm.item}>
                                    <Label type={LabelTypes.Form} label={"Сумма заказа"} />
                                    {edit ? (
                                        <span className={clForm.span}>{project?.orderSum}</span>
                                    ) : (
                                        <Item name={"orderSum"} rules={[]}>
                                            <Input disabled />
                                        </Item>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <ModelsList form={form} edit={edit} projectType={projectType} />

                        <Row
                            className={concatClasses(
                                cl.space,
                                cl.space__between,
                                cl.space__margBot,
                                cl.space__alignCenter
                            )}
                            gutter={[30, 30]}>
                            <Col span={16}>
                                <div className={clForm.item}>
                                    <Label type={LabelTypes.Form} label={"Дополнительная информация"} />
                                    {edit ? (
                                        <span className={clForm.span}>{project?.comments}</span>
                                    ) : (
                                        <Item name={"comments"} rules={[required()]}>
                                            <TextArea className={clForm.textarea150px} />
                                        </Item>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        {!edit && (
                            <Row className={concatClasses(cl.space, cl.space__alignCenter)} gutter={[30, 30]}>
                                <Col span={8}>
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        loading={addLoad || editLoad}
                                        disabled={supplyDateValid}>
                                        Сохранить проект
                                    </Button>
                                </Col>
                                <Col span={8}>
                                    <Button
                                        onClick={() => {
                                            id ? setEdit(id) : goBack(0);
                                            form.setFieldsValue({
                                                engineerList: engineersList,
                                                managerIntegrList: managersByIntegr?.list.filter((mi) =>
                                                    project?.projectsToManagers.find(
                                                        (hmi) => hmi.managerId === mi.id && mi.managerType === 0
                                                    )
                                                ),
                                                managerDistList: managersByDistr?.list.filter((mi) =>
                                                    project?.projectsToManagers.find(
                                                        (hmi) => hmi.managerId === mi.id && mi.managerType === 1
                                                    )
                                                ),
                                                modelsList: models,
                                            });
                                        }}>
                                        Отменить
                                    </Button>
                                </Col>
                                {id && (
                                    <Col span={8}>
                                        <Button
                                            className={"buttonRed"}
                                            onClick={() => {
                                                open();
                                            }}>
                                            Удалить проект
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                        )}
                    </>
                </Form>
            )}
            <ModalWrongModelType
                open={isWrongModelType}
                onCancel={() => setIsWrongModelType(false)}
                onOk={() => {
                    excelFileGenerator({
                        data: wrongModelTypeList.map(({ modelName, name }) => ({
                            Модель: modelName,
                            Название: name,
                        })),
                        fileName: "Список моделей с некорректным типом",
                    });
                    setIsWrongModelType(false);
                }}
            />
            <ModalDelete
                title={`Вы уверены, что хотите удалить проект `}
                open={visible}
                onCancel={() => close()}
                loading={deleteProjectLoad}
                onOk={() =>
                    deleteProject(id || "").then(() => {
                        close();
                        goBack(0);
                    })
                }
            />
            <ModalOneProjectExcelSave
                open={isShowSaveOneProjectExcelModal && !isWrongModelType}
                onOk={() => {
                    const data = form.getFieldsValue();
                    localStorage.setItem("OneProjectExcelDownload", "true");
                    download(downloadExcel, data);
                    goBack(0);
                }}
                onCancel={() => {
                    toggleProjectExcelSaveModal();
                    goBack(0);
                }}
            />
        </>
    );
};
export default ProjectFormEdit;
