import { FC } from "react";
import { createHashRouter, RouteObject, RouterProvider } from "react-router-dom";
import { publicRoutes } from "../../config/routes";

interface AppRouterProps {
    routes: RouteObject[];
}

const AppRouter: FC<AppRouterProps> = ({ routes = publicRoutes }) => {
    return <RouterProvider router={createHashRouter(routes)} />;
};

export default AppRouter;
