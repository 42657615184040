import useLayoutContext from "components/Contexts/LayoutContext";
import { useEffect } from "react";

interface UseLayoutArgs {
    title?: string;
    loading?: boolean;
}

type UseLayoutProps = (args: UseLayoutArgs, deps?: any[]) => void;

const useLayout: UseLayoutProps = ({ title, loading = false }, deps) => {
    const { title: contextTitle, setTitle, setLoading } = useLayoutContext();

    useEffect(() => {
        setTitle?.(title);
    }, [...(deps ?? []), loading]);

    useEffect(() => {
        setLoading?.(loading);
    }, [loading]);

    return {
        title: contextTitle,
    };
};

export default useLayout;
