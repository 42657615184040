import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { urlApi } from "config/api";
import { RootState } from "store";

export const onlyTokenApi = createApi({
    reducerPath: "TokenApi",
    baseQuery: fetchBaseQuery({
        baseUrl: urlApi,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    tagTypes: [
        "File",
    ],
    endpoints: (_) => ({}),
});
