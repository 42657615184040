import { Lesson, LessonEditResponse, LessonResponse } from "@aten/common/dist/models/Lesson";
import { UrlApi } from "config/api";
import { IPagination } from "hooks/usePagination";
import { commonApi } from "services/commonApiService";

export const lessonsApi = commonApi.injectEndpoints({
    endpoints: (build) => ({
        getLessons: build.query<LessonResponse, { pagination?: IPagination; SearchParams?: string } | void>({
            query: ({ pagination, SearchParams } = {}) => ({
                url: `${UrlApi.Lessons}${SearchParams ?? ""}`,
                params: {
                    ...pagination,
                },
            }),
            providesTags: ["Lesson"],
        }),
        getLessonsByIds: build.query<LessonResponse, string>({
            query: (ids: string) => `${UrlApi.Lessons}?ListOfIdsInStringFormat=${ids}`,
            providesTags: ["Lesson"],
        }),
        getLessonById: build.query<Lesson, string>({
            query: (id: string) => `${UrlApi.Lessons}/${id}`,
            providesTags: ["Lesson"],
        }),
        getLessonsByThemeId: build.query<LessonResponse, string>({
            query: (themeId: string) => `${UrlApi.Lessons}/?ThemeId=${themeId}`,
            providesTags: ["Lesson"],
        }),
        editLesson: build.mutation<LessonEditResponse, Lesson>({
            query: (data: Lesson) => ({
                url: `${UrlApi.Lessons}/${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["Lesson"],
        }),
        setLesson: build.mutation<LessonEditResponse, Lesson>({
            query: (data: Lesson) => ({
                url: UrlApi.Lessons,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Lesson"],
        }),
        deleteLesson: build.mutation<LessonResponse, string>({
            query: (id: string) => ({
                url: `${UrlApi.Lessons}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Lesson"],
        }),
    }),
});

export const {
    useGetLessonsQuery,
    useGetLessonsByIdsQuery,
    useGetLessonByIdQuery,
    useGetLessonsByThemeIdQuery,
    useEditLessonMutation,
    useSetLessonMutation,
    useDeleteLessonMutation,
} = lessonsApi;
