import React, {FC} from 'react';
import {Checkbox, Modal, ModalProps} from "antd";

import st from './ModalOneProjectExcelSave.module.scss'
import Title from "../../Title/Title";

interface ModalOneProjectExcelSaveProps extends ModalProps {
}

export const ModalOneProjectExcelSave: FC<ModalOneProjectExcelSaveProps> = ({...props}) => {


    const suggestOneProjectExcelDownload = localStorage.getItem('suggestOneProjectExcelDownload')

    return (
        <Modal {...props}
            className={st.modal}
               okText={'Да'}
               cancelText={'Нет'}
               open={suggestOneProjectExcelDownload !== null ? false : props.open}
        >
            <Title title={'Выгрузить проект в Excel?'}/>
            <div className={st.assumption}>
                <Checkbox id={'oneProjectExcelDownload'} onChange={()=>{
                    localStorage.setItem('suggestOneProjectExcelDownload', 'true')
                }}/>
                <label htmlFor={'oneProjectExcelDownload'} className={st.assumption__label}>
                    Больше не предлагать
                </label>
            </div>
        </Modal>
    );
};
