import { SelectOption } from "types/select";

export enum ProjectTypes {
    "ProAV",
    "KVM/PDU",
}

export const ProjectTypesList: Omit<SelectOption, "key">[] = [
    {
        value: ProjectTypes.ProAV,
        label: "ProAV",
    },
    {
        value: ProjectTypes["KVM/PDU"],
        label: "KVM/PDU",
    },
];

export const booleanSelectList = [
    { value: true, label: "Да" },
    {
        value: false,
        label: "Нет",
    },
];

export const projectStatusList = [
    { value: 0, label: "Открытый" },
    {
        value: 1,
        label: "На рассмотрении",
    },
    { value: 2, label: "Закрытый" },
    { value: 3, label: "Активный" },
    { value: 4, label: "Отменен" },
    { value: 5, label: "Отгружен" },
];
