import ProjectFormEdit from "components/Form/ProjectFormEdit/ProjectFormEdit";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import { FC } from "react";

const ProjectEdit: FC = () => {
    return (
        <LayoutWrapper>
            <ProjectFormEdit />
        </LayoutWrapper>
    );
};
export default ProjectEdit;
