import {
    Theme,
    ThemeResponse,
    ThemeTable
} from "@aten/common/dist/models/Theme";
import serializer, { Serializer } from "serializers/index";

const themesSerializer: Serializer<ThemeResponse, Theme, ThemeTable> = (
    projects
) => {
    return serializer<ThemeResponse, Theme, ThemeTable>(projects, (el) => ({
        ...el,
        key: el.id ?? 0,
        lessonsNum: el.lessonsIds?.length,
        testsNum: el.testsIds?.length,
    }));
};

export default themesSerializer;
