import { Project, ProjectResponse } from "@aten/common/dist/models/Project";
import { ProjectsDistributors, ProjectsIntegrators } from "@aten/common/models/Project";
import { UrlApi } from "config/api";
import { IPagination } from "hooks/usePagination";
import { ISort } from "hooks/useSort";
import { commonApi } from "services/commonApiService";

export const projectsApi = commonApi.injectEndpoints({
    endpoints: (builder) => ({
        getProjects: builder.query<
            ProjectResponse,
            { pagination?: IPagination; userId?: number | string; sorting?: ISort; params?: string }
        >({
            query: ({ pagination, userId, sorting, params }) => {
                return {
                    url: `${UrlApi.Projects}${params}`,
                    params: {
                        userId,
                        ...pagination,
                        ...sorting,
                    },
                };
            },
            providesTags: ["Project"],
        }),
        getDistr: builder.query<ProjectsDistributors, void>({
            query: () => ({
                url: `${UrlApi.Projects}/GetDistr`,
            }),
            providesTags: ["Project"],
        }),
        getIntegr: builder.query<ProjectsIntegrators, void>({
            query: () => ({
                url: `${UrlApi.Projects}/GetInterg`,
            }),
            providesTags: ["Project"],
        }),
        getProjectById: builder.query<Project | undefined, string>({
            query: (id) => ({
                url: UrlApi.Projects,
                params: {
                    Id: id,
                },
            }),
            transformResponse: (res: ProjectResponse) => {
                if (res.objects.length === 0) {
                    return undefined;
                }
                const projectData = res.objects[0];
                return projectData;
            },
            providesTags: ["Project", "Engineers"],
        }),
        // getProjectByIdWithOtherEntities: builder.query<Project | undefined, string>({
        //     async queryFn(id, {dispatch}) {
        //         const proj: Project | undefined = await dispatch(projectsApi.endpoints.getProjectById.initiate(id)).unwrap();
        //
        //
        //         // const managerDistr: Manager | undefined = await dispatch(managersApi.endpoints.getManagerById.initiate(proj?.managerId.toString() ?? '')).unwrap()
        //         // const managerUser: Manager | undefined = await dispatch(managersApi.endpoints.getManagerById.initiate(proj?.userId.toString() ?? '')).unwrap()
        //
        //         // const enginiersIds = await dispatch(engineersToUsersApi.endpoints.getEngineerToUsersByIds.initiate({
        //         //     userId: proj?.userId.toString() ?? ''
        //         // })).unwrap()
        //         //
        //         // const enginiersData = []
        //
        //
        //         // const pr: Project | undefined= {
        //         //     ...proj,
        //         //     // managerDistrName: fullEntityName(managerDistr),
        //         //     // managerDistrPhone: managerDistr?.phone,
        //         //     // managerDistrEmail: managerDistr?.email,
        //         //     // managerUserName: fullEntityName(managerUser),
        //         //     // managerUserPhone: managerUser?.phone,
        //         //     // managerUserEmail: managerUser?.email
        //         // }
        //         return {data: proj}
        //     },
        //     async onQueryStarted({}, {dispatch, queryFulfilled}) {
        //         await queryFulfilled
        //         await dispatch(engineersApi.util.invalidateTags(['Engineers']))
        //     }
        //
        // }),

        DeleteProject: builder.mutation({
            query: (id: string | number) => ({
                url: `${UrlApi.Projects}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Project"],
        }),
        AddProject: builder.mutation<{ addedId: number }, Project>({
            query: (data) => ({
                url: UrlApi.Projects,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Project"],
        }),
        EditProject: builder.mutation<{ updatedId: number }, Project>({
            query: (data) => ({
                url: `${UrlApi.Projects}/${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["Project"],
        }),
        GetProjectExcel: builder.query({
            query: (str: string) => {
                return {
                    url: `${UrlApi.ProjectsExcel}${str}`,
                    responseHandler: async (response: any) => {
                        return await response.blob().then((file: any) => {
                            const url = window.URL.createObjectURL(file);
                            const a = document.createElement("a");
                            a.href = url;
                            a.download = `Отчет по проектам.xlsx`;
                            a.click();
                        });
                    },
                };
            },
        }),
        CalculateOrderSum: builder.mutation({
            query: (data) => ({
                url: `${UrlApi.CalculateOrderSum}?projectId=${data.projectId}`,
                method: "POST",
            }),
        }),
        SendMailMessage: builder.mutation({
            query: ({ body }) => ({
                url: `${UrlApi.Projects}/SendMailMessageOnSelectedProjects?${Object.entries(body)
                    .map(([k, v]) => `${k}=${v}&`)
                    .join("")
                    .slice(0, -1)}`,
                method: "POST",
                body: body.projects,
            }),
        }),
    }),
});

export const {
    useGetProjectsQuery,
    useGetProjectByIdQuery,
    useDeleteProjectMutation,
    // useGetProjectByIdWithOtherEntitiesQuery,
    useAddProjectMutation,
    useEditProjectMutation,
    useGetProjectExcelQuery,
    useGetDistrQuery,
    useGetIntegrQuery,
    useSendMailMessageMutation,
    useCalculateOrderSumMutation,
} = projectsApi;
