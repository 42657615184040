import { Theme, ThemeTable } from "@aten/common/dist/models/Theme";
import { ColumnsType } from "antd/lib/table";
import { Icons } from "components/Icons/Icons";
import { authUrl } from "config/path";
import { DeletingThemesProps } from "pages/Theme/Themes";
import { generatePath, Link } from "react-router-dom";
import { ModalType } from "utils/getContentAndFuncForDelete";

type ThemesColumnsProps = {
    setTypeModal: (type: ModalType) => void;
    setDeletingTheme: (props: DeletingThemesProps) => void;
    openDelete: () => void;
};

const deleteTheme = (theme: Theme, props: ThemesColumnsProps) => {
    props.setTypeModal("theme");
    props.setDeletingTheme({
        id: theme.id?.toString() ?? "",
        title: theme.title ?? "",
    });
    props.openDelete();
};

export const themesColumns = (props: ThemesColumnsProps): ColumnsType<ThemeTable> => [
    { width: "5%", title: "№", key: "orderNumber", dataIndex: "orderNumber" },
    { width: "5%", title: "ID", key: "id", dataIndex: "id" },
    { title: "Название", key: "title", dataIndex: "title" },
    {
        title: "Краткое описание",
        key: "description",
        dataIndex: "description",
        render: (_, data) => {
            return <p dangerouslySetInnerHTML={{ __html: data.description }} />;
        },
    },
    {
        width: "10%",
        title: "Действия",
        key: "action",
        dataIndex: "action",
        render: (_, el: Theme) => {
            return (
                <>
                    <div style={{ display: "flex" }}>
                        <div style={{ marginRight: 10 }}>
                            <Link
                                to={generatePath(authUrl.Index.Lessons.Edit.url, {
                                    id: el.id.toString(),
                                })}>
                                <Icons.EditButton />
                            </Link>
                        </div>
                        <div>
                            <Icons.Delete onClick={() => deleteTheme(el, props)} />
                        </div>
                    </div>
                </>
            );
        },
    },
];
