import { Test, TestEditResponse, TestResponse } from "@aten/common/dist/models/Test";
import { UrlApi } from "config/api";
import { IPagination } from "hooks/usePagination";
import { commonApi } from "services/commonApiService";

export const testsApi = commonApi.injectEndpoints({
    endpoints: (build) => ({
        getTests: build.query<TestResponse, { pagination?: IPagination; SearchParams?: string } | void>({
            query: ({ pagination, SearchParams } = {}) => ({
                url: `${UrlApi.Tests}${SearchParams ?? ""}`,
                params: {
                    pagination,
                },
            }),
            providesTags: ["Test"],
        }),
        getTestsByIds: build.query<TestResponse, string>({
            query: (ids: string) => `${UrlApi.Tests}?ListOfIdsInStringFormat=${ids}`,
            providesTags: ["Test"],
        }),
        getTestById: build.query<Test, string>({
            query: (id: string) => ({
                url: `${UrlApi.Tests}/${id}`,
            }),
            providesTags: ["Test"],
        }),
        getTestsByThemeId: build.query<TestResponse, string>({
            query: (themeId: string) => `${UrlApi.Tests}/?ThemeId=${themeId}`,
            providesTags: ["Test"],
        }),
        setTest: build.mutation<TestEditResponse, Test | undefined>({
            query: (data: Test) => ({
                url: UrlApi.Tests,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Test"],
        }),
        editTest: build.mutation<TestEditResponse, Test | undefined>({
            query: (data: Test) => ({
                url: `${UrlApi.Tests}/${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["Test"],
        }),
        deleteTest: build.mutation<TestResponse, string>({
            query: (id: string) => ({
                url: `${UrlApi.Tests}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Test"],
        }),
    }),
});

export const {
    useGetTestsQuery,
    useGetTestsByIdsQuery,
    useGetTestByIdQuery,
    useGetTestsByThemeIdQuery,
    useEditTestMutation,
    useSetTestMutation,
    useDeleteTestMutation,
} = testsApi;
