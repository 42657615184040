import { PriceList } from "@aten/common/models/PriceList";
import { PriceListPropertySortList } from "@aten/common/models/PriceListPropertySort";
import { message, Spin } from "antd";
import { RcFile } from "antd/lib/upload/interface";
import usePage from "components/Contexts/PageContext";
import { CustomButton } from "components/CustomButton/CustomButton";
import { Icons } from "components/Icons/Icons";
import ModalDelete from "components/Modal/ModalDelete";
import UploadFile from "components/UploadFile/UploadFile";
import { useAppSelector } from "hooks/redux";
import useFluid from "hooks/useFluid";
import useModal from "hooks/useModal";
import usePagination from "hooks/usePagination";
import AddVariablesModal from "pages/PriceLists/Modals/AddVariablesModal";
import DownloadExcelModal from "pages/PriceLists/Modals/DownloadExcelModal";
import ModalEdit from "pages/PriceLists/Modals/ModalEdit";
import PriceListSearchModal from "pages/PriceLists/Modals/PriceListSearchModal";
import styles from "pages/PriceLists/PriceLists.module.scss";
import PriceListSort, { DataType } from "pages/PriceLists/PriceListSort/PriceListSort";
import PriceListsTable, { calculateWidthByColumns } from "pages/PriceLists/PriceListsTable/PriceListsTable";
import getColumns from "pages/PriceLists/utils/getColumns";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import {
    useCheckFCAPriceListMutation,
    useUploadFCAFileMutation,
    useUploadMSRPFileMutation,
} from "services/fileService";
import {
    useGetPriceListConstantForUserQuery,
    useRecalculatePriceListsMutation,
} from "services/priceListConstantService";
import { useGetPriceListParentCategoriesQuery } from "services/priceListParentCategoriesService";
import {
    useGetPriceListPropertySortAndSaveQuery,
    useSavePriceListPropertySortMutation,
} from "services/priceListPropertySortService";
import { useDeletePriceListMutation } from "services/priceListsService";

export type DeletingPriceListsProps = {
    title: string;
    id?: string;
};

const PriceListsOwn: FC = ({}) => {
    const { getFluidTableHeight } = useFluid();
    const { windowSize } = usePage();
    const { pagination, changePagination, current } = usePagination();
    const user = useAppSelector((state) => state.auth.user);
    const searchWrapper = useRef<HTMLDivElement>(null);
    const downloadWrapper = useRef<HTMLDivElement>(null);

    const { visible: sortingPriceModal, open: openSortingPriceModal, close: closeSortingPriceModal } = useModal();
    const { visible: deletePriceModal, open: openDeletePriceModal, close: closeDeletePriceModal } = useModal();
    const { visible: variablesModal, open: openVariablesModal, close: closeVariablesModal } = useModal();
    const { visible: overlapModal, open: openOverlapModal, close: closeOverlapModal } = useModal();
    const { visible: editModal, open: openEditModal, close: closeEditModal } = useModal();
    const { visible: excelModal, open: openExcelModal, close: closeExcelModal } = useModal();
    const { visible: searchModal, open: openSearchModal, close: closeSearchModal } = useModal();
    const [searchParams, setSearchParams] = useState<string | undefined>();
    const [deletingPrice, setDeletingPrice] = useState<DeletingPriceListsProps>({ title: "", id: "" });
    const [editedPriceList, setEditedPriceList] = useState<PriceList>();
    const [mainLoading, setMainLoading] = useState<boolean>(false);
    const [FCAFile, setFCAFile] = useState<Blob | undefined>();
    const [otherWrapperHeight, setOtherWrapperHeight] = useState<number>(0);

    const { data: categories, isLoading, isFetching } = useGetPriceListParentCategoriesQuery({ ...pagination });
    const {
        data: priceListConstants,
        isLoading: loadConstant,
        isFetching: fetchConstant,
    } = useGetPriceListConstantForUserQuery({ userId: user?.id ?? 0 }, { skip: !user?.id });
    const {
        data: priceListPropertySort,
        isLoading: loadSort,
        isFetching: fetchSort,
    } = useGetPriceListPropertySortAndSaveQuery({ UserId: user?.id ?? 0 }, { skip: !user?.id });

    const [deletePriceList, { isLoading: loadDelete }] = useDeletePriceListMutation();
    const [uploadMSRPFile, { isLoading: loadMSRP }] = useUploadMSRPFileMutation();
    const [checkFCAFile, { isLoading: loadCheckFCA }] = useCheckFCAPriceListMutation();
    const [uploadFCAFile, { isLoading: loadFCA }] = useUploadFCAFileMutation();
    const [recalculate, { isLoading: loadRecalculate }] = useRecalculatePriceListsMutation();
    const [saveSort, { isLoading: loadSortSaving }] = useSavePriceListPropertySortMutation();

    useEffect(() => {
        const height = Number(searchWrapper?.current?.offsetHeight) + Number(downloadWrapper?.current?.offsetHeight);

        setOtherWrapperHeight(Number.isNaN(Number(height)) ? 0 : Number(height));
    }, [windowSize.width, windowSize.height, searchWrapper.current, downloadWrapper.current]);

    const openSort = () => openSortingPriceModal();
    const openVariables = () => openVariablesModal();

    const onRecalculate = async () => {
        await recalculate();
    };

    const onFinishSort = async (propertySortList: PriceListPropertySortList) => {
        closeSortingPriceModal();

        await saveSort({ propertySortList })
            .unwrap()
            .then(() => {
                message.success("Успешно обновлено");
            });
    };

    const onDeletePriceList = async () => {
        deletingPrice?.id && (await deletePriceList({ id: Number(deletingPrice?.id) }));
        closeDeletePriceModal();
    };

    const onEditPriceList = (priceList: Partial<PriceList>) => {
        setEditedPriceList(priceList);
        openEditModal();
    };

    const columns = useMemo(
        () =>
            getColumns({
                propertySortList: priceListPropertySort,
                openDeletePriceModal,
                setDeletingPrice,
                onEdit: onEditPriceList,
            }),
        [priceListPropertySort]
    );

    const onUploadMSRP = async (file: RcFile) => {
        await uploadMSRPFile(file)
            .unwrap()
            .then(() => {
                message.success("Успешно добавлено");
            })
            .catch((error) => {
                message.error(error?.data?.message ?? "Что-то пошло не так");
            });
    };
    const onUploadFCA = async (file: RcFile) => {
        const { isExist, success } = await checkFCAFile(file)
            .unwrap()
            .catch((error) => {
                message.error(error?.data?.message ?? "Что-то пошло не так");
                return {
                    isExist: false,
                    success: false,
                };
            });

        if (success)
            if (!isExist) {
                await uploadFCAFile(file).then(() => {
                    message.success("Успешно добавлено");
                });
            } else {
                setFCAFile(file);
                openOverlapModal();
            }
    };

    const onConfirmUploadFCA = async () => {
        FCAFile &&
            (await uploadFCAFile(FCAFile).then(() => {
                message.success("Успешно добавлено");
            }));
        setFCAFile(undefined);
        closeOverlapModal();
    };

    const onSearch = (data: string) => {
        setSearchParams(data);
    };

    const onClearSearch = () => {
        setSearchParams("");
        closeSearchModal();
    };

    return (
        <Spin spinning={loadSort || fetchSort}>
            <div className={styles.topWrapper} ref={searchWrapper}>
                <CustomButton
                    wrapperClasses={styles.button}
                    type="primary"
                    buttonText="Сортировка"
                    onClick={openSort}
                    loading={loadSortSaving}
                />
                <UploadFile
                    accept={"xlsx,xls,csv"}
                    buttonProps={{
                        wrapperClasses: styles.button,
                        type: "primary",
                        buttonText: "Добавить прайс MSRP-ProAv",
                        icon: <Icons.Plus />,
                        loading: loadMSRP,
                    }}
                    onChange={onUploadMSRP}
                />
                <UploadFile
                    accept={"xlsx,xls,csv"}
                    buttonProps={{
                        wrapperClasses: styles.button,
                        type: "primary",
                        buttonText: "Добавить прайс F-ProAv",
                        icon: <Icons.Plus />,
                        loading: loadCheckFCA || loadFCA,
                    }}
                    onChange={onUploadFCA}
                />
                <CustomButton
                    wrapperClasses={styles.searchBtn}
                    type="primary"
                    onClick={openSearchModal}
                    icon={<Icons.Search />}
                />
            </div>
            <div className={styles.topWrapper} ref={downloadWrapper}>
                <CustomButton
                    wrapperClasses={`${styles.button} ${styles.button_bn}`}
                    buttonText="Загрузить Excel"
                    onClick={openExcelModal}
                    icon={<Icons.Download />}
                />
                <CustomButton
                    wrapperClasses={styles.button}
                    type="primary"
                    buttonText="Пересчитать"
                    loading={loadRecalculate}
                    onClick={onRecalculate}
                />
                <CustomButton
                    wrapperClasses={styles.button}
                    type="primary"
                    buttonText="Добавить переменные"
                    icon={<Icons.Plus />}
                    loading={loadConstant || fetchConstant}
                    onClick={openVariables}
                />
            </div>

            <PriceListsTable
                columns={columns?.filter((c: any) => !c.hidden)}
                dataSource={categories?.objects}
                pagination={{
                    current,
                    onChange: changePagination,
                    total: categories?.total ?? 0,
                }}
                scroll={{
                    x: calculateWidthByColumns(columns),
                    y: getFluidTableHeight(292 + otherWrapperHeight, 332 + otherWrapperHeight) ?? 0,
                }}
                loading={isLoading || isFetching || mainLoading}
                searchParams={searchParams}
                setMainLoading={setMainLoading}
            />

            <ModalEdit open={editModal} data={editedPriceList} onCancel={closeEditModal} />
            <ModalDelete
                title={`Вы уверены, что хотите удалить Прайс "${deletingPrice.title}"`}
                onOk={onDeletePriceList}
                open={deletePriceModal}
                onCancel={closeDeletePriceModal}
                loading={loadDelete}
            />
            <PriceListSearchModal
                open={searchModal}
                onClearSearch={onClearSearch}
                onOk={onSearch}
                onCancel={closeSearchModal}
            />
            <PriceListSort
                open={sortingPriceModal}
                columns={(columns?.slice(1) ?? []) as DataType[]}
                propertySortList={priceListPropertySort}
                onCancel={closeSortingPriceModal}
                onFinishSort={onFinishSort}
            />
            <AddVariablesModal
                initialValues={priceListConstants}
                open={variablesModal}
                onCancel={closeVariablesModal}
            />
            <ModalDelete
                width={440}
                maxTitle={"Найдены совпадения"}
                title={"Обновить данные?"}
                open={overlapModal}
                loading={loadFCA}
                onOk={onConfirmUploadFCA}
                onCancel={closeOverlapModal}
            />
            <DownloadExcelModal open={excelModal} onCancel={closeExcelModal} searchParams={searchParams} />
        </Spin>
    );
};

export default PriceListsOwn;
