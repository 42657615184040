import { PriceListConstant } from "@aten/common/models/PriceListConstant";
import { UrlApi } from "config/api";
import { IPagination } from "hooks/usePagination";
import { commonApi } from "services/commonApiService";
import { priceListsApi } from "services/priceListsService";

export const priceListConstantApi = commonApi.injectEndpoints({
    endpoints: (builder) => ({
        getPriceListConstant: builder.query<PriceListConstant, { userId: number; pagination?: IPagination }>({
            query: ({ userId, pagination }) => {
                return {
                    url: `${UrlApi.PriceListConstant}`,
                    params: {
                        userId,
                        pagination,
                    },
                };
            },
            providesTags: ["PriceListConstant"],
        }),
        getPriceListConstantById: builder.query<PriceListConstant, { id: number }>({
            query: ({ id }) => {
                return {
                    url: `${UrlApi.PriceListConstant}/${id}`,
                };
            },
            providesTags: ["PriceListConstant"],
        }),
        createPriceListConstant: builder.mutation<{ addedId: number }, PriceListConstant>({
            query: (body) => {
                return {
                    url: `${UrlApi.PriceListConstant}`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["PriceListConstant"],
        }),
        recalculatePriceLists: builder.mutation<void, void>({
            query: () => {
                return {
                    url: `${UrlApi.PriceListConstant}/PriceListRecalculating`,
                    method: "POST",
                };
            },
            onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
                await queryFulfilled;
                await dispatch(priceListsApi.util.invalidateTags(["PriceLists"]));
            },
            invalidatesTags: ["PriceListConstant"],
        }),
        changePriceListConstant: builder.mutation<PriceListConstant, PriceListConstant>({
            query: (body) => {
                return {
                    url: `${UrlApi.PriceListConstant}/${body?.id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["PriceListConstant"],
        }),
    }),
});

export const priceListConstantExtendedApi = priceListConstantApi.injectEndpoints({
    endpoints: (builder) => ({
        getPriceListConstantForUser: builder.query<PriceListConstant | undefined, { userId: number }>({
            async queryFn({ userId }, { dispatch }) {
                const { data: priceListConstant } = await dispatch(
                    priceListConstantApi.endpoints.getPriceListConstant.initiate({ userId })
                );

                if (priceListConstant === null) {
                    const { addedId } = await dispatch(
                        priceListConstantApi.endpoints.createPriceListConstant.initiate({ userId })
                    ).unwrap();

                    const { data: priceListConstant } = await dispatch(
                        priceListConstantApi.endpoints.getPriceListConstantById.initiate({ id: addedId ?? 0 })
                    );

                    return {
                        data: priceListConstant,
                    };
                }

                return {
                    data: priceListConstant,
                };
            },
        }),
    }),
});

export const {
    useGetPriceListConstantForUserQuery,
    useChangePriceListConstantMutation,
    useRecalculatePriceListsMutation,
} = priceListConstantExtendedApi;
