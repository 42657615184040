import { PriceList } from "@aten/common/models/PriceList";
import { Input, ModalProps, Row, Select } from "antd";
import usePage from "components/Contexts/PageContext";
import Form, { useForm } from "components/Form/Form";
import ModalSearch, { ModalSize } from "components/Modal/ModalSearch";
import { ColItem } from "pages/PriceLists/Modals/ModalEdit";
import { convertToRequest } from "pages/PriceLists/utils/convertFormValues";
import getColSpanForCurrentWindow from "pages/PriceLists/utils/getColSpanForCurrentWindow";
import React, { FC, useMemo } from "react";
import { booleanNullOptions } from "staticContent/options";
import { filterQueryString } from "utils/filterQueryString";
import { isNumber, urlsValidate } from "utils/getRules";

interface PriceListSearchModalProps extends Omit<ModalProps, "onOk" | "onCancel"> {
    onOk: (data: string) => void;
    onCancel: () => void;
    onClearSearch: () => void;
}

const PriceListSearchModal: FC<PriceListSearchModalProps> = ({ onCancel, onOk, onClearSearch, ...props }) => {
    const [form] = useForm<PriceList>();
    const { windowSize } = usePage();

    const colSpan = useMemo(() => getColSpanForCurrentWindow(windowSize), [windowSize.width]);

    const onFinish = () => {
        const formData = form.getFieldsValue();

        const data = convertToRequest(formData, {
            date: ["deletionDate", "changeDate"],
            type: ["isEac", "isEacbefore", "isEol", "isRetal", "isNotification", "isNotificationBefore", "isMarketing"],
        });

        onOk(filterQueryString(data));
        onCancel();
    };

    const onHandleClear = () => {
        form.resetFields();
        onClearSearch?.();
    };

    return (
        <ModalSearch
            onCancel={onCancel}
            cancelButtonProps={{ onClick: onHandleClear }}
            onOk={onFinish}
            width={ModalSize.Big}
            {...props}>
            <Form form={form}>
                <Row
                    gutter={[
                        { xs: 10, sm: 15, md: 20 },
                        { xs: 10, sm: 15, md: 20 },
                    ]}>
                    <ColItem colSpan={colSpan} name={"childCategoryName"} label={"Категория"}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"modelName"} label={"Модель"}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"partNumber"} label={"P/N"}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"fcost"} label={"Цена_F"} rules={[isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"engName"} label={"Name"}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"name"} label={"Наименование"}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"link"} label={"Ссылка"} rules={[urlsValidate()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"isEol"} label={"EOL"}>
                        <Select options={booleanNullOptions} />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"guaranteePeriod"} label={"Гарантия"}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"isEac"} label={"EAC"}>
                        <Select options={booleanNullOptions} />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"isEacbefore"} label={"EAC до"}>
                        <Select options={booleanNullOptions} />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"isNotification"} label={"Нотификация"}>
                        <Select options={booleanNullOptions} />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"isNotificationBefore"} label={"Нотификация до"}>
                        <Select options={booleanNullOptions} />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"lengthSu"} label={"Length(SU)"} rules={[isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"widthSu"} label={"Width(SU)"} rules={[isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"heightSu"} label={"Height(SU)"} rules={[isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"weightSu"} label={"Weight(SU)"} rules={[isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"lengthSuone"} label={"Length(SU1)"} rules={[isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"widthSuone"} label={"Width(SU1)"} rules={[isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"heightSuone"} label={"Height(SU1)"} rules={[isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"weightSuone"} label={"Weight(SU1)"} rules={[isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"lengthBu"} label={"Length(BU)"} rules={[isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"widthBu"} label={"Width(BU)"} rules={[isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"heightBu"} label={"Height(BU)"} rules={[isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"nwbu"} label={"N,W,(BU)"} rules={[isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"gwbu"} label={"G,W,(BU)"} rules={[isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"base"} label={"Base"}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"lengthCar"} label={"Length(CAR)"} rules={[isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"widthCar"} label={"Width(CAR)"} rules={[isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"heightCar"} label={"Height(CAR)"} rules={[isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"nwcar"} label={"N,W(CAR)"} rules={[isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"gwcar"} label={"G,W,(CAR)"} rules={[isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"manufacturerCountry"} label={"Страна производства"}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"isRetal"} label={"Розница"}>
                        <Select options={booleanNullOptions} />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"isMarketing"} label={"Маркетинг"}>
                        <Select options={booleanNullOptions} />
                    </ColItem>
                </Row>
            </Form>
        </ModalSearch>
    );
};
export default PriceListSearchModal;
