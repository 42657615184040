import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

interface excGenTypes {
  data: {[key: string]: string | number}[];
  fileName: string;
}

export const excelFileGenerator = ({ data, fileName }: excGenTypes) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const fileExtension = ".xlsx";

  const ws = XLSX.utils.json_to_sheet(data);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const generatedData = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(generatedData, fileName + fileExtension);
};
