import { Lesson, LessonsTable } from "@aten/common/dist/models/Lesson";
import { ColumnsType } from "antd/lib/table";
import { Icons } from "components/Icons/Icons";
import { authUrl } from "config/path";
import { generatePath, Link } from "react-router-dom";
import { DeletingLessonProps } from "./Lesson";

type ColumnsProps = {
    setDeletingLesson: (props: DeletingLessonProps) => void;
    openDelete: () => void;
};

const lessonColumnsTable = (props: ColumnsProps): ColumnsType<LessonsTable> => [
    { width: "5%", title: "ID", key: "id", dataIndex: "id" },
    { title: "Название", key: "title", dataIndex: "title" },
    { title: "Описание урока", key: "description", dataIndex: "description" },
    {
        width: "10%",
        title: "Действия",
        key: "action",
        dataIndex: "action",
        render: (_: any, data: Lesson) => {
            return (
                <>
                    <div style={{ display: "flex" }}>
                        <div style={{ marginRight: 10 }}>
                            <Link
                                to={generatePath(authUrl.Index.Lessons.Edit.url, {
                                    id: data.id.toString(),
                                })}>
                                <Icons.EditButton />
                            </Link>
                        </div>
                        <div>
                            <Icons.Delete
                                onClick={() => {
                                    props.setDeletingLesson({
                                        id: data.id.toString(),
                                        title: data.title,
                                    });
                                    props.openDelete();
                                }}
                            />
                        </div>
                    </div>
                </>
            );
        },
    },
];

export default lessonColumnsTable;
