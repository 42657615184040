type NameData = { name?: string; surname?: string; patronymic?: string };
type ToFullName = ({name, surname, patronymic}: NameData) => string;
type ToNameData = (fullName: string) => NameData;

/// {`name`, `surname`, `patronymic`} -> `fullName`
export const toFullName: ToFullName = ({name, surname, patronymic}) => {
    return `${name || ""} ${surname || ""} ${patronymic || ""}`.trim();
};


type FullEntityNameProps = <R extends {name?: string, surname?: string, patronymic?: string}>(arg: R | undefined) => string

export const fullEntityName: FullEntityNameProps = (arg) => {
    return arg ? `${arg.surname} ${arg.name} ${arg.patronymic ?? ''}` : '-'
}

/// `fullName` -> {`name`, `surname`, `patronymic`}
export const toNameData: ToNameData = (fullName) => {
    const nameData = fullName.split(" ").filter((e) => e !== "");
    return {
        name: nameData[0] || "",
        surname: nameData[1] || "",
        patronymic: nameData[2] || "",
    };
};
