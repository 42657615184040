import { Col, Input, Row } from "antd";
import cl from "assets/scss/Space.module.scss";
import Form, { Item, useForm } from "components/Form/Form";

import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import ModalSearch from "components/Modal/ModalSearch";
import { FC } from "react";
import concatClasses from "utils/concatClasses";
import { filterQueryString } from "utils/filterQueryString";

interface TestModalSearchProps {
    visible: boolean;
    onOk: (values: string) => void;
    close: () => void;
}

const TestModalSearch: FC<TestModalSearchProps> = ({ visible, close, onOk }) => {
    const [form] = useForm();

    const onFinish = () => {
        form.validateFields().then((values) => {
            onOk(filterQueryString(values));
            close();
        });
    };

    const onClose = () => {
        onOk("");
        close();
        form.resetFields();
    };

    return (
        <ModalSearch open={visible} onCancel={onClose} onOk={onFinish}>
            <Form form={form}>
                <Row className={concatClasses(cl.space)} gutter={[30, 30]}>
                    <Col span={12}>
                        <Item name="TestId" label={<Label type={LabelTypes.Form} label={"ID"} />}>
                            <Input />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="title" label={<Label type={LabelTypes.Form} label={"Название"} />}>
                            <Input />
                        </Item>
                    </Col>
                </Row>
            </Form>
        </ModalSearch>
    );
};
export default TestModalSearch;
