const isNumber = (str: string) => str.match(/^[-+]?[0-9]+$/g) !== null;
const isBoolean = (str: string) => str === "true" || str === "false";
const isNull = (str: string) => str === "null";
const isUndefined = (str: string) => str === "undefined";

const convertToType = (val: string | number | undefined): string | number | boolean | null | undefined => {
    const str = val + "";
    if (isNumber(str)) {
        return parseInt(str);
    }

    if (isBoolean(str)) {
        return str === "true";
    }

    if (isNull(str)) {
        return null;
    }

    if (isUndefined(str)) {
        return undefined;
    }

    return str;
};

export type TConvertToString = (val: string | number | boolean | null | undefined) => string | number | undefined;

const convertToString: TConvertToString = (val) => {
    if (val === null || typeof val === "boolean") return val + "";
    return val;
};

const stringHelper = {
    isNumber,
    convertToType,
    isNull,
    isBoolean,
    isUndefined,
    convertToString,
};
export default stringHelper;
