import { CoursesToUsersResponse } from "@aten/common/dist/models/CoursesToUsers";
import { User, UserEditResponse, UserRegistration, UserResponse } from "@aten/common/dist/models/User";
import { UrlApi } from "config/api";
import { IPagination } from "hooks/usePagination";
import { commonApi } from "services/commonApiService";
import { GetUsersByCourseIdArgs } from "types/apiTypes";

export const userApi = commonApi.injectEndpoints({
    endpoints: (builder) => ({
        getUsers: builder.query<UserResponse, { pagination?: IPagination; SearchParams?: string }>({
            query: ({ pagination, SearchParams }) => ({
                url: `${UrlApi.Users}${SearchParams ?? ""}`,
                params: {
                    ...pagination,
                },
            }),
            providesTags: ["User"],
        }),

        getUserByToken: builder.query<User, { token: string }>({
            query: ({ token }) => ({
                url: `${UrlApi.Users}/ByToken`,
                params: {
                    token,
                },
            }),
            providesTags: ["User"],
        }),

        getUserById: builder.query<User | undefined, string>({
            query: (id: string) => ({
                url: UrlApi.Users,
                params: {
                    UserId: id,
                },
            }),
            providesTags: (result, error, arg) => [{ type: "User", phones: result?.phones }],
            transformResponse: (res: UserResponse) => {
                if (res.objects.length === 0) {
                    return undefined;
                }
                return res.objects[0];
            },
        }),
        getUserByRole: builder.query<UserResponse | undefined, number>({
            query: (role) => ({
                url: UrlApi.Users,
                params: {
                    UserRole: role,
                },
            }),
            providesTags: ["User"],
        }),
        getUsersByCourseId: builder.query<UserResponse, GetUsersByCourseIdArgs | void>({
            query: ({ courseId, pagination, searchParams }: GetUsersByCourseIdArgs) => ({
                url: UrlApi.CoursesToUsers,
                params: {
                    courseId,
                    ...pagination,
                    ...searchParams,
                },
            }),
            transformResponse: ({ objects, total, ...res }: CoursesToUsersResponse) => ({
                objects: objects.map((item) => item.user!),
                total,
                ...res,
            }),
            providesTags: ["Course"],
        }),
        deleteUser: builder.mutation<{ result: string }, string>({
            query: (id) => ({
                url: `${UrlApi.Users}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["User"],
        }),
        setUser: builder.mutation<UserEditResponse, User | UserRegistration | undefined>({
            query: (data) => ({
                url: `${UrlApi.Users}/Register`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["User"],
        }),
        updateUser: builder.mutation<UserEditResponse, User | undefined>({
            query: (body: User) => ({
                url: `${UrlApi.Users}/${body.id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: ["User"],
        }),
    }),
});

export const {
    useGetUsersQuery,
    useGetUserByTokenQuery,
    useGetUserByIdQuery,
    useGetUsersByCourseIdQuery,
    useDeleteUserMutation,
    useGetUserByRoleQuery,
    useUpdateUserMutation,
    useSetUserMutation,
} = userApi;
