import { Categories, CategoriesResponse, CategoriesSetResponse } from "@aten/common/dist/models/Categories";
import { UrlApi } from "config/api";
import { commonApi } from "services/commonApiService";

export const categoriesApi = commonApi.injectEndpoints({
    endpoints: (build) => ({
        getCategories: build.query<CategoriesResponse, void>({
            query: () => ({
                url: UrlApi.Categories,
            }),
            providesTags: ["Categories"],
        }),

        setCategories: build.mutation<CategoriesSetResponse, Categories>({
            query: (data: Categories) => ({
                url: UrlApi.Categories,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Categories"],
        }),
    }),
});

export const { useGetCategoriesQuery, useSetCategoriesMutation } = categoriesApi;
