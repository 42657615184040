import { Course } from "@aten/common/dist/models/Course";
import { Col, Input, Row, Select } from "antd";
import cl from "assets/scss/Space.module.scss";
import Form, { Item, useForm } from "components/Form/Form";

import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import ModalSearch, { ModalSize } from "components/Modal/ModalSearch";
import moment from "moment";
import React, { FC, useEffect } from "react";
import concatClasses from "utils/concatClasses";
import { filterQueryString } from "utils/filterQueryString";

interface CertificatesModalSearchProps {
    data?: object;
    visible: boolean;
    close: () => void;
    onOk: (value: string) => void;
    courses?: Course[];
}

const CertificatesModalSearch: FC<CertificatesModalSearchProps> = ({ data, courses, visible, onOk, close }) => {
    const [form] = useForm();
    useEffect(() => {
        form.setFieldsValue(data);
    }, [data]);

    const onFinish = () => {
        const formData = form.getFieldsValue();
        const data = {
            ...formData,
            DateOfCreation: moment(formData.DateOfCreation).format("YYYY-MM-DD"),
        };

        onOk(filterQueryString(data));
        close();
    };

    const onClose = () => {
        form.resetFields();
        onOk("");
        close();
    };

    return (
        <ModalSearch width={ModalSize.Big} onOk={onFinish} open={visible} onCancel={onClose}>
            <Form form={form}>
                <Row className={concatClasses(cl.space)} gutter={[30, 30]}>
                    <Col span={8}>
                        <Item name="id" label={<Label type={LabelTypes.Form} label={"ID"} />}>
                            <Input />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="name" label={<Label type={LabelTypes.Form} label={"Название сертификата"} />}>
                            <Input />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="CourseId" label={<Label type={LabelTypes.Form} label={"Курс"} />}>
                            <Select
                                options={courses?.map((course) => ({
                                    key: course.id,
                                    value: course.id,
                                    label: course.title,
                                }))}
                            />
                        </Item>
                    </Col>
                </Row>
            </Form>
        </ModalSearch>
    );
};
export default CertificatesModalSearch;
