import { File, FileEditResponse } from "@aten/common/dist/models/File";
import { UrlApi } from "config/api";
import { commonApi } from "services/commonApiService";
import { onlyTokenApi } from "./onlyTokenApi";

interface objectDownloadFile {
    id: number;
    link: string | undefined;
}

export const filesApi = onlyTokenApi.injectEndpoints({
    endpoints: (build) => ({
        getFileById: build.query<string | undefined, number>({
            query: (id: number) => `${UrlApi.Files}/${id}`,
            transformResponse: (res: File) => {
                return res.link;
            },
            providesTags: ["File"],
        }),
        getFullFileById: build.query<File, string>({
            query: (id: string) => `${UrlApi.Files}/${id}`,
            providesTags: ["File"],
        }),
        getFilesByIds: build.query<File[], string>({
            query: (ids: string) => `${UrlApi.Files}?ListOfIdsInStringFormat=${ids}`,
            providesTags: ["File"],
        }),
        setFile: build.mutation<FileEditResponse, Blob>({
            query: (body: Blob) => {
                let data = new FormData();
                data.append("uploadedFile", body);
                return {
                    url: UrlApi.Files,
                    method: "POST",
                    credentials: "include",
                    body: data,
                };
            },
            invalidatesTags: ["File"],
        }),
        uploadMSRPFile: build.mutation<FileEditResponse, Blob>({
            query: (file: Blob) => {
                const formData = new FormData();

                formData.append("file", file);

                return {
                    url: `${UrlApi.PriceLists}/UploadMSRPPriceList`,
                    method: "POST",
                    body: formData,
                };
            },
            onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
                await queryFulfilled;
                await dispatch(commonApi.util.invalidateTags(["PriceListCategories", "PriceLists"]));
            },
        }),
        checkFCAPriceList: build.mutation<
            {
                isExist: boolean;
                success: boolean;
            },
            Blob
        >({
            query: (file: Blob) => {
                const formData = new FormData();

                formData.append("file", file);

                return {
                    url: `${UrlApi.PriceLists}/CheckFCAPriceList`,
                    method: "POST",
                    body: formData,
                };
            },
        }),
        uploadFCAFile: build.mutation<FileEditResponse, Blob>({
            query: (file: Blob) => {
                const formData = new FormData();

                formData.append("file", file);

                return {
                    url: `${UrlApi.PriceLists}/UploadFCAPriceList`,
                    method: "POST",
                    body: formData,
                };
            },
            onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
                await queryFulfilled;
                await dispatch(commonApi.util.invalidateTags(["PriceListCategories", "PriceLists"]));
            },
        }),
    }),
});

export const filesExtendedApi = filesApi.injectEndpoints({
    endpoints: (build) => ({
        donwloadFileAndGetLink: build.mutation<objectDownloadFile, Blob>({
            async queryFn(data, { dispatch }) {
                const addedId = await dispatch(filesApi.endpoints.setFile.initiate(data))
                    .unwrap()
                    .then((result) => result.addedId);
                return {
                    data: {
                        id: addedId,
                        link: await dispatch(filesApi.endpoints.getFileById.initiate(addedId ?? null)).unwrap(),
                    },
                };
            },
        }),
    }),
});

export const {
    useGetFileByIdQuery,
    useGetFullFileByIdQuery,
    useGetFilesByIdsQuery,
    useSetFileMutation,
    useDonwloadFileAndGetLinkMutation,
    useUploadMSRPFileMutation,
    useUploadFCAFileMutation,
    useCheckFCAPriceListMutation,
} = filesExtendedApi;
