import { PriceListConstant } from "@aten/common/models/PriceListConstant";
import { Col, Input, message, Row } from "antd";
import Form, { CustomItem, Item, useForm } from "components/Form/Form";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import Modal, { ModalProps } from "components/Modal/Modal";
import cl from "components/Modal/Modal.module.scss";
import React, { FC, useEffect } from "react";
import { useChangePriceListConstantMutation } from "services/priceListConstantService";
import concatClasses from "utils/concatClasses";
import { isNumber, required } from "utils/getRules";

interface AddVariablesModalProps extends ModalProps {
    initialValues?: PriceListConstant;
}

const AddVariablesModal: FC<AddVariablesModalProps> = ({
    initialValues,
    okButtonText = "Сохранить",
    cancelButtonText = "Отменить",
    onCancel,
    open,
    ...props
}) => {
    const [form] = useForm<PriceListConstant | undefined>();

    const [changeConstants, { isLoading }] = useChangePriceListConstantMutation();

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [initialValues, open]);

    const onFinish = async () => {
        const values = form.getFieldsValue();

        const transformedValues = Object.entries(values ?? {}).reduce<PriceListConstant>((result, [key, value]) => {
            return {
                ...result,
                [key]: Number(value),
            };
        }, {});

        values &&
            (await changeConstants(transformedValues).then(() => {
                message.success("Успешно обновлено");
                onCancel?.();
            }));
    };

    return (
        <Modal
            width={1200}
            okButtonText={okButtonText}
            onOk={onFinish}
            loading={isLoading}
            cancelButtonText={cancelButtonText}
            onCancel={onCancel}
            open={open}
            {...props}>
            <Form form={form} initialValues={initialValues}>
                <Item hidden name={"id"}>
                    <Input />
                </Item>
                <Item hidden name={"userId"}>
                    <Input />
                </Item>
                <div className={cl.block}>
                    <Row gutter={30}>
                        <Col span={12} className={cl.subBlock}>
                            <CustomItem
                                prefix={"Кабели"}
                                postfix={"%"}
                                name="landingCostCabel"
                                label={<Label type={LabelTypes.Form} label={"Landing - Cost"} />}
                                rules={[required(), isNumber()]}
                                className={concatClasses(cl.date__label, cl.input)}>
                                <Input />
                            </CustomItem>
                            <CustomItem
                                prefix={"Остальные"}
                                postfix={"%"}
                                name="landingCost"
                                label={<Label type={LabelTypes.Form} empty />}
                                rules={[required(), isNumber()]}
                                className={concatClasses(cl.date__label, cl.input)}>
                                <Input />
                            </CustomItem>
                        </Col>
                        <Col span={12}>
                            <CustomItem
                                postfix={"%"}
                                name="projectDiscount"
                                label={<Label type={LabelTypes.Form} label={"Project - Discount"} />}
                                rules={[required(), isNumber()]}
                                className={concatClasses(cl.date__label, cl.input)}>
                                <Input />
                            </CustomItem>
                        </Col>
                    </Row>
                </div>
                <div className={cl.block}>
                    <Row gutter={30}>
                        <Col span={12} className={cl.subBlock}>
                            <CustomItem
                                prefix={"Розница"}
                                postfix={"%"}
                                name="distiProfit"
                                label={<Label type={LabelTypes.Form} label={"Disti - Profit"} />}
                                rules={[required(), isNumber()]}
                                className={concatClasses(cl.date__label, cl.input)}>
                                <Input />
                            </CustomItem>
                            <CustomItem
                                prefix={"Опт"}
                                postfix={"%"}
                                name="distiProfitRetal"
                                label={<Label type={LabelTypes.Form} empty />}
                                rules={[required(), isNumber()]}
                                className={concatClasses(cl.date__label, cl.input)}>
                                <Input />
                            </CustomItem>
                        </Col>
                        <Col span={12}>
                            <CustomItem
                                postfix={"%"}
                                name="risk"
                                label={<Label type={LabelTypes.Form} label={"Risk"} />}
                                rules={[required(), isNumber()]}
                                className={concatClasses(cl.date__label, cl.input)}>
                                <Input />
                            </CustomItem>
                        </Col>
                    </Row>
                </div>
                <div className={cl.block}>
                    <Row gutter={30}>
                        <Col span={12} className={cl.subBlock}>
                            <CustomItem
                                prefix={"Розница"}
                                postfix={"%"}
                                name="siDiscount"
                                label={<Label type={LabelTypes.Form} label={"SI - Discount"} />}
                                rules={[required(), isNumber()]}
                                className={concatClasses(cl.date__label, cl.input)}>
                                <Input />
                            </CustomItem>
                            <CustomItem
                                prefix={"Опт"}
                                postfix={"%"}
                                name="siDiscountRetal"
                                label={<Label type={LabelTypes.Form} empty />}
                                rules={[required(), isNumber()]}
                                className={concatClasses(cl.date__label, cl.input)}>
                                <Input />
                            </CustomItem>
                        </Col>
                        <Col span={12}>
                            <CustomItem
                                postfix={"дней"}
                                name="daysForEolDelete"
                                label={
                                    <Label
                                        type={LabelTypes.Form}
                                        label={"Удаление позиций с параметром “Да” в EOL через"}
                                    />
                                }
                                rules={[isNumber()]}
                                className={concatClasses(cl.date__label, cl.input)}>
                                <Input />
                            </CustomItem>
                        </Col>
                    </Row>
                </div>
            </Form>
        </Modal>
    );
};

export default AddVariablesModal;
