import { useMemo, useState } from "react";

export interface ISort {
    propertyName: string | undefined;
    isAscending: boolean | undefined;
}

const useSort = () => {
    const [sort, setSort] = useState<ISort>({ propertyName: undefined, isAscending: undefined });

    const handleSortChange = (sortSetting: ISort) => {
        setSort(sortSetting);
    };

    return { sort, handleSortChange };
};

export default useSort;
