import { PlusOutlined } from "@ant-design/icons";
import type { InputRef } from "antd";
import { Button, Divider, Input, Select, Space } from "antd";
import { SelectProps } from "antd/lib/select";
import { DefaultOptionType } from "rc-select/es/Select";
import React, { FC, useEffect, useRef, useState } from "react";
import st from "./Select.module.scss";

interface SelectWithAddingProps extends SelectProps {
    inputPlaceholder?: string;
    options: DefaultOptionType[];
    addEntity?: (title: string) => void;
    showSearch?: boolean;
    addLoading?: boolean;
    isMenuLoading?: boolean;
}

const SelectWithAdding: FC<SelectWithAddingProps> = ({
    inputPlaceholder,
    options,
    showSearch,
    addEntity,
    addLoading,
    isMenuLoading,
    ...props
}) => {
    const [items, setItems] = useState<DefaultOptionType[] | undefined>();
    const [name, setName] = useState("");
    const inputRef = useRef<InputRef>(null);

    useEffect(() => {
        setItems(options);
    }, [options]);

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        const itemsList = items ?? [];
        name && setItems([...itemsList, { label: name, value: `__new__${name}` }]);
        addEntity && addEntity(name);
        setName("");
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    return (
        <Select
            {...props}
            loading={isMenuLoading}
            showSearch={showSearch}
            optionFilterProp="children"
            filterOption={(input, option) =>
                input.length >= 3
                    ? (((option?.label as string) ?? "").toLowerCase() ?? "").includes(input.toLowerCase())
                    : true
            }
            dropdownRender={(menu) => (
                <>
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    <Space className={st.adding}>
                        <Input
                            className={st.inp}
                            placeholder={inputPlaceholder}
                            ref={inputRef}
                            value={name}
                            onChange={onNameChange}
                        />
                        <Button
                            loading={addLoading}
                            className={st.btn}
                            disabled={!name?.length}
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={addItem}>
                            Добавить
                        </Button>
                    </Space>
                </>
            )}
            options={items}
        />
    );
};

export default SelectWithAdding;
