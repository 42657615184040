import { UrlApi } from "config/api";
import { commonApi } from "services/commonApiService";

export const ProjectsToManagersApi = commonApi.injectEndpoints({
    endpoints: (build) => ({
        setProjectToManager: build.mutation<
            void,
            {
                managerId: number;
                projectId: number;
            }
        >({
            query: ({ managerId, projectId }) => ({
                url: UrlApi.ProjectsToManagers,
                method: "POST",
                body: {
                    managerId,
                    projectId,
                },
            }),
            invalidatesTags: ["ProjectsToManagers"],
        }),
        getProjectToManagerRelations: build.query<{ id: number; projectId: number; managerId: number }[], string>({
            query: (projectId) => ({
                url: UrlApi.ProjectsToManagers,
                params: {
                    projectId,
                },
            }),
            providesTags: ["ProjectsToManagers"],
        }),
        deleteProjectToManager: build.mutation<void, number | undefined>({
            query: (relationId) => ({
                url: UrlApi.ProjectsToManagers + "/" + relationId,
                method: "DELETE",
            }),
            invalidatesTags: ["ProjectsToManagers"],
        }),
    }),
});

export const {
    useSetProjectToManagerMutation,
    useGetProjectToManagerRelationsQuery,
    useDeleteProjectToManagerMutation,
} = ProjectsToManagersApi;
