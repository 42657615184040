import { Model } from "@aten/common/models/Model";
import { Col, Row } from "antd";
import { FormInstance } from "antd/es";
import cl from "assets/scss/Space.module.scss";
import { ModalWithModels } from "components/Form/ProjectFormEdit/components/ModalWithModels/ModalWithModels";
import clForm from "components/Form/ProjectFormEdit/ProjectFormEdit.module.scss";
import { addModel } from "components/Form/ProjectFormEdit/utils/addModelFunc";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import ModelTextArea from "components/ModelTextArea";
import useModal from "hooks/useModal";
import React, { FC, useState } from "react";
import { useGetModelsListQuery } from "services/modelService";
import { useGetPriceListQuery } from "services/priceListsService";
import concatClasses from "utils/concatClasses";
import { ModalListTable } from "./ModalListTable";
interface ModelsListProps {
    form: FormInstance;
    edit?: string;
    projectType?: string | number;
}

const ModelsList: FC<ModelsListProps> = ({ edit, form, projectType }) => {
    const { visible, open, close } = useModal();
    const { data, isLoading } = useGetModelsListQuery({});
    const { data: price, isLoading: isLoadingPrice } = useGetPriceListQuery({});
    const type = (type: string | number) =>
        type === "KVMPDU" ? "KVM_PDU" : type === "ProAV" ? "ProAv" : type === "ProAv" ? "ProAv" : "KVM_PDU";

    const dataPrice = price?.result
        .filter((item) => item.superviserType === type(projectType ?? ""))
        .map((item) => item.model);

    const [addedModels, setAddedModels] = useState<Model[] | undefined>(undefined);

    return (
        <>
            <Row className={concatClasses(cl.space, cl.space__between, cl.space__margBot)} gutter={[30, 30]}>
                <Col span={24}>
                    <div className={clForm.title}>Модель</div>
                </Col>
                {!edit && (
                    <Col span={24} className={clForm.item}>
                        <Label type={LabelTypes.Form} label={"Быстрая вставка моделей"} />
                        <ModelTextArea open={open} addModel={setAddedModels} />
                    </Col>
                )}
            </Row>

            <ModalListTable baseModelList={dataPrice} edit={edit} form={form} />

            <ModalWithModels
                baseModelsLoading={isLoading}
                baseModels={data}
                addedModels={addedModels}
                initialModels={form.getFieldValue("modelsList")}
                onCancel={close}
                onOk={() => {
                    close();
                    addModel(addedModels, data, form);
                }}
                open={visible}
            />
        </>
    );
};

export default ModelsList;
