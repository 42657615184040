import {Role, RolesEnum} from "@aten/common/dist/models/Role";

export enum Roles {
    SI = "Интегратор",
    Disti = "Дистрибьютор",
    SupervisorKVMPDU = "Супервайзер KVM/PDU",
    SupervisorProAv = "Супервайзер ProAV",
    SupervisorTeaching = 'Супервайзер обучения',
    Admin='Администратор'
}

type TRolesToLabel = {
    [key in Roles]: Role;
};

export const rolesToLabel: TRolesToLabel = {
    [Roles.SI]: "SI",
    [Roles.Disti]: "Disti",
    [Roles.SupervisorKVMPDU]: "SupervisorKVMPDU",
    [Roles.SupervisorProAv]: "SupervisorProAv",
    [Roles.SupervisorTeaching] : 'SupervisorTeaching',
    [Roles.Admin] : 'Admin'

};
export const rolesLabel = {
    [RolesEnum.SI]: 'Интегратор',
    [RolesEnum.DISTI]: 'Дистрибьютор',
    [RolesEnum.KVMPDU]: 'Супервайзер KVM/PDU',
    [RolesEnum.PROAV]: 'Супервайзер ProAV',
    [RolesEnum.TEACHING] : 'Супервайзер обучения',
    [RolesEnum.ADMIN] : 'Администратор'

};