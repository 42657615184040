import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment/moment";
import stringHelper from "utils/helpers/stringHelper";

dayjs.extend(customParseFormat);

// todo: type it
type TransformFn = (value: any) => any;

const date: TransformFn = (date) => (date ? moment(date).toISOString() : undefined);

const type: TransformFn = (type) => stringHelper.convertToType(type);

const requestTransform = {
    date,
    type,
};

export default requestTransform;
