import {
    PriceListDeleteRequest,
    PriceListsByParentCategoryRequest,
    PriceListsWrapper,
} from "@aten/common/dist/models/PriceList";
import { PriceListDownloadRequest, PriceListEditRequest, PriceListRequest } from "@aten/common/models/PriceList";
import { UrlApi } from "config/api";
import { commonApi } from "services/commonApiService";

export const priceListsApi = commonApi.injectEndpoints({
    endpoints: (builder) => ({
        getPriceList: builder.query<PriceListRequest, {}>({
            query: ({ ...params }) => {
                return {
                    url: `${UrlApi.PriceLists}`,
                };
            },
            providesTags: ["PriceLists"],
        }),
        getPriceListByParentCategory: builder.query<PriceListsWrapper, PriceListsByParentCategoryRequest>({
            query: ({ searchParams, ...params }) => {
                return {
                    url: `${UrlApi.PriceLists}/GetByParentCategory${searchParams ? searchParams : ""}`,
                    params,
                };
            },
            providesTags: ["PriceLists"],
        }),
        deletePriceList: builder.mutation<void, PriceListDeleteRequest>({
            query: ({ id }) => {
                return {
                    url: `${UrlApi.PriceLists}/${id}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: ["PriceLists"],
        }),
        changePriceList: builder.mutation<void, PriceListEditRequest>({
            query: ({ params, body }) => {
                return {
                    url: `${UrlApi.PriceLists}/${body?.id}`,
                    method: "PUT",
                    params,
                    body,
                };
            },
            invalidatesTags: ["PriceLists"],
        }),
        priceListDownloadSorted: builder.query<Blob, PriceListDownloadRequest>({
            query: ({ searchParams }) => {
                return {
                    url: `${UrlApi.PriceLists}/ForDownloadExcelSorted${searchParams ? searchParams : ""}`,
                    responseHandler: async (response) => await response.blob(),
                };
            },
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                await dispatch(priceListsApi.util.invalidateTags(["PriceLists"]));
            },
        }),
        priceListDownloadStandard: builder.mutation<Blob, PriceListDownloadRequest>({
            query: ({ searchParams, isOwn = true }) => {
                return {
                    url: `${UrlApi.PriceLists}/ForDownloadExcelStandart${searchParams ? searchParams : ""}`,
                    params: {
                        isOwn,
                    },
                    responseHandler: async (response) => await response.blob(),
                    method: "POST",
                };
            },
            invalidatesTags: ["PriceLists"],
        }),
    }),
});

export const {
    useGetPriceListByParentCategoryQuery,
    useLazyGetPriceListByParentCategoryQuery,
    useDeletePriceListMutation,
    useChangePriceListMutation,
    useLazyPriceListDownloadSortedQuery,
    usePriceListDownloadStandardMutation,
    useGetPriceListQuery,
} = priceListsApi;
