import React, { FC, useEffect } from "react";
import { Form, Input, Modal, ModalProps } from "antd";
import { useForm } from "antd/lib/form/Form";

import st from "./ModalMinCostProject.module.scss";
import { CustomButton } from "../../CustomButton/CustomButton";
import { CostProjectValues } from "../../../pages/Projects/hooks/useProjectCost";

interface ModalMinCostProjectProps extends ModalProps {
    values?: CostProjectValues;
    setCosts: (vals: CostProjectValues) => Promise<void>;
    close: () => void;
}

export const ModalMinCostProject: FC<ModalMinCostProjectProps> = ({ values, close, setCosts, ...props }) => {
    const [form] = useForm();

    useEffect(() => {
        form.setFieldsValue(values);
    }, [values]);
    const onFinish = () => {
        const ProAV = Number(form.getFieldValue("ProAV"));
        const KVMPDU = Number(form.getFieldValue("KVMPDU"));

        !!ProAV && ProAV > 0 && !!KVMPDU && KVMPDU > 0 && setCosts(form.getFieldsValue()).then(close);
    };
    return (
        <Modal {...props} className={st.wrap} footer={false}>
            <Form form={form} layout={"vertical"}>
                <Form.Item
                    name={"ProAV"}
                    label={"Минималная сумма проекта ProAv"}
                    rules={[
                        {
                            transform: Number,
                            min: 1,
                            type: "number",
                            message: "Минималная сумма не может быть меньше 1",
                        },
                    ]}>
                    <Input type={"number"} />
                </Form.Item>
                <Form.Item
                    name={"KVMPDU"}
                    label={"Минималная сумма проекта KVM\\PDU"}
                    rules={[
                        {
                            transform: Number,
                            min: 1,
                            type: "number",
                            message: "Минималная сумма не может быть меньше 1",
                        },
                    ]}>
                    <Input type={"number"} />
                </Form.Item>
                <div className={st.footer}>
                    <CustomButton buttonText={"Отмена"} onClick={(e) => props.onCancel?.(e)} />
                    <CustomButton type="primary" buttonText={"Сохранить"} onClick={onFinish} />
                </div>
            </Form>
        </Modal>
    );
};
