import ThemesFormEdit from "components/Form/ThemesFormEdit/ThemesFormEdit";
import useLayout from "hooks/useLayout";
import { useParams } from "react-router-dom";
import { useGetThemeByIdQuery } from "services/themeService";

const ThemeEdit = () => {
    const { id } = useParams();

    const { data, isLoading } = useGetThemeByIdQuery(id ?? "", {
        skip: !id,
        refetchOnMountOrArgChange: true,
    });

    useLayout(
        {
            title: data?.title ?? "Добавить новую тему",
            loading: isLoading,
        },
        [data?.title]
    );

    return <ThemesFormEdit data={data} />;
};

export default ThemeEdit;
