import { Button, Space } from "antd";
import Modal, { ModalProps } from "components/Modal/Modal";
import React, { FC } from "react";
import { useLazyPriceListDownloadSortedQuery, usePriceListDownloadStandardMutation } from "services/priceListsService";
import concatClasses from "utils/concatClasses";
import { downloadBlob } from "utils/fileHelper";
import style from "../PriceLists.module.scss";

interface DownloadExcelModalProps extends ModalProps {
    searchParams?: string;
}

const DownloadExcelModal: FC<DownloadExcelModalProps> = ({ className, searchParams, ...props }) => {
    const [downloadSorted, { isLoading: loadSorted }] = useLazyPriceListDownloadSortedQuery();
    const [downloadStandard, { isLoading: loadStandard }] = usePriceListDownloadStandardMutation();

    const onDownloadSorted = async () => {
        const file = await downloadSorted({ searchParams }).unwrap();
        downloadBlob({ fileName: "Sorted price-lists.xlsx", file });
    };

    const onDownloadStandard = async () => {
        const file = await downloadStandard({ searchParams }).unwrap();
        downloadBlob({ fileName: "Standard price-lists.xlsx", file });
    };

    return (
        <Modal
            title={"Загрузить Excel"}
            className={concatClasses(className, style.excelModal)}
            width={600}
            {...props}
            footer={null}>
            <Space size={20} align={"center"} wrap className={style.excelModal_space}>
                <Button type={"primary"} onClick={onDownloadStandard} loading={loadStandard}>
                    Стандарт
                </Button>
                <Button type={"primary"} onClick={onDownloadSorted} loading={loadSorted}>
                    Учитывая все фильтры
                </Button>
            </Space>
        </Modal>
    );
};

export default DownloadExcelModal;
