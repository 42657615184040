import { File } from "@aten/common/models/File";
import { FetchBaseQueryMeta } from "@reduxjs/toolkit/query";
import { RcFile } from "antd/lib/upload/interface";
import { CustomUploadFile } from "components/UploadImages/UploadImages";

interface objectDownloadFile {
    id: number;
    link: string | undefined;
}

export interface DownloadBlob {
    file?: Blob;
    fileName?: string;
}

export const downloadBlob = ({ fileName, file }: DownloadBlob) => {
    const link = document.createElement("a");

    if (file) {
        link.style.display = "none";
        link.setAttribute("href", URL.createObjectURL(file));
        link.setAttribute("download", fileName ? `${fileName}` : "Unnamed");
        document.body.appendChild(link);
        link.click();
    }
};

export const linkBlob = (fileName: string) => {
    return `${process.env.REACT_APP_API_SERVER}/${fileName}`;
};

export const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

export const pictureUrl = (picture: File) => {
    const transformPicture: CustomUploadFile = {
        uid: picture.id.toString(),
        name: getFileNameFormLink(picture.link),
        url: process.env.REACT_APP_API_SERVER + "/" + picture.link,
        customStatus: "done",
    };
    return transformPicture;
};

export const newPictureUrl = (response: objectDownloadFile) => {
    const transformPicture: CustomUploadFile = {
        uid: response.id.toString(),
        name: (response.link && getFileNameFormLink(response.link)) ?? "",
        url: process.env.REACT_APP_API_SERVER + "/" + response.link,
        customStatus: "uploading",
    };
    return transformPicture;
};

export const downloadLocalBlob = (fileName: string) => {
    const link = document.createElement("a");
    if (fileName) {
        link.style.display = "none";
        link.setAttribute("href", `${process.env.REACT_APP_API_SERVER}/${fileName}`);
        link.setAttribute("download", "#");
        link.setAttribute("target", "_blank");

        document.body.appendChild(link);
        link.click();
    }
};

export const getFileNameFromMeta = (meta: FetchBaseQueryMeta) =>
    meta.response?.headers
        ?.get("content-disposition")
        ?.split("filename=")[1]
        .replace('"', "")
        .split(";")[0]
        .replace('"', "") ?? "";

export const getFileNameFormLink = (link: string) => {
    const linkFirsrtHalf = link.split("_")[0];
    return link.replace(linkFirsrtHalf + "_", "");
};
