import { CourseUsersTable } from "@aten/common/dist/models/Course";
import CourseUserProgressStatus from "components/CourseUserProgressStatus/CourseUserProgressStatus";
import { ColumnsType } from "antd/lib/table";
import { getCourseUserStatus } from "components/CourseUserProgressStatus/lib";
export const courseUsersColumnTable: ColumnsType<CourseUsersTable> = [
    {
        title: "ID",
        dataIndex: "id",
    },
    {
        title: "ФИО",
        dataIndex: "fullName",
    },
    {
        title: "Логин",
        dataIndex: "login",
    },
    {
        title: "E-mail",
        dataIndex: "email",
    },
    {
        title: "Компания",
        dataIndex: "company",
    },
    {
        title: "Статус",
        dataIndex: "progressOfCurrentUserInPercentage",
        render: (_, { progressOfCurrentUserInPercentage }) => (
            <CourseUserProgressStatus status={getCourseUserStatus(progressOfCurrentUserInPercentage)} />
        ),
    },
    {
        title: "Номер сертификата",
        // todo: add field
        dataIndex: "",
    },
];
