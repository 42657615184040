import {
    RoleToBudget,
    useAddCostMutation,
    useEditCostMutation,
    useGetCostQuery,
} from "../../../services/RoleToBudgetService";
import { useMemo } from "react";
import { message } from "antd";

export type CostProjectValues = {
    ProAV?: number;
    KVMPDU?: number;
};
export enum minSumType {
    ProAV = 0,
    KVMPDU = 1,
}
export const useProjectCost = () => {
    const { data } = useGetCostQuery();
    const [add] = useAddCostMutation();
    const [edit] = useEditCostMutation();

    const costData = useMemo(() => {
        const obj: CostProjectValues = {
            ProAV: undefined,
            KVMPDU: undefined,
        };
        if (data) {
            obj.ProAV = data.objects.find(({ projectTypeId }) => projectTypeId === minSumType.ProAV)?.minimumBudget;
            obj.KVMPDU = data.objects.find(({ projectTypeId }) => projectTypeId === minSumType.KVMPDU)?.minimumBudget;
        }
        console.log(obj);
        return obj.KVMPDU && obj.ProAV ? obj : undefined;
    }, [data]);

    const setCosts = async (vals: CostProjectValues) => {
        const cost = Object.entries(vals).map(([projectTypeId, minimumBudget]) => ({
            projectTypeId: projectTypeId === "ProAV" ? minSumType.ProAV : minSumType.KVMPDU,
            minimumBudget: Number(minimumBudget),
        }));
        console.log(cost);
        if (costData) {
            const f = data?.objects.reduce<RoleToBudget[]>((acc, el) => {
                const i = cost.find(({ projectTypeId }) => projectTypeId === el.projectTypeId);
                if (i) {
                    return [
                        ...acc,
                        {
                            projectTypeId: i.projectTypeId,
                            minimumBudget: i.minimumBudget,
                            id: el.id,
                        },
                    ];
                }
                return acc;
            }, []);
            edit(f).then((res) => {
                if ("error" in res) {
                    message.warn("Не удалось обновить. Возможно, у вас нет прав");
                    return;
                }
                message.success("Обновлено");
            });
        } else {
            console.log(cost);
            await add(cost);
        }
    };

    return { costData, setCosts };
};
