import { CourseUser, CourseUsersTable } from "@aten/common/dist/models/Course";
import { CourseUsersWithProgress } from "@aten/common/models/Course";
import serializer, { Serializer } from "serializers/index";
import { toFullName } from "utils/fullNameConverter";

const courseUsersSerializer: Serializer<CourseUsersWithProgress, CourseUser, CourseUsersTable> = (courseUsers) => {
    return serializer<CourseUsersWithProgress, CourseUser, CourseUsersTable>(courseUsers, (courseUser) => {
        const { id, name, surname, patronymic } = courseUser;
        return {
            ...courseUser,
            key: id,
            fullName: toFullName({ name, surname, patronymic }),
        };
    });
};

export default courseUsersSerializer;
