import { Members, MembersTable } from "@aten/common/dist/models/Members";
import { ColumnsType } from "antd/lib/table";
import { Icons } from "components/Icons/Icons";
import { authUrl } from "config/path";
import cl from "pages/Members/Members.module.scss";
import { DeletingMembersProps } from "pages/Members/MembersPage";
import { generatePath, Link } from "react-router-dom";

type ClolumnsProps = {
    setDeletingMembers: (props: DeletingMembersProps) => void;
    setDeleteModal: (isOpen: boolean) => void;
};

const membersColumnsTable = (props: ClolumnsProps): ColumnsType<MembersTable> => [
    {
        width: "7%",
        title: "ID",
        dataIndex: "id",
        key: "id",
        render: (_: any, data: Members) => (
            <Link
                className={cl.editById}
                to={generatePath(authUrl.Index.Members.Edit.url, {
                    id: data.id.toString(),
                })}>
                #{data.id}
            </Link>
        ),
    },
    { title: "ФИО", dataIndex: "fio", key: "fio" },
    { title: "Должность", dataIndex: "position", key: "position" },
    { title: "Описание", dataIndex: "description", key: "description" },
    {
        title: "Действия",
        dataIndex: "action",
        key: "action",
        render: (_: any, data: Members) => {
            return (
                <div style={{ display: "flex" }}>
                    <div style={{ marginRight: 10 }}>
                        <Icons.Delete
                            onClick={() => {
                                props.setDeletingMembers({ id: data.id.toString(), title: data.fio });
                                props.setDeleteModal(true);
                            }}
                        />
                    </div>
                    <div>
                        <Link
                            to={generatePath(authUrl.Index.Members.Edit.url, {
                                id: data.id.toString(),
                            })}>
                            <Icons.EditButton />
                        </Link>
                    </div>
                </div>
            );
        },
    },
];

export default membersColumnsTable;
