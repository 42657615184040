import { Upload as UploadAntd, UploadProps as UploadAntdProps } from "antd";
import { CustomButton, ICustomButton } from "components/CustomButton/CustomButton";
import React, { FC } from "react";
import concatClasses from "utils/concatClasses";

export interface UploadFileProps extends Omit<UploadAntdProps, "beforeUpload" | "onChange"> {
    buttonProps?: ICustomButton;
    onChange?: UploadAntdProps["beforeUpload"];
}

const UploadFile: FC<UploadFileProps> = ({ className, buttonProps, onChange, showUploadList = false, ...props }) => {
    const beforeUpload: UploadAntdProps["beforeUpload"] = (...args) => {
        onChange?.(...args);
        return false;
    };

    return (
        <UploadAntd
            beforeUpload={beforeUpload}
            disabled={!!buttonProps?.loading}
            className={concatClasses(className)}
            showUploadList={showUploadList}
            {...props}>
            <CustomButton loading={buttonProps?.loading} {...buttonProps} />
        </UploadAntd>
    );
};

export default UploadFile;
