import LessonFormEdit from "components/Form/LessonFormEdit/LessonFormEdit";
import useLayout from "hooks/useLayout";
import { useParams } from "react-router-dom";
import { useGetLessonByIdQuery } from "services/lessonService";

const LessonEdit = () => {
    const { id } = useParams();

    const { data, isLoading } = useGetLessonByIdQuery(id ?? "", { skip: !id });

    useLayout(
        {
            title: data?.title ?? "Добавить новый урок",
            loading: isLoading,
        },
        [data]
    );

    return <LessonFormEdit data={data}></LessonFormEdit>;
};

export default LessonEdit;
