import {Button, Modal, ModalProps, Space} from "antd";
import clSpace from "assets/scss/Space.module.scss";
import React, {FC} from "react";
import concatClasses from "utils/concatClasses";
import cl from "./Modal.module.scss";

interface ErrorModalProps extends ModalProps {
  text: string
}

const ErrorModal: FC<ErrorModalProps> = ({text, onOk, onCancel, ...props}) => {
  const footer = () => {
    return (
      <Space className={concatClasses(clSpace.space, clSpace.space__evenly)}>
        <Button onClick={onCancel}>Закрыть</Button>
      </Space>
    );
  };

  return (
    <Modal
      width={650}
      closable={false}
      footer={footer()}
      maskClosable={true}
      {...props}>
      <div className={cl.modalTitle}>{text}</div>
    </Modal>
  );
};

export default ErrorModal;
