import { EventsTable } from "@aten/common/dist/models/AtenEvent";

import { ColumnsType } from "antd/lib/table";
import { Icons } from "components/Icons/Icons";
import Tooltip from "components/Tooltip/Tooltip";
import { authUrl } from "config/path";
import { DeletingEventProps } from "pages/OfflineEvents/OfflineEvents";
import React from "react";
import { generatePath, Link } from "react-router-dom";
import { numToDate } from "utils/dateConverter";

type ColumnsProps = {
    setDeletingEvent: (props: DeletingEventProps) => void;
    setDeleteModal: (isOpen: boolean) => void;
};

const offlineEventsColumnsTable = (props: ColumnsProps): ColumnsType<EventsTable> => [
    {
        width: "7%",
        title: "ID",
        dataIndex: "id",
        key: "id",
    },
    { title: "Название", dataIndex: "title", key: "title" },
    {
        title: "Краткое описание мероприятия",
        dataIndex: "shortDescription",
        key: "shortDescription",
        render: (shortDescription) => <Tooltip title={shortDescription}>{shortDescription}</Tooltip>,
    },
    {
        width: "12%",
        title: "Кол-во мест на мероприятие",
        dataIndex: "vacancy",
        key: "vacancy",
    },
    {
        width: "12%",
        title: "Дата",
        dataIndex: "startDate",
        key: "startDate",
        render: (_, data) => `C ${numToDate(data.startDate)} по ${numToDate(data.endDate)}`,
    },
    { title: "Адрес ", dataIndex: "address", key: "address" },
    {
        title: "Контактные лица",
        dataIndex: "phone",
        key: "phone",
        render: (_, data) => {
            return data?.eventContacts.length > 0 ? (
                data?.eventContacts.length > 1 ? (
                    <Tooltip title={data.eventContacts.map((el) => el.fio).join(`, `)}>
                        <span>
                            {data.eventContacts.length > 1
                                ? `${data.eventContacts[0].fio} ...`
                                : data.eventContacts[0].fio}
                        </span>
                    </Tooltip>
                ) : (
                    data.eventContacts[0].fio
                )
            ) : (
                <>Контактов нет</>
            );
        },
    },
    {
        width: "10%",
        title: "Тематика/категория",
        dataIndex: "themes",
        key: "themes",
        render: (_, data) => <>{`${data.themes}/${data.category}`}</>,
    },
    {
        title: "Действия",
        dataIndex: "action",
        key: "action",
        render: (_, data) => {
            return (
                <div style={{ display: "flex" }}>
                    <div style={{ marginRight: 10 }}>
                        <Link
                            to={generatePath(authUrl.Index.OfflineEvents.Edit.url, {
                                id: data.id.toString(),
                            })}>
                            <Icons.EditButton />
                        </Link>
                    </div>
                    <div>
                        <Icons.Delete
                            onClick={() => {
                                props.setDeleteModal(true);
                                props.setDeletingEvent({
                                    id: data.id,
                                    title: data.title ?? "—",
                                });
                            }}
                        />
                    </div>
                </div>
            );
        },
    },
];

export default offlineEventsColumnsTable;
