import { Course } from "@aten/common/dist/models/Course";
import { Button, Col, Input, message, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { CustomButton } from "components/CustomButton/CustomButton";
import { CustomTable } from "components/CustomTable/CustomTable";
import useGetTestOptions from "components/Form/ThemesFormEdit/ModalAddToTheme/hooks/useGetTestOptions";
import { Icons } from "components/Icons/Icons";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import ModalDelete from "components/Modal/ModalDelete";
import TextEditor from "components/TextEditor/TextEditor";
import UploadPicture from "components/UploadPicture/UploadPicture";
import useModal from "hooks/useModal";
import useNavigate from "hooks/useNavigate";
import { DeletingThemesProps } from "pages/Theme/Themes";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import themesToCourses from "serializers/themesToCourses";
import {
    useDeleteCourseMutation,
    useEditCourseMutation,
    useGetCourseProgressQuery,
    useSetCourseMutation,
} from "services/coursesApi";
import { useGetFileByIdQuery } from "services/fileService";
import { useGetThemeByCourseIdQuery } from "services/themeService";
import { useDeleteThemeToCourseByIdMutation, useSetThemeToCourseMutation } from "services/themesToCoursesService";
import getContentAndFuncForDelete, { ModalType } from "utils/getContentAndFuncForDelete";
import { lettersValidate, required } from "utils/getRules";
import Form, { Item, useForm } from "../Form";
import c from "./CoursesFormEdit.module.scss";
import { themesColumns } from "./coursesFormEditColumnsTable";
import ModalAddToCourse, { ModalResponse } from "./ModalAddToCourse/ModalAddToCourse";
import { courseUsersColumnTable } from "./courseUsersColumnsTable";
import courseUsersSerializer from "serializers/courseUsers";
import { useGetUserByTokenQuery } from "services/userService";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { RolesEnum } from "@aten/common/dist/models/Role";
import { useGetLicencesTableDataQuery } from "../../../services/licencesServices";

interface CoursesFormEditProps {
    data?: Course;
}

interface UploadMainImage {
    fileImageId?: number;
}

const CoursesFormEdit: FC<CoursesFormEditProps> = ({ data: course, ...props }) => {
    const { id } = useParams();
    const { goBack } = useNavigate();
    const [form] = useForm<Course | undefined>();
    const { open: openDelete, close: closeDelete, visible: visibleDelete } = useModal();
    const { open: openAdd, close: closeAdd, visible: visibleAdd } = useModal();
    const { testsIdOptions, testsLabelOptions } = useGetTestOptions();

    const [typeModal, setTypeModal] = useState<ModalType>("course");
    const [uploadMainImage, setUploadMainImage] = useState<UploadMainImage>({ fileImageId: 0 });
    const [deletingCourseTitle, setDeletingCourseTitle] = useState<string>("");
    const [deletingTheme, setDeletingTheme] = useState<DeletingThemesProps>();

    const { data: mainImageLink, isLoading: isLoadingImg } = useGetFileByIdQuery(uploadMainImage.fileImageId ?? 0, {
        skip: !uploadMainImage.fileImageId,
    });
    const { data: themes, isLoading: isLoadingThemes } = useGetThemeByCourseIdQuery(id ?? "", {
        skip: !id,
        refetchOnMountOrArgChange: true,
    });
    const { data: courseUsers, isLoading: isCourseUsersLoading } = useGetCourseProgressQuery(
        {
            courseId: Number(id),
        },
        {
            skip: !course?.isVerified,
        }
    );

    const { data: licenses } = useGetLicencesTableDataQuery({ searchString: "" });

    const token = useSelector((state: RootState) => state.auth.token);
    const { data: currentUser } = useGetUserByTokenQuery({ token });

    const [setCourse, { isLoading: isLoadingSetCourse }] = useSetCourseMutation();
    const [editCourse, { isLoading: isLoadingEditCourse }] = useEditCourseMutation();
    const [deleteCourse, { isLoading: isLoadingDeleteCourse }] = useDeleteCourseMutation();

    const [setThemeToCourse, { isLoading: isLoadingAddTheme }] = useSetThemeToCourseMutation();
    const [deleteThemeToCourseById, { isLoading: isLoadingDeleteTheme }] = useDeleteThemeToCourseByIdMutation();

    const serializedThemes = themesToCourses(themes);
    const serializedCourseUsers = courseUsersSerializer(courseUsers);

    useEffect(() => {
        course?.imageId &&
            setUploadMainImage({
                fileImageId: course.imageId,
            });
        setDeletingCourseTitle(course?.title ?? "");
        form.setFieldsValue(course);
    }, [course, themes]);

    const deleteCurrentCourse = async () => {
        id &&
            (await deleteCourse(id).then(() => {
                message.success("Курс удален");
                closeDelete();
                goBack(500);
            }));
    };

    const deleteCurrentTheme = async () => {
        deletingTheme?.id &&
            (await deleteThemeToCourseById({
                themeId: deletingTheme.id,
                courseId: id,
            }).then(() => {
                message.success("Тема удалена");
                closeDelete();
            }));
    };

    const settingsModal = () => {
        return getContentAndFuncForDelete({
            type: typeModal,
            deleteTheme: deleteCurrentTheme,
            deleteCourse: deleteCurrentCourse,
            themeTitle: deletingTheme?.title,
            courseTitle: deletingCourseTitle,
        });
    };

    const onFinish = (values: Course) => {
        id
            ? editCourse({ ...course, ...values })
                  .unwrap()
                  .then(() => {
                      message.success("Курс отредактирован");
                      goBack();
                  })
                  .catch((er) => {
                      if (er.data.code === 404) {
                          message.error(er.data.information);
                          goBack();
                      }
                  })
            : setCourse({ ...course, ...values }).then(() => {
                  message.success("Курс добавлен");
                  goBack();
              });
    };

    const updateMainImage = (imageId: number) => {
        setUploadMainImage({ fileImageId: imageId });
        form.setFields([{ name: "imageId", value: imageId }]);
    };

    const addThemeClick = () => {
        setTypeModal("theme");
        openAdd();
    };

    const deleteCourseClick = () => {
        setTypeModal("course");
        openDelete();
    };

    const onFinishAddToCourse = async (values: ModalResponse) => {
        id &&
            (await setThemeToCourse({
                themeId: values.id,
                courseId: +id,
                indexNumber: values.indexNumber,
            })
                .unwrap()
                .then(() => {
                    message.success("Тема добавлена к курсу");
                    closeAdd();
                })
                .catch(() => {
                    message.error("Такая тема уже добавлена к курсу");
                }));
    };

    return (
        <Form form={form} data={course} onFinish={onFinish} {...props}>
            <>
                <LayoutWrapper>
                    <Row gutter={[30, 30]} className={c.row}>
                        <Col span={8}>
                            <Button
                                htmlType="submit"
                                type="primary"
                                loading={isLoadingSetCourse || isLoadingEditCourse}>
                                {id ? "Сохранить изменения" : "Добавить курс"}
                            </Button>
                        </Col>
                        <Col span={8}>
                            <Button onClick={() => goBack(0)}>
                                {id ? "Отменить изменения" : "Отменить добавление"}
                            </Button>
                        </Col>
                        {id && (
                            <Col span={8}>
                                <Button className={"buttonRed"} onClick={deleteCourseClick}>
                                    Удалить курс
                                </Button>
                            </Col>
                        )}
                    </Row>
                    <Row gutter={[30, 30]}>
                        <Col span={8}>
                            <Item
                                name="title"
                                label={<Label type={LabelTypes.Form} label={"Название"} />}
                                rules={[lettersValidate(), required()]}>
                                <Input />
                            </Item>

                            <Item
                                name="licenceId"
                                label={<Label type={LabelTypes.Form} label={"Сертификат"} />}
                                rules={[required()]}>
                                <Select
                                    options={
                                        licenses?.licencesTableData?.objects?.map(({ id, name }: any) => ({
                                            value: id,
                                            label: name,
                                        })) ?? []
                                    }
                                />
                            </Item>

                            {currentUser?.role === RolesEnum.ADMIN && (
                                <Item
                                    name="statusId"
                                    label={<Label type={LabelTypes.Form} label={"Статус"} />}
                                    rules={[lettersValidate(), required()]}>
                                    <Select
                                        disabled={currentUser?.role !== "Admin"}
                                        options={[
                                            {
                                                label: "На рассмотрении",
                                                value: "UnderReview",
                                            },
                                            {
                                                label: "Отклонен",
                                                value: "Rejected",
                                            },
                                            {
                                                label: "Подтвержден",
                                                value: "VerifiedBy",
                                            },
                                        ]}
                                    />
                                </Item>
                            )}
                        </Col>
                        <Col span={8}>
                            <Item
                                name="shortDescription"
                                label={<Label type={LabelTypes.Form} label={"Краткое описание"} />}
                                rules={[required()]}>
                                <TextArea maxLength={200} />
                            </Item>
                            <Item
                                name="finalTestId"
                                label={<Label type={LabelTypes.Form} label={"Итоговый тест"} />}
                                rules={[required()]}>
                                <Select options={testsLabelOptions} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item
                                name="imageId"
                                label={<Label type={LabelTypes.Form} label={"Изображение курса"} />}
                                rules={[required()]}>
                                <UploadPicture
                                    type={"large"}
                                    action={updateMainImage}
                                    url={mainImageLink}
                                    loading={isLoadingImg}
                                />
                            </Item>
                        </Col>
                        <Col span={24}>
                            <Item
                                name="description"
                                label={<Label type={LabelTypes.Form} label={"Полное описание"} />}
                                rules={[required()]}>
                                <TextEditor
                                    value={form.getFieldValue("description")}
                                    callback={(htmlValue) =>
                                        form.setFieldsValue({
                                            description: htmlValue,
                                        })
                                    }
                                />
                            </Item>
                        </Col>
                        <Col span={8} className={c.quantity}>
                            <span className={c.quantity__label}>Число тем:</span>{" "}
                            <span className={c.quantity__value}>{course?.listOfThemesIds?.length ?? 0}</span>
                        </Col>
                    </Row>
                </LayoutWrapper>
                {course && (
                    <LayoutWrapper>
                        <div className={c.tableHead}>
                            <div className={c.tableHead_title}>Темы курса</div>
                            <CustomButton
                                buttonText={"Добавить тему"}
                                onClick={addThemeClick}
                                type="primary"
                                icon={<Icons.Plus />}
                            />
                        </div>
                        <CustomTable
                            setFullHeight={true}
                            columns={themesColumns({
                                setDeletingTheme,
                                openDelete,
                                setTypeModal,
                            })}
                            data={serializedThemes}
                            loading={isLoadingThemes}
                        />
                        <ModalAddToCourse
                            open={visibleAdd}
                            loading={isLoadingAddTheme}
                            courseTitle={course.title}
                            themesCount={themes?.total}
                            onClose={closeAdd}
                            onAdd={onFinishAddToCourse}
                        />
                    </LayoutWrapper>
                )}
                {course?.isVerified && (
                    <LayoutWrapper>
                        <div className={c.tableHead}>
                            <div className={c.tableHead_title}>Участники курса</div>
                        </div>
                        <CustomTable
                            setFullHeight
                            columns={courseUsersColumnTable}
                            data={serializedCourseUsers}
                            loading={isCourseUsersLoading}
                        />
                    </LayoutWrapper>
                )}
                <ModalDelete
                    open={visibleDelete}
                    onCancel={closeDelete}
                    loading={isLoadingDeleteCourse || isLoadingDeleteTheme}
                    title={settingsModal()?.title}
                    onOk={settingsModal()?.func}
                />
            </>
        </Form>
    );
};

export default CoursesFormEdit;
