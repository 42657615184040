import { Typography } from "antd";
import Tooltip from "components/Tooltip/Tooltip";
import moment from "moment";
import { ReactNode } from "react";

const { Paragraph } = Typography;
const dash = "—";

const ellipsisText = (text: ReactNode) => {
    return text ? (
        <Tooltip title={text}>
            <Paragraph style={{ margin: 0 }} ellipsis={true}>
                {text}
            </Paragraph>
        </Tooltip>
    ) : (
        dash
    );
};

const link = (text: string, link?: string) => {
    return ellipsisText(
        <a href={link ?? text} target={"_blank"}>
            {text}
        </a>
    );
};

const date = (date: string | null) => (date ? moment(date).format("DD.MMMM.YYYY HH.mm.ss") : dash);

const boolValue = (value?: boolean | null) => (typeof value !== "boolean" ? "Не задано" : value ? "Да" : "Нет");

const render = {
    dash,
    ellipsisText,
    link,
    date,
    boolValue,
};

export default render;
