import { Tabs as TabsAntd, TabsProps as TabsAntdProps } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import concatClasses from "utils/concatClasses";
import style from "./Tabs.module.scss";

export interface TabsProps extends TabsAntdProps {
    showOperations?: boolean;
}

const Tabs: FC<TabsProps> = ({ className, showOperations = false, ...props }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [activeKey, setActiveKey] = useState<string>();

    useEffect(() => {
        setActiveKey(location.pathname);
    }, [location.pathname]);

    const onChange: TabsProps["onChange"] = (url) => {
        navigate(url);
    };

    return (
        <TabsAntd
            {...props}
            className={concatClasses(className, style.tabs, !showOperations && style.tabs_operations)}
            onChange={onChange}
            activeKey={activeKey}
        />
    );
};

export default Tabs;
