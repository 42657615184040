import { CustomButton } from "components/CustomButton/CustomButton";
import { CustomTable } from "components/CustomTable/CustomTable";
import { Icons } from "components/Icons/Icons";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import ModalDelete from "components/Modal/ModalDelete";
import { authUrl } from "config/path";
import useFluid from "hooks/useFluid";
import usePagination from "hooks/usePagination";
import membersColumnsTable from "pages/Members/membersColumnsTable";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { membersSerializer } from "serializers/members";
import { useDeleteMembersMutation, useGetMembersQuery } from "services/membersService";

export type DeletingMembersProps = {
    title: string;
    id: string;
};
const MembersPage: FC = () => {
    const { pagination, changePagination } = usePagination({});
    const { getFluidTableHeight } = useFluid();

    const [deleteModal, setDeleteModal] = useState(false); //todo заменить на хук useModal
    const [deletingMembers, setDeletingMembers] = useState<DeletingMembersProps>({ id: "", title: "" });

    const { data, isLoading, isFetching } = useGetMembersQuery({
        pagination,
    });

    const members = membersSerializer(data);

    const [deleteMembers] = useDeleteMembersMutation();

    return (
        <>
            <LayoutWrapper>
                <Link to={authUrl.Index.Members.New.url}>
                    <CustomButton type="primary" buttonText={"Добавить"} icon={<Icons.Plus />} />
                </Link>

                <CustomTable
                    data={members}
                    columns={membersColumnsTable({
                        setDeleteModal,
                        setDeletingMembers,
                    })}
                    pagination={{ total: data?.total, onChange: changePagination }}
                    loading={isLoading || isFetching}
                    scroll={{ y: getFluidTableHeight(340, 398) }}
                />
            </LayoutWrapper>
            <ModalDelete
                title={`Вы уверены, что хотите удалить сотрудника "${deletingMembers.title}"`}
                open={deleteModal}
                onCancel={() => setDeleteModal(false)}
                onOk={() => deleteMembers(deletingMembers.id).then(() => setDeleteModal(false))}
            />
        </>
    );
};

export default MembersPage;
