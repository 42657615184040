import { Members } from "@aten/common/dist/models/Members";
import { Button, Col, Input, message, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import ModalDelete from "components/Modal/ModalDelete";
import UploadPicture from "components/UploadPicture/UploadPicture";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetFileByIdQuery } from "services/fileService";
import { useDeleteMembersMutation, useEditMembersMutation, useSetMembersMutation } from "services/membersService";
import { required } from "utils/getRules";
import Form, { Item, useForm } from "../Form";
import c from "./MembersFormEdit.module.scss";

interface MembersProps {
    data?: Members;
}

export const MembersFormEdit: FC<MembersProps> = ({ data, ...props }) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [form] = useForm<typeof data>();

    const [fileId, setFileId] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false);

    const { data: imageLink } = useGetFileByIdQuery(fileId || 0, { skip: !fileId });

    const [editMembers] = useEditMembersMutation();
    const [setMembers] = useSetMembersMutation();
    const [deleteMembers] = useDeleteMembersMutation();

    useEffect(() => {
        data && setFileId(data?.imageId);
        form.setFieldsValue(data);
    }, [data]);

    const dataUpdate = async (newData: Members) => {
        id
            ? await editMembers({
                  ...data,
                  ...newData,
                  sortLine: "0",
                  imageId: +fileId,
              }).then(() => {
                  message.success("Сотрудник отредактирован");
              })
            : await setMembers({
                  ...newData,
                  sortLine: "0",
                  imageId: +fileId,
              }).then(() => {
                  message.success("Сотрудник добавлен");
              });
        setTimeout(() => {
            navigate(-1);
        }, 1500);
    };

    const imageUpdate = (imageId: number) => {
        setFileId(imageId);
        form.setFieldsValue({ ...data, imageId: imageId });
    };

    const deleteCurrentMembers = () => {
        id && deleteMembers(id).then(() => navigate(-1));
    };

    return (
        <Form form={form} data={{}} onFinish={dataUpdate} {...props}>
            <>
                <LayoutWrapper>
                    <Row gutter={[30, 30]} className={c.row}>
                        <Col span={8}>
                            <Button htmlType="submit" type="primary">
                                {id ? "Сохранить изменения" : "Добавить сотрудника"}
                            </Button>
                        </Col>
                        <Col span={8}>
                            <Button onClick={() => navigate(-1)}>
                                {id ? "Отменить изменения" : "Отменить добавление"}
                            </Button>
                        </Col>
                        {id && (
                            <Col span={8}>
                                <Button
                                    className={"buttonRed"}
                                    onClick={() => {
                                        setDeleteModal(true);
                                    }}>
                                    Удалить сотрудника
                                </Button>
                            </Col>
                        )}
                    </Row>
                    <Row gutter={[30, 30]}>
                        <Col span={6}>
                            <Item
                                name="fio"
                                label={<Label type={LabelTypes.Form} label={"ФИО"} />}
                                rules={[required()]}>
                                <Input />
                            </Item>
                        </Col>
                        <Col span={6}>
                            <Item
                                name="position"
                                label={<Label type={LabelTypes.Form} label={"Должность"} />}
                                rules={[required()]}>
                                <Input />
                            </Item>
                        </Col>
                        <Col span={6}>
                            <Item
                                name="description"
                                label={<Label type={LabelTypes.Form} label={"Описание сотрудника"} />}
                                rules={[required()]}>
                                <TextArea showCount maxLength={500} />
                            </Item>
                        </Col>
                        <Col span={6}>
                            <Item
                                name="imageId"
                                label={<Label type={LabelTypes.Form} label={"Изображение Сотрудника"} />}
                                rules={[required()]}>
                                <UploadPicture type={"large"} action={imageUpdate} url={imageLink} />
                            </Item>
                        </Col>
                    </Row>
                </LayoutWrapper>
                <div>
                    <ModalDelete
                        open={deleteModal}
                        onOk={deleteCurrentMembers}
                        onCancel={() => setDeleteModal(false)}
                        title={`Вы уверены, что хотите удалить сотрудника "${data?.fio ?? ""}"`}
                    />
                </div>
            </>
        </Form>
    );
};
