import {
  TestQuestion,
  TestQuestionEditResponse,
  TestQuestionResponse,
} from "@aten/common/dist/models/TestQuestion";
import { UrlApi } from "config/api";
import { commonApi } from "services/commonApiService";

export const questionApi = commonApi.injectEndpoints({
    endpoints: (build) => ({
        getTestQuestions: build.query<TestQuestionResponse, string>({
            query: () => UrlApi.TestQuestions,
            providesTags: ["TestQuestion"],
        }),
        getTestQuestionById: build.query<TestQuestion, string>({
            query: (id: string) => ({
                url: `${UrlApi.TestQuestions}/${id}`,
            }),
            providesTags: ["TestQuestion"],
            transformResponse: (res: TestQuestionResponse) => res.objects[0],
        }),
        getTestQuestionByTestId: build.query<TestQuestionResponse, string>({
            query: (testId: string) => `${UrlApi.TestQuestions}/?TestId=${testId}`,
            providesTags: ["TestQuestion"],
        }),
        setTestQuestion: build.mutation<TestQuestionEditResponse, TestQuestion | undefined>({
            query: (data: TestQuestion) => ({
                url: UrlApi.TestQuestions,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["TestQuestion", "Test"],
        }),
        editTestQuestion: build.mutation<TestQuestionEditResponse, TestQuestion | undefined>({
            query: (data: TestQuestion) => ({
                url: `${UrlApi.TestQuestions}/${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["TestQuestion", "Test"],
        }),
        deleteTestQuestion: build.mutation<TestQuestionResponse, string>({
            query: (id: string) => ({
                url: `${UrlApi.TestQuestions}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["TestQuestion", "Test"],
        }),
    }),
});

export const {
  useGetTestQuestionsQuery,
  useGetTestQuestionByTestIdQuery,
  useGetTestQuestionByIdQuery,
  useEditTestQuestionMutation,
  useSetTestQuestionMutation,
  useDeleteTestQuestionMutation,
} = questionApi;
