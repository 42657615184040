import { Advantages, AdvantagesResponse } from "@aten/common/dist/models/Advantages";
import { UrlApi } from "config/api";
import { IPagination } from "hooks/usePagination";
import { commonApi } from "services/commonApiService";

export const advantagesApi = commonApi.injectEndpoints({
    endpoints: (build) => ({
        getAdvantages: build.query<AdvantagesResponse, { pagination: IPagination }>({
            query: ({ pagination }) => ({
                url: UrlApi.Advantages,
                params: {
                  ...pagination,
                },
            }),
            providesTags: ["Advantages"],
        }),
        getAdvantagesById: build.query<Advantages, string>({
            query: (id: string) => `${UrlApi.Advantages}/${id}`,
            providesTags: ["Advantages"],
        }),
        editAdvantages: build.mutation<string, Advantages>({
            query: (body: Advantages) => ({
                url: `${UrlApi.Advantages}/${body.id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: ["Advantages"],
        }),
        setAdvantages: build.mutation<string, Advantages>({
            query: (body: Advantages) => ({
                url: `${UrlApi.Advantages}`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["Advantages"],
        }),
        deleteAdvantages: build.mutation<AdvantagesResponse, string>({
            query: (id: string) => ({
                url: `${UrlApi.Advantages}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Advantages"],
        }),
    }),
});

export const {
    useGetAdvantagesQuery,
    useGetAdvantagesByIdQuery,
    useEditAdvantagesMutation,
    useSetAdvantagesMutation,
    useDeleteAdvantagesMutation,
} = advantagesApi;
