export const versionApi = process.env.REACT_APP_API_VERSION;
export const urlApi = `${process.env.REACT_APP_API_SERVER}/${versionApi}`;
export const ymapsApiKey = process.env.REACT_APP_YMAPS_API_KEY;
export const daDataApiKey = `${process.env.REACT_APP_DADATA_API_KEY}`;

export const enum UrlApi {
    Advantages = "/Advantages",
    Users = "/Users",
    Courses = "/Courses",
    CourseProgress = "/Courses/GetForProgress",
    Categories = "/Categories",
    Catalogues = "/Catalogues",
    ThemesToCourses = "/CoursesToThemes",
    Engineers = "/Engineers",
    EngineersToUsers = "/EngineersToUsers",
    EventContacts = "/EventContacts",
    EventRegistrations = "/EventRegistrations",
    Events = "/Events",
    Files = "/Files",
    Lessons = "/Lessons",
    Managers = "/Managers",
    Models = "/Models",
    ManagersToUsers = "/ManagersToUsers",
    Licences = "/Licences",
    Members = "/Members",
    News = "/News",
    TestQuestions = "/TestQuestions",
    QuestionAnswers = "/QuestionAnswers",
    CoursesToUsers = "/CoursesToUsers",
    Phones = "/Phones",
    Tests = "/Tests",
    TestsResults = "/TestsResults",
    Themes = "/Themes",
    Projects = "/Projects",
    PriceLists = "/PriceLists",
    PriceListPropertySort = "/PriceListPropertySort",
    PriceListConstant = "/PriceListConstant",
    PriceListsCategories = "/PriceListsCategories",
    PriceListParentCategories = "/PriceListParentCategories",
    ProjectsExcel = "/Projects/DownloadExcel",
    CalculateOrderSum = "/Projects/CalculateOrderSum",
    Cooperation = "/Cooperation",
    ProjectsToEngineers = "/ProjectsToEngineers",
    ProjectsToManagers = "/ProjectsToManagers",
    LessonsToThemes = "/LessonsToThemes",
    TestsToThemes = "/TestsToThemes",
    LessonVideos = "/LessonVideos",
    LessonsToFiles = "/LessonsToFiles",
    EventRegistrationGuest = "/EventRegistrationGuest",
}
