import { LessonsToFiles, LessonsToFilesResponse } from "@aten/common/dist/models/LessonsToFiles";
import { UrlApi } from "config/api";
import { commonApi } from "services/commonApiService";

interface objectQuery {
    lessonId?: string;
    fileId?: string;
}

export const lessonsToFilesApi = commonApi.injectEndpoints({
    endpoints: (build) => ({
        getLessonToFileId: build.query<LessonsToFilesResponse, objectQuery>({
            query: ({ lessonId, fileId }) => `${UrlApi.LessonsToFiles}?LessonId=${lessonId}&AtenFileId=${fileId}`,
            providesTags: ["LessonsToFiles"],
        }),
        setLessonToFile: build.mutation<string, LessonsToFiles>({
            query: (data: LessonsToFiles) => ({
                url: UrlApi.LessonsToFiles,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Lesson", "LessonsToFiles"],
        }),
        deleteLessonToFile: build.mutation<string, string>({
            query: (id: string) => ({
                url: `${UrlApi.LessonsToFiles}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Lesson", "LessonsToFiles"],
        }),
    }),
});

export const lessonsToFilesApiExtendedApi = lessonsToFilesApi.injectEndpoints({
    endpoints: (build) => ({
        deleteLessonToFileById: build.mutation<string, objectQuery>({
            async queryFn(data, { dispatch }) {
                const lessonToFileId = await dispatch(lessonsToFilesApi.endpoints.getLessonToFileId.initiate(data))
                    .unwrap()
                    .then((result) => result.objects[0].id);
                return {
                    data: await dispatch(
                        lessonsToFilesApi.endpoints.deleteLessonToFile.initiate(lessonToFileId?.toString() ?? "")
                    ).unwrap(),
                };
            },
        }),
    }),
});

export const { useSetLessonToFileMutation, useDeleteLessonToFileByIdMutation } = lessonsToFilesApiExtendedApi;
