import { Col, DatePicker, Input, Row, Select } from "antd";
import cl from "assets/scss/Space.module.scss";
import Form, { Item, useForm } from "components/Form/Form";
import { Icons } from "components/Icons/Icons";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import ModalSearch from "components/Modal/ModalSearch";
import moment from "moment";
import style from "pages/Projects/Projects.module.scss";
import { FC } from "react";
import concatClasses from "utils/concatClasses";
import { filterQueryString } from "utils/filterQueryString";

interface CoursesModalFormProps {
    visible: boolean;
    close: () => void;
    onOk: (values: string) => void;
}

const select = [
    {
        id: 0,
        value: 0,
        title: "Сначала старые",
    },
    {
        id: 1,
        value: 1,
        title: "Сначала новые",
    },
];

const CoursesSearchModal: FC<CoursesModalFormProps> = ({ close, visible, onOk }) => {
    const { Option } = Select;
    const [form] = useForm();

    const onFinish = () => {
        form.validateFields().then((values) => {
            const data = {
                ...values,
                DateOfCreation: values.DateOfCreation ? moment(values.DateOfCreation).toISOString() : undefined,
            };
            onOk(filterQueryString(data));
            close();
        });
    };

    const onClose = () => {
        onOk("");
        close();
        form.resetFields();
    };

    return (
        <ModalSearch width={860} open={visible} onCancel={onClose} onOk={onFinish}>
            <Form form={form} className={style.search}>
                <Row className={concatClasses(cl.space, cl.space__between)} gutter={[30, 30]}>
                    <Col span={12}>
                        <Item name="CourseId" label={<Label type={LabelTypes.Form} label={"ID"} />}>
                            <Input />
                        </Item>
                    </Col>

                    <Col span={12}>
                        <Item name="Title" label={<Label type={LabelTypes.Form} label={"Название курса"} />}>
                            <Input />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="Sorting" label={<Label type={LabelTypes.Form} label={"Сортировка"} />}>
                            <Select suffixIcon={<Icons.SelectArrow />}>
                                {select.map((element) => (
                                    <Option key={element.id} value={element.value}>
                                        {element.title}
                                    </Option>
                                ))}
                            </Select>
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="DateOfCreation" label={<Label type={LabelTypes.Form} label={"Дата создания"} />}>
                            <DatePicker format={"DD.MM.YYYY"} />
                        </Item>
                    </Col>
                </Row>
            </Form>
        </ModalSearch>
    );
};
export default CoursesSearchModal;
