import { RuleObject } from "antd/lib/form";
import * as linkify from "linkifyjs";
import moment, { Moment } from "moment/moment";

export const urlsValidate = (message: string = "Неккоректная ссылка") => ({
    validator: async (_: RuleObject, names: string) => {
        if (!linkify.test(names)) {
            return Promise.reject(new Error(message));
        }
    },
});

export const numbersValidate = (message: string = "Допускаются только цифры") => ({
    validator: async (_: RuleObject, names: string) => {
        if (!/^(0|[1-9]\d*)$/.test(names)) {
            return Promise.reject(new Error(message));
        }
    },
});

export const lettersValidate = (message: string = "В названии должны присутствовать буквы") => ({
    validator: async (_: RuleObject, names: string) => {
        if (!/[a-zа-яё]/i.test(names)) {
            return Promise.reject(new Error(message));
        }
    },
});

export const required = (message: string = "Обязательное поле") => ({
    required: true,
    message,

});
export const requiredImage = (id: string, message: string = "Загрузите файл") => ({
    required: !id,
    message,
});
export const isNumber = (message: string = "Введите число") => ({
    validator: async (_: RuleObject, number: string) =>
        Number.isNaN(Number(number)) ? Promise.reject(new Error(message)) : undefined,
});

export const emailValidate = () => ({
    message: "Введите корректный E-mail",
    pattern: /.+@.+\..+/i,
});

export const phoneValidate = () => ({
    validator: async (_: RuleObject, names: string) => {
        if (names) {
            const val = names.replace(/[_ ]/g, "").length;
            if (val < 12) {
                return Promise.reject(new Error("Введите номер полностью"));
            }
        }
    },
});
export const onlyLettersValidation = (message: string = "Поле должно содержать буквы") => ({
    message,
    pattern: /[a-zA-Zа-яА-Я]/g,
});

export const dateChoseValidation = (firstDate: Moment, secondDate?: Moment | null) => {
    const currentDate = new Date().setDate(new Date().getDate() - 1);
    return {
        past: firstDate && firstDate < (secondDate ?? currentDate),
        future: firstDate && firstDate > moment(new Date()),
    };
};
