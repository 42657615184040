import { Lesson, LessonsTable } from "@aten/common/dist/models/Lesson";
import { Test, TestTable } from "@aten/common/dist/models/Test";
import { ColumnsType } from "antd/lib/table";
import { Icons } from "components/Icons/Icons";
import { authUrl } from "config/path";
import { DeletingLessonProps } from "pages/Lesson/Lesson";
import { DeletingTestProps } from "pages/Test/Test";
import { generatePath, Link } from "react-router-dom";
import { ModalType } from "utils/getContentAndFuncForDelete";

type LessonsColumnsProps = {
    setTypeModal: (type: ModalType) => void;
    setDeletingLesson: (props: DeletingLessonProps) => void;
    openDelete: () => void;
};

type TestColumnsProps = {
    setTypeModal: (type: ModalType) => void;
    setDeletingTest: (props: DeletingTestProps) => void;
    openDelete: () => void;
};

const deleteLesson = (lesson: Lesson, props: LessonsColumnsProps) => {
    props.setTypeModal("lesson");
    props.setDeletingLesson({
        id: lesson.id?.toString() ?? "",
        title: lesson.title ?? "",
    });
    props.openDelete();
};

const deleteTest = (test: Test, props: TestColumnsProps) => {
    props.setTypeModal("test");
    props.setDeletingTest({
        id: test.id?.toString() ?? "",
        title: test.title ?? "",
    });
    props.openDelete();
};

export const lessonColumns = (props: LessonsColumnsProps): ColumnsType<LessonsTable> => [
    { width: "5%", title: "№", key: "orderNumber", dataIndex: "orderNumber" },
    { width: "5%", title: "ID", key: "id", dataIndex: "id" },
    { title: "Название", key: "title", dataIndex: "title" },
    { title: "Краткое описание", key: "description", dataIndex: "description" },
    {
        width: "10%",
        title: "Действия",
        key: "action",
        dataIndex: "action",
        render: (_, el: Lesson) => {
            return (
                <>
                    <div style={{ display: "flex" }}>
                        <div style={{ marginRight: 10 }}>
                            <Link
                                to={generatePath(authUrl.Index.Lessons.Edit.url, {
                                    id: el.id.toString(),
                                })}>
                                <Icons.EditButton />
                            </Link>
                        </div>
                        <div>
                            <Icons.Delete onClick={() => deleteLesson(el, props)} />
                        </div>
                    </div>
                </>
            );
        },
    },
];

export const testColumns = (props: TestColumnsProps): ColumnsType<TestTable> => [
    { width: "5%", title: "№", key: "orderNumber", dataIndex: "orderNumber" },
    { width: "5%", title: "ID", key: "id", dataIndex: "id" },
    { title: "Название", key: "title", dataIndex: "title" },
    { title: "Описание", key: "fullDescription", dataIndex: "fullDescription" },
    {
        width: "10%",
        title: "Действия",
        key: "action",
        dataIndex: "action",
        render: (_, el: Test) => {
            return (
                <>
                    <div style={{ display: "flex" }}>
                        <div style={{ marginRight: 10 }}>
                            <Link
                                to={generatePath(authUrl.Index.Tests.Edit.url, {
                                    id: el.id.toString(),
                                })}>
                                <Icons.EditButton />
                            </Link>
                        </div>
                        <div>
                            <Icons.Delete onClick={() => deleteTest(el, props)} />
                        </div>
                    </div>
                </>
            );
        },
    },
];
