type CityCountry = { city?: string; country?: string };
type ToLocation = ({ city, country }: CityCountry) => string;
type ToCityCountry = (location: string) => CityCountry;

/// {`City`, `Country`} -> `City/Country`
export const toLocation: ToLocation = ({ city, country }) => {
  return `${city || "-"}/${country || "-"}`;
};

/// `City/Country` -> {`City`, `Country`}
export const toCityCountry: ToCityCountry = (location) => {
  const dividedLocation = location.split("/");
  return {
    city: dividedLocation[0] || "-",
    country: dividedLocation[1] || "-",
  };
};
