import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";

export interface LayoutContextProps {
    title?: string;
    setTitle?: Dispatch<SetStateAction<string | undefined>>;
    loading?: boolean;
    setLoading?: Dispatch<SetStateAction<boolean>>;
}

export const LayoutContext = createContext<LayoutContextProps>({});

interface ContextProviderProps {
    children?: ReactNode;
}

export const LayoutProvider = ({ children }: ContextProviderProps): JSX.Element => {
    const [title, setTitle] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>(false);

    return <LayoutContext.Provider value={{ title, setTitle, loading, setLoading }}>{children}</LayoutContext.Provider>;
};

const useLayoutContext = () => useContext<LayoutContextProps>(LayoutContext);

export default useLayoutContext;
