import {
    PriceListParentCategoriesRequest,
    PriceListParentCategoriesResponse,
} from "@aten/common/dist/models/PriceListsCategories";
import { UrlApi } from "config/api";
import { commonApi } from "services/commonApiService";

export const priceListParentCategoriesApi = commonApi.injectEndpoints({
    endpoints: (builder) => ({
        getPriceListParentCategories: builder.query<
            PriceListParentCategoriesResponse,
            PriceListParentCategoriesRequest
        >({
            query: (params) => {
                return {
                    url: `${UrlApi.PriceListParentCategories}/Get`,
                    params,
                };
            },
            providesTags: ["PriceListCategories"],
        }),
    }),
});

export const { useGetPriceListParentCategoriesQuery } = priceListParentCategoriesApi;
