import { Advantages, AdvantagesTable } from "@aten/common/dist/models/Advantages";
import { ColumnsType } from "antd/lib/table";
import { Icons } from "components/Icons/Icons";
import { authUrl } from "config/path";
import { DeletingAdvantagesProps } from "pages/Advantages/Advantages";
import cl from "pages/Advantages/Advantages.module.scss";
import { generatePath, Link } from "react-router-dom";

type ColumnsProps = {
    setDeletingAdvantages: (props: DeletingAdvantagesProps) => void;
    open: (isOpen: boolean) => void;
};

const certificatesColumnsTable = (props: ColumnsProps): ColumnsType<AdvantagesTable> => [
    {
        width: "7%",
        title: "ID",
        dataIndex: "id",
        key: "id",
        render: (_, data: Advantages) => (
            <Link
                className={cl.editById}
                to={generatePath(authUrl.Index.Advantages.Edit.url, {
                    id: data.id.toString(),
                })}>
                #{data.id}
            </Link>
        ),
    },
    { title: "Заголовок", dataIndex: "title", key: "title" },
    { title: "Описание", dataIndex: "description", key: "description" },
    {
        title: "Действия",
        dataIndex: "action",
        key: "action",
        render: (_, data: Advantages) => {
            return (
                <div style={{ display: "flex" }}>
                    <div style={{ marginRight: 10 }}>
                        <Icons.Delete
                            onClick={() => {
                                props.setDeletingAdvantages({
                                    id: data.id.toString(),
                                    title: data.title ?? "",
                                });
                                props.open(true);
                            }}
                        />
                    </div>
                    <div>
                        <Link
                            to={generatePath(authUrl.Index.Advantages.Edit.url, {
                                id: data.id.toString(),
                            })}>
                            <Icons.EditButton />
                        </Link>
                    </div>
                </div>
            );
        },
    },
];

export default certificatesColumnsTable;
