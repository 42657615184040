import { News } from "@aten/common/dist/models/News";
import { Button, Col, Input, message, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import ModalDelete from "components/Modal/ModalDelete";
import UploadPicture from "components/UploadPicture/UploadPicture";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetFileByIdQuery } from "services/fileService";
import { useDeleteNewsMutation, useEditNewsMutation, useSetNewsMutation } from "services/newsServices";
import { required } from "utils/getRules";
import Form, { Item, useForm } from "../Form";
import c from "./NewsFormEdit.module.scss";

interface NewsProps {
    data?: News;
}

export const NewsFormEdit: FC<NewsProps> = ({ data, ...props }) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [form] = useForm<typeof data>();

    const [fileId, setFileId] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false);

    const { data: imageLink } = useGetFileByIdQuery(fileId, { skip: !fileId });

    const [editNews] = useEditNewsMutation();
    const [setNews] = useSetNewsMutation();
    const [deleteNews] = useDeleteNewsMutation();

    useEffect(() => {
        data && setFileId(data?.imageId);
        form.setFieldsValue(data);
    }, [data]);

    const dataUpdate = async (newData: News) => {
        id
            ? await editNews({
                  ...data,
                  ...newData,
                  imageId: +fileId,
                  publicationDate: Date.parse(new Date().toDateString()),
              }).then(() => {
                  message.success("Новость отредактирована");
              })
            : await setNews({
                  ...newData,
                  imageId: +fileId,
                  publicationDate: Date.parse(new Date().toDateString()),
              }).then(() => {
                  message.success("Новость добавлена");
              });
        setTimeout(() => {
            navigate(-1);
        }, 1500);
    };

    const imageUpdate = (imageId: number) => {
        setFileId(imageId);
        form.setFieldsValue({ ...data, imageId: imageId });
    };

    const deleteCurrentNews = () => {
        id && deleteNews(id).then(() => navigate(-1));
    };

    return (
        <Form form={form} data={{}} onFinish={dataUpdate} {...props}>
            <>
                <LayoutWrapper>
                    <Row gutter={[30, 30]} className={c.row}>
                        <Col span={8}>
                            <Button htmlType="submit" type="primary">
                                {id ? "Сохранить изменения" : "Добавить новость"}
                            </Button>
                        </Col>
                        <Col span={8}>
                            <Button onClick={() => navigate(-1)}>
                                {id ? "Отменить изменения" : "Отменить добавление"}
                            </Button>
                        </Col>
                        {id && (
                            <Col span={8}>
                                <Button
                                    className={"buttonRed"}
                                    onClick={() => {
                                        setDeleteModal(true);
                                    }}>
                                    Удалить новость
                                </Button>
                            </Col>
                        )}
                    </Row>
                    <Row gutter={[30, 30]}>
                        <Col span={8}>
                            <Item
                                name="title"
                                label={<Label type={LabelTypes.Form} label={"Заголовок"} />}
                                rules={[required()]}>
                                <Input />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item
                                name="text"
                                label={<Label type={LabelTypes.Form} label={"Текст новости"} />}
                                rules={[required()]}>
                                <TextArea showCount maxLength={500} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item
                                name="imageId"
                                label={<Label type={LabelTypes.Form} label={"Изображение новости"} />}
                                rules={[required()]}>
                                <UploadPicture type={"large"} action={imageUpdate} url={imageLink} />
                            </Item>
                        </Col>
                    </Row>
                </LayoutWrapper>
                <div>
                    <ModalDelete
                        open={deleteModal}
                        onOk={() => {
                            deleteModal && deleteCurrentNews();
                        }}
                        onCancel={() => setDeleteModal(false)}
                        title={`Вы уверены, что хотите удалить новость "${data?.title ?? ""}"`}
                    />
                </div>
            </>
        </Form>
    );
};
