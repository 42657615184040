import {
  QuestionAnswer,
  QuestionAnswerResponse,
} from "@aten/common/dist/models/QuestionAnswers";
import { UrlApi } from "config/api";
import { commonApi } from "services/commonApiService";

export const questionAnswersApi = commonApi.injectEndpoints({
    endpoints: (build) => ({
        getQuestionAnswers: build.query<QuestionAnswerResponse, string>({
            query: () => UrlApi.QuestionAnswers,
            providesTags: ["QuestionAnswer"],
        }),
        getQuestionAnswerById: build.query<QuestionAnswer, string>({
            query: (themeId: string) => ({
                url: `${UrlApi.QuestionAnswers}/${themeId}`,
            }),
            providesTags: ["QuestionAnswer"],
            transformResponse: (res: QuestionAnswerResponse) => res.objects[0],
        }),
        getQuestionAnswerByThemeId: build.query<QuestionAnswerResponse, string>({
            query: (id: string) => `${UrlApi.QuestionAnswers}/${id}`,
            providesTags: ["QuestionAnswer"],
        }),
        setQuestionAnswer: build.mutation<string, QuestionAnswer>({
            query: (data: QuestionAnswer) => ({
                url: UrlApi.QuestionAnswers,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["QuestionAnswer", "TestQuestion"],
        }),
        editQuestionAnswer: build.mutation<string, QuestionAnswer>({
            query: (data: QuestionAnswer) => ({
                url: `${UrlApi.QuestionAnswers}/${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["QuestionAnswer", "TestQuestion"],
        }),
        deleteQuestionAnswer: build.mutation<QuestionAnswerResponse, string>({
            query: (id: string) => ({
                url: `${UrlApi.QuestionAnswers}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["QuestionAnswer", "TestQuestion"],
        }),
    }),
});

export const {
  useGetQuestionAnswersQuery,
  useGetQuestionAnswerByThemeIdQuery,
  useGetQuestionAnswerByIdQuery,
  useEditQuestionAnswerMutation,
  useSetQuestionAnswerMutation,
  useDeleteQuestionAnswerMutation,
} = questionAnswersApi;
