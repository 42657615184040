import { ProjectResponse } from "@aten/common/dist/models/Project";
import { Col, DatePicker, Input, Row, Select } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import cl from "assets/scss/Space.module.scss";
import Form, { Item, useForm } from "components/Form/Form";
import { Icons } from "components/Icons/Icons";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import ModalSearch, { ModalSize } from "components/Modal/ModalSearch";
import style from "pages/Projects/Projects.module.scss";
import { FC } from "react";
import { useGetDistrQuery, useGetIntegrQuery } from "services/projectsService";
import projectStatusOptions from "staticContent/projectStatusOptions";
import concatClasses from "utils/concatClasses";
import { filterQueryString } from "utils/filterQueryString";

interface ModalProjectFormProps {
    data?: ProjectResponse;
    actions?: { getData: (data: string) => void; closeModal: () => void };
    visible: boolean;
}

const ProjectModalSearch: FC<ModalProjectFormProps> = ({ data, actions, visible }) => {
    const [form] = useForm();

    const { data: distrs, isLoading: isLoadingDistrs } = useGetDistrQuery();
    const { data: integrs, isLoading: isLoadingIntegrs } = useGetIntegrQuery();

    const distrOptions: DefaultOptionType[] =
        distrs?.result?.map((item) => ({
            value: item.id,
            label: `${item.surname} ${item.name} ${item.patronymic}`,
        })) ?? [];

    const integrOptions: DefaultOptionType[] =
        integrs?.result?.map((item) => ({
            value: item.id,
            label: `${item.surname} ${item.name} ${item.patronymic}`,
        })) ?? [];

    // const distrOptions: DefaultOptionType[] = clearDublicateObjectsOptions(
    //     data?.objects.map(({ distr }) => ({
    //         value: distr.id,
    //         label: `${distr.surname} ${distr.name} ${distr.patronymic}`,
    //     }))
    // );

    // const integrOptions: DefaultOptionType[] = clearDublicateObjectsOptions(
    //     data?.objects.map(({ integrator }) => ({
    //         value: integrator.id,
    //         label: `${integrator.surname} ${integrator.name} ${integrator.patronymic}`,
    //     }))
    // );

    const selectFilterOptions = (input: string, option: DefaultOptionType | undefined) =>
        input.length >= 3
            ? (((option?.label as string) ?? "").toLowerCase() ?? "").includes(input.toLowerCase())
            : true;

    const onFinish = () => {
        form.validateFields().then((val) => {
            if (actions) {
                const str = filterQueryString({
                    ...val,
                    ShipmentStartDate: val.ShipmentStartDate?.format("MM.DD.YYYY") ?? undefined,
                });
                actions.getData(str);
                actions.closeModal();
            }
        });
    };

    const onClear = () => {
        form.resetFields();
        actions?.getData("");
        actions?.closeModal();
    };

    return (
        <ModalSearch width={ModalSize.Big} onOk={onFinish} open={visible} onCancel={onClear}>
            <Form form={form} className={style.search}>
                <Row className={concatClasses(cl.space, cl.space__between)} gutter={[30, 30]}>
                    <Col span={8}>
                        <Item name="DistrId" label={<Label type={LabelTypes.Form} label={"Дистрибьютор"} />}>
                            <Select
                                suffixIcon={<Icons.SelectArrow />}
                                showSearch
                                optionFilterProp="children"
                                filterOption={selectFilterOptions}
                                options={distrOptions}
                            />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="ProjectName" label={<Label type={LabelTypes.Form} label={"Название"} />}>
                            <Input />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="IntegratorId" label={<Label type={LabelTypes.Form} label={"Интегратор"} />}>
                            <Select
                                suffixIcon={<Icons.SelectArrow />}
                                showSearch
                                optionFilterProp="children"
                                filterOption={selectFilterOptions}
                                options={integrOptions}
                            />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="ShipmentStartDate" label={<Label type={LabelTypes.Form} label={"Дата отгрузки"} />}>
                            <DatePicker format={"DD.MM.YYYY"} />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="StatusId" label={<Label type={LabelTypes.Form} label={"Статус"} />}>
                            <Select suffixIcon={<Icons.SelectArrow />} options={projectStatusOptions} />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="CustomerName" label={<Label type={LabelTypes.Form} label={"Заказчик"} />}>
                            <Input />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="id" label={<Label type={LabelTypes.Form} label={"ID"} />}>
                            <Input />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <div className={style.sum}>
                            <Label type={LabelTypes.Form} label={"Сумма"} />
                            <Item name="OrderSumFrom" className={style.sum__item}>
                                <Input placeholder="От" />
                            </Item>
                            <Item name="OrderSumTo" className={style.sum__item}>
                                <Input placeholder="До" />
                            </Item>
                        </div>
                    </Col>
                    <Col span={8}>
                        <Item name="objectAddress" label={<Label type={LabelTypes.Form} label={"Адрес"} />}>
                            <Input />
                        </Item>
                    </Col>
                </Row>
            </Form>
        </ModalSearch>
    );
};
export default ProjectModalSearch;
