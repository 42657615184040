import { UserLogin, UserLoginResponse } from "@aten/common/dist/models/User";
import { UrlApi } from "config/api";
import { commonApi } from "services/commonApiService";
import { userApi } from "services/userService";
import { login } from "slices/authSlice";

export const authApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<UserLoginResponse, UserLogin>({
      query: (userData) => ({
        url: `${UrlApi.Users}/Login`,
        method: "POST",
        body: userData,
      }),
      onCacheEntryAdded: async (arg, { dispatch, cacheDataLoaded }) => {
        const {
          data: { token },
        } = await cacheDataLoaded;

        dispatch(userApi.endpoints.getUserByToken.initiate({ token }))
          .unwrap()
          .then((res) => {
            dispatch(login({ user: res, token }));
          })
          .catch((err) => {
            console.error(err);
          });
      },
    }),
  }),
});

export const { useLoginMutation } = authApi;
