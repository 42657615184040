import { CustomButton } from "components/CustomButton/CustomButton";
import { CustomTable } from "components/CustomTable/CustomTable";
import { Icons } from "components/Icons/Icons";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import { authUrl } from "config/path";
import useFluid from "hooks/useFluid";
import useModal from "hooks/useModal";
import usePagination from "hooks/usePagination";
import usersColumnsTable from "pages/Users/usersColumnsTable";
import UsersModalForm from "pages/Users/UsersModalForm";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import userSerializer from "serializers/user";
import { useGetUsersQuery } from "services/userService";
import cl from "./Users.module.scss";

const Users: FC = () => {
    const navigate = useNavigate();
    const { close, visible, open } = useModal({ initVisible: false });
    const { pagination, changePagination } = usePagination();
    const { getFluidTableHeight } = useFluid();

    const [searchString, setSearchString] = useState<string>("");

    const { data, isLoading, isFetching } = useGetUsersQuery(
        {
            pagination,
            SearchParams: searchString,
        },
        { refetchOnMountOrArgChange: true }
    );

    const users = userSerializer(data);

    const onAdd = () => {
        navigate(authUrl.Index.Users.New.url);
    };

    const searchUser = (val: string) => {
        setSearchString(val);
    };

    return (
        <>
            <LayoutWrapper>
                <div className={cl.actions}>
                    <CustomButton type="primary" buttonText="Добавить" icon={<Icons.Plus />} onClick={onAdd} />
                    <CustomButton wrapperClasses={cl.searchBtn} type="primary" onClick={open} icon={<Icons.Search />} />
                </div>
                <CustomTable
                    columns={usersColumnsTable()}
                    data={users}
                    loading={isLoading || isFetching}
                    pagination={{
                        total: data?.total,
                        onChange: changePagination,
                    }}
                    scroll={{ y: getFluidTableHeight(368, 407) }}
                />
            </LayoutWrapper>
            <UsersModalForm onOk={searchUser} onCancel={close} open={visible} />
        </>
    );
};

export default Users;
