import CoursesFormEdit from "components/Form/CoursesFormEdit/CoursesFormEdit";
import useLayout from "hooks/useLayout";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useGetCourseByIdQuery } from "services/coursesApi";

const CourseEdit: FC = () => {
    const { id } = useParams();

    const { data, isLoading } = useGetCourseByIdQuery(id ?? "", { skip: !id });

    useLayout(
        {
            title: data?.title ?? "Добавить новый курс",
            loading: isLoading,
        },
        [data]
    );

    return <CoursesFormEdit data={data} />;
};
export default CourseEdit;
