import { Col, DatePicker, Input, ModalProps, Row, Select } from "antd";
import cl from "assets/scss/Space.module.scss";
import Form, { Item, useForm } from "components/Form/Form";
import { Icons } from "components/Icons/Icons";
import InputWithMask from "components/Input/InputWithMask";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import ModalSearch, { ModalSize } from "components/Modal/ModalSearch";
import React, { FC } from "react";
import concatClasses from "utils/concatClasses";
import { filterQueryString } from "utils/filterQueryString";
import { required } from "utils/getRules";

interface UsersModalFormProps extends Omit<ModalProps, "onOk" | "onCancel"> {
    onOk: (data: string) => void;
    onCancel: () => void;
}

const select = [
    {
        id: 0,
        value: 0,
        title: "Инткгратор",
    },
    {
        id: 1,
        value: 1,
        title: "Дистрибьютер",
    },
];
const { RangePicker } = DatePicker;
const { Option } = Select;

const UsersModalForm: FC<UsersModalFormProps> = ({ onCancel, onOk, ...props }) => {
    const [form] = useForm();

    const onFinish = () => {
        const formStr = "MM.DD.YYYY";
        const formData = form.getFieldsValue();
        const data = {
            ...formData,
            DateOfRegistrationFrom: formData.DateOfRegistrationFrom?.[0].format(formStr),
            DateOfRegistrationTo: formData.DateOfRegistrationFrom?.[1].format(formStr),
            PhoneNumber: formData.PhoneNumber?.replace(/[' ']/g, ""),
        };
        onOk(filterQueryString(data));
        onCancel();
    };

    const onClose = () => {
        form.resetFields();
        onOk("");
        onCancel();
    };

    return (
        <ModalSearch onCancel={onClose} onOk={onFinish} width={ModalSize.Big} {...props}>
            <Form form={form}>
                <Row className={concatClasses(cl.space)} gutter={[30, 30]}>
                    <Col span={8}>
                        <Item name="Surname" label={<Label type={LabelTypes.Form} label={"Фамилия"} />}>
                            <Input />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="Name" label={<Label type={LabelTypes.Form} label={"Имя"} />}>
                            <Input />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="Patronymic" label={<Label type={LabelTypes.Form} label={"Отчество"} />}>
                            <Input />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="Login" label={<Label type={LabelTypes.Form} label={"Логин"} />}>
                            <Input />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="PhoneNumber" label={<Label type={LabelTypes.Form} label={"Телефон"} />}>
                            <InputWithMask mask="+7 999 999 99 99" />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item
                            name={"DateOfRegistrationFrom"}
                            label={<Label type={LabelTypes.Form} label={"Дата регистрации"} />}>
                            <RangePicker format={"DD.MM.YYYY"} />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="Email" label={<Label type={LabelTypes.Form} label={"E-mail"} />}>
                            <Input />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item
                            name="UserRole"
                            label={<Label type={LabelTypes.Form} label={"Роль"} />}
                            rules={[required()]}>
                            <Select suffixIcon={<Icons.SelectArrow />}>
                                {/*todo: вынести в staticContent в объект и передавать в качестве пропса options*/}
                                {select.map((element) => (
                                    <Option key={element.id} value={element.value}>
                                        {element.title}
                                    </Option>
                                ))}
                            </Select>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="Company" label={<Label type={LabelTypes.Form} label={"Компания"} />}>
                            <Input />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="City" label={<Label type={LabelTypes.Form} label={"Город"} />}>
                            <Input />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="Country" label={<Label type={LabelTypes.Form} label={"Страна"} />}>
                            <Input />
                        </Item>
                    </Col>
                </Row>
            </Form>
        </ModalSearch>
    );
};
export default UsersModalForm;
