import { authUrl } from "config/path";

interface ISiderList {
    label: string;
    key: string;
    url?: string;
    children?: ISiderList[];
}

export enum siderMenuKeys {
    Users = "Users",
    Projects = "Projects",
    Courses = "Courses",
    Themes = "Themes",
    OfflineEvents = "OfflineEvents",
    Certificates = "Certificates",
    Catalogues = "Catalogues",
    Price = "Price",
    Site = "Site",
    About = "About",
    Cooperation = "Cooperation",
    Default = "Default",
    News = "News",
    Members = "Members",
    Advantages = "Advantages",
    Lessons = "Lessons",
    Tests = "Tests",
}

export const siderMenuList: ISiderList[] = [
    {
        label: "Пользователи",
        key: "1",
        url: authUrl.Index.Users.List.url,
    },
    {
        label: "Проекты",
        key: "2",
        url: authUrl.Index.Projects.List.url,
    },
    {
        label: "Курсы",
        key: "3",
        url: authUrl.Index.Courses.List.url,
    },
    {
        label: "Темы",
        key: "4",
        url: authUrl.Index.Themes.List.url,
    },
    {
        label: "Уроки",
        key: "5",
        url: authUrl.Index.Lessons.List.url,
    },
    {
        label: "Тесты",
        key: "6",
        url: authUrl.Index.Tests.List.url,
    },
    {
        label: "Оффлайн мероприятия",
        key: "7",
        url: authUrl.Index.OfflineEvents.List.url,
    },
    {
        label: "Сертификаты",
        key: "8",
        url: authUrl.Index.Certificates.List.url,
    },
    {
        label: "Каталоги",
        key: "9",
        url: authUrl.Index.Catalogues.List.url,
    },
    {
        label: "Прайс-листы",
        key: "10",
        url: authUrl.Index.PriceLists.Own.url,
    },
    {
        label: "Новости",
        key: "11_1",
        url: authUrl.Index.News.List.url,
    },
    {
        label: "Сотрудники",
        key: "11_2",
        url: authUrl.Index.Members.List.url,
    },
    {
        label: "Преимущества",
        key: "11_3",
        url: authUrl.Index.Advantages.List.url,
    },
    {
        label: "Сотрудничество",
        key: "11_4",
        url: authUrl.Index.Cooperation.url,
    },
    {
        label: "Роли",
        key: "12",
        url: authUrl.Index.Roles.List.url,
    },
];

export const defaultSiderItem: ISiderList = {
    label: "-",
    key: "Default",
    url: "/",
};
