import { LessonVideos, LessonVideosResponse } from "@aten/common/dist/models/LessonVideos";
import { UrlApi } from "config/api";
import { commonApi } from "services/commonApiService";

export const lessonVideosApi = commonApi.injectEndpoints({
    endpoints: (build) => ({
        getLessonVideos: build.query<LessonVideosResponse, string>({
            query: () => UrlApi.LessonVideos,
            providesTags: ["LessonVideos"],
        }),
        getLessonVideosById: build.query<LessonVideos, string>({
            query: (id: string) => ({
                url: `${UrlApi.LessonVideos}/${id}`,
            }),
            providesTags: ["LessonVideos"],
        }),
        getLessonVideosByLessonId: build.query<LessonVideosResponse, string>({
            query: (lessonId: string) => `${UrlApi.LessonVideos}/?LessonId=${lessonId}`,
            providesTags: ["LessonVideos"],
        }),
        setLessonVideos: build.mutation<string, LessonVideos | undefined>({
            query: (data: LessonVideos) => ({
                url: UrlApi.LessonVideos,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Lesson"],
        }),
        editLessonVideos: build.mutation<string, LessonVideos | undefined>({
            query: (data: LessonVideos) => ({
                url: `${UrlApi.LessonVideos}/${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["Lesson"],
        }),
        deleteLessonVideos: build.mutation<LessonVideosResponse, string>({
            query: (id: string) => ({
                url: `${UrlApi.LessonVideos}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["LessonVideos"],
        }),
    }),
});

export const {
    useGetLessonVideosQuery,
    useGetLessonVideosByLessonIdQuery,
    useGetLessonVideosByIdQuery,
    useEditLessonVideosMutation,
    useSetLessonVideosMutation,
    useDeleteLessonVideosMutation,
} = lessonVideosApi;
