import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { message } from "antd";
import { urlApi } from "../../config/api";
import { RootState } from "../../store";
import { ContentType } from "../../types/service";

const baseQuery = fetchBaseQuery({
    baseUrl: urlApi,
    prepareHeaders: (headers, { getState }) => {
        headers.set("Content-Type", ContentType.Json);
        const token = (getState() as RootState).auth.token;
        if (token) {
            headers.set("Authorization", token);
        }
        return headers;
    },
});
export const baseQueryFn: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result?.error?.status === 400) {
        const res = result?.error?.data as { code: number; information: string };
        if (res.code === 404) {
            message.error(res?.information ?? "Ошибка доступа");
            return result;
        }
    }
    return result;
};
