import {
    GetPriceListPropertySort,
    PostPriceListPropertySortSave,
    PriceListPropertySort,
    PriceListPropertySortList,
    PriceListPropertyType,
} from "@aten/common/dist/models/PriceListPropertySort";
import { UrlApi } from "config/api";
import { commonApi } from "services/commonApiService";

const generateInitialPropertySortList = (userId: number) => {
    return Object.entries(PriceListPropertyType)
        .filter(([_, propertyType]) => !Number.isNaN(Number(propertyType)))
        .map<PriceListPropertySort>(([_, propertyType], index) => ({
            propertyType: propertyType as PriceListPropertyType,
            userId,
            orderNumber: index + 1,
            isShow: true,
        }));
};

export const priceListPropertySortApi = commonApi.injectEndpoints({
    endpoints: (builder) => ({
        getPriceListPropertySort: builder.query<PriceListPropertySortList, GetPriceListPropertySort>({
            query: ({ ...params }) => {
                return {
                    url: UrlApi.PriceListPropertySort,
                    params,
                };
            },
        }),
    }),
});

export const priceListPropertySortExpandedApi = priceListPropertySortApi.injectEndpoints({
    endpoints: (builder) => ({
        savePriceListPropertySort: builder.mutation<undefined, PostPriceListPropertySortSave>({
            query: ({ userId, propertySortList }) => {
                const body = userId ? generateInitialPropertySortList(userId) : propertySortList;

                return {
                    url: `${UrlApi.PriceListPropertySort}/SaveSorting`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["priceListPropertySort"],
        }),
        getPriceListPropertySortAndSave: builder.query<PriceListPropertySortList, GetPriceListPropertySort>({
            async queryFn({ UserId }, { dispatch }) {
                const priceListPropertySort = await dispatch(
                    priceListPropertySortApi.endpoints.getPriceListPropertySort.initiate(
                        { UserId },
                        { forceRefetch: true }
                    )
                ).unwrap();

                if (priceListPropertySort && Array.isArray(priceListPropertySort)) {
                    return { data: priceListPropertySort };
                }

                await dispatch(
                    priceListPropertySortExpandedApi.endpoints.savePriceListPropertySort.initiate({ userId: UserId })
                );

                const createdPriceListPropertySort = await dispatch(
                    priceListPropertySortApi.endpoints.getPriceListPropertySort.initiate({ UserId })
                ).unwrap();

                return { data: createdPriceListPropertySort };
            },
            providesTags: ["priceListPropertySort"],
        }),
    }),
});

export const { useGetPriceListPropertySortAndSaveQuery, useSavePriceListPropertySortMutation } =
    priceListPropertySortExpandedApi;
