import React, { CSSProperties, FC, Key } from "react";
import concatClasses from "utils/concatClasses";
import style from "./Icon.module.scss";

interface ArrowProps {
    width?: Key;
    height?: Key;
    rotate?: number;
    color?: string;
    className?: string;
    style?: CSSProperties;
}

const Arrow: FC<ArrowProps> = ({ width, height, rotate = 0, style: styles, color = "#333333", className }) => {
    return (
        <div
            className={concatClasses(style.wrapper, className)}
            style={{ ...styles, transform: `rotate(${rotate}deg)`, width, height }}>
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.553426 1.16589C0.553121 1.01012 0.607371 0.859161 0.706758 0.73922C0.762725 0.671714 0.831459 0.615913 0.909024 0.575012C0.986588 0.53411 1.07146 0.508913 1.15878 0.500862C1.24609 0.492812 1.33414 0.502066 1.41788 0.528096C1.50161 0.554126 1.57939 0.596418 1.64676 0.652553L5.22009 3.63922L8.80009 0.75922C8.86828 0.703843 8.94675 0.662489 9.03097 0.637534C9.1152 0.61258 9.20353 0.604517 9.29088 0.61381C9.37823 0.623103 9.46289 0.649567 9.53998 0.691683C9.61707 0.733799 9.68508 0.790736 9.74009 0.85922C9.80078 0.928198 9.84656 1.00897 9.87455 1.09648C9.90255 1.18399 9.91215 1.27634 9.90276 1.36774C9.89337 1.45913 9.86519 1.5476 9.81999 1.62759C9.77479 1.70758 9.71354 1.77736 9.64009 1.83255L5.64009 5.05255C5.5208 5.15061 5.37117 5.20421 5.21676 5.20421C5.06234 5.20421 4.91271 5.15061 4.79342 5.05255L0.793426 1.71922C0.712748 1.65234 0.648972 1.56739 0.607272 1.47125C0.565573 1.37511 0.547125 1.27049 0.553426 1.16589Z"
                    fill={color}
                />
            </svg>
        </div>
    );
};

export default Arrow;
