import { Theme, ThemeResponse } from "@aten/common/dist/models/Theme";
import { UrlApi } from "config/api";
import { IPagination } from "hooks/usePagination";
import { commonApi } from "services/commonApiService";

export const themesApi = commonApi.injectEndpoints({
    endpoints: (build) => ({
        getThemes: build.query<ThemeResponse, { pagination?: IPagination; SearchParams?: string } | void>({
            query: ({ pagination, SearchParams } = {}) => ({
                url: `${UrlApi.Themes}${SearchParams ?? ""}`,
                params: {
                    ...pagination,
                },
            }),
            providesTags: ["Theme"],
        }),
        getThemesByIds: build.query<ThemeResponse, string>({
            query: (ids: string) => `${UrlApi.Themes}?ListOfIdsInStringFormat=${ids}`,
            providesTags: ["Theme"],
        }),
        getThemeById: build.query<Theme, string>({
            query: (id: string) => ({
                url: `${UrlApi.Themes}/${id}`,
            }),
            providesTags: ["Theme"],
        }),
        getThemeByCourseId: build.query<ThemeResponse, string>({
            query: (corseId: string) => `${UrlApi.Themes}?CourseId=${corseId}`,
            providesTags: ["Theme"],
        }),
        setTheme: build.mutation<string, Theme>({
            query: (data: Theme) => ({
                url: UrlApi.Themes,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Theme"],
        }),
        editTheme: build.mutation<string, Theme>({
            query: (data: Theme) => ({
                url: `${UrlApi.Themes}/${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["Theme"],
        }),
        deleteTheme: build.mutation<ThemeResponse, string>({
            query: (id: string) => ({
                url: `${UrlApi.Themes}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Theme"],
        }),
    }),
});

export const {
    useGetThemesQuery,
    useGetThemesByIdsQuery,
    useGetThemeByCourseIdQuery,
    useGetThemeByIdQuery,
    useEditThemeMutation,
    useSetThemeMutation,
    useDeleteThemeMutation,
} = themesApi;
