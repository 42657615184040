import { Menu, MenuProps } from "antd";
import { siderMenuList } from "config/SiderMenuList";
import React, { Dispatch, FC, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

interface ISiderMenu extends MenuProps {
    activeKey?: string;
    setActiveKey: Dispatch<SetStateAction<string | undefined>>;
}

const SiderMenu: FC<ISiderMenu> = ({ activeKey, setActiveKey, ...props }) => {
    const navigate = useNavigate();

    const onClick: MenuProps["onClick"] = (menuItem) => {
        const url = (menuItem as any)?.item?.props?.url;
        if (url) {
            navigate(url);
            setActiveKey(menuItem?.key);
        }
    };

    const onOpenChange: MenuProps["onOpenChange"] = (e) => {
        console.log("-> e", e);
    };

    return (
        <Menu
            theme="dark"
            mode="inline"
            items={siderMenuList}
            {...props}
            selectedKeys={activeKey !== undefined ? [activeKey] : undefined}
            onClick={onClick}
            onOpenChange={onOpenChange}
        />
    );
};

export default SiderMenu;
