import { message } from "antd";
import UserFormEdit from "components/Form/UserFormEdit/UserFormEdit";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import useLayout from "hooks/useLayout";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useDeleteUserMutation, useGetUserByIdQuery } from "services/userService";

const UserEdit: FC = () => {
    const { id } = useParams();
    const { data: user, isLoading } = useGetUserByIdQuery(id || "", { skip: !id });

    const [deleteUserMutation, { isLoading: isLoadingDelete }] = useDeleteUserMutation();

    const deleteUser = () => {
        if (!id) {
            return;
        }

        deleteUserMutation(id)
            .unwrap()
            .then(() => {
                message.success(`Пользователь ${id} успешно удален!`);
            });
    };

    useLayout(
        {
            title:
                user?.surname && user?.name && user?.patronymic
                    ? `${user?.surname} ${user?.name} ${user?.patronymic}`
                    : "Добавить нового пользователя",
            loading: isLoading,
        },
        [user]
    );

    return (
        <LayoutWrapper>
            <UserFormEdit
                isLoadingDelete={isLoadingDelete}
                deleteUser={deleteUser}
                isCreate={!id}
                data={user}
                isLoading={isLoading}
            />
        </LayoutWrapper>
    );
};

export default UserEdit;
