import { PriceListColumns } from "pages/PriceLists/utils/getColumns";
import React from "react";
import render from "utils/helpers/render";

const getOtherColumns = (): PriceListColumns => {
    return [
        {
            title: "Модель",
            key: "modelName",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Цена_MSRP",
            key: "msrpcost",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Наименование",
            key: "name",
            render: (name) => render.ellipsisText(name),
        },
        {
            title: "Ссылка",
            key: "link",
            render: (link) => render.link(link),
        },
        {
            title: "Гарантия",
            key: "guaranteePeriod",
            render: (value) => render.ellipsisText(value),
        },
        {
            title: "Категория",
            key: "childCategoryName",
            render: (value) => render.ellipsisText(value),
        },
    ];
};

export default getOtherColumns;
