import { CataloguesFormEdit } from "components/Form/CataloguesFormEdit/CataloguesFormEdit";
import useLayout from "hooks/useLayout";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useGetCataloguesByIdQuery } from "services/cataloguesService";

const CataloguesEdit: FC = () => {
    const { id } = useParams();

    const { data, isLoading } = useGetCataloguesByIdQuery(id ?? "", { skip: !id });

    useLayout(
        {
            title: data?.title ?? "Добавить каталог",
            loading: isLoading,
        },
        [data]
    );

    return <CataloguesFormEdit data={data} />;
};

export default CataloguesEdit;
