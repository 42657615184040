// models/Role.ts
var RolesEnum = /* @__PURE__ */ ((RolesEnum2) => {
  RolesEnum2["SI"] = "SI";
  RolesEnum2["DISTI"] = "Disti";
  RolesEnum2["KVMPDU"] = "SupervisorKVMPDU";
  RolesEnum2["PROAV"] = "SupervisorProAv";
  RolesEnum2["TEACHING"] = "SupervisorTeaching";
  RolesEnum2["ADMIN"] = "Admin";
  return RolesEnum2;
})(RolesEnum || {});
export {
  RolesEnum
};
