import {
  Licences,
  LicencesResponse,
  LicencesTable,
} from "@aten/common/dist/models/Licences";
import serializer, { Serializer } from "serializers/index";

const certificateSerializer: Serializer<
  LicencesResponse,
  Licences,
  LicencesTable
> = (courses) => {
  return serializer<LicencesResponse, Licences, LicencesTable>(
    courses,
    (cour) => ({
      ...cour,
      key: cour.id,
    })
  );
};

export default certificateSerializer;
