import { LoadingOutlined } from "@ant-design/icons";
import { Spin, SpinProps } from "antd";
import React, { FC } from "react";
import concatClasses from "utils/concatClasses";
import cl from "./Loader.module.scss";

interface LoaderProps extends SpinProps {
    customClass?: string;
}

//TODO - ???
const Loader: FC<LoaderProps> = ({ customClass, ...props }) => {
    return (
        <Spin
            className={concatClasses(cl.loader, customClass)}
            size="large"
            indicator={<LoadingOutlined spin />}
            {...props}
        />
    );
};

export default Loader;
