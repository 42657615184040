import Modal, { ModalProps } from "components/Modal/Modal";
import React, { FC, Key } from "react";
import concatClasses from "utils/concatClasses";
import cl from "./Modal.module.scss";

export enum ModalSize {
    Big = 1260,
    Small = 860,
}

type ModalSearchProps = ModalProps & {
    width?: ModalSize | Key;
};

const ModalSearch: FC<ModalSearchProps> = ({
    width = ModalSize.Small,
    className,
    okButtonText = "Поиск",
    keyboard = true,
    header = false,
    cancelButtonText = "Сбросить",
    ...props
}) => {
    return (
        <Modal
            width={width}
            keyboard={keyboard}
            header={header}
            className={concatClasses(className, cl.modal_search)}
            okButtonText={okButtonText}
            cancelButtonText={cancelButtonText}
            {...props}
        />
    );
};

export default ModalSearch;
