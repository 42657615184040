import { Pagination, Select } from "antd";
import { CustomButton } from "components/CustomButton/CustomButton";
import useSelectOptions from "components/Form/CataloguesFormEdit/hooks/useSelectOptions";
import { Icons } from "components/Icons/Icons";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import ModalDelete from "components/Modal/ModalDelete";
import { authUrl } from "config/path";
import useModal from "hooks/useModal";
import { CatalogueItem } from "pages/Catalogues/CatalogueItem";
import styles from "pages/Catalogues/Catalogues.module.scss";
import React, { FC, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useDeleteCataloguesMutation, useGetCataloguesQuery } from "services/cataloguesService";
import { itemRenderPaging } from "utils/itemRenderPaging";

const pageSize = 3;

export interface ModalDeleteCataloguesProps {
    id: string;
    title: string;
}

const Catalogues: FC = () => {
    const navigate = useNavigate();

    const [pagination, setPagination] = useState<number>(1);
    const [startIndex, setStartIndex] = useState<number>(0);
    const [categoryId, setCategoryId] = useState<number | string>();
    const [modalDeleteCatalogues, setModalDeleteCatalogues] = useState<ModalDeleteCataloguesProps>({
        id: "",
        title: "",
    });

    const { data: catalogues } = useGetCataloguesQuery({
        CategoryId: categoryId,
        StartIndex: startIndex,
        ObjectsCount: pageSize,
    });
    const { options, loading } = useSelectOptions();
    const { visible, open, close } = useModal({});
    const [DeleteCataloguesMutation] = useDeleteCataloguesMutation();

    const handleChange = (value: number | string) => {
        setCategoryId(value !== "0" ? value : undefined);
        setStartIndex(0);
        setPagination(1);
    };

    const handleChangePagination = (currentPage: number) => {
        let newStartIndex = pageSize * (currentPage - 1);
        setPagination(currentPage);
        setStartIndex(newStartIndex);
    };
    const deleteCatalogues = () => {
        modalDeleteCatalogues.id && DeleteCataloguesMutation(modalDeleteCatalogues.id).then(close);
    };

    const linkToCataloguesNew = () => {
        navigate(generatePath(authUrl.Index.Catalogues.New.url));
    };

    return (
        <>
            <LayoutWrapper>
                <div className={styles.head}>
                    <Select
                        style={{ minWidth: "300px" }}
                        placeholder={"Выберете категорию"}
                        loading={loading}
                        onChange={handleChange}
                        options={[{ label: "не выбрана", value: "0", key: 0 }, ...options]}
                    />
                    <CustomButton
                        wrapperClasses={styles.head__add}
                        onClick={linkToCataloguesNew}
                        className={styles.head__add}
                        buttonText={"Добавить каталог"}
                        type="primary"
                        icon={<Icons.Plus />}
                    />
                </div>

                <div className={styles.catalogs}>
                    {catalogues?.objects.map((catalogue) => {
                        return (
                            <CatalogueItem
                                key={catalogue.id}
                                setDeleteModal={open}
                                setModalDeleteCatalogues={setModalDeleteCatalogues}
                                catalogue={catalogue}
                                className={styles.catalogs__item}
                            />
                        );
                    })}
                    {(catalogues?.total ?? 0) >= pageSize ? (
                        <Pagination
                            itemRender={itemRenderPaging}
                            current={pagination}
                            pageSize={pageSize}
                            hideOnSinglePage={true}
                            onChange={handleChangePagination}
                            total={catalogues?.total}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </LayoutWrapper>
            <div>
                <ModalDelete
                    open={visible}
                    onOk={() => deleteCatalogues()}
                    onCancel={close}
                    title={`Вы уверены, что хотите удалить каталог "${modalDeleteCatalogues.title}"?`}
                />
            </div>
        </>
    );
};

export default Catalogues;
