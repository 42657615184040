import { InputProps } from "antd";
import CustomInputField from "components/Input/CustomInputField";
import { daDataApiKey } from "config/api";
import React, { FC, useEffect, useState } from "react";
import { AddressSuggestions, DaDataAddress } from "react-dadata";
import { BaseProps } from "react-dadata/dist/BaseSuggestions";
import "react-dadata/dist/react-dadata.css";
import concatClasses from "utils/concatClasses";
import style from "./Dadata.module.scss";

interface InputDaDataAddressProps extends Omit<BaseProps<DaDataAddress>, "token" | "onChange"> {
    disabled?: boolean;
    onChange?: (address: string | undefined) => void;
    searchingCountry?: string;
}

const InputDaDataAddress: FC<InputDaDataAddressProps> = ({
    disabled,
    onChange,
    httpCache = false,
    value,
    customInput = CustomInputField,
    searchingCountry = "*",
    ...props
}) => {
    const [addressData, setAddressData] = useState<InputDaDataAddressProps["value"]>(value);

    useEffect(() => {
        setAddressData(
            typeof value === "string"
                ? {
                      value: value as string,
                      unrestricted_value: value,
                      data: {} as DaDataAddress,
                  }
                : value
        );
    }, [value]);

    const changeAddress = (address: InputDaDataAddressProps["value"]) => {
        if (address) {
            onChange && onChange(address.value);
            setAddressData(address);
        }
    };

    const onInput: InputProps["onInput"] = (event) => {
        if (!event.currentTarget.value?.length) {
            onChange && onChange(undefined);
            setAddressData(undefined);
        }
    };

    return (
        <AddressSuggestions
            containerClassName={concatClasses(style.wrap)}
            httpCache={httpCache}
            value={addressData}
            onChange={changeAddress}
            // customInput={customInput}
            filterLocations={[{ country: searchingCountry }]}
            inputProps={{
                onInput,
                disabled,
            }}
            {...props}
            token={daDataApiKey}
        />
    );
};

export default InputDaDataAddress;
