import { LicencesTableData } from "@aten/common/models/LicencesTableData";
import { Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { Icons } from "components/Icons/Icons";
import { DeletingCertificatesProps } from "pages/Certificates/Certificates";
import React from "react";
import { numToMounthDate } from "utils/dateConverter";

type ColumnsProps = {
    setModalCertificate: (isOpen: boolean) => void;
    setDataCertificate: (data: LicencesTableData) => void;
    setDeletingCertificate: (props: DeletingCertificatesProps) => void;
    setDeleteCertificateModal: (isOpen: boolean) => void;
    downloadLicences: (id: string) => void;
};

const certificatesColumnsTable = (props: ColumnsProps): ColumnsType<LicencesTableData> => [
    {
        width: "7%",
        title: "ID",
        dataIndex: "id",
        key: "id",
    },
    { title: "Название сертификата", dataIndex: "name", key: "name" },
    {
        title: "Курс",
        dataIndex: "courseName",
        key: "courseName",
    },
    {
        // \u00A0 - неразрывный пробел
        title: "Срок годности (в\u00A0днях)",
        dataIndex: "validityPeriodInDays",
        render: (_, { validityPeriodInDays }) => {
            return validityPeriodInDays;
        },
    },

    {
        title: "Статус",
        dataIndex: "isGiven",
        key: "isGiven",
        render: (_, data) => {
            return data.isGiven ? (
                <div style={{ color: "#83c447" }}>Выдан</div>
            ) : (
                <div style={{ color: "#e9bb18" }}>Ожидает выдачи</div>
            );
        },
    },
    {
        title: "Действия",
        dataIndex: "action",
        key: "action",
        render: (_, data) => {
            return (
                <Space size={10}>
                    <Icons.Download
                        onClick={() => {
                            props.downloadLicences(data.id.toString());
                        }}
                    />
                    <Icons.EditButton
                        onClick={() => {
                            props.setModalCertificate(true);
                            props.setDataCertificate(data);
                        }}
                    />
                    <Icons.Delete
                        onClick={() => {
                            props.setDeletingCertificate({ id: data.id.toString(), title: data.name });
                            props.setDeleteCertificateModal(true);
                        }}
                    />
                </Space>
            );
        },
    },
];

export default certificatesColumnsTable;
