
interface FieldsProjectList {
    field: string,
    label: string,
}
export const fieldsList: FieldsProjectList[]  = [
    {
        field: 'IsIncludeDistributor',
        label: 'Дистрибьютор'
    },
    {
        field: 'IsIncludeShippingDate',
        label: 'Дата отгрузки'
    },
    {
        field: 'IsIncludeId',
        label: 'ID'
    },

    {
        field: 'IsIncludeProjectType',
        label: 'Тип проекта',
    },
    {
        field: 'IsIncludeCountryOfShipment',
        label: 'Страна поставки',
    },
    {
        field: 'IsIncludeManagers',
        label: 'ФИО менеджера',
    },
    {
        field: 'IsIncludeIntegrator',
        label: 'Название Интегратора',
    },
    {
        field: 'IsIncludeIntegratorSite',
        label: 'Сайт Интегратора',
    },
    {
        field: 'IsIncludeIntegratorAdress',
        label: 'Адрес Интегратора',
    },
    {
        field: 'IsIncludeEngineers',
        label: 'ФИО инженера',
    },
    {
        field: 'IsIncludeName',
        label: 'Название проекта',
    },
    {
        field: 'IsIncludeCustomer',
        label: 'Название заказчика',
    },
    {
        field: 'IsIncludeCustomerSite',
        label: 'Сайт заказчика',
    },
    {
        field: 'IsIncludeCustomerAddress',
        label: 'Адрес заказчика',
    },
    {
        field: 'IsIncludeAddress',
        label: 'Адрес объекта',
    },
    {
        field: 'IsIncludeShortInstallationDescription',
        label: 'Краткое описание инсталяции',
    },
    {
        field: 'IsIncludeMakingDecisionDate',
        label: 'Дата принятия решения',
    },
    {
        field: 'IsIncludeSupplyEquipmentDate',
        label: 'Дата поставки оборудования',
    },
    {
        field: 'IsIncludeNeedFractionalDelivery',
        label: 'Необходимость дробной поставки',
    },
    {
        field: 'IsIncludeVendors',
        label: 'Конкуренты (вендоры) в проекте',
    },
    {
        field: 'IsIncludeModels',
        label: 'Модели',
    },
    {
        field: 'IsIncludeSum',
        label: 'Сумма заказа',
    },
    {
        field: 'IsIncludeComments',
        label: 'Дополнительная информация и комментарии',
    },
    {
        field: 'IsIncludeStatus',
        label: 'Статус проекта',
    },
]
