import { useMemo, useState } from "react";

export const defaultPageSize = 10;

interface UsePaginationProps {
    initialState?: IPagination;
}

export type ChangePagination = (StartIndex: number, ObjectsCount: number) => void;

export interface IPagination {
    StartIndex: number;
    ObjectsCount: number;
}

const usePagination = ({
    initialState = {
        StartIndex: 0,
        ObjectsCount: defaultPageSize,
    },
}: UsePaginationProps | undefined = {}) => {
    const [pagination, setPagination] = useState<IPagination>(initialState);

    const changePagination: ChangePagination = (StartIndex: number, ObjectsCount: number) => {
        setPagination({
            StartIndex: ObjectsCount * (StartIndex - 1),
            ObjectsCount,
        });
    };

    const current = useMemo(
        () =>
            (pagination.StartIndex < pagination.ObjectsCount
                ? pagination.ObjectsCount
                : pagination.StartIndex + pagination.ObjectsCount) / pagination.ObjectsCount,
        [pagination]
    );

    return {
        pagination,
        changePagination,
        current,
    };
};

export default usePagination;
