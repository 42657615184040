import { Button, ButtonProps } from "antd";
import { ModalProps } from "components/Modal/Modal";
import style from "components/Modal/Modal.module.scss";
import React, { FC, ReactNode } from "react";

interface ModalFooterProps {
    footer?: boolean | ReactNode;
    loading?: boolean;
    onOk?: ModalProps["onOk"];
    okButtonText?: string;
    okButtonProps?: ButtonProps;
    onCancel?: ModalProps["onCancel"];
    cancelButtonText?: string;
    cancelButtonProps?: ButtonProps;
}

const showDependOnType = (prop: boolean | ReactNode, element: ReactNode) =>
    typeof prop !== "boolean" ? prop : prop ? element : null;

export const ModalFooter: FC<ModalFooterProps> = ({
    footer,
    okButtonProps,
    cancelButtonProps,
    onCancel,
    cancelButtonText,
    okButtonText,
    onOk,
    loading,
}) => {
    return (
        <>
            {showDependOnType(
                footer,
                <div className={style.footer}>
                    <Button loading={loading} type={"primary"} htmlType={"submit"} onClick={onOk} {...okButtonProps}>
                        {okButtonText}
                    </Button>
                    <Button onClick={onCancel} {...cancelButtonProps}>
                        {cancelButtonText}
                    </Button>
                </div>
            )}
        </>
    );
};
