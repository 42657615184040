import {Model} from "@aten/common/models/Model";
import {FormInstance} from "antd/es";

export const addModel = (dataAdded?: Model[], baseData?: Model[], form?: FormInstance) => {

    if (dataAdded && form) {
        const dataFiltered = [...(baseData??[])]?.reduce<Model[]>((acc, init )=> {
            const overlap = dataAdded?.find(cur => cur.modelName === init.modelName)
            if(overlap){
                return [...acc, {...init, quantity: overlap.quantity}]
            }
            return  acc
        }, [])

        const updated = form.getFieldValue("modelsList")?.filter((i: any)=>!dataFiltered?.find(w=>w.modelName === i.modelName))

        form.setFields([
            {
                name: "modelsList",
                value: [
                    ...(updated ?? []),
                    ...dataFiltered?.map((model) => ({
                        ...model,
                        quantity: model.quantity,
                        worth: model.price * model.quantity,
                    })) ?? "",
                ],
            },
        ]);

        const entireWorth = form.getFieldValue("modelsList").reduce((a: number, b: Model) => a + b.worth, 0);
        form.setFieldValue("orderSum", entireWorth);
    }
};
