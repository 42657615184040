import { Theme, ThemeResponse, ThemeTable } from "@aten/common/dist/models/Theme";
import serializer, { Serializer } from "serializers/index";

const themesToCourses: Serializer<ThemeResponse, Theme, ThemeTable> = (themes) => {
    return serializer<ThemeResponse, Theme, ThemeTable>(themes, (theme, i) => ({
        ...theme,
        orderNumber: ++i,
        key: theme.id,
    }));
};

export default themesToCourses;
