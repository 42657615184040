import { Manager, ManagerType } from "@aten/common/dist/models/Manager";
import { Button, Col, Input, Row, Tooltip } from "antd";
import { FormInstance } from "antd/es";
import cl from "assets/scss/Space.module.scss";
import { Item, List } from "components/Form/Form";
import { ListOptions } from "components/Form/ProjectFormEdit/ProjectFormEdit";
import clForm from "components/Form/ProjectFormEdit/ProjectFormEdit.module.scss";
import { Icons } from "components/Icons/Icons";
import InputWithMask from "components/Input/InputWithMask";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import SelectWithAdding from "components/Select/SelectWithAdding";
import React, { FC } from "react";
import concatClasses from "utils/concatClasses";
import { fullEntityName } from "utils/fullNameConverter";
import { emailValidate, phoneValidate, required } from "utils/getRules";

interface ManagerIntegrListProps {
    form: FormInstance;
    managerIntegrOptions: ListOptions<Manager>[];
    setDisableManagerOption: (managerType: ListOptions<Manager>[], managerTypeValue: 0 | 1) => void;
    setRemovedManagers: React.Dispatch<React.SetStateAction<number[]>>;
    deleteFromManagerOptions: (
        managerTypeValue: ManagerType,
        id?: number,
        managerType?: ListOptions<Manager>[]
    ) => void;
    edit?: string;
    isLoading?: boolean
}

const ManagerIntegrList: FC<ManagerIntegrListProps> = ({
    edit,
    form,
    managerIntegrOptions,
    setDisableManagerOption,
    setRemovedManagers,
    deleteFromManagerOptions,
                                                           isLoading
}) => {
    const addIntegrManager = () => {
        form.setFields([
            {
                name: "managerIntegrList",
                value: [...form.getFieldValue("managerIntegrList"), ""],
            },
        ]);
    };
    return (
        <>
            <List name="managerIntegrList">
                {(fields, {  remove }) => (
                    <>
                        {fields.map((field, idx) => {
                            return (
                                <Row
                                    className={concatClasses(
                                        cl.space,
                                        cl.space__between,
                                        clForm.Row,
                                        cl.space__alignCenter
                                    )}
                                    gutter={[30, 30]}
                                    key={field.key}>
                                    <Col span={8}>
                                        <div className={clForm.item}>
                                            <Label type={LabelTypes.Form} label={"ФИО менеджера"} />
                                            {edit ? (
                                                <span className={clForm.span}>
                                                    {fullEntityName(form.getFieldValue("managerIntegrList")[idx])}
                                                </span>
                                            ) : (
                                                <Item name={[field.name, "id"]} rules={[required()]}>
                                                    <SelectWithAdding
                                                        showSearch
                                                        options={managerIntegrOptions}
                                                        onChange={(value, option: any) => {
                                                            if(typeof option.value !== 'number' && option.value.includes('__new__')) {
                                                                const [surname, name, patronymic] = option.label.split(" ");
                                                                form.setFieldValue(
                                                                    ["managerIntegrList", field.name, "name"],
                                                                    name
                                                                );
                                                                form.setFieldValue(
                                                                    ["managerIntegrList", field.name, "patronymic"],
                                                                    patronymic ?? ""
                                                                );
                                                                form.setFieldValue(
                                                                    ["managerIntegrList", field.name, "surname"],
                                                                    surname
                                                                );
                                                                form.setFieldValue(
                                                                    ["managerIntegrList", field.name, "managerType"],
                                                                    0
                                                                );
                                                                setDisableManagerOption(managerIntegrOptions, 0);

                                                                return
                                                            }
                                                            form.setFieldValue(
                                                                ["managerIntegrList", field.name, "email"],
                                                                managerIntegrOptions?.[option.orderNumber]?.email
                                                            );

                                                            form.setFieldValue(
                                                                ["managerIntegrList", field.name, "phone"],
                                                                managerIntegrOptions?.[option.orderNumber]?.phone
                                                            );
                                                            const [name, surname, patronymic] = option.label.split(" ");

                                                            form.setFieldValue(
                                                                ["managerIntegrList", field.name, "name"],
                                                                name
                                                            );
                                                            form.setFieldValue(
                                                                ["managerIntegrList", field.name, "patronymic"],
                                                                patronymic ?? ""
                                                            );
                                                            form.setFieldValue(
                                                                ["managerIntegrList", field.name, "surname"],
                                                                surname
                                                            );
                                                            form.setFieldValue(
                                                                ["managerIntegrList", field.name, "managerType"],
                                                                0
                                                            );
                                                            form.setFieldValue(
                                                                ["managerIntegrList", field.name, "id"],
                                                                "id" in option ? option.id : option.label
                                                            );
                                                            setDisableManagerOption(managerIntegrOptions, 0);
                                                        }}
                                                    />
                                                </Item>
                                            )}
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div className={clForm.item}>
                                            <Label type={LabelTypes.Form} label={"E-mail менеджера"} />
                                            {edit ? (
                                                <span className={clForm.span}>
                                                    {form.getFieldValue("managerIntegrList")[idx].email}
                                                </span>
                                            ) : (
                                                <Item
                                                    name={[field.name, "email"]}
                                                    rules={[emailValidate(), required()]}>
                                                    <Input
                                                        disabled={form.getFieldValue("managerIntegrList")[idx].email}
                                                    />
                                                </Item>
                                            )}
                                        </div>
                                    </Col>
                                    <Col span={edit ? 8 : 7}>
                                        <div className={clForm.item}>
                                            <Label type={LabelTypes.Form} label={"Телефон менеджера"} />
                                            {edit ? (
                                                <span className={clForm.span}>
                                                    {form.getFieldValue("managerIntegrList")[idx].phone}
                                                </span>
                                            ) : (
                                                <Item
                                                    rules={[phoneValidate(), required()]}
                                                    name={[field.name, "phone"]}>
                                                    <InputWithMask
                                                        mask="+7 999 999 99 99"
                                                        disabled={form.getFieldValue("managerIntegrList")[idx].phone}
                                                    />
                                                </Item>
                                            )}
                                        </div>
                                    </Col>
                                    {!edit && (
                                        <Col span={1}>
                                            <Icons.Delete
                                                onClick={() => {
                                                    const id = form.getFieldValue([
                                                        "managerIntegrList",
                                                        field.name,
                                                        "id",
                                                    ]);
                                                    setRemovedManagers((prevState) => [...prevState, id]);
                                                    deleteFromManagerOptions(0, id, managerIntegrOptions);
                                                    remove(idx);
                                                }}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            );
                        })}
                    </>
                )}
            </List>
            {!edit && (
                <Row>
                    <Col>
                        <Tooltip title={!form.getFieldValue("integratorId") ? "Выберите интегратора" : ""}>
                            <Button disabled={!form.getFieldValue("integratorId") || isLoading} loading={isLoading} onClick={addIntegrManager}>
                                Добавить менеджера
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default ManagerIntegrList;
