import buildUrl from "@aten/admin/src/utils/buildUrl";

export enum PublicPath {
    Login = "/login",

    All = "/*",
    NotFound = "/404",
}

export enum AuthorizedPath {
    List = "list",
    Edit = ":id/edit",
    New = "new",
    ForUser = ":userId",

    Index = "",
    Home = "/",
    Projects = "/projects",
    Users = "/users",
    Courses = "/courses",
    Roles = "/roles",
    Lessons = "/lessons",

    Tests = "/tests",

    OfflineEvents = "/offlineEvents",

    Certificates = "/certificates",

    Catalogues = "/catalogues",

    Themes = "/themes",

    PriceLists = "/price",
    Other = "other",
    Own = "own",

    Cooperation = "/cooperation",

    News = "/site/news",

    Advantages = "/site/advantages",

    Members = "/site/members",

    NotFound = "/404",
    All = "/*",
}

export const authUrl = buildUrl(AuthorizedPath, {
    Index: {
        Home: {},
        Projects: {
            List: {},
            Edit: {},
            New: {},
            ForUser: {},
        },
        Roles: {
            List: {},
        },
        Users: {
            List: {},
            Edit: {},
            New: {},
        },
        Courses: {
            List: {},
            Edit: {},
            New: {},
            ForUser: {},
        },
        Lessons: {
            List: {},
            Edit: {},
            New: {},
        },
        Tests: {
            List: {},
            Edit: {},
            New: {},
        },
        OfflineEvents: {
            List: {},
            Edit: {},
            New: {},
            ForUser: {},
        },
        Certificates: {
            List: {},
        },
        Catalogues: {
            List: {},
            Edit: {},
            New: {},
        },
        Themes: {
            List: {},
            Edit: {},
            New: {},
        },
        PriceLists: {
            Own: {},
            Other: {},
        },
        Cooperation: {},
        News: {
            List: {},
            Edit: {},
            New: {},
        },
        Advantages: {
            List: {},
            Edit: {},
            New: {},
        },
        Members: {
            List: {},
            Edit: {},
            New: {},
        },
    },

    NotFound: {},
    All: {},
});

export const publicUrl = buildUrl(PublicPath, {
    Login: {},

    NotFound: {},
    All: {},
});
