import { Tooltip as TooltipAnt, TooltipProps } from "antd";
import React from "react";

import st from "./Tooltip.module.scss";

type ToolProps = TooltipProps & {};

const Tooltip = ({ title, children }: ToolProps) => {
    return (
        <TooltipAnt title={title} className={st.tool}>
            <div>{children}</div>
        </TooltipAnt>
    );
};

export default Tooltip;
