import { Course, CourseResponse } from "@aten/common/dist/models/Course";
import { CourseUsersWithProgress } from "@aten/common/models/Course";
import { UrlApi } from "config/api";
import { IPagination } from "hooks/usePagination";
import { commonApi } from "services/commonApiService";
import { GetCourseProgressArgs } from "types/apiTypes";

export const coursesApi = commonApi.injectEndpoints({
    endpoints: (build) => ({
        getCourses: build.query<
            CourseResponse,
            { pagination?: IPagination; SearchParams?: string; userId?: number; IsNeedStatus?: boolean } | void
        >({
            query: ({ pagination, SearchParams, userId, IsNeedStatus } = {}) => ({
                url: `${UrlApi.Courses}${SearchParams ?? ""}`,
                params: {
                    ...pagination,
                    userId,
                    IsNeedStatus,
                },
            }),
            providesTags: ["Course"],
        }),
        getCoursesByUserId: build.query<
            CourseResponse,
            { userId?: string; pagination?: IPagination; SearchParams?: string } | void
        >({
            query: ({ userId, pagination, SearchParams } = {}) => ({
                url: `${UrlApi.CoursesToUsers}${SearchParams ?? ""}`,
                params: {
                    userId,
                    ...pagination,
                },
            }),
            transformResponse: (res: CourseResponse) => {
                return {
                    objects: res.objects.map((item: any) => item.course),
                    total: res.total,
                    requestedObjectsCount: res.requestedObjectsCount,
                    requestedStartIndex: res.requestedStartIndex,
                };
            },
            providesTags: ["Course"],
        }),
        getCourseProgress: build.query<CourseUsersWithProgress, GetCourseProgressArgs | void>({
            query: ({ courseId, pagination, searchParams }: GetCourseProgressArgs) => ({
                url: UrlApi.CourseProgress,
                params: {
                    courseId,
                    ...searchParams,
                    ...pagination,
                },
            }),
        }),
        getCourseById: build.query<Course, string>({
            query: (id: string) => `${UrlApi.Courses}/${id}`,
            providesTags: ["Course"],
        }),
        editCourse: build.mutation<CourseResponse , Course>({
            query: (body: Course) => ({
                url: `${UrlApi.Courses}/${body.id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: ["Course"],
        }),
        setCourse: build.mutation<{ addedId: number }, Course>({
            query: (body: Course) => ({
                url: UrlApi.Courses,
                method: "POST",
                body,
            }),
            invalidatesTags: ["Course"],
        }),
        deleteCourse: build.mutation<CourseResponse, string>({
            query: (id: string) => ({
                url: `${UrlApi.Courses}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Course"],
        }),
    }),
});

export const {
    useGetCoursesQuery,
    useGetCourseByIdQuery,
    useGetCoursesByUserIdQuery,
    useGetCourseProgressQuery,
    useEditCourseMutation,
    useSetCourseMutation,
    useDeleteCourseMutation,
} = coursesApi;
