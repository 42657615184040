import { PriceList } from "@aten/common/models/PriceList";
import { ColumnsType } from "antd/es/table";
import style from "pages/PriceLists/PriceListsTable/PriceListsTable.module.scss";
import React, { CSSProperties } from "react";
import concatClasses from "utils/concatClasses";
import render from "utils/helpers/render";

interface PriceListCollapseRowProps<U> {
    columns?: ColumnsType<U> | undefined;
    data?: PriceList[];
    tableCellWidth: number;
}

interface RowRenderer {
    style: CSSProperties;
    index: number;
}

const getColorByPriceListCond = (priceList?: PriceList) => {
    if (priceList?.isEol) return "#FCE7EE";
    if (priceList?.isRetal) return "#F0FCE6";

    return undefined;
};

const PriceListCollapseRow = <U,>({ data, columns, tableCellWidth }: PriceListCollapseRowProps<U>) => {
    return ({ index, style: styles }: RowRenderer) => {
        const priceList = data?.[index];

        return (
            <div
                className={style.table_tbody_row}
                style={{ ...styles, background: getColorByPriceListCond(priceList) }}
                key={index}>
                {columns?.map((column, index) => {
                    return column.key ? (
                        <div
                            key={index ?? column.key}
                            className={concatClasses(style.table_cell, style.table_tbody_cell)}
                            style={{
                                width: column?.width ?? tableCellWidth,
                                maxWidth: column?.width ?? tableCellWidth,
                                minWidth: column?.width ?? tableCellWidth,
                            }}>
                            {column?.render?.((priceList as any)[column.key], priceList as U, index) ||
                                (priceList as any)[(column as any).key] ||
                                render.dash}
                        </div>
                    ) : null;
                })}
            </div>
        );
    };
};

export default PriceListCollapseRow;
