import TestFormEdit from "components/Form/TestsFormEdit/TestsFormEdit";
import useLayout from "hooks/useLayout";
import { useParams } from "react-router-dom";
import { useGetTestByIdQuery } from "services/testsService";

const TestEdit = () => {
    const { id } = useParams();

    const { data, isLoading } = useGetTestByIdQuery(id ?? "", { skip: !id });

    useLayout(
        {
            title: data?.title ?? "Добавить новый тест",
            loading: isLoading,
        },
        [data]
    );

    return <TestFormEdit data={data}></TestFormEdit>;
};

export default TestEdit;
