import { Button, Col, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import cl from "assets/scss/Space.module.scss";
import clForm from "components/Form/ProjectFormEdit/ProjectFormEdit.module.scss";
import React, { FC, useEffect, useState } from "react";
import concatClasses from "utils/concatClasses";

let counter = 0;

interface ModelTextAreaProps {
    addModel: (param: any) => void;
    open?: ()=>void
}

const ModelTextArea: FC<ModelTextAreaProps> = ({ addModel, open }) => {
    const [state, setState] = useState<string>("");
    const [arr, setArr] = useState<any>([]);
    useEffect(() => {
        const data = state.split("\n");
        const models = data.map((row) => {
            const cells = row.split("\t");
            return {
                modelName: cells[0].trim(),
                quantity: !isNaN(+cells[1]) ? +cells[1] : 0,
            };
        }).filter(el=>!!el.modelName);
        setArr(models);
    }, [state]);

    return (
        <Row className={concatClasses(cl.space, cl.space__between, cl.space__margBot)} gutter={[30, 30]}>
            <Col span={16}>
                <TextArea
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Tab") {
                            e.preventDefault();
                            setState((prevState) => prevState + (counter === 2 ? "\n" : "\t"));
                            counter === 2 ? (counter = 1) : counter++;
                            return;
                        }
                    }}
                    className={clForm.textarea150px}
                />
            </Col>
            <Col span={8}>
                <Button
                    disabled={!state}
                    onClick={() => {
                        addModel(arr);
                        open?.()
                        setState("");
                        counter = 0;
                    }}>
                    Добавить в список
                </Button>
            </Col>
        </Row>
    );
};

export default ModelTextArea;
