import concatClasses from "utils/concatClasses";
import cl from "../Label/Label.module.scss";

export type TLabelTypes = keyof typeof typeToClass;

export enum LabelTypes {//TODO enum тип нужно в UpperCase, ключи аналогично - сделал
  Default = "Default",
  Form = "Form",
  Gray = "Gray",
}

export const typeToClass = {
  [LabelTypes.Default]: cl.label,
  [LabelTypes.Form]: concatClasses(cl.label, cl.label_form),
  [LabelTypes.Gray]: concatClasses(cl.label, cl.label_gray),
};
