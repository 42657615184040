import { News, NewsTable } from "@aten/common/dist/models/News";
import { ColumnsType } from "antd/lib/table";
import { Icons } from "components/Icons/Icons";
import { authUrl } from "config/path";
import { DeletingNewsProps } from "pages/News/News";
import cl from "pages/News/News.module.scss";
import { generatePath, Link } from "react-router-dom";
import { numToDate } from "utils/dateConverter";

type ColumnsProps = {
    setDeletingNews: (props: DeletingNewsProps) => void;
    setDeleteModal: (isOpen: boolean) => void;
};

const newsColumnsTable = (props: ColumnsProps): ColumnsType<NewsTable> => [
    {
        width: "7%",
        title: "ID",
        dataIndex: "id",
        key: "id",
        render: (_: any, data: News) => (
            <Link className={cl.editById} to={generatePath(authUrl.Index.News.Edit.url, { id: data.id.toString() })}>
                #{data.id}
            </Link>
        ),
    },
    { title: "Заголовок", dataIndex: "title", key: "title" },
    { title: "Описание", dataIndex: "text", key: "text" },
    {
        title: "Дата публикации",
        dataIndex: "publicationDate",
        key: "publicationDate",
        render: (_: any, data: News) => numToDate(data.publicationDate),
    },
    {
        title: "Действия",
        dataIndex: "action",
        key: "action",
        render: (_: any, data: News) => {
            return (
                <div style={{ display: "flex" }}>
                    <div style={{ marginRight: 10 }}>
                        <Link
                            to={generatePath(authUrl.Index.News.Edit.url, {
                                id: data.id.toString(),
                            })}>
                            <Icons.EditButton />
                        </Link>
                    </div>
                    <div>
                        <Icons.Delete
                            onClick={() => {
                                props.setDeletingNews({
                                    id: data.id.toString(),
                                    title: data.title,
                                });
                                props.setDeleteModal(true);
                            }}
                        />
                    </div>
                </div>
            );
        },
    },
];

export default newsColumnsTable;
