import { NewsFormEdit } from "components/Form/NewsFormEdit/NewsFormEdit";
import useLayout from "hooks/useLayout";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useGetNewsByIdQuery } from "services/newsServices";

const NewsEdit: FC = () => {
    const { id } = useParams();

    const { data, isLoading } = useGetNewsByIdQuery(id ?? "", { skip: !id });

    useLayout(
        {
            title: data?.title ?? "Добавить новость",
            loading: isLoading,
        },
        [data]
    );

    return <NewsFormEdit data={data} />;
};

export default NewsEdit;
