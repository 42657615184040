import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import { UploadFile } from "antd/es";
import { RcFile, UploadProps } from "antd/lib/upload";
import useModal from "hooks/useModal";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useDonwloadFileAndGetLinkMutation } from "services/fileService";
import { getBase64, newPictureUrl } from "utils/fileHelper";

export interface IUploadImages extends UploadProps {
    fileList: CustomUploadFile[];
    setFileList: Dispatch<SetStateAction<CustomUploadFile[]>>;
    onRemove: (file: CustomUploadFile) => void;
}

export interface CustomUploadFile extends UploadFile {
    customStatus?: string;
}

export const UploadImages: FC<IUploadImages> = ({ fileList, setFileList, onRemove, ...props }) => {
    const { open, close, visible } = useModal();
    const [previewImage, setPreviewImage] = useState<string>("");
    const [previewTitle, setPreviewTitle] = useState<string>("");

    const [donwloadFileAndGetLink] = useDonwloadFileAndGetLinkMutation();

    const handlePreview = async (file: CustomUploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        open();
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1));
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div>Загрузить</div>
        </div>
    );

    const onBeforeUpload: UploadProps["beforeUpload"] = async (file) => {
        const addedFile = await donwloadFileAndGetLink(file).unwrap();
        setFileList([...fileList, newPictureUrl(addedFile)]);
        return false;
    };

    const removeImage = (file: CustomUploadFile) => {
        if (file.customStatus === "done") {
            onRemove(file)
        } else {
            setFileList((prevState) => prevState?.filter((f) => f.uid !== file.uid));
        }
    };

    return (
        <>
            <Upload
                onRemove={removeImage}
                beforeUpload={onBeforeUpload}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                {...props}>
                {uploadButton}
            </Upload>
            <Modal open={visible} title={previewTitle} footer={false} onCancel={close}>
                <img src={previewImage} alt="previewImg" style={{ width: "100%", marginBottom: "30px" }} />
            </Modal>
        </>
    );
};
