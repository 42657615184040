import { Course, CourseTable } from "@aten/common/dist/models/Course";
import { ColumnsType } from "antd/es/table";
import { Icons } from "components/Icons/Icons";
import CourseUserProgressStatus from "components/CourseUserProgressStatus/CourseUserProgressStatus";
import { authUrl } from "config/path";
import { DeletingCoursesProps } from "pages/Courses/Courses";
import { generatePath, Link } from "react-router-dom";
import { numToDate } from "utils/dateConverter";
import { getCourseUserStatus } from "components/CourseUserProgressStatus/lib";

type ColumnsProps = {
    setDeletingCourse: (props: DeletingCoursesProps) => void;
    openDelete: () => void;
};

const tableColumns = (props: ColumnsProps): ColumnsType<CourseTable> => [
    {
        width: "7%",
        title: "ID",
        dataIndex: "id",
        key: "id",
    },
    { title: "Название", dataIndex: "title", key: "title" },
    {
        title: "Текстовое описание курса",
        dataIndex: "shortDescription",
        key: "shortDescription",
    },
    {
        title: "Дата создания",
        dataIndex: "dateOfCreation",
        key: "dateOfCreation",
        render: (_, data: Course) => numToDate(data.dateOfCreation),
    },
    {
        title: "Статус",
        dataIndex: "statusId",
        key: "statusId",
        render: (_, { progressOfCurrentUserInPercentage }: Course) => (
            <CourseUserProgressStatus status={getCourseUserStatus(progressOfCurrentUserInPercentage)} />
        ),
    },

    {
        width: "10%",
        title: "Действия",
        dataIndex: "action",
        key: "action",
        render: (_, data: Course) => {
            return (
                <div style={{ display: "flex" }}>
                    <div style={{ marginRight: 10 }}>
                        <Link
                            to={generatePath(authUrl.Index.Courses.Edit.url, {
                                id: data.id.toString(),
                            })}>
                            <Icons.EditButton />
                        </Link>
                    </div>
                    <div style={{ marginRight: 10 }}>
                        <Icons.Delete
                            onClick={() => {
                                props.setDeletingCourse({ id: data.id.toString(), title: data.title ?? "" });
                                props.openDelete();
                            }}
                        />
                    </div>
                </div>
            );
        },
    },
];

export default tableColumns;
