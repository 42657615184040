import { Col, Input, Row } from "antd";
import cl from "assets/scss/Space.module.scss";
import Form, { Item, useForm } from "components/Form/Form";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import ModalSearch from "components/Modal/ModalSearch";
import { FC } from "react";
import concatClasses from "utils/concatClasses";
import { filterQueryString } from "utils/filterQueryString";

interface LessonsModalFormProps {
    onOk: (values: string) => void;
    onCancel: () => void;
    visible: boolean;
}

const LessonsModalForm: FC<LessonsModalFormProps> = ({ onOk, onCancel, visible }) => {
    const [form] = useForm();

    const onFinish = () => {
        const formData = form.getFieldsValue();
        onOk(filterQueryString(formData));
        onCancel();
    };

    const onClose = () => {
        form.resetFields();
        onOk("");
        onCancel();
    };

    return (
        <ModalSearch open={visible} onCancel={onClose} onOk={onFinish}>
            <Form form={form}>
                <Row className={concatClasses(cl.space)} gutter={[30, 30]}>
                    <Col span={12}>
                        <Item name="LessonId" label={<Label type={LabelTypes.Form} label={"ID"} />}>
                            <Input />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item name="title" label={<Label type={LabelTypes.Form} label={"Название"} />}>
                            <Input />
                        </Item>
                    </Col>
                </Row>
            </Form>
        </ModalSearch>
    );
};
export default LessonsModalForm;
