import { PriceList } from "@aten/common/models/PriceList";
import { Col, ColProps, FormItemProps, Input, Row, Select } from "antd";
import usePage from "components/Contexts/PageContext";
import Form, { Item, useForm } from "components/Form/Form";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import Modal, { ModalProps } from "components/Modal/Modal";
import { convertToForm, convertToRequest } from "pages/PriceLists/utils/convertFormValues";
import getColSpanForCurrentWindow from "pages/PriceLists/utils/getColSpanForCurrentWindow";
import React, { FC, Key, useEffect, useMemo } from "react";
import { useChangePriceListMutation } from "services/priceListsService";
import { booleanNullOptions } from "staticContent/options";
import { isNumber, required, urlsValidate } from "utils/getRules";

interface ModalEditProps extends ModalProps {
    data?: Partial<PriceList>;
}

const ModalEdit: FC<ModalEditProps> = ({ data, onCancel, open, ...props }) => {
    const [form] = useForm<Partial<PriceList>>();
    const { windowSize } = usePage();

    const [editPriceList, { isLoading }] = useChangePriceListMutation();

    useEffect(() => {
        data &&
            form.setFieldsValue(
                convertToForm(data, {
                    type: [
                        "isEac",
                        "isEacbefore",
                        "isEol",
                        "isRetal",
                        "isNotification",
                        "isNotificationBefore",
                        "isMarketing",
                    ],
                })
            );
    }, [data, open]);

    const colSpan = useMemo(() => getColSpanForCurrentWindow(windowSize), [windowSize.width]);

    const onFinish = async () => {
        const values = convertToRequest(form.getFieldsValue(), {
            type: ["isEac", "isEacbefore", "isEol", "isRetal", "isNotification", "isNotificationBefore", "isMarketing"],
        });

        await editPriceList({ body: { ...values, id: data?.id } }).then(() => {
            onCancel?.();
        });
    };

    return (
        <Modal
            title={`Редактировать прайс-лист "${data?.name}"`}
            onOk={onFinish}
            onCancel={onCancel}
            loading={isLoading}
            okButtonText={"Сохранить"}
            width={1200}
            open={open}
            {...props}>
            <Form form={form}>
                <Row
                    gutter={[
                        { xs: 10, sm: 15, md: 20 },
                        { xs: 10, sm: 15, md: 20 },
                    ]}>
                    <ColItem colSpan={colSpan} name={"modelName"} label={"Модель"} rules={[required()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"partNumber"} label={"P/N"} rules={[required()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"fcost"} label={"Цена_F"} rules={[required(), isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"engName"} label={"Name"} rules={[required()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"name"} label={"Наименование"} rules={[required()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"link"} label={"Ссылка"} rules={[required(), urlsValidate()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"isEol"} label={"EOL"} rules={[required()]}>
                        <Select options={booleanNullOptions} />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"guaranteePeriod"} label={"Гарантия"} rules={[required()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"isEac"} label={"EAC"} rules={[required()]}>
                        <Select options={booleanNullOptions} />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"isEacbefore"} label={"EAC до"} rules={[required()]}>
                        <Select options={booleanNullOptions} />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"isNotification"} label={"Нотификация"} rules={[required()]}>
                        <Select options={booleanNullOptions} />
                    </ColItem>
                    <ColItem
                        colSpan={colSpan}
                        name={"isNotificationBefore"}
                        label={"Нотификация до"}
                        rules={[required()]}>
                        <Select options={booleanNullOptions} />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"lengthSu"} label={"Length(SU)"} rules={[required(), isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"widthSu"} label={"Width(SU)"} rules={[required(), isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"heightSu"} label={"Height(SU)"} rules={[required(), isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"weightSu"} label={"Weight(SU)"} rules={[required(), isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem
                        colSpan={colSpan}
                        name={"lengthSuone"}
                        label={"Length(SU1)"}
                        rules={[required(), isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem
                        colSpan={colSpan}
                        name={"widthSuone"}
                        label={"Width(SU1)"}
                        rules={[required(), isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem
                        colSpan={colSpan}
                        name={"heightSuone"}
                        label={"Height(SU1)"}
                        rules={[required(), isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem
                        colSpan={colSpan}
                        name={"weightSuone"}
                        label={"Weight(SU1)"}
                        rules={[required(), isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"lengthBu"} label={"Length(BU)"} rules={[required(), isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"widthBu"} label={"Width(BU)"} rules={[required(), isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"heightBu"} label={"Height(BU)"} rules={[required(), isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"nwbu"} label={"N,W,(BU)"} rules={[required(), isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"gwbu"} label={"G,W,(BU)"} rules={[required(), isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"base"} label={"Base"} rules={[required()]}>
                        <Input />
                    </ColItem>
                    <ColItem
                        colSpan={colSpan}
                        name={"lengthCar"}
                        label={"Length(CAR)"}
                        rules={[required(), isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"widthCar"} label={"Width(CAR)"} rules={[required(), isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem
                        colSpan={colSpan}
                        name={"heightCar"}
                        label={"Height(CAR)"}
                        rules={[required(), isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"nwcar"} label={"N,W(CAR)"} rules={[required(), isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"gwcar"} label={"G,W,(CAR)"} rules={[required(), isNumber()]}>
                        <Input />
                    </ColItem>
                    <ColItem
                        colSpan={colSpan}
                        name={"manufacturerCountry"}
                        label={"Страна производства"}
                        rules={[required()]}>
                        <Input />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"isRetal"} label={"Розница"} rules={[required()]}>
                        <Select options={booleanNullOptions} />
                    </ColItem>
                    <ColItem colSpan={colSpan} name={"isMarketing"} label={"Маркетинг"} rules={[required()]}>
                        <Select options={booleanNullOptions} />
                    </ColItem>
                </Row>
            </Form>
        </Modal>
    );
};

export default ModalEdit;

interface ColItemProps extends FormItemProps {
    colSpan?: Key;
    colProps?: ColProps;
}

export const ColItem: FC<ColItemProps> = ({ colSpan, colProps, label, ...props }) => {
    return (
        <Col span={colSpan} {...colProps}>
            <Item label={<Label type={LabelTypes.Form} label={label} />} {...props} />
        </Col>
    );
};
