import React, { FC } from "react";
import cl from "./Logo.module.scss";
import logoDefault from "assets/images/aten-logo.svg";
import logoLogin from "assets/images/logo.svg";

const logos = {
  default: logoDefault,
  login: logoLogin,
};

interface ILogo {
  type?: keyof typeof logos;
}

const Logo: FC<ILogo> = ({ type = "default" }) => {
  const classes = type === "login" ? [cl.logo, cl.logo__login].join(" ") : cl.logo;

  return (
    <div className={classes}>
      <img src={logos[type]} alt="ATEN" />
    </div>
  );
};

export default Logo;
