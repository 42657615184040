import { PriceList, PriceListsWrapper } from "@aten/common/models/PriceList";
import { PriceListParentCategory } from "@aten/common/models/PriceListsCategories";
import { Collapse, Empty } from "antd";
import { ColumnsType } from "antd/es/table";
import Loader from "components/Loader/Loader";
import PriceListCollapseHeader from "pages/PriceLists/PriceListsTable/PriceListCollapseHeader";
import PriceListCollapseRow from "pages/PriceLists/PriceListsTable/PriceListCollapseRow";
import style from "pages/PriceLists/PriceListsTable/PriceListsTable.module.scss";
import { RefObject, useEffect, useState } from "react";
import { List } from "react-virtualized";
import {
    useGetPriceListByParentCategoryQuery,
    useLazyGetPriceListByParentCategoryQuery,
} from "services/priceListsService";
import concatClasses from "utils/concatClasses";
import { sorter } from "../utils/sorter";
import { ISort } from "./PriceListsTable";

interface PriceListsCollapseProps<T extends PriceListParentCategory, U extends PriceList> {
    columns: ColumnsType<U> | undefined;
    tableCellWidth: number;
    category: T;
    wrapperRef: RefObject<HTMLDivElement>;
    className: string;
    isOther: boolean;
    searchParams?: string;
    setMainLoading?: (value: boolean) => void;
    width: number;
    sortOptions: ISort;
}

const PriceListsCollapse = <T extends PriceListParentCategory, U extends PriceList>({
    columns,
    tableCellWidth,
    category,
    wrapperRef,
    className,
    isOther,
    searchParams,
    setMainLoading,
    width,
    sortOptions,
}: PriceListsCollapseProps<T, U>) => {
    const [activeId, setActiveId] = useState<number | undefined>();
    const [data, setData] = useState<PriceListsWrapper | undefined>();
    const [sortedData, setSortedData] = useState<PriceList[] | undefined>();

    const [getPriceLists, { isLoading }] = useLazyGetPriceListByParentCategoryQuery();
    const { data: priceLists, isFetching } = useGetPriceListByParentCategoryQuery(
        { parentCategoryId: category?.id ?? 0, showNativeType: !isOther, searchParams },
        { skip: !category?.id || data === undefined }
    );

    useEffect(() => {
        setMainLoading?.(isFetching);
    }, [isFetching]);

    useEffect(() => {
        if (data !== undefined) {
            setData(priceLists);
            setSortedData(data.priceLists);
        }
    }, [priceLists, data]);

    useEffect(() => {
        sortOptions.order !== undefined
            ? setSortedData((prevSortedData) => prevSortedData?.slice(0)?.sort(returnSorted))
            : setSortedData(data?.priceLists);
    }, [sortOptions, data]);

    const returnSorted = (a: PriceList, b: PriceList) => {
        const sortedValues: any =
            sortOptions.order === "asc"
                ? {
                      aValue: a[sortOptions?.column?.key as keyof PriceList],
                      bValue: b[sortOptions?.column?.key as keyof PriceList],
                  }
                : {
                      aValue: b[sortOptions?.column?.key as keyof PriceList],
                      bValue: a[sortOptions?.column?.key as keyof PriceList],
                  };

        if (sortOptions.column?.typeSort === "boolean") return sorter.boolean(sortedValues.aValue, sortedValues.bValue);
        if (sortOptions.column?.typeSort === "date")
            return sorter.byMomentDate(sortedValues.aValue, sortedValues.bValue);
        if (sortOptions.column?.typeSort === "number") return sorter.numeric(sortedValues.aValue, sortedValues.bValue);
        if (sortOptions.column?.typeSort === "string")
            return sorter.lexicographic(sortedValues.aValue, sortedValues.bValue);

        return 0;
    };

    const onChange = async () => {
        data === undefined &&
            (await getPriceLists({ parentCategoryId: Number(category?.id), showNativeType: !isOther, searchParams })
                .unwrap()
                .then((response) => {
                    setData(response);
                }));

        setActiveId(activeId ? undefined : category?.id);
    };

    return (
        <Collapse
            bordered={false}
            onChange={onChange}
            className={concatClasses(style.collapse, className)}
            activeKey={activeId}>
            <Collapse.Panel
                showArrow={false}
                collapsible={isLoading ? "disabled" : undefined}
                className={style.collapse_panel}
                header={<PriceListCollapseHeader category={category} isLoading={isLoading} activeId={activeId} />}
                key={category?.id ?? 1}>
                <div className={concatClasses(style.collapse_mask, isFetching && style.collapse_mask_active)}>
                    <Loader
                        className={style.collapse_mask_loader}
                        style={{
                            left: wrapperRef.current?.offsetWidth ? wrapperRef.current?.offsetWidth / 2 : undefined,
                        }}
                    />
                </div>

                {!data?.priceLists?.length ? (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        style={{
                            width: wrapperRef.current?.offsetWidth ? wrapperRef.current?.offsetWidth - 8 : undefined,
                        }}
                    />
                ) : (
                    <List
                        className={style.virtualList}
                        width={width}
                        height={250}
                        rowCount={data?.priceLists?.length}
                        rowHeight={52}
                        rowRenderer={PriceListCollapseRow({
                            data: sortedData,
                            tableCellWidth,
                            columns,
                        })}
                    />
                )}
            </Collapse.Panel>
        </Collapse>
    );
};

export default PriceListsCollapse;
