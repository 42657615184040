import { CustomButton } from "components/CustomButton/CustomButton";
import { CustomTable } from "components/CustomTable/CustomTable";
import { Icons } from "components/Icons/Icons";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import ModalDelete from "components/Modal/ModalDelete";
import { authUrl } from "config/path";
import useFluid from "hooks/useFluid";
import useModal from "hooks/useModal";
import usePagination from "hooks/usePagination";

import certificatesColumnsTable from "pages/Advantages/advantagesColumnsTable";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { advantagesSerializer } from "serializers/advantages";
import { useDeleteAdvantagesMutation, useGetAdvantagesQuery } from "services/advantagesService";

export type DeletingAdvantagesProps = {
    title: string;
    id: string;
};
const AdvantagesPage: FC = () => {
    const { pagination, changePagination } = usePagination();
    const { getFluidTableHeight } = useFluid();

    const { open, visible, close } = useModal({});
    const [deletingAdvantages, setDeletingAdvantages] = useState<DeletingAdvantagesProps>();

    const { data, isLoading, isFetching } = useGetAdvantagesQuery({ pagination });

    const [deleteAdvantagesMutation, { isLoading: loadingDelete }] = useDeleteAdvantagesMutation();
    const advantages = advantagesSerializer(data);

    const deleteAdvantages = () => {
        deleteAdvantagesMutation(deletingAdvantages?.id ?? "").then(close);
    };
    return (
        <>
            <LayoutWrapper>
                <Link to={authUrl.Index.Advantages.New.url}>
                    <CustomButton type="primary" buttonText={"Добавить"} icon={<Icons.Plus />} />
                </Link>

                <CustomTable
                    data={advantages}
                    columns={certificatesColumnsTable({
                        open,
                        setDeletingAdvantages,
                    })}
                    pagination={{ total: data?.total, onChange: changePagination }}
                    loading={isLoading || isFetching}
                    scroll={{ y: getFluidTableHeight(340, 398) }}
                />
            </LayoutWrapper>
            <ModalDelete
                loading={loadingDelete}
                title={`Вы уверены, что хотите удалить новость "${deletingAdvantages?.title}"`}
                open={visible}
                onCancel={close}
                onOk={deleteAdvantages}
            />
        </>
    );
};

export default AdvantagesPage;
