import {
    priceListColumnNameToPropertyType,
    PriceListPropertySortList,
    PriceListPropertyType,
} from "@aten/common/dist/models/PriceListPropertySort";
import { PriceList } from "@aten/common/models/PriceList";
import { PriceListPropertySort } from "@aten/common/models/PriceListPropertySort";
import { Form, TableProps } from "antd";
import { Table } from "antd/es";
import { ColumnsType } from "antd/es/table";
import { ColumnTitle } from "antd/es/table/interface";
import { useForm } from "components/Form/Form";
import Modal, { ModalProps } from "components/Modal/Modal";
import update from "immutability-helper";
import DraggableBodyRow from "pages/PriceLists/PriceListSort/DraggableBodyRow";
import getColumns from "pages/PriceLists/PriceListSort/getColumns";
import React, { FC, HTMLAttributes, Key, useCallback, useEffect, useMemo, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import styles from "../PriceLists.module.scss";

export interface DataType extends PriceListPropertySort {
    key?: Key;
    title?: ColumnTitle<PriceList>;
}

interface PriceListSortProps extends ModalProps {
    columns: ColumnsType<PriceList>;
    propertySortList?: PriceListPropertySortList;
    onFinishSort: (data: PriceListPropertySortList) => Promise<void>;
}

export const getPropertyTypeByColumnName = (name: Key | undefined): PriceListPropertyType | undefined => {
    if (typeof name !== "string") return undefined;

    return priceListColumnNameToPropertyType[name];
};

const getDataFromMainTableColumns = (
    columns: ColumnsType<PriceList>,
    propertySortList?: PriceListPropertySortList
): DataType[] => {
    const hiddenFields: (Key | undefined)[] = ["actions"];

    return columns
        .filter(
            ({ key }) =>
                !hiddenFields.includes(key) &&
                !!propertySortList?.find((ps) => ps.propertyType === getPropertyTypeByColumnName(key))
        )
        .map<DataType>(({ key, title }) => {
            const propertyType = getPropertyTypeByColumnName(key);
            const propertySort = propertySortList?.find((ps) => ps.propertyType === propertyType);

            return {
                key: key,
                title,
                propertyType,
                ...propertySort,
            };
        })
        .sort((a, b) => (a?.orderNumber ?? 0) - (b?.orderNumber ?? 0));
};

const PriceListSort: FC<PriceListSortProps> = ({ columns, propertySortList, onFinishSort, ...props }) => {
    const [form] = useForm();

    const [dataSource, setDataSource] = useState<DataType[]>(getDataFromMainTableColumns(columns));
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

    useEffect(() => {
        const newData = getDataFromMainTableColumns(columns, propertySortList).sort(
            (a, b) => (a.orderNumber ?? 0) - (b.orderNumber ?? 0)
        );

        setDataSource(newData);
        setSelectedRowKeys(newData.filter((row) => row.isShow && row.key).map((row) => row.key) as Key[]);
    }, [columns, propertySortList]);

    const rowSelection = useMemo(
        () => ({
            selectedRowKeys,
            onChange: (selectedRowKeys: Key[]) => {
                setSelectedRowKeys(selectedRowKeys);

                setDataSource((dataSource) =>
                    dataSource.map(({ key, ...data }) => ({
                        key,
                        ...data,
                        isShow: !!key && selectedRowKeys.includes(key),
                    }))
                );
            },
        }),
        [selectedRowKeys, setDataSource]
    );

    const onOk = async () => {
        await onFinishSort(dataSource.map(({ key, title, ...row }) => row));
    };

    const components = {
        body: {
            row: DraggableBodyRow,
        },
    };

    const moveRow = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const dragRow = dataSource[dragIndex];
            setDataSource(
                update(dataSource, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                }).map((row, index) => ({
                    ...row,
                    orderNumber: index + 1,
                }))
            );
        },
        [dataSource]
    );

    const onRow: TableProps<DataType>["onRow"] = (_, index) => {
        return {
            index,
            moveRow,
        } as HTMLAttributes<any>;
    };

    return (
        <Modal {...props} onOk={onOk}>
            <Form form={form} onFinish={() => {}} layout={"vertical"} className={styles.sorting}>
                <DndProvider backend={HTML5Backend}>
                    <Table
                        components={components}
                        className={styles.sortTable}
                        rowClassName={() => "editable-row"}
                        rowSelection={rowSelection}
                        columns={getColumns()}
                        dataSource={dataSource}
                        pagination={false}
                        loading={false}
                        onRow={onRow}
                    />
                </DndProvider>
            </Form>
        </Modal>
    );
};

export default PriceListSort;
