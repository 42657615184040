import { ItemType } from "antd/lib/menu/hooks/useItems";
import arrayHelper from "utils/helpers/arrayHelper";

export type MenuItemOriginal = ItemType;
export type MenuItemsOriginal = MenuItemOriginal[];

export type MenuItemType = ItemType & {
    children?: MenuItemType[];
    url?: string;
    sideUrl?: boolean;
};
export type MenuItemsType = MenuItemType[];

interface UrlPartsMatchCount {
    matchCount: number;
    sideUrl: boolean;
    menuKey?: string;
}

const _getUrlPartsMatchCount = (matchCount: number, menuItem: MenuItemType): UrlPartsMatchCount => ({
    matchCount,
    menuKey: menuItem.key?.toString(),
    sideUrl: !!menuItem.sideUrl,
});

const getUrlPartsMatchCount = (menuItem: MenuItemType, pathname: string, separator: string = "/") => {
    const wordParts = pathname
        .replace(/^\//, "")
        .split(separator)
        .filter((i) => i !== "");
    const gluedParts = [];
    let matchCount = 0;

    if (!wordParts.length) return _getUrlPartsMatchCount(matchCount, menuItem);

    for (let part of wordParts) {
        gluedParts.push(part);

        if (menuItem.url?.match(new RegExp("(^|/)" + gluedParts.join(separator) + "($|" + separator + ")", "i"))) {
            matchCount++;
        } else return _getUrlPartsMatchCount(matchCount, menuItem);
    }

    return _getUrlPartsMatchCount(matchCount, menuItem);
};

const getActiveKey = (menuItems: MenuItemsType, pathname: string): string | undefined => {
    const flattenMenuItems = arrayHelper.flatten(menuItems);
    const matchedList = flattenMenuItems.map((item) => getUrlPartsMatchCount(item, pathname));

    return matchedList.reduce(
        (prev: UrlPartsMatchCount | undefined, current) =>
            current.matchCount > (prev?.matchCount ?? 0) && !current.sideUrl ? current : prev,
        undefined
    )?.menuKey;
};

const menuHelper = { getActiveKey };

export default menuHelper;
