import testSerializer from "serializers/test";
import { useGetTestsQuery } from "services/testsService";

type useGetTestOptionsResponse =
    | {
          key: number | undefined;
          value: number | undefined;
          label: string | number | null | undefined;
      }[]
    | undefined;

interface OptionsResponse {
    testsIdOptions: useGetTestOptionsResponse;
    testsLabelOptions: useGetTestOptionsResponse;
}

const useGetTestOptions = (): OptionsResponse => {
    const { data: tests } = useGetTestsQuery();
    const serializedTests = testSerializer(tests);

    return {
        testsIdOptions: serializedTests?.map((test) => ({
            key: test.id,
            value: test.id,
            label: test.id,
        })),
        testsLabelOptions: serializedTests?.map((test) => ({
            key: test.id,
            value: test.id,
            label: test.title,
        })),
    };
};

export default useGetTestOptions;
