import {toFullName} from "utils/fullNameConverter";

export const listToSelect = <R, >({
                               id,
                               name,
                               surname,
                               patronymic,
                           }: { id: number, name: string, surname: string, patronymic: string }, values: R) => ({
    value: id, label: toFullName({
        name,
        surname,
        patronymic
    }), ...values
})