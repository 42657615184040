import themesSerializer from "serializers/theme";
import { useGetThemesQuery } from "services/themeService";

type useGetThemeOptionsResponse =
    | {
          key: number | undefined;
          value: number | undefined;
          label: string | number | null | undefined;
      }[]
    | undefined;

interface OptionsResponse {
    themesIdOptions: useGetThemeOptionsResponse;
    themesLabelOptions: useGetThemeOptionsResponse;
}

const useGetThemeOptions = (): OptionsResponse => {
    const { data: themes } = useGetThemesQuery();
    const serializedThemes = themesSerializer(themes);

    return {
        themesIdOptions: serializedThemes?.map((theme) => ({
            key: theme.id,
            value: theme.id,
            label: theme.id,
        })),
        themesLabelOptions: serializedThemes?.map((theme) => ({
            key: theme.id,
            value: theme.id,
            label: theme.title,
        })),
    };
};

export default useGetThemeOptions;
