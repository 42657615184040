import { CustomButton } from "components/CustomButton/CustomButton";
import { CustomTable } from "components/CustomTable/CustomTable";
import { Icons } from "components/Icons/Icons";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import ModalDelete from "components/Modal/ModalDelete";
import { authUrl } from "config/path";
import useFluid from "hooks/useFluid";
import usePagination from "hooks/usePagination";
import newsColumnsTable from "pages/News/newsColumnsTable";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { newsSerializer } from "serializers/news";
import { useDeleteNewsMutation, useGetNewsQuery } from "services/newsServices";

export type DeletingNewsProps = {
    title: string;
    id: string;
};

const NewsPage: FC = () => {
    const { pagination, changePagination } = usePagination();
    const { getFluidTableHeight } = useFluid();

    const [deleteModal, setDeleteModal] = useState(false);
    const [deletingNews, setDeletingNews] = useState<DeletingNewsProps>({ id: "", title: "" });

    const { data, isLoading, isFetching } = useGetNewsQuery({
        pagination,
    });

    const news = newsSerializer(data);

    const [deleteNews] = useDeleteNewsMutation();

    return (
        <>
            <LayoutWrapper>
                <Link to={authUrl.Index.News.New.url}>
                    <CustomButton type="primary" buttonText={"Добавить"} icon={<Icons.Plus />} />
                </Link>

                <CustomTable
                    data={news}
                    columns={newsColumnsTable({
                        setDeleteModal,
                        setDeletingNews,
                    })}
                    paginationItem={{ total: data?.total, onChange: changePagination }}
                    loading={isLoading || isFetching}
                    scroll={{ y: getFluidTableHeight(340, 398) }}
                />
            </LayoutWrapper>
            <ModalDelete
                title={`Вы уверены, что хотите удалить новость "${deletingNews.title}"`}
                open={deleteModal}
                onCancel={() => setDeleteModal(false)}
                onOk={() => deleteNews(deletingNews.id).then(() => setDeleteModal(false))}
            />
        </>
    );
};

export default NewsPage;
