import React, { FC } from "react";
import { Button, Col, Input, Row, Select } from "antd";
import { Item, List } from "components/Form/Form";
import concatClasses from "utils/concatClasses";
import cl from "assets/scss/Space.module.scss";
import Label from "components/Label/Label";
import { LabelTypes } from "components/Label/labelTypes";
import { required } from "utils/getRules";
import { Model } from "@aten/common/models/Model";
import { Icons } from "components/Icons/Icons";
import { useRefreshState } from "utils/refreshState";

import clForm from "../../ProjectFormEdit.module.scss";
import st from "./ModelsList.module.scss";

interface ModalListTableProps {
    edit?: string;
    form: any;
    baseModelList?: Model[];
}

export const ModalListTable: FC<ModalListTableProps> = ({ edit, form, baseModelList }) => {
    const refresh = useRefreshState();
    const renderSum = () => {
        let sum = 0;
        form.getFieldValue("modelsList")?.forEach((el: Model) => {
            if (el.worth) {
                sum = sum + el.worth;
            }
        });
        return sum;
    };
    return (
        <div className={st.table}>
            <Row className={st.table__head}>
                {/*<Col className={st.table__elem} span={6}>*/}
                {/*    Модель*/}
                {/*</Col>*/}
                <Col className={st.table__elem} span={6}>
                    Наименование
                </Col>
                <Col className={st.table__elem} span={6}>
                    Цена
                </Col>
                <Col className={st.table__elem} span={6}>
                    Количество
                </Col>
                <Col className={st.table__elem} span={6}>
                    Стоимость
                </Col>
            </Row>
            <List name={"modelsList"}>
                {(fields, { add, remove }) => (
                    <>
                        <div className={st.table__body}>
                            {fields.map((field, idx) => {
                                const currentElem = form.getFieldValue("modelsList")[idx];

                                return (
                                    <div key={idx}>
                                        <Row
                                            className={concatClasses(
                                                cl.space,
                                                cl.space__between,
                                                cl.space__alignEnd,
                                                st.table__row,
                                                currentElem?.isEol && st.table__row_red,
                                                currentElem?.isRetal && st.table__row_green
                                            )}>
                                            <Col span={6} className={st.table__elem}>
                                                <div className={concatClasses(clForm.item, st.table__rowElem)}>
                                                    <Label type={LabelTypes.Form} />
                                                    {edit ? (
                                                        <span className={clForm.span}>
                                                            {form.getFieldValue("modelsList")[idx].modelName}
                                                        </span>
                                                    ) : (
                                                        <Item
                                                            className={st.item}
                                                            name={[field.name, "modelName"]}
                                                            rules={[required()]}>
                                                            <Select
                                                                filterOption={(input, option) =>
                                                                    input.length >= 3
                                                                        ? (option?.label ?? "")
                                                                              .toLowerCase()
                                                                              .includes(input.toLowerCase())
                                                                        : true
                                                                }
                                                                showSearch
                                                                onChange={(value, option: Model | Model[]) => {
                                                                    if (!Array.isArray(option)) {
                                                                        form.setFieldValue(
                                                                            ["modelsList", field.name, "id"],
                                                                            option.id
                                                                        );
                                                                        form.setFieldValue(
                                                                            ["modelsList", field.name, "modelId"],
                                                                            option.id
                                                                        );
                                                                        form.setFieldValue(
                                                                            ["modelsList", field.name, "modelName"],
                                                                            option.modelName
                                                                        );
                                                                        form.setFieldValue(
                                                                            ["modelsList", field.name, "name"],
                                                                            option.name
                                                                        );
                                                                        form.setFieldValue(
                                                                            ["modelsList", field.name, "worth"],
                                                                            option.worth
                                                                        );
                                                                        form.setFieldValue(
                                                                            ["modelsList", field.name, "quantity"],
                                                                            option.quantity
                                                                        );
                                                                        form.setFieldValue(
                                                                            ["modelsList", field.name, "price"],
                                                                            option.price
                                                                        );
                                                                        form.setFieldValue(
                                                                            ["modelsList", field.name, "isRetal"],
                                                                            option.isRetal
                                                                        );
                                                                        form.setFieldValue(
                                                                            ["modelsList", field.name, "isEol"],
                                                                            option.isEol
                                                                        );
                                                                    }
                                                                    refresh();
                                                                }}
                                                                options={baseModelList?.map(
                                                                    ({
                                                                        modelName,
                                                                        id,
                                                                        name,
                                                                        worth,
                                                                        price,
                                                                        quantity,
                                                                        projectId,
                                                                        isEol,
                                                                        isRetal,
                                                                    }) => {
                                                                        return {
                                                                            value: id,
                                                                            label: modelName,
                                                                            modelName,
                                                                            id,
                                                                            modelId: id,
                                                                            projectId,
                                                                            disabled: form
                                                                                .getFieldValue("modelsList")
                                                                                .find(
                                                                                    (el: any) =>
                                                                                        el.modelId === id ||
                                                                                        el.modelName === id
                                                                                ),
                                                                            name,
                                                                            worth,
                                                                            price,
                                                                            quantity,
                                                                            isEol,
                                                                            isRetal,
                                                                        };
                                                                    }
                                                                )}
                                                            />
                                                        </Item>
                                                    )}
                                                </div>
                                            </Col>
                                            {/*<Col className={st.table__elem} span={6}>*/}
                                            {/*    <div className={concatClasses(clForm.item, st.table__rowElem)}>*/}
                                            {/*        <Label type={LabelTypes.Form} />*/}
                                            {/*        {edit ? (*/}
                                            {/*            <span className={clForm.span}>*/}
                                            {/*                {form.getFieldValue("modelsList")[idx].name}*/}
                                            {/*            </span>*/}
                                            {/*        ) : (*/}
                                            {/*            <Item*/}
                                            {/*                className={st.item}*/}
                                            {/*                name={[field.name, "name"]}*/}
                                            {/*                rules={[required()]}>*/}
                                            {/*                <Input disabled />*/}
                                            {/*            </Item>*/}
                                            {/*        )}*/}
                                            {/*    </div>*/}
                                            {/*</Col>*/}

                                            <Col className={st.table__elem} span={6}>
                                                <div className={concatClasses(clForm.item, st.table__rowElem)}>
                                                    <Label type={LabelTypes.Form} />
                                                    {edit ? (
                                                        <span className={clForm.span}>
                                                            {form.getFieldValue("modelsList")[idx].price}
                                                        </span>
                                                    ) : (
                                                        <Item className={st.item} name={[field.name, "price"]}>
                                                            <Input
                                                                disabled
                                                                onChange={(e) => {
                                                                    if (
                                                                        +form.getFieldValue("modelsList")[idx].quantity
                                                                    ) {
                                                                        const worth =
                                                                            +e.target.value *
                                                                            +form.getFieldValue("modelsList")[idx]
                                                                                .quantity;
                                                                        form.setFieldValue(
                                                                            ["modelsList", idx, "worth"],
                                                                            worth
                                                                        );
                                                                        const entireWorth = form
                                                                            .getFieldValue("modelsList")
                                                                            .reduce(
                                                                                (a: number, b: Model) =>
                                                                                    !b.isEol &&
                                                                                    !b.isRetal &&
                                                                                    a + b.worth,
                                                                                0
                                                                            );
                                                                        form.setFieldValue("orderSum", entireWorth);
                                                                    }
                                                                }}
                                                                type={"number"}
                                                            />
                                                        </Item>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col className={st.table__elem} span={6}>
                                                <div className={concatClasses(clForm.item, st.table__rowElem)}>
                                                    <Label type={LabelTypes.Form} />
                                                    {edit ? (
                                                        <span className={clForm.span}>
                                                            {form.getFieldValue("modelsList")[idx].quantity}
                                                        </span>
                                                    ) : (
                                                        <>
                                                            <Item
                                                                className={st.item}
                                                                name={[field.name, "quantity"]}
                                                                rules={[
                                                                    required(""),
                                                                    {
                                                                        type: "number",
                                                                        min: 1,
                                                                        message: "",
                                                                        transform: Number,
                                                                    },
                                                                ]}>
                                                                <Input
                                                                    disabled={
                                                                        !form.getFieldValue("modelsList")[idx].modelName
                                                                    }
                                                                    onChange={(e) => {
                                                                        if (
                                                                            +form.getFieldValue("modelsList")[idx].price
                                                                        ) {
                                                                            const worth =
                                                                                +e.target.value *
                                                                                +form.getFieldValue("modelsList")[idx]
                                                                                    .price;
                                                                            form.setFieldValue(
                                                                                ["modelsList", idx, "worth"],
                                                                                worth
                                                                            );
                                                                            const entireWorth = form
                                                                                .getFieldValue("modelsList")
                                                                                .reduce((a: number, b: Model) => {
                                                                                    if (!b.isEol && !b.isRetal)
                                                                                        return a + b.worth;
                                                                                    return a;
                                                                                }, 0);
                                                                            form.setFieldValue("orderSum", entireWorth);
                                                                        }
                                                                    }}
                                                                    type={"number"}
                                                                />
                                                            </Item>
                                                        </>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col className={st.table__elem} span={6}>
                                                <div className={concatClasses(clForm.item, st.table__rowElem)}>
                                                    <Label type={LabelTypes.Form} />
                                                    {edit ? (
                                                        <span className={clForm.span}>
                                                            {form.getFieldValue("modelsList")[idx].worth}
                                                        </span>
                                                    ) : (
                                                        <>
                                                            <Item
                                                                className={st.item}
                                                                name={[field.name, "worth"]}
                                                                rules={[]}>
                                                                <Input disabled />
                                                            </Item>
                                                            <Icons.Delete
                                                                onClick={() => {
                                                                    remove(idx);
                                                                    const entireWorth = form
                                                                        .getFieldValue("modelsList")
                                                                        .reduce(
                                                                            (a: number, b: Model) => a + b.worth,
                                                                            0
                                                                        );
                                                                    form.setFieldValue("orderSum", entireWorth);
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={st.table__footer}>
                            <div>Расчет общей стоимости всех моделей согласно данным из таблицы</div>
                            <div>{renderSum()}</div>
                        </div>
                        {!edit && (
                            <Row
                                className={concatClasses(
                                    cl.space,
                                    cl.space__alignEnd,
                                    cl.space__margBot,
                                    cl.space__end,
                                    cl.space__margTop
                                )}
                                gutter={[30, 30]}>
                                <Col span={8}>
                                    <Button type="primary" onClick={() => add("", 0)}>
                                        <Icons.Plus />
                                        Добавить модели
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </List>
        </div>
    );
};
