import { LoadingOutlined } from "@ant-design/icons";
import { PriceListParentCategory } from "@aten/common/models/PriceListsCategories";
import Arrow from "components/Icons/Arrow";
import style from "pages/PriceLists/PriceListsTable/PriceListsTable.module.scss";
import React from "react";

interface PriceListCollapseHeaderProps<T extends PriceListParentCategory> {
    category: T;
    isLoading?: boolean;
    activeId?: number;
}

const PriceListCollapseHeader = <T extends PriceListParentCategory>({
    category,
    isLoading,
    activeId,
}: PriceListCollapseHeaderProps<T>) => {
    return (
        <div className={style.text}>
            {category?.name ?? "—"}
            <div className={style.expand_icon}>
                {isLoading ? <LoadingOutlined /> : <Arrow width={22} height={22} rotate={activeId ? 0 : 180} />}
            </div>
        </div>
    );
};

export default PriceListCollapseHeader;
