// models/PriceListPropertySort.ts
var PriceListPropertyType = /* @__PURE__ */ ((PriceListPropertyType2) => {
  PriceListPropertyType2[PriceListPropertyType2["PriceListsCategoryId"] = 1] = "PriceListsCategoryId";
  PriceListPropertyType2[PriceListPropertyType2["ModelId"] = 2] = "ModelId";
  PriceListPropertyType2[PriceListPropertyType2["PartNumber"] = 3] = "PartNumber";
  PriceListPropertyType2[PriceListPropertyType2["Fcost"] = 4] = "Fcost";
  PriceListPropertyType2[PriceListPropertyType2["DdpCost"] = 5] = "DdpCost";
  PriceListPropertyType2[PriceListPropertyType2["Sicost"] = 6] = "Sicost";
  PriceListPropertyType2[PriceListPropertyType2["LastThirtyDaysCostChangesPositive"] = 7] = "LastThirtyDaysCostChangesPositive";
  PriceListPropertyType2[PriceListPropertyType2["EngName"] = 8] = "EngName";
  PriceListPropertyType2[PriceListPropertyType2["Name"] = 9] = "Name";
  PriceListPropertyType2[PriceListPropertyType2["Link"] = 10] = "Link";
  PriceListPropertyType2[PriceListPropertyType2["IsEol"] = 11] = "IsEol";
  PriceListPropertyType2[PriceListPropertyType2["GuaranteePeriod"] = 12] = "GuaranteePeriod";
  PriceListPropertyType2[PriceListPropertyType2["ChangeDate"] = 13] = "ChangeDate";
  PriceListPropertyType2[PriceListPropertyType2["IsEac"] = 14] = "IsEac";
  PriceListPropertyType2[PriceListPropertyType2["IsEacbefore"] = 15] = "IsEacbefore";
  PriceListPropertyType2[PriceListPropertyType2["IsNotification"] = 16] = "IsNotification";
  PriceListPropertyType2[PriceListPropertyType2["IsNotificationBefore"] = 17] = "IsNotificationBefore";
  PriceListPropertyType2[PriceListPropertyType2["LengthSu"] = 18] = "LengthSu";
  PriceListPropertyType2[PriceListPropertyType2["WidthSu"] = 19] = "WidthSu";
  PriceListPropertyType2[PriceListPropertyType2["HeightSu"] = 20] = "HeightSu";
  PriceListPropertyType2[PriceListPropertyType2["WeightSu"] = 21] = "WeightSu";
  PriceListPropertyType2[PriceListPropertyType2["LengthSuone"] = 22] = "LengthSuone";
  PriceListPropertyType2[PriceListPropertyType2["WidthSuone"] = 23] = "WidthSuone";
  PriceListPropertyType2[PriceListPropertyType2["HeightSuone"] = 24] = "HeightSuone";
  PriceListPropertyType2[PriceListPropertyType2["WeightSuone"] = 25] = "WeightSuone";
  PriceListPropertyType2[PriceListPropertyType2["LengthBu"] = 26] = "LengthBu";
  PriceListPropertyType2[PriceListPropertyType2["WidthBu"] = 27] = "WidthBu";
  PriceListPropertyType2[PriceListPropertyType2["HeightBu"] = 28] = "HeightBu";
  PriceListPropertyType2[PriceListPropertyType2["Nwbu"] = 29] = "Nwbu";
  PriceListPropertyType2[PriceListPropertyType2["Gwbu"] = 30] = "Gwbu";
  PriceListPropertyType2[PriceListPropertyType2["Base"] = 31] = "Base";
  PriceListPropertyType2[PriceListPropertyType2["LengthCar"] = 32] = "LengthCar";
  PriceListPropertyType2[PriceListPropertyType2["WidthCar"] = 33] = "WidthCar";
  PriceListPropertyType2[PriceListPropertyType2["HeightCar"] = 34] = "HeightCar";
  PriceListPropertyType2[PriceListPropertyType2["Nwcar"] = 35] = "Nwcar";
  PriceListPropertyType2[PriceListPropertyType2["Gwcar"] = 36] = "Gwcar";
  PriceListPropertyType2[PriceListPropertyType2["ManufacturerCountry"] = 37] = "ManufacturerCountry";
  PriceListPropertyType2[PriceListPropertyType2["IsRetal"] = 38] = "IsRetal";
  PriceListPropertyType2[PriceListPropertyType2["IsMarketing"] = 39] = "IsMarketing";
  PriceListPropertyType2[PriceListPropertyType2["SuperviserType"] = 40] = "SuperviserType";
  PriceListPropertyType2[PriceListPropertyType2["Msrpcost"] = 41] = "Msrpcost";
  PriceListPropertyType2[PriceListPropertyType2["Fcacost"] = 42] = "Fcacost";
  PriceListPropertyType2[PriceListPropertyType2["DeletionDate"] = 43] = "DeletionDate";
  PriceListPropertyType2[PriceListPropertyType2["NumberOfDaysOfDeletion"] = 44] = "NumberOfDaysOfDeletion";
  return PriceListPropertyType2;
})(PriceListPropertyType || {});
var priceListColumnNameToPropertyType = {
  childCategoryName: 1 /* PriceListsCategoryId */,
  modelName: 2 /* ModelId */,
  partNumber: 3 /* PartNumber */,
  fcost: 4 /* Fcost */,
  ddpcost: 5 /* DdpCost */,
  sicost: 6 /* Sicost */,
  lastThirtyDaysCostChangesPositive: 7 /* LastThirtyDaysCostChangesPositive */,
  engName: 8 /* EngName */,
  name: 9 /* Name */,
  link: 10 /* Link */,
  isEol: 11 /* IsEol */,
  guaranteePeriod: 12 /* GuaranteePeriod */,
  changeDate: 13 /* ChangeDate */,
  isEac: 14 /* IsEac */,
  isEacbefore: 15 /* IsEacbefore */,
  isNotification: 16 /* IsNotification */,
  isNotificationBefore: 17 /* IsNotificationBefore */,
  lengthSu: 18 /* LengthSu */,
  widthSu: 19 /* WidthSu */,
  heightSu: 20 /* HeightSu */,
  weightSu: 21 /* WeightSu */,
  lengthSuone: 22 /* LengthSuone */,
  widthSuone: 23 /* WidthSuone */,
  heightSuone: 24 /* HeightSuone */,
  weightSuone: 25 /* WeightSuone */,
  lengthBu: 26 /* LengthBu */,
  widthBu: 27 /* WidthBu */,
  heightBu: 28 /* HeightBu */,
  nwbu: 29 /* Nwbu */,
  gwbu: 30 /* Gwbu */,
  base: 31 /* Base */,
  lengthCar: 32 /* LengthCar */,
  widthCar: 33 /* WidthCar */,
  heightCar: 34 /* HeightCar */,
  nwcar: 35 /* Nwcar */,
  gwcar: 36 /* Gwcar */,
  manufacturerCountry: 37 /* ManufacturerCountry */,
  isRetal: 38 /* IsRetal */,
  isMarketing: 39 /* IsMarketing */,
  superviserType: 40 /* SuperviserType */,
  msrpcost: 41 /* Msrpcost */,
  fcacost: 42 /* Fcacost */,
  deletionDate: 43 /* DeletionDate */,
  numberOfDaysOfDeletion: 44 /* NumberOfDaysOfDeletion */
};
export {
  PriceListPropertyType,
  priceListColumnNameToPropertyType
};
