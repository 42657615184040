import { commonApi } from "./commonApiService";
import { ResponseApi } from "@aten/common/models";
import { minSumType } from "../pages/Projects/hooks/useProjectCost";

export interface RoleToBudget {
    id?: number;
    projectTypeId: minSumType;
    minimumBudget: number;
}

type o = ResponseApi<RoleToBudget[]>;
export const RoleToBudgetApi = commonApi.injectEndpoints({
    endpoints: (build) => ({
        getCost: build.query<o, void>({
            query: () => ({
                url: "/RoleToBudget",
            }),
        }),
        edit: build.mutation({
            query: (body: RoleToBudget) => ({
                method: "PUT",
                url: `/RoleToBudget/${body.id}`,
                body,
            }),
        }),
        add: build.mutation({
            query: (body: RoleToBudget) => ({
                method: "POST",
                url: "/RoleToBudget",
                body,
            }),
        }),
    }),
});

export const RoleToBudgetExtendedApi = RoleToBudgetApi.injectEndpoints({
    endpoints: (build) => ({
        addCost: build.mutation<{}, RoleToBudget[]>({
            queryFn: async (data, { dispatch }) => {
                const s = await Promise.all(
                    data.map((el) => dispatch(RoleToBudgetApi.endpoints.add.initiate(el)).unwrap())
                );

                return { data: {} };
            },
        }),

        editCost: build.mutation<{}, RoleToBudget[] | undefined>({
            queryFn: async (data, { dispatch }) => {
                if (data) {
                    await Promise.all(data.map((el) => dispatch(RoleToBudgetApi.endpoints.edit.initiate(el)).unwrap()));
                }

                return { data: {} };
            },
        }),
    }),
});

export const { useGetCostQuery, useAddCostMutation, useEditCostMutation } = RoleToBudgetExtendedApi;
