import { ThemeTable } from "@aten/common/dist/models/Theme";
import { ColumnsType } from "antd/es/table";
import { Icons } from "components/Icons/Icons";
import { authUrl } from "config/path";
import { DeletingThemesProps } from "pages/Theme/Themes";
import { generatePath, Link } from "react-router-dom";

type ColumnsProps = {
    setDeletingTheme: (props: DeletingThemesProps) => void;
    openDelete: () => void;
};

const themesColumnsTable = (props: ColumnsProps): ColumnsType<ThemeTable> => [
    { width: "5%", title: "ID", key: "id", dataIndex: "id" },
    { title: "Название", key: "title", dataIndex: "title" },
    {
        title: "Описание темы",
        key: "description",
        dataIndex: "description",
        render: (_, data) => {
            return <div dangerouslySetInnerHTML={{ __html: data.description }} />;
        },
    },
    { title: "Число уроков", key: "lessonsNum", dataIndex: "lessonsNum" },
    { title: "Число тестов", key: "testsNum", dataIndex: "testsNum" },
    {
        width: "10%",
        title: "Действия",
        key: "action",
        dataIndex: "action",
        render: (_, data) => {
            return (
                <>
                    <div style={{ display: "flex" }}>
                        <div style={{ marginRight: 10 }}>
                            <Link
                                to={generatePath(authUrl.Index.Themes.Edit.url, {
                                    id: data.id?.toString(),
                                    info: data.id?.toString(),
                                })}>
                                <Icons.EditButton />
                            </Link>
                        </div>
                        <div>
                            <Icons.Delete
                                onClick={() => {
                                    props.setDeletingTheme({
                                        id: data.id?.toString() ?? "",
                                        title: data.title ?? "",
                                    });
                                    props.openDelete();
                                }}
                            />
                        </div>
                    </div>
                </>
            );
        },
    },
];

export default themesColumnsTable;
