import { UserLogin } from "@aten/common/dist/models/User";
import { Button, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import Loader from "components/Loader/Loader";
import Logo from "components/Logo/Logo";
import ErrorModal from "components/Modal/ErrorModal";
import { FC, useState } from "react";
import { useLoginMutation } from "services/authService";
import { required } from "utils/getRules";
import cl from "./Login.module.scss";

interface formData {
    email: string;
    password: string;
}

const Login: FC = () => {
    const [form] = useForm<formData>();

    const [servErr, setServErr] = useState({
        existence: false,
        text: "",
    });

    const [login, { isLoading, isError }] = useLoginMutation();

    const onFinish = async (values: formData) => {
        const credentials: UserLogin = { ...values, remember: true, returnUrl: "/" };
        await login(credentials)
            .unwrap()
            .catch((err) => {
                err.status === 500 && setServErr({ existence: true, text: err.data.Title });
                form.setFields([
                    {
                        name: "password",
                        errors: [err.data.error],
                    },
                ]);
            });
    };

    return (
        <div className={cl.login}>
            <Logo type="login" />
            {!isLoading ? (
                <div className={cl.login__form}>
                    <div className={cl.login__form_block}>
                        <div className={cl.login__form_title}>Логин</div>
                        <Form form={form} colon onFinish={onFinish}>
                            <Form.Item className={cl.login__form_input} name="email" rules={[required()]}>
                                <Input placeholder="Логин" />
                            </Form.Item>
                            <Form.Item name="password" rules={[required()]}>
                                <Input.Password placeholder="Пароль" />
                            </Form.Item>
                            <div className={cl.login__form_button}>
                                <Button onClick={form.submit} type="primary">
                                    Войти
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            ) : (
                <Loader />
            )}

            {servErr && (
                <ErrorModal
                    open={servErr.existence}
                    text={servErr.text}
                    onCancel={() => setServErr({ text: "", existence: false })}
                />
            )}
        </div>
    );
};

export default Login;
