import {Model} from "@aten/common/models/Model";
import {Modal, ModalProps, Row} from "antd";
import {Icons} from "components/Icons/Icons";
import Loader from "components/Loader/Loader";
import React, {FC, useMemo} from 'react';

import st from './ModalWithModels.module.scss'
import Tooltip from "../../../../Tooltip/Tooltip";
import {excelFileGenerator} from "../../../../../utils/excelFileGenerator";

interface ModalWithModelsProps extends ModalProps {
    initialModels?: Model[]
    addedModels?: Model[]
    baseModels?: Model[]
    baseModelsLoading?: boolean
}

const CreateStr = (arr?: Model[]) => {
    return <ul>{arr?.map(({modelName, quantity}, index) => <li key={index}>{modelName} - {quantity}</li>)}</ul>
}
export const ModalWithModels: FC<ModalWithModelsProps> = ({
                                                              initialModels,
                                                              baseModels,
                                                              baseModelsLoading,
                                                              addedModels,
                                                              ...props
                                                          }) => {

    const sortedList = useMemo(() => {
        return {
            new: baseModels?.reduce((acc: Model[], {modelName}) => {
                const overlap = addedModels?.find((mod) => mod.modelName === modelName)
                if (overlap) {
                    return [...acc, overlap]
                }
                return acc
            }, []),

            noInBase: addedModels?.filter(({modelName}) => !baseModels?.find((mod) => mod.modelName === modelName)) ?? [],
        }
    }, [initialModels, addedModels])

    const excelNonExistent = (data: Model[]) => {
        return data.map(({modelName, quantity}) => ({"Модель": modelName, "Количество": quantity}))
    }
    return (
        <Modal {...props} okText={'Добавить в список'} className={st.modal}
               okButtonProps={{disabled: baseModelsLoading || (!sortedList?.new?.length)}} forceRender
               destroyOnClose={true}>
            {baseModelsLoading ?
                <Loader/> :
                <>
                    <Row gutter={30} className={st.row}>
                        {!!sortedList.new?.length && <div>
                            <span className={st.text}>Новые модели:</span> <br/>
                            {CreateStr(sortedList.new)}
                        </div>}
                    </Row>

                    <Row gutter={30}>
                        {!!sortedList.noInBase?.length && <div className={st.nonExistent}>
                            <div>
                                <span className={st.text}>Этих моделей нет в базе:</span>
                                <Tooltip title={'Выгрузить несуществующие модели в excel'}>
                                    <Icons.Download onClick={() => excelFileGenerator({
                                        data: excelNonExistent(sortedList.noInBase),
                                        fileName: 'Несуществующие модели'
                                    })}/>
                                </Tooltip>
                            </div>
                            {CreateStr(sortedList.noInBase)}
                        </div>}
                    </Row>
                </>
            }
        </Modal>
    );
};

