import { Lesson, LessonResponse, LessonsTable } from "@aten/common/dist/models/Lesson";
import serializer, { Serializer } from "serializers/index";

const lessonsSerializer: Serializer<LessonResponse, Lesson, LessonsTable> = (lessons) => {
  return serializer<LessonResponse, Lesson, LessonsTable>(lessons, (les, i) => ({
    ...les,
    orderNumber: ++i,
    key: les.id,
  }));
};

export default lessonsSerializer;
