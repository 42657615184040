import { FC, ReactNode } from "react";
import c from "./LayoutMain.module.scss";

interface LayoutWrapperProps {
    children: ReactNode | ReactNode[];
}

const LayoutWrapper: FC<LayoutWrapperProps> = ({ children }) => {
    return <div className={c.layout_wrapper}>{children}</div>;
};

export default LayoutWrapper;
