import React from 'react';
import st from "../ModalWithModels/ModalWithModels.module.scss";
import {Modal, ModalProps} from "antd";

interface ModalWithWrongTypesProps extends ModalProps {
}
export const ModalWrongModelType = ({...props}: ModalWithWrongTypesProps) => {
    return (
        <Modal {...props} okText={'Выкачать Excel'} className={st.modal}
               forceRender
               destroyOnClose={true}>


            Тип моделей не соответствует типу проекта
        </Modal>
    );
};
