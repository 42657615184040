import { AdvantagesFormEdit } from "components/Form/AdvantagesFormEdit/AdvantagesFormEdit";
import useLayout from "hooks/useLayout";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useGetAdvantagesByIdQuery } from "services/advantagesService";

const AdvantagesEdit: FC = () => {
    const { id } = useParams();

    const { data, isLoading } = useGetAdvantagesByIdQuery(id ?? "", { skip: !id });

    useLayout(
        {
            title: data?.title ?? "Добавить преимущество",
            loading: isLoading,
        },
        [data]
    );

    return <AdvantagesFormEdit data={data} />;
};

export default AdvantagesEdit;
