import { CustomButton } from "components/CustomButton/CustomButton";
import { CustomTable } from "components/CustomTable/CustomTable";
import { Icons } from "components/Icons/Icons";
import LayoutWrapper from "components/Layouts/LayoutWrapper";
import ModalDelete from "components/Modal/ModalDelete";
import { authUrl } from "config/path";
import useFluid from "hooks/useFluid";
import useModal from "hooks/useModal";
import usePagination from "hooks/usePagination";
import offlineEventsColumnsTable from "pages/OfflineEvents/offlineEventsColumnsTable";
import OfflineEventsSearchModal from "pages/OfflineEvents/OfflineEventsSearchModal";
import cl from "pages/Theme/Themes.module.scss";
import React, { FC, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { eventsSerializer } from "serializers/event";
import { useDeleteEventMutation, useGetEventsQuery } from "services/eventsServise";
import { filterQueryString } from "utils/filterQueryString";

export type DeletingEventProps = {
    title: string;
    id: number;
};
const OfflineEvents: FC = () => {
    const navigate = useNavigate();
    const { userId } = useParams();
    const { pagination, changePagination } = usePagination();
    const { visible: searchModal, open: openSearchModal, close: closeSearchModal } = useModal();
    const { getFluidTableHeight } = useFluid();

    const [deleteModal, setDeleteModal] = useState(false);
    const [searchParams, setSearchParams] = useState<string>("");
    const [deletingEvent, setDeletingEvent] = useState<DeletingEventProps>({
        title: "",
        id: 0,
    });

    const { data, error, isLoading, isFetching } = useGetEventsQuery(
        {
            searchParams,
            pagination,
            userId,
        },
        { refetchOnMountOrArgChange: true }
    );
    const events = eventsSerializer(data);

    const [deleteEvent] = useDeleteEventMutation();

    const deleteCurrentEvent = () => {
        deleteEvent(deletingEvent.id.toString()).then(() => setDeleteModal(false));
    };

    return (
        <LayoutWrapper>
            <div className={cl.actions}>
                <CustomButton
                    onClick={() => navigate(generatePath(authUrl.Index.OfflineEvents.New.url))}
                    type="primary"
                    buttonText="Добавить мероприятие"
                    icon={<Icons.Plus />}
                />
                <CustomButton
                    wrapperClasses={cl.searchBtn}
                    type="primary"
                    onClick={openSearchModal}
                    icon={<Icons.Search />}
                />
            </div>
            <CustomTable
                columns={offlineEventsColumnsTable({
                    setDeleteModal,
                    setDeletingEvent,
                })}
                data={events}
                loading={isLoading || isFetching}
                pagination={{ ...pagination, total: data?.total, onChange: changePagination }}
                scroll={{ y: getFluidTableHeight(386, 414) }}
            />
            <OfflineEventsSearchModal
                visible={searchModal}
                onOk={(params) => {
                    setSearchParams(filterQueryString(params));
                    closeSearchModal();
                }}
                onCancel={closeSearchModal}
            />
            <ModalDelete
                open={deleteModal}
                onOk={deleteCurrentEvent}
                onCancel={() => setDeleteModal(false)}
                title={`Вы уверены, что хотите удалить мероприятие "${deletingEvent.title ?? ""}"`}
            />
        </LayoutWrapper>
    );
};

export default OfflineEvents;
